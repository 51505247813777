import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import DataService from "../services/data.service";
import { STATUSES } from "../common-status/constant";
import LocalStorageDataService from "../../components/common-function/GetLocalstorage";
const { REACT_APP_ENV } = process.env;
const user = LocalStorageDataService.userDetail()

var mappingArray = null
export const getProjectResourceMapping =
    async () => {
        const res = await DataService.project_resource_mapping_list_columns();
        const resp = res.data;
        mappingArray = resp.data.rows;
        return resp;
    }

export const projectByIdWithResources = createAsyncThunk(
    "get/projectByIdWithResources",
    async (id) => {
        const mappingRes = await getProjectResourceMapping()
        if (mappingRes.status) {
            const res = await DataService.project_by_id(id);
            const resp = res.data;
            return resp;
        }
    }
);

export const getProjectRoleMaster = createAsyncThunk(
    "get/projectRoleMaster",
    async (data) => {
        const res = await DataService.project_role_master(data);

        return res.data;
    }
)

// export const postCreateProjectRoleMaster = createAsyncThunk(
//     "post/projectRoleMaster",
//     async () => {
//         const res = await DataService.project_role_master_save();

//         return res.data;
//     }
// )


export const getDefaultsettingList = createAsyncThunk("defaultsetting/list", async (data) => {
    const res = await DataService.default_setting_list(data);
    return res.data;
});

export const defaultsettingCreate = createAsyncThunk("defaultsetting/create", async (data) => {
    const res = await DataService.default_setting_create(data);
    return res.data;
});

export const defaultsettingEdit = createAsyncThunk("defaultsetting/edit", async (id) => {
    const res = await DataService.default_setting_edit(id);
    return res.data;
});

export const defaultsettingUpdate = createAsyncThunk("defaultsetting/update", async (data) => {
    const res = await DataService.default_setting_update(data.ID, data.addvalues);
    return res.data;
});

export const defaultsettingArchive = createAsyncThunk("defaultsetting/archive", async (id) => {
    const res = await DataService.default_setting_archive(id);
    return res.data;
});

export const getProjectFilterList = createAsyncThunk("project-role/filter-list", async (filter) => {
    const res = await DataService.project_role_master_filter(filter);
    return res.data;
});

export const defaultallprojectColumn = createAsyncThunk("set-all-project-column", async (data) => {
    return data;
});

export const defaultMyallprojectColumn = createAsyncThunk("set-my-project-column", async (data) => {
    return data;
});

export const defaultshiftColumn = createAsyncThunk("set-shift-column", async (data) => {
    return data;
});

export const defaultassignshiftColumn = createAsyncThunk("set-assign-shift-column", async (data) => {
    return data;
});


const projectSlice = createSlice({
    name: "project",
    initialState: {

        project: {},
        project_status: STATUSES.IDLE,
        project_error: '',


        projects_roles: [],

        projects_res_mapping: [],
        projects_res_mapping_status: STATUSES.IDLE,
        projects_res_mapping_error: '',
        projects_res_mappings_count: 0,


        defaultsetting_List: [],
        defaultsetting_Create: [],
        defaultsetting_Edit: [],
        defaultsetting_Update: [],
        defaultsetting_Archive: [],

        projectRoleFilterList: [],
        projectRoleOption: [],

        resourceName: [],
        resourceIdarray:[],

        defaultAllProjectColumns:[],
        defaultMyAllProjectColumns:[],
        defaultshiftColumns:[],
        defaultassignshiftColumns:[],
    },
    reducers: {},
    extraReducers(builder) {
        builder
            //  PROJECT By ID with Resources
            .addCase(projectByIdWithResources.pending, (state, action) => {
                // getProjectResourceMapping();
                state.project_status = STATUSES.LOADING;
            })
            .addCase(projectByIdWithResources.fulfilled, (state, action) => {
                state.project_status = !action.payload.data ? STATUSES.FAILURE : STATUSES.SUCCESS;
                state.project_error = !action.payload.data ? false : true;
                const data = action.payload.data;
                state.project = !data.id ? {} :
                    {
                        ...data,
                        is_jira_project_text: data.is_jira_project ? "Yes" : "No",
                        status_text: data.status ? "Active" : "Inactive",
                        status_value: data.status ? "True" : "False",
                        project_type_name: data.project_type.project_type_name,
                        is_jira_project: data.is_jira_project === true ? true : false,
                        status: data.status === true ? "Active" : "Inactive",
                        is_private: data.is_private === true ? "Yes" : "No",
                        resource: null,
                        // projectsResources: mappingArray.length && mappingArray.map((element) => { return element.project.id === data.id && element.resource }).filter(e => e !== false),
                        // projectsResourcesNames: mappingArray.length && mappingArray.map((element) => { return element.project.id === data.id && element.resource.name }).filter(e => e !== false),

                    };

                let resourceNameArr = [];
                // eslint-disable-next-line
                mappingArray && mappingArray.length && mappingArray.map((element) => {
                    if (element.project__id === data.id) {
                        return resourceNameArr.push({
                            name: element.resource__name
                        })
                    }
                }).filter(e => e !== false);
                state.resourceName = resourceNameArr && resourceNameArr.length > 0 ? resourceNameArr : []
                
                let resourceIdArr = [];
                 mappingArray && mappingArray.length && mappingArray.map((element) => {
                    if (element.project__id === data.id) {
                        return resourceIdArr.push({
                            name: element.resource__name,
                            id: element.resource__id
                        })
                    }
                }).filter(e => e !== false);
                state.resourceIdarray = resourceIdArr && resourceIdArr.length > 0 ? resourceIdArr : []
            })
            .addCase(projectByIdWithResources.rejected, (state, action) => {
                state.project_status = STATUSES.FAILURE;
                state.project_error = action.error
            })


            // getProjectRole
            .addCase(getProjectRoleMaster.pending, (state, action) => {
                state.project_status = STATUSES.LOADING;
            })
            .addCase(getProjectRoleMaster.fulfilled, (state, action) => {
                state.project_status = !action.payload.data ? STATUSES.FAILURE : STATUSES.SUCCESS;
                state.project_error = !action.payload.data ? false : true;
                state.projects_roles = action.payload.data
            })
            .addCase(getProjectRoleMaster.rejected, (state, action) => {
                state.project_status = STATUSES.FAILURE;
                state.project_error = action.error
            })


            /// project default setting store ////
            /// get project default setting list
            .addCase(getDefaultsettingList.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(getDefaultsettingList.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                // state.defaultsetting_List = action.payload;
                let newArray = [];
                let newArray2 = []
                let result = action.payload;
                let res = result && result.data && result.data.rows
                let count = result && result.data && result.data.count
                res.map(e => {
                    return newArray.push({
                        ...e,
                        project_type_id: e.project_type_id,
                        project_type: e.project_type && e.project_type.project_type_name,
                        department_id: e.department_id,
                        department: e.department && e.department.name,
                        domain_id: e.domain_id,
                        domain: e.domain && e.domain.name,
                        workflow_id: e.workflow_id,
                        workflow: e.workflow && e.workflow.name,
                    })
                })
                if (newArray.length > 0) {
                    newArray2 = { "data": { "rows": newArray, "count": count } }
                }
                state.defaultsetting_List = newArray2 && newArray2.data ? newArray2 : action.payload;
            })
            .addCase(getDefaultsettingList.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })
            ///project default setting create
            .addCase(defaultsettingCreate.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(defaultsettingCreate.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_defaultsetting_Create`;
                state.defaultsetting_Create = action.payload;
            })
            .addCase(defaultsettingCreate.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ///project default setting edit
            .addCase(defaultsettingEdit.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(defaultsettingEdit.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.defaultsetting_Edit = action.payload;
            })
            .addCase(defaultsettingEdit.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ///project default setting Update
            .addCase(defaultsettingUpdate.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(defaultsettingUpdate.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_defaultsetting_Update`;
                state.defaultsetting_Update = action.payload;
            })
            .addCase(defaultsettingUpdate.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ///project default setting archive
            .addCase(defaultsettingArchive.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(defaultsettingArchive.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_defaultsetting_Archive`;
                state.defaultsetting_Archive = action.payload;
            })
            .addCase(defaultsettingArchive.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ///project role list
            .addCase(getProjectFilterList.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(getProjectFilterList.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_projectRoleFilterList`;
                state.projectRoleFilterList = action.payload;
                state.projectRoleOption = !action.payload.data.rows ? [] : action.payload.data.rows.map((e) => ({ ...e, label: e.role_name, value: e.id }));

            })
            .addCase(getProjectFilterList.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })
            .addCase(defaultallprojectColumn.fulfilled, (state, action) => {
                localStorage.setItem(`${REACT_APP_ENV}_${user.id}_allproject_column`, JSON.stringify(action.payload))
                state.defaultAllProjectColumns = action.payload
            }) 
            .addCase(defaultMyallprojectColumn.fulfilled, (state, action) => {
                localStorage.setItem(`${REACT_APP_ENV}_${user.id}_my_project_column`, JSON.stringify(action.payload))
                state.defaultMyAllProjectColumns = action.payload
            }) 
            // default shift column added here bcz no slice comp is created for shiftslices
            .addCase(defaultshiftColumn.fulfilled, (state, action) => {
                localStorage.setItem(`${REACT_APP_ENV}_${user.id}_shift_column`, JSON.stringify(action.payload))
                state.defaultshiftColumns = action.payload
            }) 
            .addCase(defaultassignshiftColumn.fulfilled, (state, action) => {
                localStorage.setItem(`${REACT_APP_ENV}_${user.id}_assign_shift_column`, JSON.stringify(action.payload))
                state.defaultassignshiftColumns = action.payload
            }) 
    },
});


export default projectSlice.reducer;