import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Autocomplete, Box, TextField } from '@mui/material';
import { Avatar } from 'rsuite';

function stringAvatar(name) {
  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children: firstLetterWord(name),
  };
}
function stringToColor(string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

}
function firstLetterWord(str) {
  let result = "";

  // Traverse the string.
  let v = true;
  for (let i = 0; i < str.length; i++) {
    // If it is space, set v as true.
    if (str[i] === ' ') {
      v = true;
    }

    // Else check if v is true or not.
    // If true, copy character in output
    // string and set v as false.
    else if (str[i] !== ' ' && v === true) {
      result += (str[i]);
      v = false;
    }
  }
  return result;
}

export default function BasicMenu({ selectedMember, assigneeFilter }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };



  return (
    <div>
      <Button
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <KeyboardArrowDownIcon />
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={() => assigneeFilter("all")}>All

        </MenuItem>
        <MenuItem>
          <Autocomplete size="small"
            // disabled={formValues && formValues.assignee_id === LocalStorageDataService.userDetail().id ? false : (formValues && !formValues.assignee_id && disabledAssigne() === true) ? false : true}/
            className="custom-select-name"
            sx={{ width: 300 }}
            autoHighlight
            // value={assignee_name_default}
            renderOption={(props, option) => (
              <Box key={props.id} component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                <span className="name-and-short">
                  <Avatar style={{ marginRight: "10px", textTransform: "uppercase", height: "30px", width: "30px", fontSize: "16px" }} {...stringAvatar(option.label)} />{"   "} <span className="short-cut-full-name" style={{ fontSize: "16px", textTransform: "capitalize", }}>{option.label}</span>
                </span>
              </Box>
            )}
            onChange={(event, newValue) => {
              // changeStatus(newValue);
              assigneeFilter(newValue)
            }}
            options={
              selectedMember && selectedMember.map((option) => {
                return { key: option.value, value: option.value, label: option.label, name: "assignee" }
              })

            }
            renderInput={(params) => <TextField {...params} label={"Select Assignee"} />}
          />

        </MenuItem>
      </Menu>
    </div>
  );
}