import { AccessTime, AddCircleOutline, CalendarMonth, Close, RemoveCircleOutline } from '@mui/icons-material'
import { Button, Dialog, DialogContent, DialogTitle, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Tooltip } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import GetLocalstorage from '../../../components/common-function/GetLocalstorage';
import { InputFields } from '../../../components/common-function/InputFields';
import { STATUSES } from '../../../redux/common-status/constant';
import { toast } from "react-toastify";
import './screeningForm.scss'

import { getAllScreeningFormList, getScreeningFormList, jobScreeningForm, jobScreeningFormUpdate, screeningFormCreate, screeningFormEdit, screeningFormUpdate, storyScreeningFormAnswerUpdate, storyScreeningFormCreate } from '../../../redux/features/candidateScreeningFormSlice';
import { getJobPostList } from '../../../redux/features/jobPostSlice';
import { CKEditor } from 'ckeditor4-react';
import { getCommentId } from '../../../redux/features/taskSlice';
import LoaderForm from '../../../components/common-function/LoaderForm';
import CryptoJS from 'crypto-js';
import moment from 'moment';
const { REACT_APP_ENCRPTION_KEY_FOR_UI, REACT_APP_ENCRPTION_KEY_FOR_IV } = process.env;
const user = GetLocalstorage.userDetail();
const userId = user && user.id

const formErrors = Object.freeze({
    titleErr: 'Title is required',
    statusErr: 'Status is required',
    defaultFormErr: "Default form screening is required"
});
const initialFormData = Object.freeze({
    form_data: [],
    title: "",
    status: "",
});

const initialJobScreeningForm = Object.freeze({
    form_data: [],
    default_screening_form: "",
});


export default function DefaultScreeningForm({ title, closeModal, editDefaultFormData, setEditDefaultFormData, filterQuery,
    jobId, editJobScreening,
    editStoryScreeningData, storyData, questionData, openScreeningSection, screeningCommentData, setScreeningCommentData,
    setQuetionsData, storyId, screeningId, setScreeningId, otherQuestion, setOtherQuestion, loadingScreeningData, defaultQuestion }) {
    const dispatch = useDispatch();

    const [open, setOpen] = useState(true);
    const [formValues, setFormValues] = useState(initialFormData)

    const [formErr] = useState(formErrors)
    const [errStatus, setErrStatus] = useState(false);
    const [reload, setReload] = useState(false)
    const [ID, setID] = useState("");
    const [errorForEmptyQuestion, setErrorForEmptyQyestion] = useState(false);
    const [questionArray, setQuestionArray] = useState([{ screening_question: "", screening_answer: "" }])
    const [defaultFormId, setDefaultFormId] = useState(0)
    const { status, defaultScreeningForm_dropDown, screeningForm_Create, screeningForm_Update, screeningForm_Edit,
        jobScreeningForm_Create, jobScreeningForm_Update,
        storyScreeningForm_Create, storyScreeningFormAnswer_Update, error } = useSelector(state => state.candidateScreeningForm)

    const [formValuesJobScreening, setFormValuesJobScreening] = useState(initialJobScreeningForm)
    const [viewformData, setViewformData] = useState([])
    const [selectedValueDefault, setSelectedValueDefault] = useState({})

    // const [formValuesStory,] = useState(initialStoryScreeningForm)
    const [comment, setComment] = useState('');
    const [commentActive, setCommentActive] = useState(false)
    const [showCandidateForm, setShowCandidateForm] = useState(openScreeningSection === true ? true : false)

    useEffect(() => {
        if (status === STATUSES.FAILURE && error) {
            toast.error(error.message)
            setReload(false)
        }
        // eslint-disable-next-line
    }, [error])

    useEffect(() => {
        // default screening edit 
        if (editDefaultFormData && editDefaultFormData.id) {
            setFormValues(editDefaultFormData);
            setID(editDefaultFormData.id)
            editDefaultFormData.form_data && editDefaultFormData.form_data.length > 0 && setQuestionArray(editDefaultFormData.form_data)
            // } else {
            //     if (defaultQuestion?.length > 0) {
            //         setQuestionArray(defaultQuestion)
            //     }
            // }
        }


        // jobscreening screening edit 
        if (editJobScreening && editJobScreening.id) {
            setFormValuesJobScreening({
                default_screening_form: editJobScreening.default_screening_form_id,
            })
            setSelectedValueDefault({ 'value': editJobScreening.default_screening_form_id, 'label': editJobScreening.default_screening_form && editJobScreening.default_screening_form.title })
            setID(editJobScreening.id)
            // setJobId(editJobScreening.job_id)
            editJobScreening.form_data && editJobScreening.form_data.length > 0 && setQuestionArray(editJobScreening.form_data)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const decryptMessage = (Base64CBC) => {
        var iv = CryptoJS.enc.Utf8.parse(REACT_APP_ENCRPTION_KEY_FOR_IV);
        var key = REACT_APP_ENCRPTION_KEY_FOR_UI;
        key = CryptoJS.enc.Utf8.parse(key);
        var decrypted = CryptoJS.AES.decrypt(Base64CBC, key, { iv: iv, mode: CryptoJS.mode.CBC });
        decrypted = decrypted.toString(CryptoJS.enc.Utf8);
        return decrypted;
    };
    const encryptMessage = (message) => {
        var key = REACT_APP_ENCRPTION_KEY_FOR_UI;
        key = CryptoJS.enc.Utf8.parse(key);
        var iv = CryptoJS.enc.Utf8.parse(REACT_APP_ENCRPTION_KEY_FOR_IV)
        var encrypted = CryptoJS.AES.encrypt(message, key, { iv: iv, mode: CryptoJS.mode.CBC });
        encrypted = encrypted.toString();
        return encrypted;
    };

    // start common section
    const handleClose = () => {
        setOpen(false);
        closeModal(false)
        setQuestionArray([])
        //default
        setFormValues(initialFormData);
        editDefaultFormData && editDefaultFormData.id && setEditDefaultFormData({})

        //job post
        setFormValuesJobScreening(initialJobScreeningForm)
        setID(0)
        screeningId && setScreeningId("")
    }

    const addMoreQuestions = () => {
        setErrorForEmptyQyestion(false);
        if (questionArray.length > 0) {
            setQuestionArray([...questionArray, { screening_question: "", screening_answer: "" }])
        } else {
            setQuestionArray([{ screening_question: "", screening_answer: "" }])
        }
    }

    const removeQuestions = (i) => {
        setErrorForEmptyQyestion(false);
        const updateformData = questionArray.filter((item, index) => index !== i);
        setQuestionArray(updateformData)
    }
    // end common section

    // start default form section
    const handleQuestonChange = (evt, index = null) => {
        setErrorForEmptyQyestion(false);
        const { name, value } = evt.target || evt;

        const valueArray = questionArray && questionArray.length > 0 && questionArray.map((item, i) => i === index ?

            { ...item, [name]: value } : item)
        index !== null && setQuestionArray([...valueArray])
    }

    const handleChange = (evt) => {
        const { name, value } = evt.target || evt;
        setFormValues({ ...formValues, [name]: value })
        setFormValuesJobScreening({ ...formValuesJobScreening, [name]: value })
    }

    // default screening form update
    if (reload === 'submit' && status === `${STATUSES.SUCCESS}_screeningForm_Update` && screeningForm_Update && screeningForm_Update.message) {
        if (screeningForm_Update.status === 200) {
            toast.success(screeningForm_Update.message)
            dispatch(getScreeningFormList(filterQuery))
            handleClose()
        } else {
            toast.error(screeningForm_Update.message)
        }
        setReload(false)
    }

    // default screening form create
    if (reload === 'submit' && status === `${STATUSES.SUCCESS}_screeningForm_Create` && screeningForm_Create && screeningForm_Create.message) {
        if (screeningForm_Create.status === 200) {
            toast.success(screeningForm_Create.message)
            dispatch(getScreeningFormList(filterQuery))
            handleClose()
        } else {
            toast.error(screeningForm_Create.message)
        }
        setReload(false)
    }

    const defaultFormSubmitData = (e) => {
        e.preventDefault()
        if (formValues.title === "" || formValues.status === "") {
            setErrStatus(true)
        }
        let emptyQuestionArray = [];
        emptyQuestionArray = questionArray.filter((item) => item.screening_question === "")
        if (formValues.title !== "" && formValues.status !== "") {
            if (emptyQuestionArray.length === 0) {
                let addvalues = { ...formValues }
                if (ID) {
                    addvalues['form_data'] = [...questionArray]
                } else {
                    addvalues['form_data'] = [...defaultQuestion, ...questionArray]
                }
                // addvalues['form_data'] = [...defaultQuestion,...questionArray]
                setErrStatus(false)
                setReload('submit')

                if (ID) {
                    addvalues['updated_by'] = userId
                    dispatch(screeningFormUpdate({ ID, addvalues }))
                } else {
                    addvalues['created_by'] = userId
                    dispatch(screeningFormCreate(addvalues))
                }
            } else {
                setErrorForEmptyQyestion(true);
                // toast.error("Questions Can not be empty!")
            }
        }
    }
    // end default form section

    // start jobpost form section
    useEffect(() => {
        title === 'jobpost' && dispatch(getAllScreeningFormList())
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const changeDefaultScreeningForm = (evt) => {
        if (evt) {
            const { value } = evt.target || evt;
            setDefaultFormId(value)
            setReload('default_data_get')
            setFormValuesJobScreening({ ...formValuesJobScreening, 'default_screening_form': value })
            dispatch(screeningFormEdit(value))
        } else {
            setDefaultFormId(0)
        }
    }
    // job screening form view question
    if (reload === 'default_data_get' && status === `${STATUSES.SUCCESS}` && screeningForm_Edit && screeningForm_Edit.data) {
        let editData = screeningForm_Edit.data
        editData.form_data && setViewformData(editData.form_data)
        setReload(false)
    }

    // job screening form create
    if (reload === 'submit' && status === `${STATUSES.SUCCESS}_jobScreeningForm_Create` && jobScreeningForm_Create && jobScreeningForm_Create.message) {
        if (jobScreeningForm_Create.status === 200) {
            toast.success(jobScreeningForm_Create.message)
            handleClose()
        } else {
            toast.error(jobScreeningForm_Create.message)
        }
        setReload(false)
    }

    //job screening form update
    if (reload === 'submit' && status === `${STATUSES.SUCCESS}_jobScreeningForm_Update` && jobScreeningForm_Update && jobScreeningForm_Update.message) {
        if (jobScreeningForm_Update.status === 200) {
            toast.success(jobScreeningForm_Update.message)
            dispatch(getJobPostList(filterQuery))
            handleClose()
        } else {
            toast.error(jobScreeningForm_Update.message)
        }
        setReload(false)
    }

    const jobPostSubmitData = (e) => {
        e.preventDefault()
        if (formValuesJobScreening.default_screening_form === "") {
            setErrStatus(true)
        }
        let emptyQuestionArray = [];
        emptyQuestionArray = questionArray.filter((item) => item.screening_question === "")
        if (emptyQuestionArray.length === 0) {
            if (formValuesJobScreening.default_screening_form !== "") {
                setErrorForEmptyQyestion(false);
                let addvalues = { ...formValuesJobScreening }
                addvalues['form_data'] = questionArray.concat(viewformData)
                addvalues['job'] = jobId

                setErrStatus(false)
                setReload('submit')

                if (ID) {
                    addvalues['updated_by'] = userId
                    dispatch(jobScreeningFormUpdate({ ID, addvalues }))
                } else {
                    addvalues['created_by'] = userId
                    dispatch(jobScreeningForm(addvalues))
                }
            }
        } else {
            setErrorForEmptyQyestion(true);
            // toast.error("Questions Can not be empty!")
        }
    }
    // end jobpost form section

    // start story form section
    const handleAnswerChange = (evt, index = null) => {
        const { name, value } = evt.target || evt;
        if (title === 'story') {
            const answrerArray = questionData && questionData.length > 0 && questionData.map((item, i) => i === index ?
                { ...item, [name]: value } : item)
            index !== null && setQuetionsData([...answrerArray])
        }
    }
    const { callDetailById } = useSelector((state) => state.task);

    const inputHandler = (evt) => {
        var newContent = evt.editor.getData();
        setComment(newContent)
    }

    // story screening form create
    if (reload === 'submit' && status === `${STATUSES.SUCCESS}_storyScreeningForm_Create` && storyScreeningForm_Create && storyScreeningForm_Create.message) {
        if (storyScreeningForm_Create.status === 200) {
            toast.success(storyScreeningForm_Create.message)
            setScreeningId(storyScreeningForm_Create.data.id)
            // setComment(storyScreeningForm_Create.data.comment)
            setComment(" ")
            dispatch(getCommentId(storyScreeningForm_Create.data.story));

            let quesArray = []
            let questionArrayAdd = []

            questionData && questionData.length > 0 && questionData.map(e => {
                return quesArray.push({
                    screening_question: e.screening_question,
                    screening_answer: e.screening_answer ? e.screening_answer : "NA",
                    question_key: e?.question_key ?? undefined,

                    disabled: true
                })
            })
            setQuetionsData(quesArray)
            questionArray && questionArray.length > 0 && questionArray.map(e => {

                if (e.screening_question) {
                    questionArrayAdd.push({
                        screening_question: e.screening_question,
                        screening_answer: e.screening_answer ? encryptMessage(e.screening_answer) : encryptMessage('NA'),
                        question_key: e?.question_key ?? undefined,

                        disabled: true
                    })
                }
                return questionArrayAdd
            })
            setQuetionsData(quesArray.concat(questionArrayAdd))
            setQuestionArray([])
        } else {
            toast.error(storyScreeningForm_Create.message)
        }
        setReload(false)
    }

    // story screening form screening answer update 
    if (reload === 'submit' && status === `${STATUSES.SUCCESS}_storyScreeningFormAnswer_Update` && storyScreeningFormAnswer_Update && storyScreeningFormAnswer_Update.message) {
        if (storyScreeningFormAnswer_Update.status === 200) {
            toast.success(storyScreeningFormAnswer_Update.message)
            let quesArray = []
            let questionArrayAdd = []
            questionData && questionData.length > 0 && questionData.map(e => {
                return quesArray.push({
                    screening_question: e.screening_question,
                    screening_answer: e.screening_answer ? e.screening_answer : "",
                    question_key: e?.question_key ?? undefined,

                    disabled: e.screening_answer ? true : false
                })
            })

            questionArray && questionArray.length > 0 && questionArray.map(e => {

                if (e.screening_question) {
                    questionArrayAdd.push({
                        screening_question: e.screening_question,
                        screening_answer: e.screening_answer ? e.screening_answer : 'NA',
                        question_key: e?.question_key ?? undefined,

                        disabled: e.screening_answer ? true : false
                    })
                }
                return questionArrayAdd
            })
            setQuetionsData(quesArray.concat(questionArrayAdd))
            setQuestionArray([])
        } else {
            toast.error(storyScreeningFormAnswer_Update.message)
        }
        setReload(false)
    }

    const storyScreningSubmitData = (e) => {
        e.preventDefault()
        setCommentActive(false);
        const callDetailData = callDetailById && callDetailById.data && callDetailById.data.rows[0];
        // let data = editStoryScreeningData && editStoryScreeningData;
        let quesArray = []
        let questionArrayAdd = []
        // let flag1 = false
        // let flag2 = false

        questionData && questionData.length > 0 && questionData.map(e => {
            // if (e.screening_answer.trim() === "") {
            //     flag1 = true
            // }
            return quesArray.push({
                screening_question: e.screening_question,
                screening_answer: e.screening_answer.trim() === "" ? encryptMessage('NA') : encryptMessage(e.screening_answer),
                question_key: e.question_key,
            })
        })
        questionArray && questionArray.length > 0 && questionArray.map(e => {

            // if (e.screening_answer.trim() === "") {
            //     flag2 = true
            // }
            if (e.screening_question) {
                questionArrayAdd.push({
                    screening_question: e.screening_question,
                    screening_answer: e.screening_answer.trim() === "" ? encryptMessage('NA') : encryptMessage(e.screening_answer),
                    question_key: e?.question_key ?? undefined,

                })
            }
            return questionArrayAdd
        })

        let candidate_id = storyData && storyData.candidate_info && storyData.candidate_info[0].id
        let addvalues = {
            // ...formValuesStory,
            job: storyData.epic_details && storyData.epic_details.job_id,
            epic: storyData.epic_details && storyData.epic_details.id,
            project: storyData.epic_details && storyData.epic_details.project_id,
            story: storyId,
            candidate: candidate_id,
            tts_call_details: callDetailData && callDetailData.id,
            call_date: callDetailData && callDetailData.call_date,
            call_time: callDetailData && callDetailData.call_time,
            resource: userId,
            comment: comment,
            status: true,
            created_by: userId,
            story_screening_form_data: quesArray.concat(questionArrayAdd)
        }

        let emptyQuestionArray = [];
        emptyQuestionArray = questionArray.filter((item) => item.screening_question === "")
        if (emptyQuestionArray.length === 0) {
            screeningCommentData.push({
                comment: comment,
                disabled: true,
                date: moment().format('DD-MM-YYYY'),
                time: moment().format('HH:mm:ss')
            })
            if (screeningId) {
                addvalues['story_screening_form_data'] = questionArrayAdd
            }
            setReload('submit')
            let dataUpdate

            // if (flag1 === true || flag2 === true) {
            //     return toast.error("Answers Can not be empty! ")
            // }
            // eslint-disable-next-line
            questionData && questionData.map((item) => {
                if (item.screening_answer && item.disabled === false) {
                    dataUpdate = {
                        screening_answer: item.screening_answer.trim() === "" ? encryptMessage('NA') : encryptMessage(item.screening_answer),
                        screening_question: item.screening_question,
                        question_key: item.question_key,
                        id: item.id,
                    }
                    dispatch(storyScreeningFormAnswerUpdate(dataUpdate))
                }
            })
            dispatch(storyScreeningFormCreate(addvalues))
        } else {
            toast.error("Questions Can not be empty!")
        }
    }
    // end story form section

    useEffect(() => {
        if (otherQuestion === true) {
            setOtherQuestion(false)
            setComment("")
            setQuestionArray([])
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [otherQuestion])

    return (
        <div>
            {title === 'story' ?
                <StoryCandidateScreeningForm
                    decrept={decryptMessage}
                    addMoreQuestions={addMoreQuestions}
                    removeQuestions={removeQuestions}
                    questionArray={questionArray}
                    submitData={storyScreningSubmitData}
                    inputHandler={inputHandler}
                    handleQuestonChange={handleQuestonChange}
                    viewformData={questionData}
                    handleAnswerChange={handleAnswerChange}
                    screeningId={screeningId}
                    comment={comment}
                    commentActive={commentActive}
                    setCommentActive={setCommentActive}
                    showCandidateForm={showCandidateForm}
                    setShowCandidateForm={setShowCandidateForm}
                    loadingScreeningData={loadingScreeningData}
                    screeningCommentData={screeningCommentData}
                /> :
                <Dialog className="candidate-screening-modal-box"
                    hideBackdrop
                    fullWidth
                    maxWidth={"md"}
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="child-modal-title"
                    aria-describedby="child-modal-description"
                >
                    <Button
                        edge="start"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                        style={{ position: "absolute", top: "10px", right: "10px" }}>
                        <Close />
                    </Button>
                    <DialogTitle style={{ background: '#2196f3', color: '#fff', marginBottom: "20px" }}>Candidate Screening Questions Set List</DialogTitle>

                    <DialogContent style={{ maxHeight: 'calc(100vh - 148px)' }}>
                        {title === 'jobpost' &&
                            <JobPostCandidateScreeningForm
                                addMoreQuestions={addMoreQuestions}
                                removeQuestions={removeQuestions}
                                questionArray={questionArray}
                                submitData={jobPostSubmitData}
                                defaultScreeningForm_dropDown={defaultScreeningForm_dropDown}
                                changeDefaultScreeningForm={changeDefaultScreeningForm}
                                defaultFormId={defaultFormId}
                                formValues={formValuesJobScreening}
                                errStatus={errStatus}
                                formErr={formErr}
                                handleQuestonChange={handleQuestonChange}
                                viewformData={viewformData}
                                selectedValueDefault={selectedValueDefault}
                                ID={ID}
                                errorForEmptyQuestion={errorForEmptyQuestion}
                            />}

                        {title === 'defaultform' && <DefaultForm
                            addMoreQuestions={addMoreQuestions}
                            removeQuestions={removeQuestions}
                            questionArray={questionArray}
                            handleChange={handleChange}
                            submitData={defaultFormSubmitData}
                            formValues={formValues}
                            errStatus={errStatus}
                            formErr={formErr}
                            handleQuestonChange={handleQuestonChange}
                            errorForEmptyQuestion={errorForEmptyQuestion}
                            defaultQuestion={defaultQuestion}
                        />}

                    </DialogContent>
                </Dialog>}
        </div>
    )
}

export const ApproverForm1 = ({ approverList, handleClose, handleChange, submitData, open, fieldType }) => {
    return (
        <Dialog className="candidate-screening-modal-box"
            hideBackdrop
            fullWidth
            maxWidth={"md"}
            open={open}
            onClose={handleClose}
            aria-labelledby="child-modal-title"
            aria-describedby="child-modal-description"
        >
            <Button
                edge="start"
                color="inherit"
                onClick={handleClose}
                aria-label="close"
                style={{ position: "absolute", top: "10px", right: "10px" }}>
                <Close />
            </Button>

            <DialogTitle style={{ background: '#2196f3', color: '#fff', marginBottom: "20px" }}>
                {fieldType === 'rejected' ? 'Rejected' :
                    fieldType === 'assign-approver' ? 'Assign ' :
                        fieldType === 's' ? 'Start' :
                            'Reschedule'}</DialogTitle>
            <form style={{ margin: "20px 20px 20px 20px" }}>
                <div className="add-new-user-form">
                    {fieldType === 'assign-approver' &&
                        <div className='formInput' style={{ flexBasis: "100%" }}>
                            <label>Select Job Approver </label>
                            <Select
                                className="select"
                                placeholder="Select Approver"
                                isSearchable={true}
                                onChange={handleChange}
                                name="" approver
                                options={Array.isArray(approverList) ? approverList.map(item => ({ label: item.display_name, value: item.id, name: "approver" })) : []}
                            />
                        </div>
                    }
                    <div className='formInput' style={{ flexBasis: "100%" }}>
                        <label>Comment </label>
                        <textarea
                            className="select"
                            placeholder="Comment"
                            onChange={handleChange}
                            name="approver_comment"
                        />
                    </div>

                    <div className="formInput">
                        <button className="btn btn-submit" onClick={submitData}>Submit</button>
                    </div>
                </div>
                <br />
                <br />
            </form>
        </Dialog>
        // </div>
        // </div>
    )
}

// Assign Approver 

// default screening form
export const DefaultForm = ({ addMoreQuestions, removeQuestions, questionArray, handleChange, submitData,
    formValues, errStatus, formErr, handleQuestonChange, errorForEmptyQuestion, defaultQuestion }) => {
    return <>
        <form>
            <div className="add-new-user-form-default">
                <InputFields
                    label={"Title"}
                    name="title"
                    defaultValue={formValues.title}
                    type="text"
                    placeholder="Title"
                    onChange={handleChange}
                    errStatus={errStatus}
                    formErr={formErr.titleErr}
                />
                <div className="formInput" style={{ flexBasis: '30%' }}>
                    <FormControl>
                        <FormLabel id="demo-row-radio-buttons-group-label">Status</FormLabel>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="status"
                            value={formValues.status}
                            onChange={(e) => { handleChange(e) }}
                        >
                            <FormControlLabel value={true} control={<Radio />} label="Active" />
                            <FormControlLabel value={false} control={<Radio />} label="Inactive" />
                        </RadioGroup>

                    </FormControl>
                    {errStatus === true && formValues && formValues.status === "" && <p style={{ color: "#96332c", fontSize: 13 }}>{formErr.statusErr}</p>}

                </div>
            </div>

            <div className="add-new-user-form-default">
                <div className="formInput">
                    <div style={{ display: "flex" }}>
                        <h5>Questions</h5>
                        <Tooltip title="Add New Questions">
                            <AddCircleOutline onClick={() => addMoreQuestions()} style={{ marginLeft: "5px", color: "green", cursor: "pointer" }} />
                        </Tooltip>
                    </div>
                </div>
            </div>

            <div className="add-new-user-form-default">
                {
                    defaultQuestion.length > 0 &&
                    <div className="formInput">
                        <h5 style={{ marginBottom: '15px' }}>Default Screening Questions</h5>
                        {
                            defaultQuestion && defaultQuestion.map((item, i) => {
                                return (
                                    <p style={{ fontSize: "16px" }}>
                                        <strong>Question {i + 1}. &nbsp;</strong>
                                        <p key={i}>{item.screening_question}</p>
                                    </p>
                                )
                            })
                        }
                    </div>
                }
            </div>

            {
                questionArray.map((item, i) => {

                    return (
                        <>
                            {item?.question_key === undefined &&
                                <div className="add-new-user-form-default" style={{ width: "100%", alignItems: "center" }} key={i}>
                                    <div className="formInput">
                                        <label> Question </label>
                                        <div style={{ display: "flex" }}>
                                            <input
                                                name={"screening_question"}
                                                onChange={(e) => handleQuestonChange(e, i)}
                                                type="text"
                                                placeholder="Question"
                                                value={item.screening_question}
                                            />
                                        </div>
                                    </div>
                                    {i !== 0 ?
                                        <div style={{ flexBasis: '30%', marginTop: '4%' }}>
                                            <Tooltip title="Remove">
                                                <RemoveCircleOutline style={{ color: "red", cursor: "pointer" }} onClick={() => removeQuestions(i)} />
                                            </Tooltip>
                                        </div>
                                        : ""}
                                </div>
                            }</>
                    )
                })
            }
            {errorForEmptyQuestion && <div style={{ color: "#96332c" }}>Questions Can not be empty! </div>}
            <div className="formInput" style={{ flexBasis: "100%" }}>
                <button className="btn btn-submit" style={{ width: "200px" }} onClick={submitData}>Submit</button>
            </div>
        </form>
    </>
}


// screening form for jobpost page h
export const JobPostCandidateScreeningForm = ({ addMoreQuestions, removeQuestions, questionArray, errorForEmptyQuestion,
    submitData, defaultScreeningForm_dropDown, changeDefaultScreeningForm, defaultFormId,
    formValues, errStatus, formErr, handleQuestonChange, viewformData, selectedValueDefault, ID
}) => {
    return <>
        <form>
            {/* select default screening  section */}
            <div className='add-new-user-form-default'>
                <div className="formInput">
                    <label> Select Default Screening Questions</label>
                    {
                        ID ?
                            <input
                                name={"default_screening_form"}
                                type="text"
                                disabled
                                placeholder="Question"
                                value={selectedValueDefault && selectedValueDefault.label}
                            /> :
                            <Select
                                className="select-a"
                                placeholder="Select Default Screening Questions"
                                name={"default_screening_form"}
                                isClearable={true}
                                options={
                                    defaultScreeningForm_dropDown && defaultScreeningForm_dropDown.map((option) => {
                                        return { label: option.title, value: option.id, name: "default_screening_form", key: option.id }
                                    })
                                }
                                onChange={(e) => changeDefaultScreeningForm(e)}
                            />
                    }
                    {errStatus === true && formValues && formValues.default_screening_form === "" && <p style={{ color: "#96332c", fontSize: 13 }}>{formErr.defaultFormErr}</p>}
                </div>
            </div>

            {/* view section */}
            <div className="add-new-user-form-default">
                {
                    defaultFormId > 0 &&
                    <div className="formInput">
                        <h5 style={{ marginBottom: '15px' }}>Default Screening Questions</h5>
                        {
                            viewformData && viewformData.map((item, i) => {
                                return (
                                    <p style={{ fontSize: "16px" }}>
                                        <strong>Question {i + 1}. &nbsp;</strong>
                                        <p key={i}>{item.screening_question}</p>
                                    </p>
                                )
                            })
                        }
                    </div>
                }
            </div>

            {/* add more question btn section */}
            <br />
            <div className='add-new-user-form-default'>
                <div className="formInput">
                    <div style={{ display: "flex" }}>
                        <h6>Add New Questions</h6>
                        <AddCircleOutline onClick={() => addMoreQuestions()} style={{ marginLeft: "5px", color: "green", cursor: "pointer" }} />
                    </div>
                </div>
            </div>

            {/* add new question section */}
            {
                questionArray.map((item, i) => {
                    return (
                        <div className="add-new-user-form-default" style={{ width: "100%", alignItems: "center" }} key={i}>
                            <div className="formInput">
                                <label>Question</label>
                                <div style={{ display: "flex" }}>
                                    {item?.disabled === true ?
                                        <span>{item.screening_question}</span>
                                        :
                                        <input
                                            name={"screening_question"}
                                            onChange={(e) => handleQuestonChange(e, i)}
                                            type="text"
                                            placeholder="Question"
                                            value={item.screening_question}
                                        />
                                    }

                                </div>
                            </div>
                            {item?.disabled !== true &&
                                <div className="formInput" style={{ flexBasis: '30%', marginTop: '4%' }}>
                                    <Tooltip title="Remove">
                                        <RemoveCircleOutline style={{ color: "red", cursor: "pointer" }} onClick={() => removeQuestions(i)} />
                                    </Tooltip>
                                </div>}
                        </div>

                    )
                })
            }
            {errorForEmptyQuestion && <div style={{ color: "#96332c" }}>Questions Can not be empty! </div>}

            {/* submit btn section */}
            <div className="formInput" style={{ marginTop: "100px" }}>
                <button className="btn btn-submit" onClick={submitData}>Submit</button>
            </div>
        </form>
    </>
}

// screening form for storypage
export const StoryCandidateScreeningForm = ({ addMoreQuestions, removeQuestions, questionArray, submitData, commentActive, setCommentActive,
    inputHandler, handleQuestonChange, viewformData, handleAnswerChange, screeningId, comment, loadingScreeningData, decrept, screeningCommentData,
}) => {
    return (
        <div>
            {
                loadingScreeningData === false ?
                    <form style={{ width: "100%", margin: "unset", padding: "unset", boxShadow: "unset" }}>

                        {/* view section */}
                        <div className="add-new-user-form-default" style={{ display: 'flow-root' }}>
                            <h6 style={viewformData && viewformData.length === 0 ? { marginBottom: '15px', color: "red" } : { marginBottom: '15px' }}>
                                {viewformData && viewformData.length === 0 ? 'Default screening questions are not available' : ""}
                            </h6>
                            {
                                viewformData && viewformData.map((item, i) => {
                                    return (
                                        <p style={{ fontSize: "16px" }}>
                                            <div className="formInputDescription">
                                                <strong>Q. {i + 1}: &nbsp;</strong>
                                                <span style={{ lineBreak: 'anywhere', color: '#000' }}>{item.screening_question}</span>
                                                {item.disabled === true ?
                                                    <>
                                                        <br />
                                                        <strong>Ans: &nbsp;</strong>
                                                        <span style={{ lineBreak: 'anywhere', color: '#686565' }}>{decrept(item.screening_answer) ? decrept(item.screening_answer) : item.screening_answer}</span>
                                                    </>
                                                    :

                                                    <textarea
                                                        name="screening_answer"
                                                        onChange={(e) => handleAnswerChange(e, i)}
                                                        rows="1"
                                                        placeholder="Answer"></textarea>
                                                }
                                            </div>
                                        </p>
                                    )
                                })
                            }
                        </div>

                        {/* show all comment data */}
                        {screeningCommentData && screeningCommentData.length > 0 ? <>
                            <h5 style={{ marginBottom: "10px", padding: "1px", background: "#e7e8e4" }}>Comment</h5>
                            <div className="add-new-user-form-default" style={{ width: "100%", alignItems: "center" }}>
                                <div className="formInputDescription">
                                    {
                                        screeningCommentData && screeningCommentData.map((item, i) => {
                                            return (
                                                <div className="screening-comment-section-div" style={item.disabled === true && item.comment ? { borderBottom: "1px solid #e4e3e3" } : {}}>
                                                    {item.disabled === true && item.comment ? <>
                                                        <p className='screening-comment-sec1'><span dangerouslySetInnerHTML={{ __html: item.comment }} /></p>
                                                        <div class="icon-screening-comment">
                                                            <Tooltip title="Comment Date">
                                                                <CalendarMonth className='icon-comment' />
                                                            </Tooltip>
                                                            <span>
                                                                <p>{item.date}</p>
                                                            </span>
                                                        </div>
                                                        <div class="icon-screening-comment">
                                                            <Tooltip title="Comment Time">
                                                                <AccessTime className="icon-comment" />
                                                            </Tooltip>
                                                            <span>
                                                                <p>{item.time}</p>
                                                            </span>
                                                        </div>
                                                    </>
                                                        : ""
                                                    }
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </>
                            : ""
                        }

                        {/* add more question btn section */}
                        <div className='add-new-user-form-default'>
                            <div className="formInput">
                                <div style={{ display: "flex" }}>
                                    <strong style={{ fontSize: "16px" }}>Add New Questions</strong>
                                    <Tooltip title="Add New Questions">
                                        <AddCircleOutline onClick={() => addMoreQuestions()} style={{ marginLeft: "5px", color: "green", cursor: "pointer" }} />
                                    </Tooltip>
                                </div>
                            </div>
                        </div>
                        {
                            questionArray.map((item, i) => {
                                return (
                                    <div className="add-new-user-form-default" style={{ width: "100%", alignItems: "center" }} key={i}>
                                        <div className="formInputDescription">
                                            <strong style={{ fontSize: "16px", color: "#575757" }}>Question</strong>
                                            <div style={{ display: "flex" }}>
                                                {item.disabled === true ?
                                                    <span>{item.screening_question}</span>
                                                    :
                                                    <textarea
                                                        rows="1"
                                                        name={"screening_question"}
                                                        onChange={(e) => handleQuestonChange(e, i)}
                                                        type="text"
                                                        placeholder="Question"
                                                        value={item.screening_question}
                                                    />
                                                }
                                            </div>
                                        </div>
                                        <div className="formInput" style={{ flexBasis: "90%" }}>
                                            <strong style={{ fontSize: "16px", color: "#575757" }}>Answer</strong>

                                            <div style={{ display: "flex" }}>
                                                {item.disabled === true ?
                                                    <>
                                                        <br />
                                                        <strong>Ans : &nbsp;</strong>
                                                        <span>{item.screening_answer}</span>
                                                    </>
                                                    :
                                                    <textarea
                                                        rows="1"
                                                        name={"screening_answer"}
                                                        onChange={(e) => handleQuestonChange(e, i)}
                                                        type="text"
                                                        placeholder="Answer"
                                                        value={item.screening_answer}
                                                    />}
                                            </div>
                                        </div>

                                        <div className="formInput" style={{ flexBasis: '8%', marginTop: '6%' }}>
                                            {
                                                item.disabled === true ? "" : <Tooltip title="Remove">
                                                    <RemoveCircleOutline style={{ color: "red", cursor: "pointer" }} onClick={() => removeQuestions(i)} />
                                                </Tooltip>
                                            }

                                        </div>
                                    </div>
                                )
                            })
                        }
                        <div className='formInput'>
                            <strong style={{ fontSize: "16px" }}>Comment</strong>

                            {commentActive === false &&
                                <div className="bottom-commrnt-col-right">
                                    <div className="formInput" style={{ flexBasic: "100%" }}>
                                        <input type="text" className="comment-single-text-box" placeholder="Add a Comment ...."
                                            onClick={() => setCommentActive(true)} />
                                    </div>
                                    {/* <p style={{ marginBottom: 20 }} ><span dangerouslySetInnerHTML={{ __html: formValues.comment }} /></p> */}
                                </div>
                            }
                            {commentActive === true &&
                                <div className="bottom-commrnt-col-right">
                                    <CKEditor
                                        name="comment"
                                        initData={comment}
                                        defaultValue={comment}

                                        config={{
                                            toolbar: [
                                                ["Source"],
                                                ["Styles", "Format", "Font", "FontSize"],
                                                ["Bold", "Italic"],
                                                ["Undo", "Redo"],
                                                ["EasyImageUpload"],
                                                { name: 'insert', items: ['Image', 'Table', 'Smiley', 'PageBreak', 'Iframe'] },
                                            ],
                                            height: "120px"
                                        }}
                                        onChange={(e) => inputHandler(e)}
                                    />

                                </div>
                            }

                        </div>
                        <div className="formInput">
                            <button className="btn btn-submit" style={{ marginBottom: "20px" }}
                                onClick={(e) => submitData(e)}>Submit</button>
                        </div>

                    </form>
                    : <LoaderForm />
            }
        </div>
    )
}
// assign approver form for jobpost page

export const JobPostAssignApprover = ({ ID, selectedValueDefault, defaultScreeningForm_dropDown, changeDefaultScreeningForm, errStatus, formValues, formErr }) => {
    return (
        <form>
            <div className='add-new-user-form-default'>
                <div className="formInput">
                    <label>Assign approver</label>



                </div>
            </div>
        </form>
    );
};

