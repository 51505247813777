import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import DataService from "../services/data.service";
import { STATUSES } from "../common-status/constant";
import LocalStorageDataService from "../../components/common-function/GetLocalstorage";
const { REACT_APP_ENV } = process.env;
const user = LocalStorageDataService.userDetail()

export const createZoomMeeting = createAsyncThunk("create_zoom", async (data) => {
    if (data.ID === '--1') {
        return [];
    } else {
        const res = await DataService.create_zoom_meeting(data.ID, data.addvalues);
        return res.data;
        // let data = {
        //     "data": {
        //       "uuid": "kT4ubOCyQ46nDTIBzGzT6Q==",
        //       "id": 99900368670,
        //       "host_id": "aDXx3ZUaTSCNCIEaBejiVQ",
        //       "host_email": "developmentapp@unicodesystems.org",
        //       "topic": "Check on dev",
        //       "type": 2,
        //       "status": "waiting",
        //       "start_time": "2023-05-03T00:00:00Z",
        //       "duration": 30,
        //       "timezone": "Asia/Kolkata",
        //       "agenda": "Create zoom Meeting form Testing",
        //       "created_at": "2023-04-27T05:08:44Z",
        //       "start_url": "https://zoom.us/s/99900368670?zak=eyJ0eXAiOiJKV1QiLCJzdiI6IjAwMDAwMSIsInptX3NrbSI6InptX28ybSIsImFsZyI6IkhTMjU2In0.eyJhdWQiOiJjbGllbnRzbSIsInVpZCI6ImFEWHgzWlVhVFNDTkNJRWFCZWppVlEiLCJpc3MiOiJ3ZWIiLCJzayI6IjAiLCJzdHkiOjEwMCwid2NkIjoiYXcxIiwiY2x0IjowLCJtbnVtIjoiOTk5MDAzNjg2NzAiLCJleHAiOjE2ODI1NzkzMjQsImlhdCI6MTY4MjU3MjEyNCwiYWlkIjoiRkp1YVRnQk9ULUdFaGM0SVdpQUk3QSIsImNpZCI6IiJ9.fYbtdDRqC3nn2Mdi3CBg30KApnvzkpRK6neyjPyOwE8",
        //       "join_url": "https://zoom.us/j/99900368670?pwd=RGc2YmVWb04vTmZabmJnVXIvcVJDZz09",
        //       "password": "8M5H33VEJN",
        //       "h323_password": "9231535629",
        //       "pstn_password": "9231535629",
        //       "encrypted_password": "RGc2YmVWb04vTmZabmJnVXIvcVJDZz09",
        //       "settings": {
        //         "host_video": true,
        //         "participant_video": true,
        //         "cn_meeting": false,
        //         "in_meeting": false,
        //         "join_before_host": true,
        //         "jbh_time": 0,
        //         "mute_upon_entry": false,
        //         "watermark": false,
        //         "use_pmi": false,
        //         "approval_type": 2,
        //         "audio": "voip",
        //         "auto_recording": "none",
        //         "enforce_login": false,
        //         "enforce_login_domains": "",
        //         "alternative_hosts": "",
        //         "alternative_host_update_polls": false,
        //         "close_registration": false,
        //         "show_share_button": false,
        //         "allow_multiple_devices": false,
        //         "registrants_confirmation_email": true,
        //         "waiting_room": false,
        //         "request_permission_to_unmute_participants": false,
        //         "registrants_email_notification": true,
        //         "meeting_authentication": false,
        //         "encryption_type": "enhanced_encryption",
        //         "approved_or_denied_countries_or_regions": {
        //           "enable": false
        //         },
        //         "breakout_room": {
        //           "enable": false
        //         },
        //         "alternative_hosts_email_notification": true,
        //         "show_join_info": false,
        //         "device_testing": false,
        //         "focus_mode": false,
        //         "enable_dedicated_group_chat": false,
        //         "private_meeting": false,
        //         "email_notification": true,
        //         "host_save_video_order": false
        //       },
        //       "pre_schedule": false,
        //       "html_zoom_details": "\n            <div style=\"@import url(https://fonts.googleapis.com/css?family=Rubik:300,400,500,700|Open+Sans:300,400,600,700); font-family: 'Open Sans', sans-serif; border: 1px solid #eee;\">\n                <div style=\"background-color: #f2f3f8; max-width:700px;  margin:0 auto;\" width=\"100%\">\n                    <div style=\"max-width:670px;background:#fff; margin-top:20px; padding:30px;\">\n                        <div style=\"color:#1e1e2d; font-weight:500;font-size:20px;\">Meeting Details </div>\n                        <br>\n                        <div style=\"padding: 4px; font-size: 16px;font-family: monospace;\">Meeting Id : 99900368670</div>\n                        <div style=\"padding: 4px; font-size: 16px;font-family: monospace;\">Agenda : Create zoom Meeting form Testing</div>\n                        <div style=\"padding: 4px; font-size: 16px;font-family: monospace;\">Topic : Check on dev</div>\n                        <div style=\"padding: 4px; font-size: 16px;font-family: monospace;\">Password : 8M5H33VEJN</div>\n                        <div style=\"padding: 4px; font-size: 16px;font-family: monospace;\">Zoom Link : <a href=\"https://zoom.us/j/99900368670?pwd=RGc2YmVWb04vTmZabmJnVXIvcVJDZz09\">Click here to join zoom</a></div>\n                        <div style=\"padding: 4px; font-size: 16px;font-family: monospace;\">Start Time : 03 May 2023 12:00 am</div>\n                    </div>\n                </div>\n            </div>       \n            "
        //     },
        //     "message": "Success",
        //     "status": 200
        //   }

        //   return data;
    }
});

export const getZoomAccountList = createAsyncThunk("get/zoom-account", async (data) => {
    const res = await DataService.get_mst_zoom_account(data);
    return res.data;
});

export const getZoomCallLogDetails = createAsyncThunk("zoom-call-log/details", async (data) => {
    const res = await DataService.get_zoom_meeting(data);
    return res.data;
})

export const defaultZoomColumn = createAsyncThunk("set-zoom-column", async (data) => {
    return data;
});

const zoomSlice = createSlice({
    name: "zoomSlice",
    initialState: {
        create_zoom_meeting: [],
        zoomAccountList: [],
        status: STATUSES.IDLE,
        error: '',
        zoomCallDetails: [],
        defaultZoomColumns:[],
    },
    reducers: {},
    extraReducers(builder) {
        builder
            //create Zoom Meeting
            .addCase(createZoomMeeting.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(createZoomMeeting.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_create_zoom_meeting`;
                state.create_zoom_meeting = action.payload;
            })
            .addCase(createZoomMeeting.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            //get zoom account list
            .addCase(getZoomAccountList.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(getZoomAccountList.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_zoomAccountList`;
                state.zoomAccountList = action.payload;
            })
            .addCase(getZoomAccountList.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            //get Zoom call details
            .addCase(getZoomCallLogDetails.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(getZoomCallLogDetails.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_zoomCallDetails`;
                state.zoomCallDetails = action.payload
            })
            .addCase(getZoomCallLogDetails.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            .addCase(defaultZoomColumn.fulfilled, (state, action) => {
                localStorage.setItem(`${REACT_APP_ENV}_${user.id}_zoom`, JSON.stringify(action.payload))
                state.defaultZoomColumns = action.payload
            }) 
    },
});
export default zoomSlice.reducer;