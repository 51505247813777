import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { STATUSES } from "../common-status/constant";
import dataService from "../services/data.service";

export const getClientList = createAsyncThunk("get/clients", async (data) => {
    const res = await dataService.client_list_all(data);
    const resp = res.data;
    return resp;
});

export const getIndustryList = createAsyncThunk("get/clients-list", async (data) => {
    const res = await dataService.industry_list_by_client((data));
    const resp = res.data;
    return resp;
});

export const getDepartmentList = createAsyncThunk("get/departments-list", async (data) => {
    const res = await dataService.department_list_by_clientInd(data);
    const resp = res.data;
    return resp;
});

export const getSpecialisationList = createAsyncThunk("get/specialisation-list", async (data) => {
    const res = await dataService.specialization_list_by_clientIndDepartment(data);
    const resp = res.data;
    return resp;
});

export const getSkillsList = createAsyncThunk("get/skills-list", async (data) => {
    const res = await dataService.skill_list_by_clientIndDepartmentSpecil(data);
    const resp = res.data;
    return resp;
});

export const getAllHeadsList = createAsyncThunk("get/heads-list", async (data) => {
    const res = await dataService.getAllHeads(data);
    const resp = res.data;
    return resp;
});

export const getClientSideInterviewerList = createAsyncThunk("get/client-inteviewer-list", async (data) => {
    const res = await dataService.all_client_resource_list(data);
    const resp = res.data;
    return resp;
});

export const candidateTestCreate = createAsyncThunk("candidate-test-creation", async (data) => {
    const res = await dataService.ces_candidate_test_cteation(data);
    const resp = res.data;
    return resp;
});

export const questionlevelList = createAsyncThunk("question-lavel-list", async (data) => {
    const res = await dataService.questionlevel_list(data);
    const resp = res.data;
    return resp;
});

export const questionTypeList = createAsyncThunk("question-type-list", async (data) => {
    const res = await dataService.question_type_list(data);
    const resp = res.data;
    return resp;
})

export const questionslist = createAsyncThunk("question-list", async (data) => {
    const res = await dataService.question_type(data);
    const resp = res.data;
    return resp;
});

export const existingQuestionList = createAsyncThunk("existing-qusetion-list", async (data) => {
    const res = await dataService.ces_qusetionlist_by_testtype(data);
    const resp = res.data;
    return resp;
});

export const questionCreate = createAsyncThunk("question/create", async (data) => {
    const res = await dataService.question_create(data);
    return res.data;
    // let datas = { "data": { "id": 2470, "title": "Which of the following port is the default where webpack-dev-server runs?", "description": "Which of the following port is the default where webpack-dev-server runs?", "test_type": null, "images": null, "technology": 56, "question_level": 24, "question_type": 1, "marks": 1, "industry": 6, "department": 12, "status": true, "created_on": "2023-07-13T09:32:09.003178Z", "created_by": 1, "updated_on": "2023-07-13T09:32:09.017778Z", "updated_by": null }, "message": "Success", "status": 200 }
    // return datas
});

export const saveScreenFlowData = createAsyncThunk("screen-flow-data", async (data) => {
    const res = await dataService.screen_flow_test_api(data);
    const resp = res.data;
    return resp;
});

export const getScreenTestList = createAsyncThunk("screen-flow/list", async (data) => {
    const res = await dataService.get_screen_flow_test_api(data);
    const resp = res.data;
    return resp;
});


const hiringRequestSlice = createSlice({
    name: "hiringRequestSlice",
    initialState: {
        client_List: [],
        industry_List: [],
        department_List: [],
        specialisation_List: [],
        skill_List: [],
        heads_List: [],
        client_interviewer_List: [],
        // screening test
        candidate_test_Create: [],
        questionlevel_list: [],
        questionType_List: [],
        question_list: [],
        screen_flow_Create: [],

        screen_flow_test: [],
        screen_flow_test_count: 0,
        test_status: true,



        status: 'idle',
        error: ''
    },
    reducers: {},
    extraReducers(builder) {
        builder
            //get active client list
            .addCase(getClientList.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(getClientList.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                // state.client_List = action.payload;
                state.client_List = !action.payload.data.rows ? [] : action.payload.data.rows.map((e) => ({ ...e, label: e.name, value: e.id }));
                state.error = ""
            })
            .addCase(getClientList.rejected, (state, action) => {
                state.status = STATUSES.FAILURE;
                state.error = action.error
            })

            //get active industry list
            .addCase(getIndustryList.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(getIndustryList.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                // state.industry_List = action.payload;
                state.industry_List = !action.payload.data.industries ? [] : action.payload.data.industries.map((e) => ({ ...e, label: e.industry_name, value: e.id }));
                state.error = ""
            })
            .addCase(getIndustryList.rejected, (state, action) => {
                state.status = STATUSES.FAILURE;
                state.error = action.error
            })

            //get active department list by industry and client bases
            .addCase(getDepartmentList.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(getDepartmentList.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                // state.department_List = action.payload;
                state.department_List = !action.payload.data.departments ? [] : action.payload.data.departments.map((e) => ({ ...e, label: e.name, value: e.id }));
                state.error = ""
            })
            .addCase(getDepartmentList.rejected, (state, action) => {
                state.status = STATUSES.FAILURE;
                state.error = action.error
            })

            //get active specialisation list by department
            .addCase(getSpecialisationList.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(getSpecialisationList.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                // state.specialisation_List = action.payload;
                state.specialisation_List = !action.payload.data.specializations ? [] : action.payload.data.specializations.map((e) => ({ ...e, label: e.name, value: e.id }));
                state.error = ""
            })
            .addCase(getSpecialisationList.rejected, (state, action) => {
                state.status = STATUSES.FAILURE;
                state.error = action.error
            })

            //get active specialisation list by department
            .addCase(getSkillsList.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(getSkillsList.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                // state.skill_List = action.payload;
                state.skill_List = !action.payload.data.rows ? [] : action.payload.data.rows.map((e) => ({ ...e }));
                state.error = ""
            })
            .addCase(getSkillsList.rejected, (state, action) => {
                state.status = STATUSES.FAILURE;
                state.error = action.error
            })

            //get active heads list
            .addCase(getAllHeadsList.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(getAllHeadsList.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                // state.heads_List = action.payload;
                state.heads_List = !action.payload.data.rows ? [] : action.payload.data.rows.map((e) => ({ ...e, label: e.head_name, value: e.id }));
                state.error = ""
            })
            .addCase(getAllHeadsList.rejected, (state, action) => {
                state.status = STATUSES.FAILURE;
                state.error = action.error
            })

            //get active client side inteviwer list
            .addCase(getClientSideInterviewerList.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(getClientSideInterviewerList.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                // state.client_interviewer_List = action.payload;
                state.client_interviewer_List = !action.payload.data.rows ? [] : action.payload.data.rows.map((e) => ({ ...e, label: `${e.name} (${e.email})`, value: e.id }));
                state.error = ""
            })
            .addCase(getClientSideInterviewerList.rejected, (state, action) => {
                state.status = STATUSES.FAILURE;
                state.error = action.error
            })

            // candidate test creation for CES
            .addCase(candidateTestCreate.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(candidateTestCreate.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_candidate_test_Create`;
                state.candidate_test_Create = action.payload;
                state.error = ""
            })
            .addCase(candidateTestCreate.rejected, (state, action) => {
                state.status = STATUSES.FAILURE;
                state.error = action.error
            })

            // candidate test creation for CES
            .addCase(questionlevelList.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(questionlevelList.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                // state.questionlevel_list = action.payload;
                state.questionlevel_list = !action.payload.data.rows ? [] : action.payload.data.rows[0]
                state.error = ""
            })
            .addCase(questionlevelList.rejected, (state, action) => {
                state.status = STATUSES.FAILURE;
                state.error = action.error
            })

            // screening questions list for CES
            .addCase(questionslist.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(questionslist.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.question_list = action.payload;
                state.error = ""
            })
            .addCase(questionslist.rejected, (state, action) => {
                state.status = STATUSES.FAILURE;
                state.error = action.error
            })



            // qusetion list by test type & technology & qusetion lavel
            .addCase(existingQuestionList.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(existingQuestionList.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                // state.questionlevel_list = action.payload;
                state.exiti = !action.payload.data.rows ? [] : action.payload.data.rows[0]
                state.error = ""
            })
            .addCase(existingQuestionList.rejected, (state, action) => {
                state.status = STATUSES.FAILURE;
                state.error = action.error
            })

            // candidate test creation for CES
            .addCase(questionTypeList.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(questionTypeList.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                // state.questionlevel_list = action.payload;
                state.questionType_List = !action.payload.data.rows ? [] : action.payload.data.rows
                state.error = ""
            })
            .addCase(questionTypeList.rejected, (state, action) => {
                state.status = STATUSES.FAILURE;
                state.error = action.error
            })

            //question create
            .addCase(questionCreate.pending, (state, action) => {
                state.status = STATUSES.LOADING;
                state.error = ""
            })
            .addCase(questionCreate.fulfilled, (state, action) => {
                // state.status = `${STATUSES.SUCCESS}_question_Create`;
                // state.question_Create = action.payload;
                // state.error = ""
                let result = action.payload;
                if (result.status === 200) {
                    state.status = `${STATUSES.SUCCESS}_question_Create`;
                    state.question_Create = action.payload;
                } else {
                    state.status = STATUSES.FAILURE
                    state.error = action.payload
                }

            })
            .addCase(questionCreate.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            //screen flow data create
            .addCase(saveScreenFlowData.pending, (state, action) => {
                state.status = STATUSES.LOADING;
                state.error = ""
            })
            .addCase(saveScreenFlowData.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_screen_flow_Create`;
                state.screen_flow_Create = action.payload;
                state.error = ""
            })
            .addCase(saveScreenFlowData.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            //screen flow test list
            .addCase(getScreenTestList.pending, (state, action) => {
                state.status = STATUSES.LOADING;
                state.test_status = true;
                state.error = ""
            })
            .addCase(getScreenTestList.fulfilled, (state, action) => {
                state.test_status = false;
                state.screen_flow_test = action.payload;
                state.screen_flow_test_count = !action.payload.data.row_count ? 0 : action.payload.data.row_count
                state.error = ""
            })
            .addCase(getScreenTestList.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.test_status = false;
                state.error = action.error
            })



    },
});


export default hiringRequestSlice.reducer;