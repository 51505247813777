import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import Navbar from "../../components/navbar/Navbar"
import Sidebar from "../../components/sidebar/Sidebar"
import "./hiring.scss";
import { STATUSES } from "../../redux/common-status/constant";
import LoaderForm from "../../components/common-function/LoaderForm";
import { Tooltip } from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { RefreshButton } from "../../components/common-function/RefreshButton";
import { hiringRequestEdit } from "../../redux/features/masterSlice";
import { toast, ToastContainer } from "react-toastify";

const ViewHiringRequest = () => {
    const dispatch = useDispatch()
    const { id } = useParams()
    const { view_data, status, error } = useSelector(state => state.master)
    const skillsArray = view_data?.data?.skills_tech_data?.[0]?.skills || [];

    //error handling
    useEffect(() => {
        if (status === STATUSES.FAILURE && error) {
            toast.error(error.message)
        }
        // eslint-disable-next-line
    }, [error])

    useEffect(() => {
        if (id) {
            dispatch(hiringRequestEdit(id))
        }
        // eslint-disable-next-line
    }, [dispatch])
    
    return (
        <div className="resource">
            <Sidebar />
            <div className="resourceContainer">
                <Navbar />
                <ToastContainer />
                <div className="top-headings">
                    <h3>View Hiring Request </h3>
                    <div>
                        <Tooltip title="Back">
                            <Link to={-1} className="btn btn-add-new"><ArrowBackIcon /></Link>
                        </Tooltip>
                        <RefreshButton api={hiringRequestEdit} filter={id} style={{ marginTop: 5 }} />
                    </div>
                </div>
                {(status === STATUSES.LOADING) && <LoaderForm />}

                <div className="view-details-row">
                    <div className="detailItem">
                        <span className="itemKey">Client :</span>
                        <span className="itemKey2">{view_data && view_data.data && view_data.data.client.name ? view_data.data.client.name : "N/A"}</span>
                    </div>

                    <div className="detailItem">
                        <span className="itemKey">Industry :</span>
                        <span className="itemKey2">{view_data && view_data.data && view_data.data.industry?.industry_name
                            ? view_data.data.industry?.industry_name
                            : "N/A"}</span>
                    </div>


                    <div className="detailItem">
                        <span className="itemKey"> Department:</span>
                        <span className="itemKey2">{view_data && view_data.data && view_data.data.department.name ? view_data.data.department.name : "N/A"}</span>
                    </div>

                    <div className="detailItem">
                        <span className="itemKey">Specialzation :</span>
                        <span className="itemKey2">{view_data?.data?.skills_tech_data?.[0]?.technology?.name ?? "N/A"}</span>
                    </div>

                    <div className="detailItem">
                        <span className="itemKey">Skills :</span>
                        <span className="itemKey2">
                            {skillsArray.map((skill, index) => (
                                <React.Fragment key={skill.id}>
                                    {index > 0 && ", "}
                                    {skill.skill_name}
                                </React.Fragment>
                            ))}
                        </span>
                    </div>

                    <div className="detailItem">
                        <span className="itemKey">Salary Budget From: </span>
                        <span className="itemKey2">
                            <span className="itemKey2">
                                {view_data?.data?.client_budget_from !== undefined
                                    ? view_data.data.client_budget_from
                                    : 0}
                            </span>
                        </span>
                    </div>

                    <div className="detailItem">
                        <span className="itemKey">Salary Budget To: </span>
                        <span className="itemKey2">
                            <span className="itemKey2">
                                {view_data?.data?.client_budget_to !== undefined
                                    ? view_data.data.client_budget_to
                                    : 0}
                            </span>
                        </span>
                    </div>

                    <div className="detailItem">
                        <span className="itemKey">Amount in currency :</span>
                        <span className="itemKey2">{view_data?.data?.currency.currency_name ?? "N/A"}</span>
                    </div>

                    <div className="detailItem">
                        <span className="itemKey">Payment Type :</span>
                        <span className="itemKey2">{view_data?.data?.payment_type.name ?? "N/A"}</span>
                    </div>

                    <div className="detailItem">
                        <span className="itemKey">Hiring Mode :</span>
                        <span className="itemKey2">{view_data?.data?.hiring_mode.name ?? "N/A"}</span>
                    </div>

                    <div className="detailItem">
                        <span className="itemKey">Position/Role Name :</span>
                        <span className="itemKey2">{view_data?.data?.role ?? "N/A"}</span>
                    </div>

                    <div className="detailItem">
                        <span className="itemKey">Number Of Vacancies : </span>
                        <span className="itemKey2">{view_data?.data?.number_of_vacancies ?? 0}</span>
                    </div>

                    <div className="detailItem">
                        <span className="itemKey">Type Of Vacancy : </span>
                        <span className="itemKey2">{view_data?.data?.type_of_vacancy.vacancy_type ?? "N/A"}</span>
                    </div>

                    <div className="detailItem">
                        <span className="itemKey">Hours par day	(In The Case Of Part Time ) : </span>
                        <span className="itemKey2">{view_data?.data?.hours_per_day ?? 0}</span>
                    </div>

                    <div className="detailItem">
                        <span className="itemKey">Number of months (In The Case Of Part Time ): </span>
                        <span className="itemKey2">{view_data?.data?.expected_duration_in_months ?? 0}</span>
                    </div>

                    <div className="detailItem">
                        <span className="itemKey">Contract Type (In The Case Of Contractual ): </span>
                        <span className="itemKey2">{view_data?.data?.contract_type ?? "N/A"}</span>
                    </div>

                    <div className="detailItem">
                        <span className="itemKey">Working Mode: </span>
                        <span className="itemKey2">{view_data?.data?.working_mode.working_mode ?? "N/A"}</span>
                    </div>

                    <div className="detailItem">
                        <span className="itemKey">Job Location: </span>
                        <span className="itemKey2">{view_data?.data?.job_location.job_location ?? "N/A"}</span>
                    </div>

                    <div className="detailItem">
                        <span className="itemKey">Add More Specifications: </span>
                        <span className="itemKey2">{view_data?.data?.add_more_specifications_data?.map(spec => spec.specification_name).join(', ') || "N/A"}</span>
                    </div>

                    <div className="detailItem">
                        <span className="itemKey">Education Qualification: </span>
                        <span className="itemKey2">{view_data?.data?.education_qualification_data?.map(edu => edu.education_qualification_name).join(', ') ?? "N/A"}</span>
                    </div>

                    <div className="detailItem">
                        <span className="itemKey">Total Years of Experience (From- To): </span>
                        <span className="itemKey2">
                            {view_data?.data?.total_experience_from === 0 ? 0 : (view_data?.data?.total_experience_from || "N/A")} -
                            {view_data?.data?.total_experience_to === 0 ? 0 : (view_data?.data?.total_experience_to || "N/A")}
                        </span>
                    </div>

                    <div className="detailItem">
                        <span className="itemKey">Total Years of Relevant Experience From (From- To): </span>
                        <span className="itemKey2">
                            {view_data?.data?.relevant_experience_expected_from === 0 ? 0 : (view_data?.data?.relevant_experience_expected_from || "N/A")} -
                            {view_data?.data?.relevant_experience_expected_to === 0 ? 0 : (view_data?.data?.relevant_experience_expected_to || "N/A")}
                        </span>
                    </div>

                    <div className="detailItem">
                        <span className="itemKey">Expected ETA (Number + Unit ): </span>
                        <span className="itemKey2">{view_data?.data?.expected_ETA ? `${view_data?.data?.expected_ETA} ${view_data?.data?.urgency_unit.urgency_type}` : "N/A"}</span>
                    </div>

                    <div className="detailItem" style={{ display: 'flex', alignItems: 'center' }}>
                        <span className="itemKey" style={{ marginRight: '10px' }}>Job Description and Responsibility:</span>
                        <span className="itemKey2" dangerouslySetInnerHTML={{ __html: view_data?.data?.job_description || "N/A" }} />
                    </div>

                    <div className="detailItem">
                        <span className="itemKey">Client side Interviewer (if Any) :</span>
                        <span className="itemKey2">{view_data?.data?.client_interviewer_data?.map(csi => csi.name).join(', ') ?? "N/A"}</span>
                    </div>

                    <div className="detailItem">
                        <span className="itemKey">Selected Feedback Heads for Interviewer :</span>
                        <span className="itemKey2">{view_data?.data?.head_ids_data?.map(heads => heads?.interview_feedback_head?.head_name).join(', ') ?? "N/A"}</span>
                    </div>

                </div>

            </div>
        </div >
    )

}

export default ViewHiringRequest