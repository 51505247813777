import "./sidebar.scss"
import DashboardIcon from '@mui/icons-material/Dashboard';
import LogoutIcon from '@mui/icons-material/Logout';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import ControlCameraIcon from '@mui/icons-material/ControlCamera';
import FolderIcon from '@mui/icons-material/Folder';
import ContactsIcon from '@mui/icons-material/Contacts';
import CardMembershipIcon from '@mui/icons-material/CardMembership';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import EngineeringIcon from '@mui/icons-material/Engineering';
import { Link, useNavigate } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { logout } from '../../redux/features/authSlice'
import { ProSidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import 'react-pro-sidebar/dist/css/styles.css';
import BadgeIcon from '@mui/icons-material/Badge';
import LocalStorageDataService from '../common-function/GetLocalstorage';
import { Ballot, Assignment, MenuBook, TrendingUp, InterpreterMode } from "@mui/icons-material";
import Logo from '../../images/fevicon.png'
import { getMenuListForSideBar } from "../../redux/features/sidebarSlice";
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import { Tooltip } from "@mui/material";
import SettingsIcon from '@mui/icons-material/Settings';

let user = LocalStorageDataService.userDetail();
let tmsRole = user && user.tms_role
const tmsRoleId = user && user.tms_role_id
const roleWiseFilter = tmsRole && `{"filter":{"status":{"value":"True","operation": "eq"},"role_id":{"value":${tmsRoleId},"operation": "eq"}},"no_limit":True}`

const Sidebar = () => {
  const { logoutUser, authLoggedIn } = useSelector((state) => state.authUser)
  const [urlPath, setUrlPath] = useState();
  const [childUrlPath, setChildUrlPath] = useState();
  const [parentUrlPath, setParentUrlPath] = useState();
  const { sidebarMenuList } = useSelector((state) => state.sidebar);

  const dispatch = useDispatch();
  const navigate = useNavigate()

  const logout_user = useCallback(() => {
    dispatch(logout());
    window.location.reload('/');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (authLoggedIn === false && logoutUser && logoutUser.message) {
    navigate('/')
  }

  const { data } = useSelector((state) => state.navbartoggle);

  const fetchUrlPath = () => {
    const menuData = JSON.parse(localStorage.getItem('menuData'));
    const path = window.location.href;
    let pathArr = path.split('/');
    pathArr = pathArr.splice(3);
    const propertyName = pathArr.join('/');
    const currentMenu = document.getElementById(propertyName);
    setChildUrlPath(propertyName);
    if (menuData !== null) {
      const propertyObject = menuData.data.rows.filter((item) => item.menu === propertyName)[0];
      if (propertyObject) {
        if (propertyObject.parent_menu) {
          setUrlPath(propertyObject.parent_menu);
          currentMenu?.parentNode.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
          const parentPropertyObject = menuData.data.rows.filter((item) => item.menu === propertyObject.parent_menu)[0];

          if (parentPropertyObject?.parent_menu)
            return setParentUrlPath(parentPropertyObject?.parent_menu)

          return setParentUrlPath(parentPropertyObject?.menu)
        }
        currentMenu?.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
        return setParentUrlPath(propertyObject?.menu);
      }
    }
  };

  function isVisible(arr) {
    const res = [];
    arr?.map((submenusItem) => {
      if (!submenusItem.menu?.url_slug.match("edit") && !submenusItem.menu?.url_slug.match("view")) {
        // --------------------------------
        if (submenusItem.menu.url_slug === 'my-intervie-ws' || submenusItem.menu.url_slug === 'manage-availability') {
          if (tmsRole.role_name === 'Admin' || tmsRole.role_name === 'HR' || user.is_interviewer === true || tmsRole.role_name === 'Agent') {
            res.push(submenusItem)
          }
        } else {
          res.push(submenusItem);
        }

        // (tmsRole.role_name === 'Admin' || tmsRole.role_name === 'HR' || user.is_interviewer === true) &&
        // submenusItem
        //   :    
        //   submenusItem

      }
      // return submenusItem;
    })
    return res;
  }

  useEffect(() => fetchUrlPath(), [sidebarMenuList, dispatch])

  useEffect(() => {
    // dispatch(getMenuListForSideBar(roleWiseFilter))
    dispatch(getMenuListForSideBar(tmsRoleId))
  }, [dispatch])


  //implement parent menu toggle functionality
  const openCloseMenu = (pathLink) => {
    (parentUrlPath === pathLink) ? setParentUrlPath('') : setParentUrlPath(pathLink);
  }
  const openCloseSubmenu = (e, pathLink) => {
    e.stopPropagation();
    (urlPath === pathLink) ? setUrlPath('') : setUrlPath(pathLink);
  }
  return (

    // dynamic side bar menu code using api 
    <div className={data === true ? 'sidebar-collapse sidebar' : 'sidebar'} >
      <div className="top">
        <Link to="/dashboard" style={{ textDecoration: "none" }}>
          <span className="logo"><img src={Logo} alt={Logo} /><span className="logo-text">RTMS</span></span>
        </Link>
      </div>
      <ProSidebar>
        {/* {console.log(sidebarMenuList)} */}
        <Menu>
          {sidebarMenuList && sidebarMenuList.length > 0 && sidebarMenuList.map((item, index) => {
            return (

              item?.submenu.length === 0 ?
                <Tooltip title={item.menu?.title}>
                  <MenuItem id={item.menu?.url_slug} key={index} className={item.menu?.url_slug === parentUrlPath ? "active" : ""} icon={
                    item.menu?.class_name === "DashboardIcon" ? <DashboardIcon /> :
                      item.menu?.class_name === "SupervisedUserCircleIcon" ? <SupervisedUserCircleIcon /> :
                        item.menu?.class_name === "ControlCameraIcon" ? <ControlCameraIcon /> :
                          item.menu?.class_name === "FolderIcon" ? <FolderIcon /> :
                            item.menu?.class_name === "ContactsIcon" ? <ContactsIcon /> :
                              item.menu?.class_name === "CardMembershipIcon" ? <CardMembershipIcon /> :
                                item.menu?.class_name === "AutorenewIcon" ? <AutorenewIcon /> :
                                  item.menu?.class_name === "CalendarMonthIcon" ? <CalendarMonthIcon /> :
                                    item.menu?.class_name === "BadgeIcon" ? <BadgeIcon /> :
                                      item.menu?.class_name === "Assignment" ? <Assignment /> :
                                        item.menu?.class_name === "MenuBook" ? <MenuBook /> :
                                          item.menu?.class_name === "AccountCircleIcon" ? <AccountCircleIcon /> :
                                            item.menu?.class_name === "Ballot" ? <Ballot /> :
                                              item.menu?.class_name === "EngineeringIcon" ? <EngineeringIcon /> :
                                                item.menu?.class_name === "LibraryBooksIcon" ? <LibraryBooksIcon /> :
                                                  item.menu?.class_name === "TrendingUp" ? <TrendingUp /> :
                                                    item.menu?.class_name === "InterpreterMode" ? <InterpreterMode /> :
                                                      item.menu?.class_name === "Settings" ? <SettingsIcon /> :
                                                        <PictureAsPdfIcon />}>

                    {/* {item.menu?.title} */}
                    {tmsRole && (tmsRole.role_key?.match('hr') || tmsRole.role_key?.match('agent')) && item.menu?.url_slug.match('my-jobs') ? "My Jobs" :
                      tmsRole && (tmsRole.role_key !== 'hr' || tmsRole.role_key !== 'agent') && item.menu?.url_slug.match('my-jobs') ? "Epics" :
                        item.menu?.title
                    }
                    <Link to={"/" + item.menu?.url_slug} />
                  </MenuItem>
                </Tooltip>

                : item?.submenu.length > 0 &&
                <Tooltip title={item.menu?.title}>
                  <SubMenu key={index} title={item.menu?.title}
                    onClick={() => { openCloseMenu(item.menu?.url_slug) }}
                    open={item.menu?.url_slug === parentUrlPath}
                    icon={
                      item.icon === "DashboardIcon" ? <DashboardIcon /> :
                        item.menu?.class_name === "SupervisedUserCircleIcon" ? <SupervisedUserCircleIcon /> :
                          item.menu?.class_name === "ControlCameraIcon" ? <ControlCameraIcon /> :
                            item.menu?.class_name === "FolderIcon" ? <FolderIcon /> :
                              item.menu?.class_name === "ContactsIcon" ? <ContactsIcon /> :
                                item.menu?.class_name === "CardMembershipIcon" ? <CardMembershipIcon /> :
                                  item.menu?.class_name === "AutorenewIcon" ? <AutorenewIcon /> :
                                    item.menu?.class_name === "CalendarMonthIcon" ? <CalendarMonthIcon /> :
                                      item.menu?.class_name === "BadgeIcon" ? <BadgeIcon /> :
                                        item.menu?.class_name === "Assignment" ? <Assignment /> :
                                          item.menu?.class_name === "MenuBook" ? <MenuBook /> :
                                            item.menu?.class_name === "AccountCircleIcon" ? <AccountCircleIcon /> :
                                              item.menu?.class_name === "Ballot" ? <Ballot /> :
                                                item.menu?.class_name === "EngineeringIcon" ? <EngineeringIcon /> :
                                                  item.menu?.class_name === "LibraryBooksIcon" ? <LibraryBooksIcon /> :
                                                    item.menu?.class_name === "TrendingUp" ? <TrendingUp /> :
                                                      item.menu?.class_name === "InterpreterMode" ? <InterpreterMode /> :
                                                        <PictureAsPdfIcon />}>

                    {item.submenu.map((subItem, subIndex) => {
                      if (!subItem.menu?.url_slug.match("edit") && !subItem.menu?.url_slug.match("view")) {
                        return (
                          subItem.submenu.length === 0 ?
                            <Tooltip title={subItem.menu?.title}>
                              <MenuItem id={subItem.menu?.url_slug} key={subIndex} active={urlPath === subItem.menu?.url_slug}>
                                {subItem.menu?.title}
                                <Link to={"/" + subItem.menu?.url_slug} />
                              </MenuItem>
                            </Tooltip>

                            : subItem.submenu.length > 0 && isVisible(subItem.submenu)?.length > 0 &&
                            <SubMenu key={subIndex} title={subItem.menu?.title} onClick={(e) => { openCloseSubmenu(e, subItem.menu?.url_slug) }} open={subItem.menu?.url_slug === urlPath}>

                              {subItem.submenu.map((submenusItem, submenusIndex) => {
                                if (!submenusItem.menu?.url_slug.match("edit") && !submenusItem.menu?.url_slug.match("view")) {
                                  // --------------------------------
                                  return ((submenusItem.menu.url_slug === 'my-intervie-ws' || submenusItem.menu.url_slug === 'manage-availability') ?
                                    ((tmsRole.role_name === 'Admin' || tmsRole.role_name === 'HR' || user.is_interviewer === true || tmsRole.role_name === 'Agent') ?
                                      <Tooltip title={submenusItem.menu?.title}>
                                        <MenuItem id={submenusItem.menu?.url_slug} key={submenusIndex} active={childUrlPath === submenusItem.menu?.url_slug}>
                                          {submenusItem.menu?.title}
                                          {submenusItem.menu?.url_slug && <Link to={"/" + submenusItem.menu?.url_slug}></Link>}
                                        </MenuItem>
                                      </Tooltip>
                                      :
                                      null) :

                                    <Tooltip title={submenusItem.menu?.title}>
                                      <MenuItem id={submenusItem.menu?.url_slug} key={submenusIndex} active={childUrlPath === submenusItem.menu?.url_slug}>
                                        {submenusItem.menu?.title}
                                        {submenusItem.menu?.url_slug && <Link to={"/" + submenusItem.menu?.url_slug}></Link>}
                                      </MenuItem>
                                    </Tooltip>

                                  )

                                  // --------------------------------
                                  // return (
                                  //   <Tooltip title={submenusItem.menu?.title}>
                                  //     <MenuItem id={submenusItem.menu?.url_slug} key={submenusIndex} active={urlPath === submenusItem.menu?.url_slug}>
                                  //       {submenusItem.menu?.title}
                                  //       {submenusItem.menu?.url_slug && <Link to={"/" + submenusItem.menu?.url_slug}></Link>}
                                  //     </MenuItem>
                                  //   </Tooltip>
                                  // )
                                }
                                return true;
                              })
                              }

                            </SubMenu>
                          // <MenuItem key={subIndex}>
                          //   {subItem.menu?.title}
                          //   <Link to={"/" + subItem.menu?.url_slug} />
                          // </MenuItem>
                        )
                      }
                      return true;
                    })}
                  </SubMenu>
                </Tooltip>
            )
          }
          )}
          <MenuItem icon={<LogoutIcon />} onClick={logout_user}>
            Logout
            <Link to={"/"} />
          </MenuItem>
        </Menu>
      </ProSidebar>

      {/* previous code */}
      {/* <ProSidebar>
        <Menu>
          {sidebarMenuList && sidebarMenuList.length > 0 && sidebarMenuList.map((item, index) => {
            return (

              item.submenu.length === 0 ?
                <MenuItem id={item.link}  key={index} className={item.link === parentUrlPath ? "active" : ""} icon={
                  item.icon === "DashboardIcon" ? <DashboardIcon /> :
                    item.icon === "SupervisedUserCircleIcon" ? <SupervisedUserCircleIcon /> :
                      item.icon === "ControlCameraIcon" ? <ControlCameraIcon /> :
                        item.icon === "FolderIcon" ? <FolderIcon /> :
                          item.icon === "ContactsIcon" ? <ContactsIcon /> :
                            item.icon === "CardMembershipIcon" ? <CardMembershipIcon /> :
                              item.icon === "AutorenewIcon" ? <AutorenewIcon /> :
                                item.icon === "CalendarMonthIcon" ? <CalendarMonthIcon /> :
                                  item.icon === "BadgeIcon" ? <BadgeIcon /> :
                                    item.icon === "Assignment" ? <Assignment /> :
                                      item.icon === "MenuBook" ? <MenuBook /> :
                                        item.icon === "AccountCircleIcon" ? <AccountCircleIcon /> :
                                          item.icon === "Ballot" ? <Ballot /> :
                                            item.icon === "EngineeringIcon" ? <EngineeringIcon /> :
                                              item.icon === "LibraryBooksIcon" ? <LibraryBooksIcon /> :
                                                item.icon === "TrendingUp" ? <TrendingUp /> :
                                                  item.icon === "InterpreterMode" ? <InterpreterMode /> :
                                                  item.icon === "Settings" ? <SettingsIcon /> :
                                                    <PictureAsPdfIcon />}>

                  {item.title}
                  <Link to={"/" + item.link} />
                </MenuItem>
                
                : item.submenu.length > 0 &&
              <Tooltip title = {item.title}> <SubMenu key={index} title={item.title}
                  onClick={() => { openCloseMenu(item.link) }}
                  open={item.link === parentUrlPath}
                  icon={
                    item.icon === "DashboardIcon" ? <DashboardIcon /> :
                      item.icon === "SupervisedUserCircleIcon" ? <SupervisedUserCircleIcon /> :
                        item.icon === "ControlCameraIcon" ? <ControlCameraIcon /> :
                          item.icon === "FolderIcon" ? <FolderIcon /> :
                            item.icon === "ContactsIcon" ? <ContactsIcon /> :
                              item.icon === "CardMembershipIcon" ? <CardMembershipIcon /> :
                                item.icon === "AutorenewIcon" ? <AutorenewIcon /> :
                                  item.icon === "CalendarMonthIcon" ? <CalendarMonthIcon /> :
                                    item.icon === "BadgeIcon" ? <BadgeIcon /> :
                                      item.icon === "Assignment" ? <Assignment /> :
                                        item.icon === "MenuBook" ? <MenuBook /> :
                                          item.icon === "AccountCircleIcon" ? <AccountCircleIcon /> :
                                            item.icon === "Ballot" ? <Ballot /> :
                                              item.icon === "EngineeringIcon" ? <EngineeringIcon /> :
                                                item.icon === "LibraryBooksIcon" ? <LibraryBooksIcon /> :
                                                  item.icon === "TrendingUp" ? <TrendingUp /> :
                                                    item.icon === "InterpreterMode" ? <InterpreterMode /> :
                                                      <PictureAsPdfIcon />}>

                  {item.submenu.map((subItem, subIndex) => {
                    if (!subItem.link.match("edit") && !subItem.link.match("view")) {
                      return (
                        subItem.menuItem.length === 0 ?
                          <Tooltip title={subItem.title}>
                            <MenuItem id={subItem.link} key={subIndex} active={urlPath === subItem.link}>
                              {subItem.title}
                              <Link to={"/" + subItem.link} />
                            </MenuItem>
                          </Tooltip>

                          : subItem.menuItem.length > 0 &&

                          <SubMenu key={subIndex} title={subItem.title} open={item.link === parentUrlPath}>

                            {subItem.menuItem.map((submenusItem, submenusIndex) => {
                              if (!submenusItem.link.match("edit") && !submenusItem.link.match("view")) {
                                return (
                                  <Tooltip title={submenusItem.title}>
                                    <MenuItem id={submenusItem.link} key={submenusIndex} active={urlPath === submenusItem.link}>
                                      {submenusItem.title}
                                      {submenusItem.link && <Link to={"/" + submenusItem.link}></Link>}
                                    </MenuItem>
                                  </Tooltip>
                                )
                              }
                              return true;
                            })
                            }

                          </SubMenu>

                        // <MenuItem key={subIndex}>
                        //   {subItem.title}
                        //   <Link to={"/" + subItem.link} />
                        // </MenuItem>
                      )
                    }
                    return true;
                  })}
                </SubMenu>
                </Tooltip> 
            )
          }
          )}
          <MenuItem icon={<LogoutIcon />} onClick={logout_user}>
            Logout
            <Link to={"/"} />
          </MenuItem>
        </Menu>
      </ProSidebar> */}

    </div>
  )
}

export default Sidebar
