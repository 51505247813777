import Navbar from "../../components/navbar/Navbar"
import Sidebar from "../../components/sidebar/Sidebar"
import "./master.scss"
import { toast, ToastContainer } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { postUploadResume } from "../../redux/features/masterSlice";
// import { errorHandling } from "../../redux/features/authLogoutSlice";
import { STATUSES } from "../../redux/common-status/constant";
import CONSTANTS from "../../components/constant/constantComponents";
import * as React from 'react';
import Button from '@mui/material/Button';
import LoaderForm from "../../components/common-function/LoaderForm";
import { AppBar, Autocomplete, Box, Checkbox, Tab, Tabs, TextField } from '@mui/material';
import { getEpicListByprojectId, getTaskListType, getTaskType, getResourceWiseProjectList, postTeamMemberCreate, taskAssigneeUpdateById, getEpicList, resourceProjectList, getMyJobList, getTeamMemberList } from "../../redux/features/taskSlice";
import { useEffect } from "react";
import { Tooltip } from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Dropzone from 'react-dropzone'
import GetLocalstorage from "../../components/common-function/GetLocalstorage";
import ResumeList from "../resume/ResumeList";
import ResumeAnalysisReport from "../reports/ResumeAnalysisReport";
import LocalStorageDataService from "../../components/common-function/GetLocalstorage";
// import dataService from "../../redux/services/data.service";
import { id } from "date-fns/locale";
// import Select from 'react-select';
// import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
// import CheckBoxIcon from '@mui/icons-material/CheckBox';

// const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
// const checkedIcon = <CheckBoxIcon fontSize="small" />;

const initialFormData = Object.freeze({
    epic_id: "",
    file: ""
});

const formErrData = Object.freeze({
    epicErr: "Job is required",
    fileErr: "File is required",
});

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`horizontal-tabpanel-${index}`}
            aria-labelledby={`horizontal-tab-${index}`}
            {...other}
        >
            {value === index && (
                <>
                    {children}
                </>
            )}
        </div>
    );
}

const user = LocalStorageDataService.userDetail();
const UploadResume = () => {
    // const inputRef = useRef(null)
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [formValues, setFormValues] = useState(initialFormData)
    const [formErr] = useState(formErrData)
    const [errStatus, setErrStatus] = useState(false);
    const { status, error, createUploadResume, } = useSelector(state => state.master)
    const { taskTypeList, epicList, epicListById } = useSelector(state => state.task)
    const { resumeList } = useSelector(state => state.resume)
    const [reload, setReload] = useState(false)
    const [fileErr, setFileErr] = useState("")
    const [defaultEpicValue, setDefaultEpicValue] = useState(null)
    const [defaultProjectValue, setDefaultProjectValue] = useState(null)
    const [projectId, setProjectId] = useState('')
    const [tabvalue, settabValue] = useState(0);

    const [jobApproverStatus, setJobApproverStatus] = useState("");
    const handleTabChange = (event, newValue) => {
        settabValue(newValue);
    };

    const { teamMemberList, createTeamMember, myJobList } = useSelector(state => state.task);
    // const [selectedMember, setSelectedMember] = useState([]);
    // const [selectedTempMember, setSelectedTempMember] = useState([]);
    const { epicId } = useParams()
    // const [activeMultiEpic, setActiveMultiEpic] = useState(false)
    // const [issueSelectedAssignee, setIssueSelectedAssignee] = useState({})
    // const [relatedLoader, setRelatedLoader] = useState(false)
    // const [epicIdByName, setEpicIdByName] = useState("")

    const [apiLoading, setApiLoading] = useState(false)
    const [defaultValueInTeam, setDefaultValueInTeam] = useState([])
    const [apiSubmitLoading, setApiSubmitLoading] = useState(false)


    const roleWiseFilter = `{"filter":{"resource_id":{"value":${user.id},"operation": "eq"}}, "order_by":{"column":"-id"}, "skip":0, "limit":25} `

    useEffect(() => {
        dispatch(getMyJobList(roleWiseFilter))
        dispatch(getResourceWiseProjectList(`{"filter":{"resource":{"value":${GetLocalstorage.userDetail().id},"operation":"eq"},"status":{"value":"True","operation":"eq"}},"order_by":{"column":"-id"},"no_limit":True}`))

        // dispatch(getTaskListType())
        // taskListType()
        // dispatch(getEpicList(`{"filter":{"issue_type":{"value":${epicIdByName},"operation": "eq"}},"no_limit":"True"}}`))
        // dispatch(resourceProjectList(`{"filter":{"jira_rtms_issue_id":{"value":${epicId},"operation":"eq"}},"no_limit":True}}`))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // const taskListType = async () => {
    //     const res = await dataService.task_type_list();
    //     let flag = false
    //     let epicIds
    //     if (res && res.data && res.data.data && res.data.data.rows) {

    //         res.data.data.rows.map(e => {

    //             if (e.issue_name === 'Epic' || e.issue_name === 'epic') {
    //                 epicIds = e.id;
    //                 flag = true
    //             }
    //         })
    //         if (flag === true) {
    //             dispatch(getEpicList({ "issue_type": epicIds }))
    //         }
    //     }
    // }
    //error handling
    useEffect(() => {
        if (status === STATUSES.FAILURE && error) {
            toast.error(error.message)
            setReload(false)
        } else if (status === `${STATUSES.SUCCESS}_createUploadResume`) {
            setReload(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error])



    // if (reload === true && taskTypeList && taskTypeList.data) {
    //     setDefaultEpicValue(null)
    //     setReload(false)
    //     // taskTypeList.data.rows && taskTypeList.data.rows.map((option) => {
    //     taskTypeList.data.rows && taskTypeList.data.rows.forEach((option) => {
    //         if (option.issue_name === 'Epic' || option.issue_name === 'epic') {
    //             const epic_id = option.id;
    //             dispatch(getEpicList({ "issue_type": epic_id }))
    //             setEpicIdByName(epic_id)
    //         }
    //     })
    // }

    if (reload === 'submit' && status === `${STATUSES.SUCCESS}_createUploadResume` && createUploadResume && createUploadResume.message) {
        if (createUploadResume.status === 200) {
            setFormValues(initialFormData);
            setDefaultEpicValue(null)
            setDefaultProjectValue(null)
            toast.success(createUploadResume.message)
        } else {
            toast.error(createUploadResume.message)
        }
        setReload(false)
    }

    if (apiLoading === true && teamMemberList && teamMemberList.data) {
        let array = []
        teamMemberList.data && teamMemberList.data.rows.map((item, index) => {
            if (item.is_temporary_reallocated === false) {
                array.push({
                    key: index,
                    label: item?.resource?.display_name,
                    value: item.resource_id,
                    is_temporary_reallocated: item?.is_temporary_reallocated,
                    name: 'team_members'
                })
            }
            return array
        })
        array.length > 0 && setDefaultValueInTeam(array)
        setApiLoading(false)
    }

    const updateTeamMember = (members) => {
        let array = []
        let array1 = []
        defaultValueInTeam.map((o) => array1.push(o.value));
        members.map(e => {
            return array.push(e.value)
        })
        // let array3 = array1.filter(function (obj) { return array.indexOf(obj) === -1; });

        let teamMemberData = {
            "jira_rtms_issue": epicId,
            "resource": defaultValueInTeam?.map((o) => o.value),
            "temp_reallocated": array,
            "status": 1,
            "created_by": LocalStorageDataService.userDetail().id
        }

        dispatch(postTeamMemberCreate(teamMemberData))
        // setDefaultValueInTeam(members);
        setApiSubmitLoading('submit')
    }

    if (apiSubmitLoading === 'submit' && createTeamMember && createTeamMember.message) {
        if (createTeamMember.status === 200) {
            toast.success(createTeamMember.message)
        } else {
            toast.error(createTeamMember.message)
        }
        setApiSubmitLoading(false)
    }

    const handleChange = (evt) => {
        if (evt) {
            const { name, value, label } = evt;
            if (name === 'epic_id') {
                setDefaultEpicValue(label)
                setJobApproverStatus(evt.job_approver_status)
                setDefaultValueInTeam([])
                setApiLoading(true)
                dispatch(getTeamMemberList(`{"filter":{"jira_rtms_issue_id":{"value":${value}, "operation":"eq"},"status":{"value":True, "operation":"eq"}}, "order_by":{"column":"-id"}, "no_limit":True}`))
            }
            setFormValues({ ...formValues, [name]: value, project: evt.project });
        } else {
            setDefaultEpicValue(null)
            setDefaultValueInTeam([])
            dispatch(getTeamMemberList(0))

            setFormValues({ ...formValues, epic_id: null });
        }

    }

    const submitData = (e) => {
        e.preventDefault()

        if (formValues.epic_id === "" || formValues.file === "") {
            setErrStatus(true)
        }
        if (formValues.epic_id && formValues.file) {
            const formData = new FormData();
            formData.append("file", formValues.file);
            formData.append("epic", `${formValues.epic_id}`);
            formData.append("project", `${formValues.project}`);

            dispatch(postUploadResume(formData))
            setReload('submit')
        }
    }


    const onFileChange = (event) => {
        // Update the state      
        let file = event[0]
        const fileType = file.name.split(".")[1];
        if (fileType === "pdf" || fileType === "doc" || fileType === "docx" || fileType === "zip" || fileType === "csv" || fileType === "xlsx") {
            // if (file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' || file.type === "application/pdf" || file.type === "application/zip" || file.type === "application/msword") {
            setFileErr("")
            setFormValues({ ...formValues, file: file });
        } else {
            setFormValues({ ...formValues, file: "" });
            setFileErr("You can only upload pdf, docx, doc, zip, csv and xlsx files")
        }
    }

    return (
        <div className="jiraStatus">
            <Sidebar />
            <div className="jiraStatusContainer">
                <Navbar />
                <ToastContainer />
                <div className="datatable">
                    <div className="top-headings">
                        <h3>Upload Resume</h3>

                        <Tooltip title="Back">
                            <button onClick={() => navigate(-1)} className="btn btn-add-new"><ArrowBackIcon /></button>
                        </Tooltip>
                    </div>
                    {(status === STATUSES.LOADING || reload === true || reload === 'edit_data') && <LoaderForm />}
                    <form>
                        <div className="add-new-user-form" style={{ marginTop: "20px" }}>

                            {/* <div style={{ flexBasis: "49%", marginBottom: "20px" }}>
                                <label style={{ marginBottom: "15px", display: "block" }} >
                                    Project
                                </label>
                                <Autocomplete
                                    style={{ width: "100%" }}
                                    value={defaultProjectValue}
                                    id="controllable-states-demo"
                                    onChange={(event, newValue) => {
                                        handleProjectChange(newValue);
                                    }}

                                    options={resourceWiseProjectList && resourceWiseProjectList.map((e, key) => {
                                        return { key: e.id, name: "project_id", value: e.id, label: e.project_name }
                                    })}
                                    // sx={{ width: 400 }}
                                    renderInput={(params) => <TextField {...params} label="Select Project" />}
                                />

                                {errStatus === true && formValues && formValues.project_id === "" && <p style={{ color: "#96332c", fontSize: 10 }}>{formErr.projectErr}</p>}
                            </div> */}

                            <div style={{ flexBasis: "49%", marginBottom: "20px" }}>
                                <label style={{ marginBottom: "15px", display: "block" }}>
                                    JOB
                                </label>
                                <Autocomplete
                                    style={{ width: "100%" }}
                                    id="controllable-states-demo"
                                    value={defaultEpicValue}
                                    onChange={(event, newValue) => {
                                        handleChange(newValue);
                                    }}
                                    options={
                                        myJobList && myJobList.data && myJobList.data.rows ? myJobList.data.rows.map((e) => {
                                            return {
                                                name: "epic_id", value: e?.id, label: e?.summary,
                                                job_approver_status: e?.job?.approver_status,
                                                project: e.project
                                            }
                                        }) : []
                                    }
                                    renderInput={(params) => <TextField {...params} label="Select Job" />}
                                />
                                {errStatus === true && formValues && formValues.epic_id === "" && <p style={{ color: "#96332c", fontSize: 10 }}>{formErr.epicErr}</p>}
                            </div>
                            <div style={{ flexBasis: "49%", marginBottom: "20px" }}>
                                {/* <label style={{ marginBottom: "15px", display: "block" }} >
                                    Team Member
                                </label> */}



                                {/* <Autocomplete
                                    multiple
                                    id="checkboxes-tags-demo"
                                    // onChange={(event, newValue) => {
                                    //     updateTeamMember(newValue);
                                    // }}
                                    options={teamMemberList && teamMemberList.data && teamMemberList.data.rows ? teamMemberList.data.rows.map((item, index) => {
                                        return {
                                            key: index,
                                            label: item?.resource?.display_name,
                                            value: item.resource_id,
                                            is_temporary_reallocated: item?.is_temporary_reallocated,
                                            name: 'team_members'
                                        }
                                    }) : []}
                                    getOptionLabel={(option) => option.label}
                                    // getOptionSelected={(option, value) => option.value === value.value}
                                    getOptionSelected={(option, value) => option.is_temporary_reallocated === value.is_temporary_reallocated}
                                    value={defaultValueInTeam}
                                    // defaultValue={defaultValueInTeam}
                                    disableCloseOnSelect
                                    renderOption={(props, option, { selected }) => (
                                        console.log(option, selected, "uuuu"),
                                        console.log(defaultValueInTeam, "defaultValueInTeam"),
                                        <li {...props}>
                                            <Checkbox
                                                icon={icon}
                                                checkedIcon={checkedIcon}
                                                style={{ marginRight: 8 }}
                                                checked={selected}
                                            // checked={teamMemberList && teamMemberList.data && teamMemberList.data?.rows.find((v) => v.is_temporary_reallocated === false) ? true : false}
                                            />
                                            {option.label}
                                        </li>
                                    )}
                                    style={{ width: 500 }}
                                    renderInput={(params) => (<TextField {...params} label="Select team members" placeholder="Favorites" />)}
                                /> */}

                                {/* <Autocomplete
                                    multiple
                                    // onChange={(event, newValue) => {
                                    //     updateTeamMember(newValue);
                                    // }}
                                    value={defaultValueInTeam}
                                    // defaultValue={defaultValueInTeam}
                                    id="checkboxes-tags-demo"
                                    getOptionLabel={(option) => option.label}
                                    options={teamMemberList && teamMemberList.data && teamMemberList.data.rows ? teamMemberList.data.rows.map((item, index) => {
                                        return {
                                            key: index,
                                            label: item?.resource?.display_name,
                                            value: item.resource_id,
                                            is_temporary_reallocated: item?.is_temporary_reallocated,
                                            name: 'team_members'
                                        }
                                    }) : []}
                                    disableCloseOnSelect
                                    renderOption={(props, option, { selected }) => (
                                        <li {...props}>
                                            <Checkbox
                                                icon={icon}
                                                checkedIcon={checkedIcon}
                                                style={{ marginRight: 8 }}
                                                checked={selected}
                                            />
                                            {option.label}
                                        </li>
                                    )}
                                    style={{ width: 500 }}
                                    renderInput={(params) => (
                                        <TextField {...params} label="Checkboxes" placeholder="Favorites" />
                                    )}
                                /> */}

                            </div>
                            {defaultEpicValue &&
                                <div className="formInput">
                                    <label >
                                        Upload Resume(Pdf/Zip/Docx/Doc/Csv/Xlsx)
                                    </label>
                                    <div className="file-dropzone-tabs">
                                        <Dropzone onDrop={acceptedFiles => onFileChange(acceptedFiles)}>
                                            {({ getRootProps, getInputProps }) => (
                                                <section>
                                                    <div {...getRootProps()}>
                                                        <input {...getInputProps()} />
                                                        <p>{formValues.file ? formValues.file.name : "Drag 'n' drop some files here, or click to select files"}</p>
                                                    </div>
                                                </section>
                                            )}
                                        </Dropzone>
                                    </div>
                                    {/* <input ref={inputRef} type="file" name="file" onChange={onFileChange} /> */}
                                    {errStatus === true && formValues && formValues.file === "" && <p style={{ color: "#96332c", fontSize: 13 }}>{formErr.fileErr}</p>}
                                    {fileErr !== "" && <p style={{ color: "#96332c", fontSize: 13 }}>{fileErr}</p>}

                                </div>
                            }
                        </div>
                        {jobApproverStatus === 4 ?
                            <Button className="submit-modal" onClick={submitData}>{CONSTANTS.COMMON_BUTTONS.SUBMIT}</Button>
                            : jobApproverStatus === "" ? " "
                                : <p style={{ color: "#96332c", fontSize: 20, margin: "10px" }}>Job is not live</p>}
                    </form>

                    <Box className="resume-tabs" sx={{ bgcolor: 'background.paper', width: '100%', marginTop: '10px' }}>
                        <AppBar position="static" style={{ backgroundColor: '#e7e7e7', color: '#000', borderRadius: '6px' }}>
                            <Tabs
                                value={tabvalue}
                                onChange={handleTabChange}
                                indicatorColor="primary"
                                textColor="primary"
                                variant="fullWidth"
                                aria-label="full width tabs example"
                            >
                                <Tab
                                    value={0}
                                    label="Resume Analysis Report"
                                />
                                <Tab value={1} label={`Failed Resume List(${resumeList.data && resumeList.data.count ? resumeList.data.count : 0})`} />

                            </Tabs>
                        </AppBar>
                        <TabPanel value={tabvalue} index={1} className="custom-loader-tabs" >
                            <ResumeList />
                        </TabPanel>
                        <TabPanel value={tabvalue} index={0} className="custom-loader-tabs" >
                            <ResumeAnalysisReport type={'tab'} />
                        </TabPanel>
                    </Box>
                </div>
            </div >
        </div >
    )
}

export default UploadResume
