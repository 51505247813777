import Navbar from "../../components/navbar/Navbar"
import Sidebar from "../../components/sidebar/Sidebar"
import './interview.scss'
import { toast, ToastContainer } from "react-toastify";
import { Card, CardContent, Dialog, DialogContent, FormControl, Grid, List, ListItem, Rating, Stack, TablePagination, TextField, Tooltip, Button, Paper, DialogTitle, Divider } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { interviewUpdate, getInterviewList, checkManageAvlList, getcommentInterviewerAgentList, interviewFeedbackUpdate, getAllInterviewList } from "../../redux/features/interviewSlice";
import { STATUSES } from "../../redux/common-status/constant";
import * as React from 'react';
import LoaderForm from "../../components/common-function/LoaderForm";
import TablePaginationActions from "../../components/TablePaginationActions";
import { Close } from "@mui/icons-material";
import CustomNoRowsOverlay from "../../components/CustomEmptyOverlay";
import { RefreshButton } from "../../components/common-function/RefreshButton";
//import { DefaultFilterColumns } from "../../components/common-function/DefaultFilterColumns";
import GetLocalstorage from "../../components/common-function/GetLocalstorage";
import Select from "react-select";
import { candidateOptionList, jobPostOptionList, resourceListOption, scheduleOptionList } from "../../redux/features/optionsSlice";
import { DateRangePicker } from 'rsuite';
import moment from "moment";
import ActionComponent from "./actionComponent";
import { styled } from '@mui/material/styles';
import EmailTemplet from "../../components/email-template/EmailTemplate";
import dataService from "../../redux/services/data.service";
import AddFeedBackform from "./AddFeedBackform";
import { getInterviewerFeedbackList } from "../../redux/features/masterSlice";

import { DefaultFilterColumns } from "../../components/common-function/DefaultFilterColumns";
import FileViewer from "react-file-viewer";
import CreatableSelect from 'react-select/creatable';
import MoreFilters from "../../components/moreFilters/MoreFIlters";



const ROLE = GetLocalstorage.userDetail() && GetLocalstorage.userDetail().tms_role && GetLocalstorage.userDetail().tms_role
const user = GetLocalstorage.userDetail()
const userInterviwer = user && user["show-candidate-resume"]

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    flexGrow: 1,
}));
const initialFormData = Object.freeze({

});


// const getUnique = (arr, index) => {

//     const unique = arr
//         .map(e => e[index])

//         // store the keys of the unique objects
//         .map((e, i, final) => final.indexOf(e) === i && i)

//         // eliminate the dead keys & store unique objects
//         .filter(e => arr[e]).map(e => arr[e]);

//     return unique;
// }

// const formErrData = Object.freeze({
//     descriptionErr: "Description is required",
//     interview_typeErr: "Interviews key is required",
//     interview_nameErr: "Interviews name is required",
//     statusErr: "Status is required"
// })

const { REACT_APP_API_BASEURL } = process.env;


const Interviews = ({ title }) => {
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const [formValues, setFormValues] = useState(initialFormData)
    // const [formErr] = useState(formErrData)
    const [errStatus, setErrStatus] = useState(false);
    const { status, error, interview_List, interview_Edit, interview_Update, manageAvlList, commentInterviewerAgentList, interview_feedback_Update,
        interview_reschedule_request_count_list, headDetails_feedBack } = useSelector(state => state.interview)

    const { resources_interviewer, resources_agent, jobPostOptions, candidateOptions, scheduleStatusOptions } = useSelector(state => state.dropdownOptions)
    const [currentPage, setCurrentPage] = useState(0);
    const [ID, setID] = useState("");
    const [reload, setReload] = useState(false)
    const [filterQuery, setFilterQuery] = useState(title === 'my-interview' ? ROLE && ROLE.role_key === 'agent' ?
        `{"filter":{"agent_id":{"value":${GetLocalstorage.userDetail().id}, "operation":"eq"}},"order_by":{"column":"-id"}, "columns" : [${DefaultFilterColumns.INTERVIEW_LIST_COLUMNS}],"skip":0, "limit":25}`
        : `{"filter":{"interviewer_id":{"value":${GetLocalstorage.userDetail().id}, "operation":"eq"}},"order_by":{"column":"-id"}, "columns" : [${DefaultFilterColumns.INTERVIEW_LIST_COLUMNS}],"skip":0, "limit":25}`
        : `{"order_by":{"column":"-id"},"skip":0, "limit":25,"columns" : [${DefaultFilterColumns.INTERVIEW_LIST_COLUMNS}]}`)
    const [pageSize, setPageSize] = useState(25);
    const [limit, setLimit] = useState(25);
    const [columnVisibilityModel, setColumnVisibilityModel] = useState({ id: false });
    const [filterInputs, setFilterInputs] = useState({})

    //const [anchorEl, setAnchorEl] = useState(null);
    const [fieldType, setFieldType] = useState("")
    const [avlStatus, setAvlStatus] = useState(false)
    const [activeColumn, setActiveColumn] = useState(false)
    const [showEmailModel, setShowEmailModel] = useState(false);
    const [shiftTime, setShiftTime] = useState("Default Time (09:30-19:15)")
    const [candidateEmail, setCandidateEmail] = useState([])

    const { interviewerFeedbackList, status: feedbackStatus } = useSelector((state) => state.master);


    //const [candidateOptions, setCandidateOption] = useState([])
    // const [interViewerOptions, setInterViewerOption] = useState([])
    // const [jobOptions, setJobOption] = useState([])

    //add feedback
    const [feedBackLoading, setFeedBackLoading] = useState(false)
    const [headData, setHeadData] = useState([])
    const [feedBackArray, setFeedBackArray] = useState([{
        interview_feedback_head: "",
        rating_value: "",
        sequence_number: "",
        rating_comment: "",
        status: true
    }])

    const [addHeadData, setAddHeadData] = useState([])
    const [opnenResume, setOpenResume] = useState(false)
    const [resumeFilePath, setResumeFilePath] = useState("")
    const [resumeFileType, setResumeFileType] = useState("")

    // start slot code 
    const [slotLoading, setSlotLoading] = useState(false)
    const [mangageAvalList, setMangageAvalList] = useState([])
    const [selectedGapSlotsIs, setSelectedGapSlotsIs] = useState([])
    const [bookedSlots, setBookedSlots] = useState([])
    const [slotArray, setSlotArray] = useState([])
    const [flag, setFlag] = useState(false)
    const [selectedSlotsIs, setSelectedSlotsIs] = useState([])
    const [singleSlot, setSingleSlot] = useState("")
    const [selectedSingleGapSlots, setSelectedSingleGapSlotsIs] = useState([])
    const setSelectedGapSlotsForSubmit = useState([])[1]
    const [selectDueration, setSelectDueration] = useState({ value: 30, label: 30 })
    const [selectedSlotsForSubmit, setSelectedSlotsForSubmit] = useState([])
    const { interviewers_shift } = useSelector(state => state.dropdownOptions)
    const [scheduledTime, setScheduledTime] = useState("")
    const [caneledInterviewInterviewer, setCaneledInterviewInterviewer] = useState(null)
    const [objectDetail, setObjectDetails] = useState({})



    const nothing = () => {
        //nothig
    }
    const [interviewUpdateDoneData, setInterviewUpdateDoneData] = useState(null)

    const [filterClosingFields, setFilterClosingFields] = useState({ columnField: '', operatorValue: '' });

    const countRequest = () => {
        ROLE && ROLE.role_key === 'agent' && dispatch(getAllInterviewList(`{"filter":{"agent_id":{"value":${GetLocalstorage.userDetail().id}, "operation":"eq"}},"order_by":{"column":"-id"},"no_limit":True, "columns":[${DefaultFilterColumns.INTERVIEW_LIST_COLUMNS}]}`))

    }

    // const createMeetingHyperLink = (meetLink) => {
    //     if (typeof meetLink !== "string" || meetLink.trim() === "") {
    //         return "Invalid meeting link";
    //     }

    //     let meetingPlatformName = "";

    //     if (meetLink.indexOf("meet") > -1) {
    //         meetingPlatformName = "Google Meet";
    //     } else if (meetLink.indexOf("zoom") > -1) {
    //         meetingPlatformName = "Zoom";
    //     }

    //     if (meetingPlatformName) {
    //         return `<a href="${meetLink}" target="_blank">${meetingPlatformName} Meeting</a>`;
    //     } else {
    //         return "Unknown meeting platform";
    //     }
    // };

    const updateAcceptStatus = (data) => {
        let ID = data.id
        let addvalues = {
            meeting_url: data.meeting_url,
            comment: data.comment,
            comment_by: GetLocalstorage.userDetail().id,
            status: true,
        }
        let interviewer_status_id = scheduleStatusOptions.filter(e => e.status_key === 'in_progress' && e)
        addvalues['status_key'] = interviewer_status_id.length > 0 ? interviewer_status_id[0].status_key : ""
        addvalues['status_id'] = interviewer_status_id.length > 0 ? interviewer_status_id[0].id : ""
        setReload(true)
        dispatch(interviewUpdate({ ID, addvalues }))
    }


    const createMeetingHyperLink = (data, meetLink) => {

        let meetingPlatformName = "";
        if (meetLink?.indexOf("meet") > -1) {
            // if (meetLink && meetLink.indexOf("meet") > -1){
            meetingPlatformName = "Google Meet";

        } else if (meetLink?.indexOf("zoom") > -1) {
            meetingPlatformName = "Zoom";
        }
        return meetingPlatformName ? <span onClick={() => updateAcceptStatus(data)}><a href={meetLink} target="_blank"> {meetingPlatformName} </a></span> : <span> NA</span>
    }
    useEffect(() => {
        dispatch(getInterviewList(filterQuery))
        // countRequest()
        dispatch(resourceListOption())
        dispatch(jobPostOptionList())
        dispatch(candidateOptionList())
        dispatch(scheduleOptionList())
        // dispatch(getInterviewerFeedbackList())
        // if (status === STATUSES.FAILURE && error) {
        //     setReload(false)
        //     errorHandling(error, navigate)
        // }
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (status === STATUSES.FAILURE && error) {
            toast.error(error.message)
            setReload(false)
        }
        // eslint-disable-next-line
    }, [error])

    // if (loadingOption === true && interview_AllList && interview_AllList.message) {
    //     let candiArray = []
    //     let interArray = []
    //     let jobArray = []
    //     if (interview_AllList.status === 200 && interview_AllList.data) {
    //         interview_AllList.data && interview_AllList.data.rows.map(e => {
    //             if (e.candidate_id) {
    //                 candiArray.push({
    //                     value: e.candidate_id,
    //                     label: e.candidate.candidate_name,
    //                 })
    //             }
    //             if (e.interviewer_id) {
    //                 interArray.push({
    //                     value: e.interviewer_id,
    //                     label: e.interviewer.display_name,
    //                 })
    //             }
    //             if (e.job_id) {
    //                 jobArray.push({
    //                     value: e.job_id,
    //                     label: e.job.job_title,
    //                 })
    //             }

    //         })
    //     }
    //     //setCandidateOption(getUnique(candiArray, 'value'))
    //     //setInterViewerOption(getUnique(interArray, 'value'))
    //     //setJobOption(getUnique(jobArray, 'value'))
    //     setLoadingOption(false)
    // }

    //const handleOpen = () => setOpen(true);
    const handleClose = () => {
        if (ID) { setID(0); setFormValues(initialFormData) } setOpen(false); setFormValues(initialFormData);
        setErrStatus(false)
        dispatch(checkManageAvlList('type'))
        dispatch(getcommentInterviewerAgentList('type'))
        setAvlStatus(false)
        setAddHeadData([])
        setHeadData([])
        setSlotArray([])
        setSelectedSlotsForSubmit([])
        setSelectedSlotsIs([])
        setSingleSlot("")
        setScheduledTime("")
        setObjectDetails({})
        setCaneledInterviewInterviewer(null)
    }

    if (reload === true && status === `${STATUSES.SUCCESS}_interview_Update` && interview_Update && interview_Update.message) {
        if (interview_Update.status === 200) {
            toast.success(interview_Update.message)
            dispatch(getInterviewList(filterQuery))
            countRequest()
            handleClose()
        } else {
            toast.error(interview_Update.message)
        }
        setReload(false)
    }

    if (reload === true && status === `${STATUSES.SUCCESS}_interview_feedback_Update` && interview_feedback_Update && interview_feedback_Update.message) {
        if (interview_feedback_Update.status === 200) {
            toast.success(interview_feedback_Update.message)
            dispatch(getInterviewList(filterQuery))
            countRequest()
            handleClose()
        } else {
            toast.error(interview_feedback_Update.message)
        }
        setReload(false)
    }

    // heads details on the based on story_id
    if (feedBackLoading === true && status === `${STATUSES.SUCCESS}_headDetails_feedBack` && headDetails_feedBack && headDetails_feedBack.message) {
        if (headDetails_feedBack.status === 200) {
            let feedBackHead = headDetails_feedBack?.data && headDetails_feedBack.data.rows
            setHeadData(feedBackHead)
            let array = []
            // eslint-disable-next-line
            feedBackHead && feedBackHead.map((item, i) => {
                array.push({
                    status: true,
                    interview_feedback_head: item.interview_feedback_head__id,
                    sequence_number: i + 1,
                    rating_comment: "",
                    rating_value: ""
                })
            })
            setFeedBackArray(array)
            // toast.success(headDetails_feedBack.message)
        } else {
            setHeadData([])
            // toast.error(headDetails_feedBack.message)
        }
        setFeedBackLoading(false)
    }

    //   const STATUSES = {
    //     LOADING: 'loading', // Define your loading status here
    // };

    // const handleInputChange = (event) => {
    //     const { name, value } = event.target;
    //     setFilterInputs({ ...filterInputs, [name]: value });
    // };

    const handleChange = (evt, subType) => {
        const { name, value } = evt.target || evt;
        if (name === 'scheduled_date') {
            setAvlStatus(false)
            dispatch(checkManageAvlList('type'))
            setSlotArray([])
            setSelectedSlotsForSubmit([])
            setSelectedSlotsIs([])
            setSingleSlot("")
            setScheduledTime("")
        }
        setFormValues({ ...formValues, [name]: value })
    }

    const handleChangeFeedBack = (evt, index = null) => {
        const { name, value } = evt.target || evt;
        const valueArray = feedBackArray.length > 0 && feedBackArray.map((item, i) => i === index ?
            { ...item, [name]: value } : item)
        index !== null && setFeedBackArray([...valueArray])
    }

    const handleCancelCreate = async (e) => {
        e.preventDefault()
        if (!formValues.comment) {
            setErrStatus(true)
        } else {
            const req = await dataService.resource_view(formValues.obj.interviewer_id)
            const res = req.data

            if (req && req.data) {
                setShiftTime(res.data.shift && res.data.shift.start_time ? res.data.shift.start_time + "-" + res.data.shift.end_time : "Default Time (09:30-19:15)")
            }
            const request = await dataService.get_task_By_id(formValues.obj.story_id)
            const response = request.data
            if (response && response.data) {
                if (response.data.candidate_email && response.data.candidate_email.length > 0) {
                    setCandidateEmail([response.data.candidate_email[0].email])

                }
            }
            // handleClose()
            setOpen(false)
            setShowEmailModel(true)
        }

    }

    const closeEmailModel = () => {
        setShowEmailModel(false)
    }


    const submitData = async (e, type) => {
        e.preventDefault()
        let flag;
        let dataIs = ""

        if (fieldType === 'r' && formValues.scheduled_date && formValues.scheduled_time && formValues.comment) {
            let interviewer_status_id = scheduleStatusOptions.filter(e => e.status_key === 'reschedule' && e)
            dataIs = { ...formValues }
            //dataIs['interview_status'] = interviewer_status_id.length > 0 ? interviewer_status_id[0].id : ""
            dataIs['status_key'] = interviewer_status_id.length > 0 ? interviewer_status_id[0].status_key : ""
            dataIs['status_id'] = interviewer_status_id.length > 0 ? interviewer_status_id[0].id : ""
            flag = true
            setErrStatus(false)
        } else {
            setErrStatus(true)
        }

        // request for reschedule section
        if (fieldType === 'rr' && formValues.scheduled_date && formValues.scheduled_time && formValues.comment) {

            if (selectedSlotsForSubmit && selectedSlotsForSubmit.length === 0 && scheduledTime === "") {
                return toast.error("Please slelect slot !", {
                    position: toast.POSITION.TOP_CENTER
                })
            }

            let interviewer_status_id = scheduleStatusOptions.filter(e => e.status_key === 'reschedule_request' && e)
            dataIs = { ...formValues }
            //dataIs['interview_status'] = interviewer_status_id.length > 0 ? interviewer_status_id[0].id : ""
            dataIs['requested_reschedule_date'] = formValues.scheduled_date ? formValues.scheduled_date : ""
            dataIs['requested_reschedule_time'] = formValues.scheduled_time ? formValues.scheduled_time : ""
            dataIs['requested_reschedule_comment'] = formValues.comment ? formValues.comment : ""
            dataIs['status_key'] = interviewer_status_id.length > 0 ? interviewer_status_id[0].status_key : ""
            dataIs['status_id'] = interviewer_status_id.length > 0 ? interviewer_status_id[0].id : ""
            let length = selectedSlotsForSubmit.length - 1

            if (scheduledTime) {
                flag = true
                let default_slot = selectDefaultSlotsChange(scheduledTime)
                let length = default_slot.length - 1
                dataIs['requested_reschedule_time'] = default_slot[0]
                dataIs['requested_reschedule_end_time'] = default_slot[length]
                // addvalues['booked_slots'] = [{ scheduled_time: default_slot[0], scheduled_end_time: default_slot[length] }]
            } else {
                dataIs['requested_reschedule_time'] = convertTime12to24IsChange(selectedSlotsForSubmit[0])
                dataIs['requested_reschedule_end_time'] = convertTime12to24IsChange(selectedSlotsForSubmit[length])
            }

            flag = true
            setErrStatus(false)
        } else {
            setErrStatus(true)
        }

        if (fieldType === 'cr' && formValues.comment) {
            let interviewer_status_id = scheduleStatusOptions.filter(e => type === 'cs' ? e.status_key === 'reschedule' : e.status_key === 'cancel_interview' && e)
            dataIs = { ...formValues }

            //dataIs['interview_status'] = interviewer_status_id.length > 0 ? interviewer_status_id[0].id : ""
            dataIs['status_key'] = interviewer_status_id.length > 0 ? interviewer_status_id[0].status_key : ""
            dataIs['status_id'] = interviewer_status_id.length > 0 ? interviewer_status_id[0].id : ""
            dataIs['requested_schedule_date'] = formValues.obj.scheduled_date ?? ' '
            dataIs['requested_schedule_time'] = formValues.obj.requested_reschedule_time ?? ' '
            dataIs['requested_reschedule_end_time'] = formValues.obj.requested_reschedule_end_time ?? ' '

            delete dataIs['obj']

            flag = true
            setErrStatus(false)

        } else {
            setErrStatus(true)
        }

        if (fieldType === 'ci' && formValues.comment) {
            let interviewer_status_id = scheduleStatusOptions.filter(e => e.status_key === 'cancel_interview' && e)
            dataIs = formValues
            //dataIs['interview_status'] = interviewer_status_id.length > 0 ? interviewer_status_id[0].id : ""
            dataIs['status_key'] = interviewer_status_id.length > 0 ? interviewer_status_id[0].status_key : ""
            dataIs['status_id'] = interviewer_status_id.length > 0 ? interviewer_status_id[0].id : ""
            flag = true
            setErrStatus(false)
        } else {
            setErrStatus(true)
        }

        if (fieldType === 'd' && formValues.comment && formValues?.candidate_interview_status) {
            let interviewer_status_id = scheduleStatusOptions.filter(e => e.status_key === 'done' && e)
            let { comment } = formValues
            dataIs = { ...interviewUpdateDoneData, comment }
            //dataIs['interview_status'] = interviewer_status_id.length > 0 ? interviewer_status_id[0].id : ""
            dataIs['status_key'] = interviewer_status_id.length > 0 ? interviewer_status_id[0].status_key : ""
            dataIs['status_id'] = interviewer_status_id.length > 0 ? interviewer_status_id[0].id : ""
            flag = true
            setErrStatus(false)
        } else {
            setErrStatus(true)
        }

        if (fieldType === 'i' && formValues.comment) {
            let interviewer_status_id = scheduleStatusOptions.filter(e => e.status_key === 'interrupted' && e)
            dataIs = formValues
            //dataIs['interview_status'] = interviewer_status_id.length > 0 ? interviewer_status_id[0].id : ""
            dataIs['status_key'] = interviewer_status_id.length > 0 ? interviewer_status_id[0].status_key : ""
            dataIs['status_id'] = interviewer_status_id.length > 0 ? interviewer_status_id[0].id : ""
            flag = true
            setErrStatus(false)

        }
        else {
            setErrStatus(true)
        }

        // if (fieldType === 'af' && formValues.comment) {
        //     // let interviewer_status_id = scheduleStatusOptions.filter(e => e.status_key === 'done' && e)
        //     // dataIs = formValues
        //     //dataIs['interview_status'] = interviewer_status_id.length > 0 ? interviewer_status_id[0].id : ""
        //     // dataIs['status_key'] = interviewer_status_id.length > 0 ? interviewer_status_id[0].status_key : ""
        //     // dataIs['status_id'] = interviewer_status_id.length > 0 ? interviewer_status_id[0].id : ""
        //     // dataIs['feedback_by_interviewer'] = formValues.comment
        //     // dataIs['feedback_by_interviewer_resource'] = GetLocalstorage.userDetail().id

        //     flag = true
        //     setErrStatus(false)

        // } else {
        //     setErrStatus(true)
        // }

        if (fieldType === 'as') {
            let interviewer_status_id = scheduleStatusOptions.filter(e => e.status_key === 'accept_schedule' && e)
            dataIs = formValues
            //dataIs['interview_status'] = interviewer_status_id.length > 0 ? interviewer_status_id[0].id : ""
            dataIs['status_key'] = interviewer_status_id.length > 0 ? interviewer_status_id[0].status_key : ""
            dataIs['status_id'] = interviewer_status_id.length > 0 ? interviewer_status_id[0].id : ""

            flag = true
            setErrStatus(false)

        } else {
            setErrStatus(true)
        }

        if (fieldType === 's' && formValues.scheduled_date) {
            let interviewer_status_id = scheduleStatusOptions.filter(e => e.status_key === 'in_progress' && e)
            dataIs = { ...formValues }
            //dataIs['interview_status'] = interviewer_status_id.length > 0 ? interviewer_status_id[0].id : ""
            dataIs['status_key'] = interviewer_status_id.length > 0 ? interviewer_status_id[0].status_key : ""
            dataIs['status_id'] = interviewer_status_id.length > 0 ? interviewer_status_id[0].id : ""
            flag = true
            setErrStatus(false)
        } else {
            setErrStatus(true)
        }

        if (flag === true) {

            setReload(true)
            let addvalues = dataIs
            // if (fieldType === 'af') {
            //     delete addvalues['comment']
            //     delete addvalues['comment_by']
            // }
            if (fieldType !== 'r' || fieldType !== 'rr') {
                delete addvalues['scheduled_date']
                delete addvalues['scheduled_time']
            }
            delete addvalues['interviewer_id']
            delete addvalues['interview_status']
            if (ID > 0) {
                if (fieldType === 'd') {
                    let feedbackData = headData && headData.length > 0 ? feedBackArray : addHeadData
                    let flag = false
                    if (feedbackData.length > 0) {
                        feedbackData.map((e, i) => {
                            if (!e.rating_value || !e.rating_comment) {
                                setErrStatus(true)
                                setReload(false)
                                flag = true
                            }
                        })
                    }
                    if(!formValues.rating_comment || !formValues.rating_value){
                        setErrStatus(true)
                        setReload(false)
                        flag = true
                    }
                    let finalData = {
                        feedback: formValues.comment,
                        interview_details: formValues.id,
                        jira_rtms_issue: formValues.story_id,
                        feedback_details: headData && headData.length > 0 ? feedBackArray : addHeadData,
                        feedback_to_agent: {
                            "rating_comment": formValues.rating_comment,
                            "rating_value": formValues.rating_value
                        },
                    }
                    if (formValues?.candidate_interview_status) {
                        finalData.candidate_interview_status = formValues.candidate_interview_status
                        addvalues.candidate_interview_status = formValues.candidate_interview_status;
                    }
                    if (flag === false) {
                        const res = await dispatch(interviewUpdate({ ID, addvalues }))
                        if (res?.payload?.status === 200) {
                            dispatch(interviewFeedbackUpdate(finalData))
                            setReload(true)
                        }
                    }

                } else if (fieldType === 'rr') {
                    dispatch(interviewUpdate({ ID, addvalues }))
                } else if (fieldType === 's') {
                    dispatch(interviewUpdate({ ID, addvalues }))
                    window.open(addvalues.meeting_url)

                } else {
                    dispatch(interviewUpdate({ ID, addvalues }))
                }
            }
        }

        // if (formValues.interview_type === "" || formValues.interview_name === "" || formValues.description === "" || formValues.status === "") {
        //     setErrStatus(true)
        // }

        // if (formValues.interview_type !== "" && formValues.interview_name !== "" && formValues.description !== "" && formValues.status !== "") {
        //     let userId = GetLocalstorage.userDetail().id
        //     let addvalues = { ...formValues, created_by: userId, assigned_by: userId }
        //     setErrStatus(false)
        //     setReload(true)
        //     handleClose()
        //     if (ID) {
        //         dispatch(interviewUpdate({ ID, addvalues }))
        //     } else {
        //         dispatch(interviewCreate(addvalues))
        //     }
        // }
    }


    if (reload === 'edit_data' && status === `${STATUSES.SUCCESS}` && interview_Edit && interview_Edit.data) {
        let editData = interview_Edit.data
        setFormValues({
            interview_name: editData.interview_name,
            interview_type: editData.interview_type,
            description: editData.description,
            status: editData.status
        })
        setID(editData.id)
        setOpen(true)
        setReload(false)
    }

    // const handleClickEdit = (id) => {
    //     dispatch(interviewEdit(id))
    //     setReload('edit_data')
    // }
    const onfilterChange = (evt) => {
        const { value, name } = evt.target || evt
        if (name === 'interview_link') {
            setFilterInputs({ ...filterInputs, [name]: filterInputs.interview_link ? !filterInputs.interview_link : true })

        } else if (name === 'attachemnt') {
            setFilterInputs({ ...filterInputs, [name]: filterInputs.attachemnt ? !filterInputs.attachemnt : true })

        } else {
            setFilterInputs({ ...filterInputs, [name]: value })
        }

    }

    // const refresh = () => {
    //     dispatch(getInterviewList(filterQuery))
    //     countRequest()
    // }

    const clear = () => {
        setFilterInputs({
            interviewer_name: null,
            candidate_name: null,
            status: null,
            scheduled_by: null,
            job_title: '',
            feedback_by_interviewer: "",
            interview_link: "",
            meeting_url: "",
            date: '',
            time: '',
            interview_message: ''
        })
        let filter = title === 'my-interview' ? ROLE && ROLE.role_key === 'agent' ?
            `{"filter":{"agent_id":{"value":${GetLocalstorage.userDetail().id}, "operation":"eq"}},"order_by":{"column":"-id"},"skip":0, "limit":25,"columns":[${DefaultFilterColumns.INTERVIEW_LIST_COLUMNS}]}`
            : `{"filter":{"interviewer_id":{"value":${GetLocalstorage.userDetail().id}, "operation":"eq"}},"order_by":{"column":"-id"},"skip":0, "limit":25,"columns":[${DefaultFilterColumns.INTERVIEW_LIST_COLUMNS}]}`
            : `{"order_by":{"column":"-id"},"skip":0, "limit":${limit},"columns":[${DefaultFilterColumns.INTERVIEW_LIST_COLUMNS}]}`
        setFilterQuery(filter)
        dispatch(getInterviewList(filter))
        dispatch(getcommentInterviewerAgentList('type'))
        countRequest()
    }

    const search = () => {
        let filter = {
            filter: {},
            order_by: { column: '-id' },
            skip: 0,
            limit: limit,
            columns: DefaultFilterColumns.INTERVIEW_LIST_COLUMNS_JSON,
        };

        if (title === 'my-interview' && ROLE && ROLE.role_key === 'agent') {
            filter.filter.agent_id = { value: GetLocalstorage.userDetail().id, operation: 'eq' };
        }
        else if (title === 'my-interview' && ROLE?.role_key === 'agent') {
            filter = { "filter": { "agent_id": { "value": GetLocalstorage.userDetail().id, "operation": "eq" } }, "skip": 0, "limit": limit, "order_by": { "column": "-id" }, "columns": DefaultFilterColumns.INTERVIEW_LIST_COLUMNS_JSON }

        }
        if (filterInputs.interviewer_name) {
            if (title === 'my-interview' && GetLocalstorage.userDetail().is_interviewer === true && ROLE && (ROLE.role_key !== 'agent' || ROLE.role_key !== 'admin')) {
                filter['filter']['interviewer_id'] = { "value": GetLocalstorage.userDetail().id, "operation": "eq" }
            } else {
                filter['filter']['interviewer_id'] = { "value": filterInputs.interviewer_name.value, "operation": "eq" }

            }
        }
        if (filterInputs.interviewer_name === "") {
            delete filter['filter']['interviewer_id']
        }
        if (filterInputs.candidate_name) {
            filter['filter']['candidate_id'] = { "value": filterInputs.candidate_name.value, "operation": "eq" }
        }
        if (filterInputs.candidate_name === '') {
            delete filter['filter']['candidate_id']
        }
        if (filterInputs.interview_message) {
            filter['filter']['interview_message'] = { "value": filterInputs.interview_message, "operation": "contains" }
        }
        if (filterInputs.interview_message === '') {
            delete filter['filter']['interview_message']
        }
        if (filterInputs.feedback_by_interviewer) {
            filter['filter']['feedback_by_interviewer'] = { "value": filterInputs.feedback_by_interviewer, "operation": "contains" }
        }
        if (filterInputs.feedback_by_interviewer === '') {
            delete filter['filter']['feedback_by_interviewer']
        }
        if (filterInputs.interview_link) {
            filter['filter']['meeting_url'] = { "value": filterInputs.interview_link, "operation": "eq" }
        }
        if (filterInputs.attachemnt) {
            filter['filter']['attachemnt'] = { "value": filterInputs.attachemnt, "operation": "eq" }
        }
        if (filterInputs.job_title) {
            filter['filter']['job_id'] = { "value": filterInputs.job_title.value, "operation": "eq" }
        }
        if (filterInputs.job_title === '') {
            delete filter['filter']['job_id']
        }
        if (filterInputs.time) {
            filter['filter']['scheduled_time'] = { "value": filterInputs.time, "operation": "eq" }
        }
        if (filterInputs.date) {
            let date_filter = { "value": [moment(filterInputs.date[0]).format('YYYY-MM-DD'), moment(filterInputs.date[1]).format('YYYY-MM-DD')], "operation": "range" }
            filter['filter']['scheduled_date'] = date_filter
        }
        if (filterInputs.scheduled_by) {
            if (title === 'my-interview' && ROLE && ROLE.role_key === 'agent') {
                filter['filter']['agent_id'] = { "value": GetLocalstorage.userDetail().id, "operation": "eq" }

            } else {
                filter['filter']['agent_id'] = { "value": filterInputs.scheduled_by.value, "operation": "eq" }

            }
        }
        if (filterInputs.scheduled_by === '') {
            delete filter['filter']['agent_id']
        }
        if (filterInputs.status) {
            filter['filter']['interview_status_id'] = { "value": filterInputs.status.value, "operation": "eq" }
        }
        if (filterInputs.status === '') {
            delete filter['filter']['interview_status_id']
        }
        setFilterQuery(JSON.stringify(filter))
        dispatch(getInterviewList(JSON.stringify(filter)))
        countRequest()

    }

    const activeModel = (obj, type) => {
        setID(obj.id)
        // let key = type === 'rr' || type === 'as' ||  type === 'af' ? 'comment_by_interviewer' : 'comment_by_agent'
        // if (type === 'ci' || type === 'ad') {
        //     if (GetLocalstorage.userDetail().tms_role && GetLocalstorage.userDetail().tms_role.role_key === 'agent' && GetLocalstorage.userDetail().id === obj.agent_id) {
        //         key = 'comment_by_agent'
        //     } else {
        //         key = 'comment_by_interviewer'
        //     }
        // }
        if (type === 'sc') {
            let filter = { "filter": { "interviewer_details_id": { "value": obj.id, "operation": "eq" } }, "order_by": { "column": "-id" }, "no_limit": 'True' }
            //let filter = { "filter": { "interviewer_detail_id": { "value": 27, "operation": "eq" }}}

            dispatch(getcommentInterviewerAgentList(JSON.stringify(filter)))
        }


        if (type === 'd') {
            setFeedBackLoading(true)
            // dispatch(getHeadsDetalsOnFeedback(obj.story_id))
            setFormValues(obj)
            setInterviewUpdateDoneData({ scheduled_date: obj.scheduled_date, scheduled_time: obj.scheduled_time, meeting_url: obj.meeting_url, comment: "", comment_by: GetLocalstorage.userDetail().id, interview_status: obj.interview_status__status_key, interviewer_id: obj.interviewer_id, status: obj.status })

        } else if (type === 'rr') {
            if (GetLocalstorage.userDetail()?.tms_role?.role_key === "agent") {
                setCaneledInterviewInterviewer(obj?.interviewer_id)
            }
            setFormValues({ scheduled_date: obj.scheduled_date, scheduled_time: obj.scheduled_time, comment: "", comment_by: GetLocalstorage.userDetail().id })
        } else if (type === "vf") {
            setObjectDetails(obj)
            dispatch(getInterviewerFeedbackList(obj.story_id))

        } else if (type === 'cr') {
            setFormValues({ comment: "", comment_by: GetLocalstorage.userDetail().id, obj: obj })

        } else if (type === 's') {
            setFormValues({ scheduled_date: obj.scheduled_date, scheduled_time: obj.scheduled_time, meeting_url: obj.meeting_url, comment: "", comment_by: GetLocalstorage.userDetail().id, interview_status: obj.interview_status__status_key, interviewer_id: obj.interviewer_id, status: obj.status })
        }
        else {
            setFormValues({ scheduled_date: obj.scheduled_date, scheduled_time: obj.scheduled_time, meeting_url: obj.meeting_url, comment: "", comment_by: GetLocalstorage.userDetail().id, interview_status: obj.interview_status__status_key, interviewer_id: obj.interviewer_id, status: obj.status })
        }


        setFieldType(type)
        setOpen(true)
    }

    const checkAvailability = (e) => {
        e.preventDefault()
        //let filter = { "interviewer_id": 14, "schedule_date": '2023-04-29' }
        let filter = { "interviewer_id": formValues.interviewer_id ? formValues.interviewer_id : GetLocalstorage.userDetail().id, "schedule_date": formValues.scheduled_date }
        //let filter = { "filter": { "resource_id": { "value": formValues.interviewer_id ? formValues.interviewer_id : GetLocalstorage.userDetail().id, "operation": "eq" }, "schedule_date": { "value": formValues.scheduled_date, "operation": "eq" } } }
        setAvlStatus(true)
        dispatch(checkManageAvlList(JSON.stringify(filter)))
    }

    // start slot code
    // Convert minutes to a time in format hh:mm
    // Returned value is in range 00  to 24 hrs
    function timeFromMins(mins) {
        function z(n) { return (n < 10 ? '0' : '') + n; }
        let h = (mins / 60 | 0) % 24;
        let m = mins % 60;
        return z(h) + ':' + z(m);
    }

    // Add two times in hh:mm format
    function addTimes(t0, t1) {
        return timeFromMins(timeToMins(t0) + timeToMins(t1));
    }

    function timeToMins(time) {
        let b = time.split(':');
        return b[0] * 60 + +b[1];
    }
    // all ready Booked slots section (red)
    const alreadyBookedSlots = (resp) => {
        let array3 = []
        let array4 = []
        let array5 = []

        let defaultSlotTime = resp.data.default_interview_blocktime_in_mins ? Number(resp.data.default_interview_blocktime_in_mins) : 10
        // eslint-disable-next-line
        resp.data && resp.data.interview_schedules && resp.data.interview_schedules.map((e) => {
            let startTime = e.scheduled_time
            let endTime = e.scheduled_end_time
            let todayDate = moment(resp.data.schedule_date).format("MM-DD-YYYY"); //Instead of today date, We can pass whatever date        
            // let startTime = "11:40:00"
            // let endTime = "11:50:00"

            let startDate = new Date(`${todayDate} ${startTime}`);
            let endDate = new Date(`${todayDate} ${endTime}`);
            let timeDiff = Math.abs(startDate.getTime() - endDate.getTime());
            let hh = Math.floor(timeDiff / 1000 / 60 / 60);
            hh = ('0' + hh).slice(-2)

            timeDiff -= hh * 1000 * 60 * 60;
            let mm = Math.floor(timeDiff / 1000 / 60);
            mm = ('0' + mm).slice(-2)

            timeDiff -= mm * 1000 * 60;

            let minuteDiff = Number(hh) * 60 + Number(mm);
            let hourFromMinutes = startTime;
            let hours = hourFromMinutes.split(':')[0];
            let minutes = hourFromMinutes.split(':')[1];
            //let hourUpdate = hours + ":" + minutes

            let i = 0;
            if (e.is_interview_gap_allowed === false) {
                for (i; i <= minuteDiff; i += defaultSlotTime) {
                    // let correctTime = addTimes(hourUpdate, `00:${i}`)

                    let momentInTime = moment(todayDate)
                        .add(hours, "hours")
                        .add(Number(minutes) + i, "minutes")
                        .format("LT");
                    array3.push(momentInTime);

                    let momentInRangeTime = moment(resp.data.schedule_date)
                        .add(hours, "hours")
                        .add(Number(minutes) + i + defaultSlotTime, "minutes")
                        .format("LT");

                    array4.push(momentInTime + "~" + momentInRangeTime);
                }
                array4.pop();
            } else if (e.is_interview_gap_allowed === true) {
                let gap_start_time = e.scheduled_time;
                let gap_end_time = e.scheduled_end_time;
                let newGapSlots = selectedGapSlotsChange(
                    gap_start_time,
                    gap_end_time,
                    resp
                );
                array5.push(newGapSlots[0]);
            }
            // const lastIndex = array4 && array4.length - 1
            // lastIndex > 0 && array5.push(array4[lastIndex])
            return array3
        })

        // let uniqueArray5 = [...new Map(array5.map((item) => [item, item])).values()];
        // setSelectedGapSlotsIs(uniqueArray5)
        setSelectedGapSlotsIs(array5)
        setBookedSlots(array4)
    }

    //all slot in range format section
    const selectedSlotsForBooking = (startTime, endTime, resp) => {
        let array3 = []
        let array4 = []
        let defaultSlotTime = resp.data.default_interview_blocktime_in_mins ? Number(resp.data.default_interview_blocktime_in_mins) : 10
        // eslint-disable-next-line
        // resp.data && resp.data.interview_schedules && resp.data.interview_schedules.map((e) => {
        // let startTime = e.scheduled_time
        // let endTime = e.scheduled_end_time
        let todayDate = moment(resp.data.schedule_date).format("MM-DD-YYYY"); //Instead of today date, We can pass whatever date        
        // let startTime = "11:40:00"
        // let endTime = "11:50:00"

        let startDate = new Date(`${todayDate} ${startTime}`);
        let endDate = new Date(`${todayDate} ${endTime}`);
        let timeDiff = Math.abs(startDate.getTime() - endDate.getTime());
        let hh = Math.floor(timeDiff / 1000 / 60 / 60);
        hh = ('0' + hh).slice(-2)

        timeDiff -= hh * 1000 * 60 * 60;
        let mm = Math.floor(timeDiff / 1000 / 60);
        mm = ('0' + mm).slice(-2)

        timeDiff -= mm * 1000 * 60;

        let minuteDiff = Number(hh) * 60 + Number(mm);
        let hourFromMinutes = startTime;
        let hours = hourFromMinutes.split(':')[0];
        let minutes = hourFromMinutes.split(':')[1];
        //let hourUpdate = hours + ":" + minutes

        let i = 0
        for (i; i <= minuteDiff; i += defaultSlotTime) {
            let momentInTime = moment(todayDate)
                .add(hours, 'hours')
                .add(Number(minutes) + i, 'minutes')
                .format('LT');
            array3.push(momentInTime)

            let momentInRangeTime = moment(resp.data.schedule_date)
                .add(hours, 'hours')
                .add(Number(minutes) + i + defaultSlotTime, 'minutes')
                .format('LT');

            array4.push(momentInTime + "~" + momentInRangeTime)
        }
        array4.pop()
        return array4
    }

    const selectedGapSlotsChange = (startTime, endTime, resp) => {
        let array3 = []
        let array4 = []
        let defaultSlotTime = resp.data.default_interview_blocktime_in_mins ? Number(resp.data.default_interview_blocktime_in_mins) : 10
        // eslint-disable-next-line
        // resp.data && resp.data.interview_schedules && resp.data.interview_schedules.map((e) => {
        // let startTime = e.scheduled_time
        // let endTime = e.scheduled_end_time
        let todayDate = moment(resp.data.schedule_date).format("MM-DD-YYYY"); //Instead of today date, We can pass whatever date        
        // let startTime = "11:40:00"
        // let endTime = "11:50:00"

        let startDate = new Date(`${todayDate} ${startTime}`);
        let endDate = new Date(`${todayDate} ${endTime}`);
        let timeDiff = Math.abs(startDate.getTime() - endDate.getTime());
        let hh = Math.floor(timeDiff / 1000 / 60 / 60);
        hh = ('0' + hh).slice(-2)

        timeDiff -= hh * 1000 * 60 * 60;
        let mm = Math.floor(timeDiff / 1000 / 60);
        mm = ('0' + mm).slice(-2)

        timeDiff -= mm * 1000 * 60;

        let minuteDiff = Number(hh) * 60 + Number(mm);
        let hourFromMinutes = startTime;
        let hours = hourFromMinutes.split(':')[0];
        let minutes = hourFromMinutes.split(':')[1];
        //let hourUpdate = hours + ":" + minutes

        let i = 0
        for (i; i <= minuteDiff; i += defaultSlotTime) {
            let momentInTime = moment(todayDate)
                .add(hours, 'hours')
                .add(Number(minutes) + i, 'minutes')
                .format('LT');
            array3.push(momentInTime)

            let momentInRangeTime = moment(resp.data.schedule_date)
                .add(hours, 'hours')
                .add(Number(minutes) + i + defaultSlotTime, 'minutes')
                .format('LT');

            array4.push(momentInTime + "~" + momentInRangeTime)
        }
        array4.pop()
        return array4
    }

    const selectSlotsChange = (itm, e, evt) => {

        // console.log('itm -> ', itm)
        // console.log('e -> ', e)
        // console.log('evt -> ', evt)

        let newItem = itm.split('~');
        let notAllowForSelect = selectedGapSlotsIs && selectedGapSlotsIs.length > 0 && selectedGapSlotsIs.filter(x => x === itm)

        setSingleSlot({ slot: newItem[0], data: e })
        let defaultSelectedDueration = evt ? evt.value : selectDueration.value
        if (checkBookedSlot(newItem[0]) === true) {
            toast.warning("Already interview scheduled for this time!", {
                position: toast.POSITION.TOP_CENTER
            })
            setSelectedSlotsIs([])
            setSelectedSlotsForSubmit([])
            setSelectedSingleGapSlotsIs([])
            setSelectedGapSlotsForSubmit([])

        } else if (notAllowForSelect.length > 0) {
            toast.warning(`This is Buffer time so this block is not available for schedule`, {
                position: toast.POSITION.TOP_CENTER
            })
            setSelectedSlotsIs([])
            setSelectedSlotsForSubmit([])
            setSelectedSingleGapSlotsIs([])
            setSelectedGapSlotsForSubmit([])

        }
        else
            if (defaultSelectedDueration) {
                //let hourFromMinutes = e.availability_from;
                let time = convertTime12to24(newItem[0])
                let newDateObj = addTimes(time, `00:${defaultSelectedDueration}`)
                let newTimeString = convertTime12to24Is(newDateObj)
                let hours = newTimeString.split(':')[0];
                let minutes = newTimeString.split(':')[1]
                let momentInTime = moment(mangageAvalList.data.schedule_date)
                    .add(hours, 'hours')
                    .add((minutes), 'minutes')
                    .format('LT');

                const filterSlotArray = e.slots && e.slots.filter(x => x === momentInTime)
                if (filterSlotArray.length > 0) {
                    const lastIndex = e.slots && e.slots.findIndex(x => x === momentInTime)
                    const firstIndex = e.slots && e.slots.findIndex(x => x === newItem[0])
                    const activeSlots = e.slots && e.slots.slice(firstIndex, lastIndex + 1)
                    let length = activeSlots.length - 1

                    let scheduled_start_time = convertTime12to24IsChange(activeSlots[0])
                    let scheduled_end_time = convertTime12to24IsChange(activeSlots[length])


                    const activeSlotsForGap = e.slots && e.slots.slice(lastIndex, lastIndex + 2)
                    let gapLength = activeSlotsForGap.length - 1

                    let gap_start_time = convertTime12to24IsChange(activeSlotsForGap[0])
                    let gap_end_time = convertTime12to24IsChange(activeSlotsForGap[gapLength])

                    let newGapSlots = []
                    if (mangageAvalList && mangageAvalList.data.is_interview_gap_allowed === true) {
                        newGapSlots = selectedGapSlotsChange(gap_start_time, gap_end_time, mangageAvalList)
                    }

                    let newActiveSlots = selectedSlotsForBooking(scheduled_start_time, scheduled_end_time, mangageAvalList)
                    if (bookedSlots.length > 0) {
                        let intersection = bookedSlots.filter(function (e) {
                            return newActiveSlots.indexOf(e) > -1;
                        });

                        let interSectionGap = bookedSlots.filter(e => (String(e) === String(newGapSlots[0])))

                        if (interSectionGap.length > 0) {
                            setSelectedSlotsIs([])
                            setSelectedSlotsForSubmit([])
                            setSelectedSingleGapSlotsIs([])
                            setSelectedGapSlotsForSubmit([])
                            // let uniqueIntersectionArray = [...new Map(interSectionGap.map((item) => [item, item])).values()];
                            // return toast.warning(`Buffer time is not available for this ${uniqueIntersectionArray.map(e => e)} please change duration time`, {
                            //     position: toast.POSITION.TOP_CENTER
                            // })
                            return toast.warning(`Required duration not available`, {
                                position: toast.POSITION.TOP_CENTER
                            })
                        } else {
                            setSelectedSlotsIs(newActiveSlots)
                            setSelectedSlotsForSubmit(activeSlots)
                            setSelectedSingleGapSlotsIs(newGapSlots)
                            setSelectedGapSlotsForSubmit(activeSlotsForGap)
                        }

                        if (intersection.length > 0) {
                            setSelectedSlotsIs([])
                            setSelectedSlotsForSubmit([])
                            setSelectedSingleGapSlotsIs([])
                            setSelectedGapSlotsForSubmit([])

                            // let uniqueIntersectionArray = [...new Map(intersection.map((item) => [item, item])).values()];
                            // toast.warning(`Already booked for this time ${uniqueIntersectionArray.map(e => e)} `, {
                            //     position: toast.POSITION.TOP_CENTER
                            // })

                            toast.warning(`Required duration not available`, {
                                position: toast.POSITION.TOP_CENTER
                            })
                        } else {
                            setSelectedSlotsIs(newActiveSlots)
                            setSelectedSlotsForSubmit(activeSlots)
                            setSelectedSingleGapSlotsIs(newGapSlots)
                            setSelectedGapSlotsForSubmit(activeSlotsForGap)
                        }
                    } else {
                        setSelectedSlotsIs(newActiveSlots)
                        setSelectedSlotsForSubmit(activeSlots)
                        setSelectedSingleGapSlotsIs(newGapSlots)
                        setSelectedGapSlotsForSubmit(activeSlotsForGap)
                    }

                } else {
                    toast.warning(`Validate with min-max date from ${itm} to ${momentInTime}`, {
                        position: toast.POSITION.TOP_CENTER
                    })
                    setSelectedSlotsIs([])
                    setSelectedSlotsForSubmit([])
                    setSelectedSingleGapSlotsIs([])
                    setSelectedGapSlotsForSubmit([])
                }

            } else {
                toast.warning("Please select interview duration!", {
                    position: toast.POSITION.TOP_CENTER
                })
                setSelectedSlotsIs([])
                setSelectedSlotsForSubmit([])
                setSelectedSingleGapSlotsIs([])
                setSelectedGapSlotsForSubmit([])
            }
    }

    // check availability section 
    const checkAvailabilityRR = async (e) => {
        e.preventDefault()
        if (formValues && formValues.scheduled_date) {
            let interviewerId = GetLocalstorage.userDetail()?.tms_role?.role_key === "agent" ? caneledInterviewInterviewer : formValues.interviewer_id ? formValues.interviewer_id : GetLocalstorage.userDetail().id
            let filter = { "interviewer_id": interviewerId, "schedule_date": formValues.scheduled_date }
            //let filter = { "interviewer_id": 14, "schedule_date": '2023-04-29' }

            setAvlStatus(true)
            //setFlag(true)
            setSlotLoading(true)
            const res = await dataService.manage_availability(JSON.stringify(filter));
            const resp = res.data;
            if (resp.data === null && resp.status === 400) {
                toast.warning(resp.message, {
                    position: toast.POSITION.TOP_CENTER
                })
            }
            setMangageAvalList(resp)
            resp && resp.data && alreadyBookedSlots(resp)
            // let array = []
            const calculateTimeSlots = (startTime, endTime, scheduleDate, defaultSlotTime) => {
                const array2 = [];
                const array3 = [];

                const todayDate = moment(scheduleDate).format("MM-DD-YYYY");
                const startDate = new Date(`${todayDate} ${startTime}`);
                const endDate = new Date(`${todayDate} ${endTime}`);
                let timeDiff = Math.abs(startDate.getTime() - endDate.getTime());

                let hh = Math.floor(timeDiff / 1000 / 60 / 60);
                hh = ('0' + hh).slice(-2);

                timeDiff -= hh * 1000 * 60 * 60;
                let mm = Math.floor(timeDiff / 1000 / 60);
                mm = ('0' + mm).slice(-2);

                let minuteDiff = Number(hh) * 60 + Number(mm);

                let hours = startTime.split(':')[0];
                let minutes = startTime.split(':')[1];

                for (let i = 0; i <= minuteDiff; i += defaultSlotTime) {
                    const momentInTime = moment(scheduleDate)
                        .add(hours, 'hours')
                        .add(Number(minutes) + i, 'minutes')
                        .format('LT');

                    array2.push(momentInTime);

                    const momentInRangeTime = moment(scheduleDate)
                        .add(hours, 'hours')
                        .add(Number(minutes) + i + defaultSlotTime, 'minutes')
                        .format('LT');

                    array3.push(momentInTime + "~" + momentInRangeTime);
                }

                array3.pop();

                return { slots: array2, slotsRange: array3, availability_from: startTime, availability_to: endTime };
            };

            if (user?.tms_role?.role_key === "agent" || user?.is_interviewer === true) {
                if (resp.data && resp.data.default_availability_from_settings.length > 0) {
                    const defaultSlotTime = Number(resp.data.default_interview_blocktime_in_mins) || 10;
                    // eslint-disable-next-line
                    let slotArray = [];

                    slotArray.push(calculateTimeSlots("00:00:00", "24:00:00", resp.data.schedule_date, defaultSlotTime));

                    setSlotArray(slotArray);
                }
            } else {
                if (resp.data && resp.data.default_availability_from_settings.length > 0) {
                    const defaultSlotTime = Number(resp.data.default_interview_blocktime_in_mins) || 10;

                    // eslint-disable-next-line
                    let slotArray = [];

                    resp.data.default_availability_from_settings.forEach((e) => {
                        const startTime = e.availability_from;
                        const endTime = e.availability_to;
                        slotArray.push(calculateTimeSlots(startTime, endTime, resp.data.schedule_date, defaultSlotTime));
                    });

                    setSlotArray(slotArray);
                }
            }

            // if (resp.data && resp.data.default_availability_from_settings.length > 0) {
            //     //setFlag(false)
            //     let defaultSlotTime = resp.data.default_interview_blocktime_in_mins ? Number(resp.data.default_interview_blocktime_in_mins) : 10

            //     // eslint-disable-next-line
            //     resp.data.default_availability_from_settings.map((e, index) => {
            //         //   array.push(e.availability_from + '00:10:00')
            //         let array2 = []
            //         let array3 = []

            //         // let startTime = e.availability_from
            //         // let endTime = e.availability_to
            //         let startTime = e.availability_from
            //         let endTime = e.availability_to

            //         let todayDate = moment(resp.data.schedule_date).format("MM-DD-YYYY"); //Instead of today date, We can pass whatever date        

            //         let startDate = new Date(`${todayDate} ${startTime}`);
            //         let endDate = new Date(`${todayDate} ${endTime}`);
            //         let timeDiff = Math.abs(startDate.getTime() - endDate.getTime());

            //         let hh = Math.floor(timeDiff / 1000 / 60 / 60);
            //         hh = ('0' + hh).slice(-2)

            //         timeDiff -= hh * 1000 * 60 * 60;
            //         let mm = Math.floor(timeDiff / 1000 / 60);
            //         mm = ('0' + mm).slice(-2)

            //         timeDiff -= mm * 1000 * 60;
            //         // let ss = Math.floor(timeDiff / 1000);
            //         // ss = ('0' + ss).slice(-2)


            //         let minuteDiff = Number(hh) * 60 + Number(mm)
            //         // if (minuteDiff > 0) {
            //         //     minuteDiff = minuteDiff - 10
            //         // }
            //         let hourFromMinutes = e.availability_from;
            //         let hours = hourFromMinutes.split(':')[0];
            //         let minutes = hourFromMinutes.split(':')[1];
            //         let i = 0

            //         for (i = 0; i <= minuteDiff; i += defaultSlotTime) {

            //             let momentInTime = moment(resp.data.schedule_date)
            //                 .add(hours, 'hours')
            //                 .add(Number(minutes) + i, 'minutes')
            //                 .format('LT');


            //             array2.push(momentInTime)
            //             // let extedTime = i + defaultSlotTime > i ? i : i + defaultSlotTime
            //             let momentInRangeTime = moment(resp.data.schedule_date)
            //                 .add(hours, 'hours')
            //                 .add(Number(minutes) + i + defaultSlotTime, 'minutes')
            //                 .format('LT');

            //             array3.push(momentInTime + "~" + momentInRangeTime)

            //         }
            //         array3.pop()
            //         array2.length > 0 && array.push({ "slots": array2, "slotsRange": array3, "availability_from": startTime, "availability_to": endTime })
            //         console.log(array,"array")
            //         //return array

            //     })
            //     setSlotArray(array)
            // }
            //dispatch(getMangageAvaList(JSON.stringify(filter)))
            setSlotLoading(false)
        } else {
            toast.warning('Select Interviewer and date !')
        }

    }

    if (mangageAvalList.status === 200 && mangageAvalList.data && mangageAvalList.data.default_availability_from_settings && flag === true) {
        setFlag(false)
        let array = []
        if (mangageAvalList.data.default_availability_from_settings.length > 0) {
            //setFlag(false)
            // eslint-disable-next-line
            mangageAvalList.data.default_availability_from_settings.map((e, index) => {
                //   array.push(e.availability_from + '00:10:00')
                let array2 = []
                // let startTime = e.availability_from
                // let endTime = e.availability_to
                let startTime = e.availability_from
                let endTime = e.availability_to

                let todayDate = moment(mangageAvalList.data.schedule_date).format("MM-DD-YYYY"); //Instead of today date, We can pass whatever date        

                let startDate = new Date(`${todayDate} ${startTime}`);
                let endDate = new Date(`${todayDate} ${endTime}`);
                let timeDiff = Math.abs(startDate.getTime() - endDate.getTime());

                let hh = Math.floor(timeDiff / 1000 / 60 / 60);
                hh = ('0' + hh).slice(-2)

                timeDiff -= hh * 1000 * 60 * 60;
                let mm = Math.floor(timeDiff / 1000 / 60);
                mm = ('0' + mm).slice(-2)

                timeDiff -= mm * 1000 * 60;
                // let ss = Math.floor(timeDiff / 1000);
                // ss = ('0' + ss).slice(-2)


                let minuteDiff = Number(hh) * 60 + Number(mm)
                // if (minuteDiff > 0) {
                //     minuteDiff = minuteDiff - 10
                // }
                let hourFromMinutes = e.availability_from;
                let hours = hourFromMinutes.split(':')[0];
                let minutes = hourFromMinutes.split(':')[1];
                let i;
                for (i = 0; i <= minuteDiff; i += 10) {
                    let momentInTime = moment(mangageAvalList.data.schedule_date)
                        .add(hours, 'hours')
                        .add(Number(minutes) + i, 'minutes')
                        .format('LT');

                    array2.push(momentInTime)
                }
                array2.length > 0 && array.push({ "slots": array2, "availability_from": startTime, "availability_to": endTime })
                //return array


            })
            setSlotArray(array)
        }
        alreadyBookedSlots()

    }
    if (mangageAvalList.status === 400 && flag === true) {
        setFlag(false)
    }

    // change duration section 
    const onChangeDueration = (evt) => {
        setSelectedSlotsIs([])
        setSelectedSlotsForSubmit([])
        setSelectedSingleGapSlotsIs([])
        setSelectedGapSlotsForSubmit([])

        if (evt) {
            setSelectDueration(evt)
            if (singleSlot) {
                selectSlotsChange(singleSlot.slot, singleSlot.data, evt)
            }
        } else {
            setSelectDueration("")
        }

    }

    // change time section 
    const chanageScheduledTime = (evt) => {
        if (evt) {
            const { value } = evt.target || evt

            let filterUser = interviewers_shift.filter(x => x.id === formValues?.interviewer_id)
            const defaultSelectedDueration = selectDueration.value

            if (filterUser.length > 0) {
                let start = filterUser[0].shift__start_time ? filterUser[0].shift__start_time : '09:30:00'
                let end = filterUser[0].shift__end_time ? filterUser[0].shift__end_time : '19:15:00'
                let aa1 = start.split(":");
                let aa2 = end.split(":");
                let aa3 = value.split(":");
                let aa4 = addTimes(value, `00:${defaultSelectedDueration}`).split(":");
                let flag = true

                let dd1 = Number(aa1[0])
                let dd11 = Number(aa1[1])
                let dd2 = Number(aa2[0])
                let dd21 = Number(aa2[1])
                let dd3 = Number(aa3[0])
                let dd31 = Number(aa3[1])
                let dd4 = Number(aa4[0])
                let dd41 = Number(aa4[1])
                if (dd1 > dd3) {
                    toast.warning("Check your shift time");
                    flag = false
                } else if (dd4 > dd2) {
                    toast.warning("Check your shift time");
                    flag = false
                } else if (dd1 === dd3 && dd11 > dd31) {
                    toast.warning("Check your shift time with interview duration");
                    flag = false
                } else if (dd4 >= dd2 && dd21 < dd41) {
                    toast.warning(`Check your shift end time  with interview duration is ${addTimes(value, `00:${defaultSelectedDueration}`)}`);
                    flag = false
                } else {
                    flag = true
                    setScheduledTime(value)

                }
                // if (dd1 <= dd3 <  dd2) 
                // { toast.warning("check your shift time"); flag = false }

                //if (dd2 > dd3) { toast.warning("check your shift time"); flag = false }
                if (flag === true) {
                    setScheduledTime(value)
                }

            } else {
                setScheduledTime(value)

            }

        } else {
            setScheduledTime("")
        }


    }

    const selectDefaultSlotsChange = (itm) => {
        let defaultSelectedDueration = selectDueration.value
        let array = []

        if (defaultSelectedDueration) {
            //let hourFromMinutes = e.availability_from;

            let hours = itm.split(':')[0];
            let minutes = itm.split(':')[1]
            for (let i = 0; i <= defaultSelectedDueration; i += 10) {
                let momentInTime = moment(formValues.scheduled_date)
                    .add(hours, 'hours')
                    .add(Number(minutes) + i, 'minutes')
                    .format('HH:mm:ss');
                array.push(momentInTime)
            }
        }
        return array
    }

    const convertTime12to24 = (time12h) => {
        const [time, modifier] = time12h.split(' ');

        let [hours, minutes] = time.split(':');

        if (hours === '12') {
            hours = '00';
        }

        if (modifier === 'PM') {
            hours = parseInt(hours, 10) + 12;
        }

        return `${hours}:${minutes}`;
    }

    const convertTime12to24IsChange = (time12h) => {
        const [time, modifier] = time12h.split(' ');

        let [hours, minutes] = time.split(':');

        if (hours === '12') {
            hours = '00';
        }

        if (modifier === 'PM') {
            hours = parseInt(hours, 10) + 12;
        }

        return `${hours}:${minutes}:00`;
    }


    const convertTime12to24Is = (time12h) => {
        const [time, modifier] = time12h.split(' ');

        let [hours, minutes] = time.split(':');

        // if (hours === '12') {
        //     hours = '00';
        // }

        if (modifier === 'PM') {
            hours = parseInt(hours, 10) + 12;
        }

        return `${hours}:${minutes}:00`;
    }

    const checkBookedSlot = (itm) => {
        let is_ckecked = bookedSlots.length > 0 && bookedSlots.find(x => x === itm) ? true : false;
        return is_ckecked;
    }

    let selected_slots_is = false;
    let booked_slots_is = false;
    let gap_slots_is = false;
    let gap_single_slots_is = false


    const filterRescheduleRequest = () => {
        // eslint-disable-next-line
        let interviewer_status_id = scheduleStatusOptions.filter(e => e.status_key === 'reschedule_request' || e.status_key === 'request_to_reschedule' && e)
        let filter = { "filter": { "agent_id": { "value": GetLocalstorage.userDetail().id, "operation": "eq" } }, "order_by": { "column": "-id" }, "skip": 0, "limit": limit, "columns": DefaultFilterColumns.INTERVIEW_LIST_COLUMNS_JSON }

        if (interviewer_status_id.length > 0 && activeColumn === false) {
            filter['filter']['interview_status_id'] = { "value": interviewer_status_id[0].id, "operation": "eq" }
            setFilterQuery(JSON.stringify(filter))
            dispatch(getInterviewList(JSON.stringify(filter)))
            countRequest()
        } else {
            setFilterQuery(JSON.stringify(filter))
            dispatch(getInterviewList(JSON.stringify(filter)))
            countRequest()
        }
        setActiveColumn(!activeColumn)

    }

    //custom pagination
    const handlePagination = (data) => {
        setCurrentPage(data)
        let newFilterData = JSON.parse(filterQuery);
        newFilterData['skip'] = data * pageSize
        newFilterData['limit'] = limit
        //setLimit(limit)
        setFilterQuery(JSON.stringify(newFilterData))
        dispatch(getInterviewList(JSON.stringify(newFilterData)))
        countRequest()
    }

    const handlePageSizeChange = (newPageSize) => {
        setPageSize(newPageSize.target.value)
        setCurrentPage(0)
        setLimit(newPageSize.target.value)
        let newFilterData = JSON.parse(filterQuery);
        newFilterData['skip'] = 0
        newFilterData['limit'] = newPageSize.target.value
        setFilterQuery(JSON.stringify(newFilterData))
        dispatch(getInterviewList(JSON.stringify(newFilterData)))
        countRequest()
    }

    function CustomPagination() {
        return (
            <TablePagination
                component="div"
                count={(interview_List && interview_List.data && interview_List.data.rows && interview_List.data.count) || 0}
                page={currentPage}
                rowsPerPage={pageSize}
                rowsPerPageOptions={GetLocalstorage.get_page_item_list()}
                onPageChange={() => setCurrentPage(pageSize)}
                onRowsPerPageChange={handlePageSizeChange}
                ActionsComponent={(subProps) =>
                    <TablePaginationActions {...subProps}
                        handlePagination={handlePagination}
                    />}
            />
        );
    }

    // sorting
    const handleSort = (sort) => {
        if (sort.length) {
            let newFilterData = JSON.parse(filterQuery);
            newFilterData['skip'] = 0;
            newFilterData['limit'] = limit;
            newFilterData['order_by'] = {
                "column": sort[0].sort === "desc" ?
                    '-' + sort[0].field : sort[0].field
            }
            setFilterQuery(JSON.stringify(newFilterData))
            dispatch(getInterviewList(JSON.stringify(newFilterData)))
            countRequest()
        } else {
            let filter = title === 'my-interview' ? ROLE && ROLE.role_key === 'agent' ?
                `{"filter":{"agent_id":{"value":${GetLocalstorage.userDetail().id}, "operation":"eq"}},"order_by":{"column":"-id"},"skip":0, "limit":25,"columns":[${DefaultFilterColumns.INTERVIEW_LIST_COLUMNS}]}`
                : `{"filter":{"interviewer_id":{"value":${GetLocalstorage.userDetail().id}, "operation":"eq"}},"order_by":{"column":"-id"},"skip":0, "limit":25,"columns":[${DefaultFilterColumns.INTERVIEW_LIST_COLUMNS}]}`
                : `{"order_by":{"column":"-id"},"skip":0, "limit":${limit},"columns":[${DefaultFilterColumns.INTERVIEW_LIST_COLUMNS}]}`
            setFilterQuery(filter)
            // setFilterQuery(`{ "skip": 0, "limit": ${limit}, "order_by": { "column": "-id" } }`)
        }
    }


    const actionColumn = [
        { field: "id", headerName: "ID", width: 70, filterable: false, },
        {
            field: "candidate", headerName: "Candidate Name", width: 230, filterable: false, sortable: false, renderCell: (params) => {
                return (
                    <>
                        {
                            userInterviwer === true ?
                                <div onClick={() => handleResumeOpen(params.row)} className={`cellWithStatus`} style={{ cursor: "pointer" }}>
                                    <Tooltip title={params.row.candidate__candidate_name}>
                                        <p>{params.row.candidate__candidate_name}</p>
                                    </Tooltip>
                                </div>
                                :
                                <div>
                                    <Tooltip title={params.row.candidate__candidate_name}>
                                        <p>{params.row.candidate__candidate_name}</p>
                                    </Tooltip>
                                </div>
                        }
                    </>
                )
            }
        },
        {
            field: "interviewer", headerName: "Interviewer Name", width: 230, filterable: false, renderCell: (params) => {
                return (
                    <div className={`cellWithStatus`}>
                        {params.row.interviewer__display_name}
                    </div>
                )
            }
        },
        {
            field: "agent_id", headerName: "Scheduled By", width: 230, filterable: false, sortable: false, renderCell: (params) => {
                return (
                    <div className={`cellWithStatus`}>
                        {params.row.agent__display_name}
                    </div>
                )
            }
        },

        {
            field: "meeting_url", headerName: "Meetings", width: 300, filterable: false, sortable: false, renderCell: (params) => {
                return (
                    <div className={`cellWithStatus`}>
                        {params.row.interview_status__status_key === 'done' ? (
                            "N/A"
                        ) : (
                            <Tooltip title={params.row.meeting_url}>
                                {createMeetingHyperLink(params.row, params.row.meeting_url)}
                            </Tooltip>
                        )}
                    </div>

                )
            }
        },
        {
            field: "scheduled_date", headerName: "Scheduled Date", width: 150, filterable: false, type: 'date',
            renderCell: (params) => {
                return (
                    <div className="cellWithStatus">
                        {params.row.scheduled_date ? moment(params.row.scheduled_date).format('DD-MM-YYYY') : "N/A"}
                    </div>
                );
            },
        },
        { field: "scheduled_time", headerName: "Scheduled Time", width: 150, filterable: false },
        {
            field: "job", headerName: "Job Title", width: 230, filterable: false, sortable: false, renderCell: (params) => {
                return (
                    <div className={`cellWithStatus`}>
                        <Tooltip title={params.row.job__job_title}>
                            <p>{params.row.job__job_title}</p>
                        </Tooltip>
                    </div>
                )
            }
        },
        {
            field: "subject", headerName: "Interview Message", width: 300, filterable: false, sortable: false, renderCell: (params) => {
                return (
                    <div className={`cellWithStatus`}>
                        <Tooltip title={params.row.subject}>
                            <p>{params.row.subject}</p>
                        </Tooltip>
                    </div>
                )
            }
        },
        {
            field: "feedback_by_interviewer", headerName: "Interviewer Feedback", width: 300, filterable: false, sortable: false, renderCell: (params) => {
                return (
                    <div className={`cellWithStatus`}>

                        <Tooltip title={params.row.feedback_by_interviewer}>
                            <p>{params.row.feedback_by_interviewer}</p>
                        </Tooltip>
                    </div>
                )
            }
        },
        {
            field: "skills_name", headerName: "Skills", width: 300, filterable: false, sortable: false, renderCell: (params) => {
                return (
                    <div className={`cellWithStatus`}>

                        <Tooltip title={params.row.skills_name}>
                            <p>{params.row.skills_name}</p>
                        </Tooltip>
                    </div>
                )
            }
        },
        {
            field: "interview_status_id",
            headerName: "Interview Status",
            width: 300,
            filterable: false,
            sortable: false,
            renderCell: (params) => {
                return (
                    <div className={`cellWithStatus`}>
                        {/* {params.row.interview_status__status_name} */}
                        {params.row.interview_status__status_key === "accept_schedule" ? "Accepted" : params.row.interview_status__status_key === "reschedule" ? "Rescheduled" : params.row.interview_status__status_key === "reschedule_request" ? "Requested to Reschedule" : params.row.interview_status__status_name}
                        {params.row.interviewer_id === GetLocalstorage.userDetail().id && params.row.interview_status__status_key !== 'accept_schedule' && params.row.interview_status__status_key !== 'done' && params.row.interview_status__status_key !== 'cancel_interview'}
                    </div>
                );
            },
        },
        {
            field: "action",
            headerName: "Action",
            width: "120",
            hideable: false,
            filterable: false,
            sortable: false,
            renderCell: (params) => {
                return (
                    <div className="cellAction">
                        <ActionComponent
                            activeModel={activeModel}
                            params={params}
                            userId={GetLocalstorage.userDetail().id}
                            tmsRole={GetLocalstorage.userDetail()?.tms_role?.role_key}
                        />
                    </div>
                );
            },
        },
    ];

    const onFilterChange = (filterModel, r) => {

        if (filterModel.items.length) {
            setFilterClosingFields(() => ({ columnField: filterModel.items[0].columnField, operatorValue: filterModel.items[0].operatorValue }))
        }

        if (filterModel.items.length == 0 ? true : filterModel.items.length && filterClosingFields.columnField === filterModel.items[0].columnField && filterClosingFields.operatorValue === filterModel.items[0].operatorValue && filterModel.items[0].value === undefined && r.reason === "upsertFilterItem") {
            setReload(true);
            setTimeout(() => {
                setReload(false)
                setCurrentPage(0)
                //setPageSize(10)
                let filter = title === 'my-interview' ? ROLE && ROLE.role_key === 'agent' ?
                    `{"filter":{"agent_id":{"value":${GetLocalstorage.userDetail().id}, "operation":"eq"}},"order_by":{"column":"-id"},"skip":0, "limit":25,"columns":[${DefaultFilterColumns.INTERVIEW_LIST_COLUMNS}]}`
                    : `{"filter":{"interviewer_id":{"value":${GetLocalstorage.userDetail().id}, "operation":"eq"}},"order_by":{"column":"-id"},"skip":0, "limit":25,"columns":[${DefaultFilterColumns.INTERVIEW_LIST_COLUMNS}]}`
                    : `{"order_by":{"column":"-id"},"skip":0, "limit":${limit},"columns":[${DefaultFilterColumns.INTERVIEW_LIST_COLUMNS}]}`
                setFilterQuery(filter)
                // setFilterQuery(`{"order_by":{"column":"-id"},"skip":0, "limit":${limit}}`)
                dispatch(getInterviewList(filter))
                countRequest()
            }, 0);
        }

        if (filterModel.items && filterModel.items.length > 0 && filterModel.items[0].columnField && filterModel.items[0].value) {
            let operatorValue = filterModel.items[0].operatorValue === "contains" ? "icontains" :
                filterModel.items[0].operatorValue === "startsWith" ? 'istartswith' :
                    filterModel.items[0].operatorValue === "endsWith" ? 'iendswith' : filterModel.items[0].operatorValue
            const filterData = `{"filter":{"${[filterModel.items[0].columnField]}":{"value":"${filterModel.items[0].value}","operation": "${operatorValue}"}},"order_by":{"column":"-id"},"skip":0, "limit":${limit}},"columns":[${DefaultFilterColumns.INTERVIEW_LIST_COLUMNS_JSON}]`
            setFilterQuery(filterData)
            setCurrentPage(0)
            dispatch(getInterviewList(filterData))
            countRequest()
        }

    }


    const handleResumeOpen = (data) => {
        const resumeId = data?.candidate__file_upload__id
        const fileUrlSplit = data?.candidate__file_upload__file_path.split(".")
        const fileTypeIndex = fileUrlSplit.length - 1
        setResumeFilePath(REACT_APP_API_BASEURL + "recruitment/resume/download/" + resumeId)
        setResumeFileType(fileUrlSplit[fileTypeIndex])
    }

    // advance search filter fields
    const FilterComponents = () => {
        return (
            <>
                <List>
                    <ListItem>
                        <FormControl style={{ width: '100%' }}>
                            <Select
                                name="interviewer_name"
                                placeholder="Select Interviewer Name"
                                value={title === 'my-interview' && ROLE && ROLE.role_key !== 'agent' ? { value: GetLocalstorage.userDetail().id, label: GetLocalstorage.userDetail().display_name } : filterInputs.interviewer_name}
                                onChange={(evt) => { setFilterInputs({ ...filterInputs, interviewer_name: evt }) }}
                                width='300px'
                                isDisabled={title === 'my-interview' && ROLE && ROLE.role_key !== 'agent' ? true : false}
                                options={resources_interviewer} />
                        </FormControl>
                    </ListItem>

                    <ListItem>
                        <FormControl style={{ width: '100%' }}>
                            <Select
                                name="candidate_name"
                                placeholder="Select Candidate Name"
                                value={filterInputs.candidate_name}
                                onChange={(evt) => { setFilterInputs({ ...filterInputs, candidate_name: evt }) }}
                                width='300px'
                                options={candidateOptions} />
                        </FormControl>
                    </ListItem>

                    <ListItem>
                        <FormControl style={{ width: '100%' }}>
                            <Select
                                name="scheduled_by"
                                placeholder="Select Scheduled By"
                                value={title === 'my-interview' && ROLE && ROLE.role_key === 'agent' ? { value: GetLocalstorage.userDetail().id, label: GetLocalstorage.userDetail().display_name } : filterInputs.scheduled_by}
                                onChange={(evt) => { setFilterInputs({ ...filterInputs, scheduled_by: evt }) }}
                                width='300px'
                                isDisabled={title === 'my-interview' && ROLE && ROLE.role_key === 'agent' ? true : false}
                                options={resources_agent} />
                        </FormControl>
                    </ListItem>

                    <ListItem>
                        <FormControl style={{ width: '100%' }}>
                            <Select
                                name="status"
                                placeholder="Select Status"
                                value={filterInputs.status}
                                onChange={(evt) => { setFilterInputs({ ...filterInputs, status: evt }) }}
                                width='300px'
                                options={scheduleStatusOptions} />
                        </FormControl>
                    </ListItem>

                    <ListItem>
                        <FormControl style={{ width: '100%' }}>
                            <Select
                                name="job_title"
                                placeholder="Select Job Title"
                                value={filterInputs.job_title}
                                onChange={(evt) => { setFilterInputs({ ...filterInputs, job_title: evt }) }}
                                width='300px'
                                options={jobPostOptions} />
                        </FormControl>
                    </ListItem>

                    <ListItem>
                        <FormControl style={{ width: '100%', zIndex: '0' }}>
                            <DateRangePicker
                                format="dd-MM-yyyy"
                                placeholder="dd-mm-yyyy ~ dd-mm-yyyy"
                                showMeridian
                                value={filterInputs.date}
                                onChange={(evt) => { setFilterInputs({ ...filterInputs, date: evt }) }}
                            />
                        </FormControl>
                    </ListItem>

                    <ListItem>
                        <FormControl style={{ width: '100%' }}>
                            <TextField
                                //style={{ height: '35px', borderColor: '#ddd' }}
                                id="interviewtime"
                                type="time"
                                name="time"
                                value={filterInputs.time}
                                onChange={(evt) => { onfilterChange(evt) }}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                inputProps={{
                                    step: 300, // 5 min
                                }}
                            // sx={{ width: 150 }}
                            />
                        </FormControl>
                    </ListItem>

                </List>
            </>
        )
    }

    // advance search - searchHandler
    const handleFilterSearch = (drawerOpen) => {
        search();
        drawerOpen(false);
    }

    // advance search - resetHandler
    const handleFilterReset = () => {
        clear();
    }

    useEffect(() => {
        if (resumeFilePath !== "" && resumeFileType !== "") {
            setOpenResume(true)
        }
    }, [resumeFilePath, resumeFileType])

    const handleResumeClose = () => {
        setOpenResume(false)
    }

    return (
        <div className="interviewListing">
            <Sidebar />
            <div className="interviewListingContainer">
                <Navbar />

                <ToastContainer />
                <div className="datatable">
                    <div className="datatableTitle">
                        <h3>{title === 'my-interview' ? 'My' : 'All'} Interviews List</h3>
                        <div style={{ display: 'flex', alignItems: 'flex-end', gap: '6px' }}>
                            {/* <Tooltip title={"Add Interviews"}>
                                <button style={{ marginRight: 5 }} onClick={handleOpen} className="btn-add-new ">
                                    <Add
                                    />
                                </button>
                            </Tooltip> */}
                            {/* advance search filter start */}
                            <MoreFilters filterComponents={FilterComponents} handleFilterReset={handleFilterReset} handleFilterSearch={handleFilterSearch}
                                isFilterDisabled={status.includes('loading') === true ? true : false} />
                            {/* advance search filter end */}
                            <RefreshButton api={getInterviewList} filter={filterQuery} style={{ marginTop: 0 }} />
                        </div>
                    </div>
                    {(status === `${STATUSES.LOADING}_interview` || reload === true || reload === 'edit_data') && <LoaderForm />}


                    <div style={{ display: 'flex', marginTop: "35px", marginRight: '10px' }}>
                        {ROLE && ROLE.role_key === 'agent' && <button disabled={(status === `${STATUSES.LOADING}__interview`) ? true : false} className="btn-sm filter-btn-primary" style={(status === `${STATUSES.LOADING}__interview`) ? { flexBasis: "auto", marginLeft: "auto", backgroundColor: 'grey' } : { flexBasis: "auto", marginLeft: "auto" }} onClick={() => filterRescheduleRequest()} >Reschedule Requests ({interview_reschedule_request_count_list.length})</button>}
                    </div>


                    <Card>
                        <CardContent>
                            <Grid
                                container
                                rowSpacing={1}
                            // columnSpacing={{ xs: 2, sm: 2, md: 2 }}
                            >
                                <Grid item md={7}>
                                    <CustomPagination />
                                </Grid>
                                <Grid item md={5}>
                                    <Stack
                                        style={{
                                            justifyContent: "flex-end",
                                            marginTop: "6px",
                                            display: 'flex'
                                        }}
                                        direction={"row"}
                                    >
                                    </Stack>
                                </Grid>
                            </Grid>
                            {
                                !reload &&
                                <DataGrid
                                    className="datagrid"
                                    columnVisibilityModel={columnVisibilityModel}
                                    onColumnVisibilityModelChange={(newModel) =>
                                        setColumnVisibilityModel(newModel)
                                    }
                                    rows={interview_List && interview_List.data && interview_List.data.rows ? interview_List.data.rows : []}
                                    columns={actionColumn}
                                    pageSize={pageSize}
                                    style={{ fontSize: 16 }}
                                    onFilterModelChange={onFilterChange}
                                    filterMode="server"
                                    components={{
                                        // Pagination: CustomPagination,
                                        NoRowsOverlay: CustomNoRowsOverlay
                                    }}
                                    hideFooterPagination={true}
                                    onSortModelChange={(sort) => handleSort(sort)}
                                />
                            }
                        </CardContent>
                    </Card>
                </div>

                <Dialog
                    hideBackdrop
                    fullWidth
                    maxWidth={"md"}
                    open={opnenResume}
                    onClose={handleResumeClose}
                    aria-labelledby="child-modal-title"
                    aria-describedby="child-modal-description"
                >
                    <Button
                        edge="start"
                        color="inherit"
                        onClick={handleResumeClose}
                        aria-label="close"
                        style={{ position: "absolute", top: "10px", right: "10px" }}>
                        <Close />
                    </Button>
                    <DialogTitle style={{ background: '#2196f3', color: '#fff', marginBottom: "20px" }}> Candidate Resume</DialogTitle>

                    <DialogContent>
                        {
                            resumeFileType === "doc" ?
                                <iframe className="google-docs iframe" src={`https://docs.google.com/viewer?url=${resumeFilePath}&embedded=true`} frameborder="0"
                                    scrolling="no" marginHeight="0" marginWidth="0" role="document" aria-label="PDF document" title="PDF document" style={{ borderStyle: 'none', width: '100%', height: '800px' }}></iframe>

                                : <FileViewer fileType={resumeFileType} filePath={resumeFilePath} />
                        }

                    </DialogContent>

                </Dialog>

                {
                    feedbackStatus === "loading" ?
                        <LoaderForm />
                        :
                        <Dialog
                            hideBackdrop
                            fullWidth
                            maxWidth={"md"}
                            open={open}
                            onClose={handleClose}
                            aria-labelledby="child-modal-title"
                            aria-describedby="child-modal-description"
                        >
                            <Button
                                edge="start"
                                color="inherit"
                                onClick={handleClose}
                                aria-label="close"
                                style={{ position: "absolute", top: "10px", right: "10px" }}>
                                <Close />
                            </Button>
                            <DialogTitle style={{ background: '#2196f3', color: '#fff', marginBottom: "20px" }}>
                                {fieldType === 'rr' ? 'Reschedule Request' :
                                    // fieldType === 'af' ? 'Add Feedback' :
                                    fieldType === 'cr' ? 'Confirm Reschedule' :
                                        fieldType === 'ci' ? 'Cancel Interview' :
                                            fieldType === 'as' ? 'Accept Schedule' :
                                                fieldType === 'd' ? ' Interview Done Request & Add Feedback' :
                                                    fieldType === 's' ? 'Start' :
                                                        fieldType === 'vf' ? 'view feedback' + `(${objectDetail?.interviewer__display_name})` :
                                                            fieldType === 'i' ? 'Interrupted' :
                                                                fieldType === 'sc' ? ` Show Comments ${commentInterviewerAgentList && commentInterviewerAgentList.data ? `- (${commentInterviewerAgentList.data.count})` : ''}` :
                                                                    'Reschedule'}</DialogTitle>

                            <DialogContent>
                                {fieldType === 'sc' ?
                                    <div>
                                        {commentInterviewerAgentList && commentInterviewerAgentList.data && commentInterviewerAgentList && commentInterviewerAgentList.data.rows && commentInterviewerAgentList.data.rows.map((e, i) => {
                                            return (
                                                <div>
                                                    <table>
                                                        <tr>
                                                            <td class="text-dark">Comment </td>
                                                            <td> :</td>
                                                            <td> {e.comment}</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="text-dark">Comment By  </td>
                                                            <td> :</td>
                                                            <td> {e.comment_by && e.comment_by.display_name}</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="text-dark">Against Request </td>
                                                            <td> :</td>
                                                            {/* <td> {e.previous_interview_status ? e.previous_interview_status.status_name : ''} - {e.interview_status ? e.interview_status.status_name : ''}</td> */}
                                                            <td>{e.previous_interview_status ? e.previous_interview_status.status_name === "Reschedule Request" ? "Requested to Reschedule" : e.previous_interview_status.status_name === "Accept Schedule" ? "Accepted" : e.previous_interview_status.status_name === "Reschedule" ? "Rescheduled" : e.previous_interview_status.status_name : ""}
                                                                -
                                                                {/* {e.interview_status ? e.interview_status.status_name : ''} */}
                                                                {e.interview_status ? e.interview_status.status_name === "Reschedule Request" ? "Requested to Reschedule" : e.interview_status.status_name === "Accept Schedule" ? "Accepted" : e.interview_status.status_name === "Reschedule" ? "Rescheduled" : e.interview_status.status_name : ""}
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </div>
                                            )
                                        })}
                                        {commentInterviewerAgentList && commentInterviewerAgentList.status === 400 &&

                                            <p>{commentInterviewerAgentList.message}</p>
                                        }
                                    </div>
                                    :
                                    fieldType === 'cr' ?
                                        <div>
                                            <p><span style={{ fontWeight: 'bold' }}>Job Title: </span>{formValues && formValues.obj ? formValues?.obj?.job__job_title : 'No job found'}</p>
                                            <p><span style={{ fontWeight: 'bold' }}>Candidate Name :</span> {formValues && formValues.obj && formValues.obj.candidate__candidate_name && formValues.obj.candidate__candidate_name}</p>
                                            <p><span style={{ fontWeight: 'bold' }}>Interviewer Name : </span> {formValues && formValues.obj && formValues.obj.interviewer__display_name && formValues.obj.interviewer__display_name}</p>
                                            <p style={{ padding: '10px' }}><span style={{ fontWeight: 'bold' }}>Scheduled Date : </span>{formValues && formValues.obj && formValues.obj.scheduled_date} <span style={{ fontWeight: 'bold', marginLeft: '20px' }}> Scheduled Time : </span>{formValues && formValues.obj && formValues.obj.scheduled_date && formValues.obj.scheduled_time + "-" + formValues.obj.scheduled_end_time}</p>
                                            <p style={{ padding: '10px' }}><span style={{ fontWeight: 'bold' }}>New Scheduled Date:</span> {formValues && formValues.obj && formValues.obj.requested_reschedule_date} <span style={{ fontWeight: 'bold', marginLeft: '20px' }}>New Scheduled Time : </span>{formValues && formValues.obj && formValues.obj.requested_reschedule_time + '-' + formValues.obj.requested_reschedule_end_time}</p>
                                            <p style={{ marginBottom: '10px' }}><span style={{ fontWeight: 'bold' }}>Comment for reschedule request : </span>{formValues && formValues.obj && formValues.obj.requested_reschedule_comment}</p>
                                            <form>
                                                <div className="add-new-user-form">
                                                    <div className="formInput" style={{ flexBasis: '100%', marginTop: '20px' }}>
                                                        <label >
                                                            Comment/ Reason
                                                        </label>

                                                        <textarea defaultValue={formValues.comment} name={'comment'} onChange={handleChange} rows={3} placeholder={'comment'}></textarea>
                                                        {errStatus === true && (!formValues.comment || formValues.comment === "") && <p style={{ color: "#96332c", fontSize: 13 }}>{'This field is required'}</p>}
                                                    </div>
                                                </div>
                                            </form>
                                            {reload === false && <button style={{ backgroundColor: '#0052cc', color: '#fff', padding: '12px', borderRadius: '6px', fontWeight: '500', margin: '10px' }} onClick={(e) => submitData(e, 'cs')} >Confirm schedule</button>}
                                            {reload === false && <button style={{ backgroundColor: '#e31616', color: '#fff', padding: '12px', borderRadius: '6px', margin: '10px', fontWeight: '500' }} onClick={(e) => submitData(e, 'ci')}>Cancel Interview</button>}
                                            {reload === false && <button style={{ backgroundColor: '#00bcd4', color: '#fff', padding: '12px', borderRadius: '6px', margin: '10px', fontWeight: '500' }} onClick={(e) => handleCancelCreate(e)}>Cancel & Create New</button>}

                                        </div>
                                        :
                                        fieldType === 'vf' ?
                                            <div>
                                                <div>
                                                    <table>
                                                        <thead>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td>
                                                                    {
                                                                        interviewerFeedbackList && interviewerFeedbackList?.data && interviewerFeedbackList?.data.map((feedback, i) => (


                                                                            <div className="widget" style={{ marginBottom: "16px", width: "400px", height: "auto" }}>
                                                                                <div className="left">
                                                                                    <Tooltip title="Candidate Name">
                                                                                        <span className="counter" style={{ fontSize: "20px" }}>{objectDetail?.candidate__candidate_name || ""}</span>
                                                                                    </Tooltip>
                                                                                    <Tooltip title="Interview Date">
                                                                                        <span className="title">{feedback?.interview_date || ""}</span>
                                                                                    </Tooltip>
                                                                                    <Tooltip title="Interviewer Feedback">
                                                                                        <span className="title">{feedback?.feedback || ""}</span>
                                                                                    </Tooltip>
                                                                                    <Tooltip title={"Overall Average Rating :" + feedback?.avg_rating}>
                                                                                        <span className="counter">
                                                                                            <Rating
                                                                                                value={feedback?.avg_rating || 0}
                                                                                                name={'avg_rating'}
                                                                                                precision={0.1}
                                                                                                readOnly
                                                                                            />
                                                                                        </span>
                                                                                    </Tooltip>
                                                                                    <Divider />
                                                                                    <br />
                                                                                    {feedback && feedback.feedback_details && feedback.feedback_details.length > 0 && feedback.feedback_details.map((item) => {
                                                                                        return <>
                                                                                            <span className="title" style={{ fonstSize: "14px", fontWeight: "bold" }}>{item?.head_comment_name || ""}
                                                                                                <Rating
                                                                                                    value={item?.rating_value || 0}
                                                                                                    name={'rating_value'}
                                                                                                    readOnly
                                                                                                    precision={0.1}

                                                                                                />
                                                                                            </span>
                                                                                            <span className="title">{item?.rating_comment || ""}</span>
                                                                                        </>
                                                                                    })}
                                                                                </div>
                                                                            </div>
                                                                        ))
                                                                    }
                                                                </td>
                                                                <td>
                                                                    {interviewerFeedbackList && interviewerFeedbackList?.data && interviewerFeedbackList?.data.map((feedback, i) => (

                                                                        feedback?.feedback_to_agent?.rating_comment &&
                                                                        <div className="widget" style={{ marginBottom: "16px", width: "400px", height: "auto" }}>
                                                                            <div className="right">
                                                                                <Tooltip title="Recuirter/Agent Name">
                                                                                    <span className="counter" style={{ fontSize: "20px" }}>{objectDetail?.agent__display_name || ""}</span>
                                                                                </Tooltip>
                                                                                <Tooltip title="Interview Date">
                                                                                    <span className="title">{feedback?.interview_date || ""}</span>
                                                                                </Tooltip>
                                                                                <Tooltip title="Interviewer Feedback">
                                                                                    <span className="title">{feedback?.feedback_to_agent.rating_comment || ""}</span>
                                                                                </Tooltip>
                                                                                <Tooltip title={"Overall Average Rating :" + feedback?.feedback_to_agent?.rating_value}>
                                                                                    <span className="counter">
                                                                                        <Rating
                                                                                            value={feedback?.feedback_to_agent?.rating_value || 0}
                                                                                            name={'avg_rating'}
                                                                                            precision={0.1}
                                                                                            readOnly
                                                                                        />
                                                                                    </span>
                                                                                </Tooltip>
                                                                                <Divider />

                                                                            </div>
                                                                        </div>
                                                                    ))

                                                                    }
                                                                </td>




                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>

                                            : <form>
                                                <div className="add-new-user-form">

                                                    {(fieldType === 'rr') &&
                                                        <FormControl>
                                                            <TextField
                                                                id="interviewdate"
                                                                type="date"
                                                                name={'scheduled_date'}
                                                                onChange={(evt) => handleChange(evt)}
                                                                value={formValues.scheduled_date}
                                                                sx={{ width: 220 }}
                                                                InputLabelProps={{
                                                                    shrink: true,
                                                                }}
                                                                disabled={(fieldType === 's' || fieldType === 'as') ? true : false}
                                                                inputProps={{ min: moment().format('YYYY-MM-DD'), max: null }}
                                                            />
                                                            {((fieldType === 'rr') && errStatus === true && (!formValues.scheduled_date || formValues.scheduled_date === "")) && <p style={{ color: "#96332c", fontSize: 13 }}>{'This field is required'}</p>}

                                                            <button style={{ width: '100%', marginTop: ' 22px' }} className="submit-modal" onClick={checkAvailabilityRR}>
                                                                Show Availability
                                                            </button>

                                                            {avlStatus === true && slotLoading === true ? <LoaderForm /> :

                                                                ((avlStatus === true && mangageAvalList && mangageAvalList.data && slotArray && slotArray.length === 0) || (avlStatus === true && mangageAvalList && !mangageAvalList.data)) &&
                                                                <div className='col' style={{ flexBasis: '16%', marginRight: "10px" }}>
                                                                    <div className="formInput-date">
                                                                        {/* <div className="datepicker-input-custom"> */}
                                                                        <label>Time</label><br />
                                                                        <FormControl>
                                                                            <TextField
                                                                                id="interviewtime"
                                                                                type="time"
                                                                                name={'scheduled_time'}
                                                                                onChange={(evt) => chanageScheduledTime(evt)}
                                                                                value={scheduledTime}
                                                                                sx={{ width: 220 }}
                                                                                InputLabelProps={{
                                                                                    shrink: true,
                                                                                }}
                                                                            />

                                                                        </FormControl>
                                                                    </div>
                                                                </div>
                                                            }



                                                        </FormControl>
                                                    }
                                                    {(fieldType === 'rr') &&
                                                        <FormControl>
                                                            <div className='col' style={{ flexBasis: '20%', width: "200px", marginTop: "-px" }}>
                                                                <div className="formInput">

                                                                    <CreatableSelect
                                                                        className="select-a"
                                                                        classNamePrefix="select"
                                                                        placeholder="Select Duration"
                                                                        isSearchable={true}
                                                                        isClearable
                                                                        //isMulti
                                                                        name="interviewer_id"
                                                                        defaultValue={selectDueration}
                                                                        options={[
                                                                            { value: 10, label: 10 },
                                                                            { value: 20, label: 20 },
                                                                            { value: 30, label: 30 },
                                                                            { value: 40, label: 40 },
                                                                            { value: 50, label: 50 },
                                                                            { value: 60, label: 60 }
                                                                        ]}
                                                                        onChange={onChangeDueration}
                                                                    />
                                                                    <label style={{ marginTop: "5px" }}>Interview Duration</label>
                                                                </div>
                                                            </div>
                                                        </FormControl>
                                                    }
                                                    {(fieldType === 'rr') &&
                                                        <FormControl>
                                                            <div className='col' style={{ flexBasis: '20%', width: "200px", marginTop: "-1px" }}>

                                                            </div>

                                                        </FormControl>
                                                    }
                                                    {avlStatus === true && slotLoading === false && mangageAvalList && mangageAvalList.data &&
                                                        <div className='add-new-user-form row' >
                                                            <div className="formInput" style={{ flexBasis: "100%" }}>
                                                                {mangageAvalList.status === 400 ? <label style={{ color: 'red' }}>{mangageAvalList.message}</label> : <label style={{ fontSize: 'medium', fontWeight: 'bold' }}>Available Slots</label>}<br />

                                                                <FormControl>
                                                                    {mangageAvalList && mangageAvalList.data &&
                                                                        < Stack spacing={{ xs: 1, sm: 2 }} style={{ marginTop: '10px' }} direction="row" useFlexGap flexWrap="wrap">
                                                                            {
                                                                                slotArray && slotArray.length > 0 && slotArray.map((e, i) => {
                                                                                    return (
                                                                                        <div key={i} style={{ flexBasis: "100%" }}>
                                                                                            <div style={{ width: "150px" }} className='add-new-user-form row'>
                                                                                                <Item style={{ color: 'blue', margin: 5, border: "1px solid #171819" }} >
                                                                                                    {e.availability_from && e.availability_from.split(":")[0] + ":" + e.availability_from.split(":")[1]}~
                                                                                                    {e.availability_to && e.availability_to.split(":")[0] + ":" + e.availability_to.split(":")[1]}
                                                                                                </Item>
                                                                                            </div>
                                                                                            <div key={i} className='add-new-user-form row' style={{ marginLeft: 155, marginTop: -46 }}>
                                                                                                {e.slotsRange && e.slotsRange.length > 0 && e.slotsRange.map((itm, idx) => {
                                                                                                    selected_slots_is = selectedSlotsIs.find(x => x === itm)
                                                                                                    booked_slots_is = bookedSlots.find(x => x === itm)
                                                                                                    gap_slots_is = selectedGapSlotsIs.find(x => x === itm)
                                                                                                    gap_single_slots_is = selectedSingleGapSlots.find(x => x === itm)

                                                                                                    return (
                                                                                                        <div key={idx} style={(booked_slots_is || selected_slots_is || gap_slots_is || gap_single_slots_is) ? { margin: 5, cursor: "not-allowed" } : { margin: 5, cursor: 'pointer' }}
                                                                                                            onClick={() => (booked_slots_is === itm || gap_slots_is === itm || gap_single_slots_is === itm) ? nothing() : selectSlotsChange(itm, e)}>
                                                                                                            <Item style={
                                                                                                                booked_slots_is ? { backgroundColor: 'red', color: '#fff', width: "150px" }
                                                                                                                    : selected_slots_is ? { backgroundColor: '#2b70ed', color: '#fff', width: "150px" }
                                                                                                                        : gap_slots_is ? { backgroundColor: '#484545', color: '#fff', width: "150px" }
                                                                                                                            : gap_single_slots_is ? { backgroundColor: '#484545', color: '#fff', width: "150px" }
                                                                                                                                : { backgroundColor: 'green', color: '#fff', width: "150px" }

                                                                                                            }>{itm}</Item>
                                                                                                        </div>
                                                                                                    )
                                                                                                })
                                                                                                }
                                                                                                <br />

                                                                                            </div>
                                                                                            {/* <br /> */}
                                                                                        </div>
                                                                                    )
                                                                                })
                                                                            }
                                                                            {/* <br /> */}
                                                                            {/* {
                                                                                mangageAvalList.data && mangageAvalList.data.interview_schedules && mangageAvalList.data.interview_schedules.length > 0 &&
                                                                                <Item style={{ color: 'green' }}>Already Booked for this time</Item>
                                                                            }
                                                                            {
                                                                                mangageAvalList.data && mangageAvalList.data.interview_schedules && mangageAvalList.data.interview_schedules.map((e, i) => {
                                                                                    return (
                                                                                        <Item key={i}>{e.scheduled_time} - {e.scheduled_end_time}</Item>
                                                                                    )
                                                                                })
                                                                            } */}
                                                                            {mangageAvalList.data.length === 0 &&
                                                                                <Item>{'No schedule found for this date !'}</Item>
                                                                            }

                                                                        </Stack>
                                                                    }
                                                                </FormControl>
                                                            </div>
                                                        </div>

                                                    }

                                                    {(fieldType === 'r' || fieldType === 'cr' || fieldType === 'as') &&
                                                        <FormControl>
                                                            <TextField
                                                                id="interviewdate"
                                                                type="date"
                                                                name={'scheduled_date'}
                                                                onChange={(evt) => handleChange(evt)}
                                                                value={formValues.scheduled_date}
                                                                sx={{ width: 220 }}
                                                                InputLabelProps={{
                                                                    shrink: true,
                                                                }}
                                                                disabled={(fieldType === 's' || fieldType === 'as') ? true : false}
                                                                inputProps={{ min: moment().format('YYYY-MM-DD'), max: null }}
                                                            />
                                                            {((fieldType === 'r' || fieldType === 'cr') && errStatus === true && (!formValues.scheduled_date || formValues.scheduled_date === "")) && <p style={{ color: "#96332c", fontSize: 13 }}>{'This field is required'}</p>}

                                                        </FormControl>
                                                    }

                                                    {(fieldType === 's') &&
                                                        <div>
                                                            <label> Meeting Url</label> <br />
                                                            <FormControl>
                                                                <TextField
                                                                    id=""
                                                                    type="text"
                                                                    name={''}
                                                                    onChange={(evt) => handleChange(evt)}
                                                                    value={formValues.meeting_url}
                                                                    sx={{ width: 220, my: 1 }}
                                                                    InputLabelProps={{
                                                                        shrink: true,
                                                                    }}
                                                                    disabled={true}
                                                                />

                                                            </FormControl> <br />

                                                        </div>
                                                    }
                                                    {(fieldType === 'r' || fieldType === 'cr' || fieldType === 'as') &&
                                                        <FormControl style={{ flexBasis: '20%', marginRight: '432px' }} >
                                                            <TextField
                                                                //style={{ height: '35px', borderColor: '#ddd' }}
                                                                id="interviewtime"
                                                                type="time"
                                                                name="scheduled_time"
                                                                value={formValues.scheduled_time}
                                                                onChange={(evt) => { handleChange(evt) }}
                                                                InputLabelProps={{
                                                                    shrink: true,
                                                                }}
                                                                disabled={fieldType === 'as' ? true : false}
                                                                inputProps={{
                                                                    step: 300, // 5 min
                                                                }}
                                                                sx={{ width: 150 }}

                                                            />
                                                            {((fieldType === 'r' || fieldType === 'cr') && errStatus === true && (!formValues.scheduled_time || formValues.scheduled_time === "")) && <p style={{ color: "#96332c", fontSize: 13 }}>{'This field is required'}</p>}

                                                        </FormControl>
                                                    }
                                                    {(fieldType === 'r' || fieldType === 'cr') && formValues.scheduled_date && manageAvlList && manageAvlList.data && manageAvlList.data.default_availability_from_settings &&
                                                        <FormControl>
                                                            <Stack spacing={{ xs: 1, sm: 2 }} style={{ marginTop: '10px' }} direction="row" useFlexGap flexWrap="wrap">
                                                                {
                                                                    manageAvlList.data.default_availability_from_settings.length > 0 && manageAvlList.data.default_availability_from_settings.map((e, i) => {
                                                                        return (
                                                                            <Item key={i}>{e.availability_from} - {e.availability_to}</Item>
                                                                        )
                                                                    })
                                                                }
                                                                {
                                                                    manageAvlList.data.default_availability_from_settings.length === 0 &&
                                                                    <Item>No schedule found for this date !</Item>

                                                                }

                                                            </Stack>
                                                        </FormControl>}

                                                    {(fieldType === 'r' || fieldType === 'cr') && formValues.scheduled_date && manageAvlList && !manageAvlList.data && manageAvlList.status === 400 &&
                                                        <FormControl>
                                                            <Stack spacing={{ xs: 1, sm: 2 }} style={{ marginTop: '10px' }} direction="row" useFlexGap flexWrap="wrap">
                                                                <Item>{manageAvlList.message}</Item>
                                                            </Stack>
                                                        </FormControl>}

                                                    {(fieldType === 'r' || fieldType === 'cr') && avlStatus === false && formValues.scheduled_date &&
                                                        <FormControl>
                                                            <button className="submit-modal" onClick={checkAvailability}>
                                                                show availability
                                                            </button>
                                                        </FormControl>
                                                    }

                                                    {fieldType === 'd' &&

                                                        <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
                                                            <label>Candidate Interview Status <span style={{ color: "#96332c", fontSize: 10 }}>*</span>
                                                            </label>
                                                            <select
                                                                name="candidate_interview_status"
                                                                onChange={handleChange}
                                                                style={{ padding: "7px" }}
                                                                required
                                                            >
                                                                <option value="" selected disabled>Select Candidate Interview Status</option>
                                                                <option value="selected">Selected</option>
                                                                <option value="rejected">Rejected</option>

                                                            </select>
                                                            {((fieldType === 'd') && errStatus === true && (!formValues?.candidate_interview_status || formValues?.candidate_interview_status === "")) && <p style={{ color: "#96332c", fontSize: 13 }}>{'This field is required'}</p>}
                                                            <AddFeedBackform
                                                                formValues={formValues}
                                                                headData={headData}
                                                                handleChangeFeedBack={handleChangeFeedBack}
                                                                addHeadData={addHeadData}
                                                                setAddHeadData={setAddHeadData}
                                                                errStatus={errStatus}
                                                            />
                                                        </div>
                                                    }

                                                    {(fieldType === 'r' || fieldType === 'rr' || fieldType === 'ci' || fieldType === 'd' || fieldType === 'i') &&
                                                        <div className="formInput" style={{ flexBasis: '100%', marginTop: '20px' }}>
                                                            {fieldType === 'd' ?
                                                                <label>Remark <span style={{ color: "#96332c", fontSize: 10 }}>*</span></label>
                                                                :
                                                                <label >
                                                                    Comment/ Reason
                                                                </label>
                                                            }

                                                            <textarea defaultValue={formValues.comment} name={'comment'} onChange={handleChange} rows={3} placeholder={fieldType === 'd' ? "Remark" : "Comment/ Reason"}></textarea>

                                                            {((fieldType === 'r' || fieldType === 'rr' || fieldType === 'ci' || fieldType === 'd' || fieldType === 'i') && errStatus === true && (!formValues.comment || formValues.comment === "")) && <p style={{ color: "#96332c", fontSize: 13 }}>{'This field is required'}</p>}
                                                        </div>}
                                                    {fieldType === 'd' &&
                                                        <>
                                                            <label style={{ flexBasis: '100%', backgroundColor: '#2196f3', marginBottom: 15, padding: 10, color: '#fff' }}>Feedback for Recruiter</label>

                                                            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                                                                <Grid item xs={4}>
                                                                    <div className="formInput20">
                                                                        <FormControl>
                                                                            <label>{formValues.agent__display_name}</label>
                                                                        </FormControl>
                                                                    </div>
                                                                    <h6></h6>
                                                                    <Rating
                                                                        // value={formValues.ele}
                                                                        name={'rating_value'}
                                                                        onChange={handleChange}
                                                                        precision={0.1}
                                                                        style={{marginTop:'14px'}}
                                                                    />
                                                                    {((fieldType === 'd') && errStatus === true && (!formValues.rating_value)) && <p style={{ color: "#96332c", fontSize: 13 }}>{'This field is required'}</p>}
                                                                </Grid>
                                                                <Grid item xs={8}>


                                                                {(fieldType === 'r' || fieldType === 'rr' || fieldType === 'ci' || fieldType === 'd' || fieldType === 'i') &&
                                                        <div className="formInput" style={{ flexBasis: '100%', marginTop: '20px' }}>

                                                            <textarea defaultValue={formValues.rating_comment} name={'rating_comment'} onChange={handleChange} rows={3} placeholder={fieldType === 'd' ? "Comment" : "Comment"}></textarea>

                                                            {((fieldType === 'd') && errStatus === true && (!formValues.rating_comment)) && <p style={{ color: "#96332c", fontSize: 13 }}>{'This field is required'}</p>}
                                                        </div>}
                                                        
                                                                    
                                                                </Grid>
                                                            </Grid>
                                                        </>
                                                    }
                                                </div>
                                                {formValues.interview_status__status_key === 'cancel_interview' && fieldType === 'ci' ?
                                                    <button disabled style={{ backgroundColor: '#ddd', padding: '10px', borderRadius: '5px' }}>Cancelled interview</button>
                                                    :
                                                    fieldType !== 'sc' && reload === false &&
                                                    <button className="submit-modal" onClick={submitData}>{
                                                        fieldType === 'rr' ? 'Request for Reschedule' :
                                                            // fieldType === 'af' ? 'submit' :
                                                            fieldType === 'cr' ? 'Confirm Reschedule' :
                                                                fieldType === 'ci' ? 'Submit' :
                                                                    fieldType === 'as' ? 'Accept Schedule' :
                                                                        fieldType === 'd' ? 'Submit' :
                                                                            fieldType === 's' ? 'Join Meeting' :
                                                                                fieldType === 'i' ? 'Interrupted' :


                                                                                    'Submit'}</button>
                                                }
                                            </form>
                                }
                            </DialogContent>

                        </Dialog>
                }
                {showEmailModel && (
                    <EmailTemplet
                        setShowEmailModel={closeEmailModel}
                        to_email={candidateEmail}
                        project_id={
                            formValues && formValues.obj && formValues.obj.epic__project_id
                        }
                        data={formValues.obj}
                        story_id={formValues.obj && formValues.obj.story_id}
                        module={'interviewer'}
                        shiftTime={shiftTime}
                        interviewer_status={scheduleStatusOptions.filter(e => e.status_key === 'cancel_interview' && e)}
                        interviewerFormValues={formValues}
                        interviewUpdateApi={interviewUpdate}
                        setInterviewReload={setReload}

                    />
                )}
            </div>
        </div >
    )
}

export default Interviews
