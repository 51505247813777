import React, { useEffect, useState } from "react";
import Widget from "../../components/widget/Widget"
import "./dashboardMenu.scss"
import { useDispatch, useSelector } from "react-redux";
import { allProjects, allResources, allShift, allTask, checkIsInterviewerAval, myProjects, dailyWorkFlowSummary } from "../../redux/features/dashboardSlice";
import GetLocalstorage from "../../components/common-function/GetLocalstorage";
import { Dialog, DialogContent, SwipeableDrawer, Stack, Typography } from "@mui/material";
import DailyWorkLog from "./DailyWorkLog";
import { PictureAsPdf } from "@mui/icons-material";
import CalendarTodayRoundedIcon from '@mui/icons-material/CalendarTodayRounded';
import { Link } from "react-router-dom";
import moment from "moment";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
const rtm_user = GetLocalstorage.userDetail()
const availability = localStorage.getItem('is_interviewr_availabilty')

const DashboardMenu = () => {

    if (window.location === window.location.origin + '/dashboard' || window.location === `${window.location.origin}/dashboard`) {
        window.location = '/dashboard?reload=done'
    }
    const dispatch = useDispatch();

    const { resourceList, shiftList, projectList, myProjectList, taskList, interviewerAval, dailyWorkFlowSummaryList, activityDropDownList, projectDropDownList } = useSelector(state => state.dashboardCard)
    const tmsRole = rtm_user && rtm_user.tms_role
    const setAvl = useState((availability === 'true' || interviewerAval === true) ? true : false)[1]
    //const [openInterviewerModal, setOpenInterviewerModal] = useState(rtm_user.is_interviewer === true ? avl === true ? false : true : avl === true ? false : true)
    const [openInterviewerModal, setOpenInterviewerModal] = useState(false)
    const [openModal, setOpenModal] = useState(false)
    const [clearOpen, setClearOpen] = useState(true)

    useEffect(() => {
        dispatch(dailyWorkFlowSummary(`{"filter":{"resource":{"value":${GetLocalstorage?.userDetail().id},"operation":"eq"},"date":{"value":"${moment().format("YYYY-MM-DD")}","operation":"eq"}},"skip":0,"limit":10}`))
        dispatch(allResources('{"skip":0,"no_limit":True}'))
        dispatch(allProjects())
        if (tmsRole && (tmsRole.role_key === 'admin' || tmsRole.role_key === 'super_admin')) {
            dispatch(allShift('{"skip":0,"limit":10}'))
            // dispatch(allReport())
            // dispatch(allProjects('name'))
        } else {
            dispatch(allTask())
            dispatch(myProjects())
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch])
    const closeModal = () => {
        setAvl(true)
        dispatch(checkIsInterviewerAval())
        localStorage.setItem("is_interviewr_availabilty", true)
        setOpenInterviewerModal(false)
    }

    // const handleClose = () => {
    //     setOpenModal(false)
    //     setOpenModal(false)
    //     setClearOpen(true)
    //     setTimeout(() => {
    //         setClearOpen(false)
    //     }, 200)

    // }

    const handleOpen = () => {
        setOpenModal(true)
        setClearOpen(false)
    }

    return (

        <div className="dashboard">
            <Sidebar />
            <div className="dashboardContainer">
                <Navbar />

                <div className="widgets">
                    {
                        tmsRole && (tmsRole.role_key === 'admin' || tmsRole.role_key === 'super_admin') ?
                            <>
                                <Widget type="resources" count={(resourceList && resourceList.count) ? resourceList.count : 0} />
                                <Widget type="projects" count={(projectList && projectList.count) ? projectList.count : 0} />
                                <Widget type="shifts" count={(shiftList && shiftList.count) ? shiftList.count : 0} />
                                <Widget type="reports" count={2} />
                            </>
                            : <>
                                <Widget type="tasks" count={(taskList && taskList.count) ? taskList.count : 0} />
                                <Widget type="myProjects" count={(myProjectList && myProjectList.count) ? myProjectList.count : 0} />


                            </>
                    }
                    {/* for Daily WorkLog Widget */}
                    <div className="widget">
                        <div className="left">
                            <span className="title">Daily WorkLog</span>
                            <span className="counter">
                                {/* {dailyWorkFlowSummaryList?.data?.count ? dailyWorkFlowSummaryList?.data?.count : 0} */}
                                <Stack direction={"row"} alignItems={'center'}>
                                    <Typography variant="subtitle1" fontSize={10} fontWeight={'bold'}>Total Logged Hours: &nbsp; </Typography>
                                    <Typography variant="subtitle1" fontSize={10}> {dailyWorkFlowSummaryList?.data?.total_logged_hour ? dailyWorkFlowSummaryList?.data?.total_logged_hour : 0}H</Typography>
                                    <Typography variant="subtitle1" fontSize={10} fontWeight={'bold'} ml={'8px'}>Efficiency till now:  &nbsp;</Typography>
                                    <Typography variant="subtitle1" fontSize={10}> {dailyWorkFlowSummaryList?.data?.efficiency_till_now ? dailyWorkFlowSummaryList?.data?.efficiency_till_now : 0} </Typography>
                                    <Typography variant="subtitle1" fontSize={10} fontWeight={'bold'} ml={'8px'}>Efficiency for the day:  &nbsp;</Typography>
                                    <Typography variant="subtitle1" fontSize={10} > {dailyWorkFlowSummaryList?.data?.efficiency_for_the_day ? dailyWorkFlowSummaryList?.data?.efficiency_for_the_day : 0} </Typography>
                                </Stack>
                            </span>
                            <span className="link" onClick={handleOpen}>                               
                             <Link>See All Worklog Summary</Link>
                            </span>
                        </div>
                        <div className="right">
                            <div className="percentage positive">
                            </div>

                            <CalendarTodayRoundedIcon className="icon"
                                style={{
                                    color: "purple",
                                    backgroundColor: " rgba(128,0,128,0.2)",
                                    color: "green",
                                    backgroundColor: "rgba(0,128,0,0.2)",
                                }}
                            />

                        </div>
                    </div>
                </div>
                <Dialog
                    open={openInterviewerModal}
                    onClose={() => closeModal()}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description">

                    <DialogContent>
                        <div className="popup-confirming">
                            <h3 style={{ color: '#ff0000d1' }}>Assignment for Interviewer</h3>
                            <p>{"If you do not mark your custom availability, It will be considered you are available throughout your shift time for any interviews."}</p>
                        </div>
                    </DialogContent>
                </Dialog>

                {/* <div className="charts">
                    <Featured />

                </div> */}

                <SwipeableDrawer
                    anchor={"right"}
                    open={openModal}
                    // onClose={handleClose}
                    onOpen={openModal}
                >

                    <DailyWorkLog
                        allProjects={allProjects}
                        allResources={allResources}
                        resourceList={resourceList}
                        projectList={projectList}
                        activityDropDownList={activityDropDownList}
                        projectDropDownList={projectDropDownList}
                        setOpenModal={setOpenModal}
                        openModal={openModal}
                        clearOpen={clearOpen}
                        setClearOpen={setClearOpen}
                    />
                </SwipeableDrawer>

            </div>

        </div>
    )
}

export default DashboardMenu
