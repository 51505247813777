import Select from 'react-select';
import { memo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { InputFields } from '../../../common-function/InputFields';
import { Checkbox, Tooltip } from '@mui/material';
import { AddCircleOutline, RemoveCircleOutline } from '@mui/icons-material';
import { useState } from 'react';
import dataService from '../../../../redux/services/data.service';
import { toast } from 'react-toastify';
import QuestionTableList from './QuestionTableList';
import GetLocalstorage from '../../../common-function/GetLocalstorage';
import { questionCreate } from '../../../../redux/features/hiringRequestSlice';
import { STATUSES } from '../../../../redux/common-status/constant';
import LoaderForm from '../../../common-function/LoaderForm';

const initialQuestionData = Object.freeze({
    test_type_key: null,
    title: "",
    technology_key: "",
    answer_option: [
        {
            answer_option: "",
            is_correct: false
        }
    ],
    question_type: ""

});

const PartThree = ({ formValues, setFormValues, formData, setFormData,
    index, technologies, testTypes, test_type_key, level_key, questionType_List, is_mcq, for_agent }) => {

    let department_id = formValues && formValues.department_id
    let industry_id = formValues && formValues.industry_id

    const [questionIds, setQuestionIds] = useState([])
    const dispatch = useDispatch()
    // search 
    const [selectTechnology, setSelectTechnology] = useState("")
    const [selectQuestionType, setSelectQuestionType] = useState("")
    const [questionObj, setQuestionObj] = useState(initialQuestionData)

    // add 
    const [disabledAddButton, setDisabledAddButton] = useState(false)

    const [showAddForm, setShowAddForm] = useState(false)
    const [reload, setReload] = useState(false)

    const [answerFieldActive, setAnswerFieldActive] = useState(false)
    const [answerOptionsArray, setAnswerOptionsArray] = useState([])


    const { question_Create, status } = useSelector(state => state.hiringRequest)
    const [existingData, setExistingData] = useState([])
    const [rowCount, setRowCount] = useState(0)

    const [selectionModel, setSelectionModel] = useState([])
    const [showTable, setShowTable] = useState(false)

    let techArray = []
    let typeArray = []
    let qusetionTypeArray = []

    if (is_mcq === true || is_mcq === 'true') {
        let arr = questionType_List.filter((item) => item.question_type_key === 'mcq')
        typeArray.push(arr[0]?.question_type_key)
        // eslint-disable-next-line
        arr.map((option) => {
            qusetionTypeArray.push({
                question_type: option.question_type,
                question_type_key: option.question_type_key,
                name: 'question_type_key',
            })
        })
    } else {
        // eslint-disable-next-line
        questionType_List.map((item) => {
            typeArray.push(item.question_type_key)
            qusetionTypeArray = questionType_List
        })

    }

    technologies.map((item) => {
        return techArray.push(item.key)
    })

    let filter = {
        "filter": {
            "test_type__test_type_key": { "value": test_type_key, "operation": "icontains" },
            "question__technology__technology_key": { "value": techArray, "operation": "in" },
            "question__question_type__question_type_key": { "value": typeArray, "operation": "in" },
            "question__question_level__level_key": { "value": level_key, "operation": "eq" },
        }, "order_by": { "column": "-id" }, "skip": 0, "limit": Number(testTypes[index].no_of_predefined_questions),
    }
    if (testTypes[index]?.is_for_agent_panel === true) {
        filter["filter"]["question__for_agent"] = { "value": "True", "operation": "eq" }
    }
    // console.log(level_key)
    const [filterQuery, setFilterQuery] = useState(filter)

    const functionSpec = async () => {
        const response = await dataService.ces_qusetionlist_by_testtype(filterQuery);
        const result = response && response.data
        if (result.status === 200) {
            let array = []
            result.data.rows.map((item) => {
                array.push({
                    ...item,
                    id: item.question_id
                })
            })
            setExistingData(array)
            setRowCount(result.data.count)
        } else {
            setExistingData([])
            setRowCount(0)
            toast.error(response.data.message)
        }
    }

    useEffect(() => {
        functionSpec()
        // eslint-disable-next-line
    }, [filterQuery])

    useEffect(() => {
        // let totalLimit = formData && formData.no_of_predefined_questions ? Number(formData && formData.no_of_predefined_questions) : 0
        let totalLimit = testTypes[index].no_of_predefined_questions ? Number(testTypes[index].no_of_predefined_questions) : 0
        let filter
        filter = {
            "filter": {
                "test_type__test_type_key": { "value": test_type_key, "operation": "icontains" },
                "question__technology__technology_key": { "value": techArray, "operation": "in" },
                "question__question_type__question_type_key": { "value": typeArray, "operation": "in" },
                "question__question_level__level_key": { "value": level_key, "operation": "eq" },
            }, "order_by": { "column": "-id" }, "skip": 0, "limit": totalLimit,
        }
        if (testTypes[index]?.is_for_agent_panel === true) {
            filter["filter"]["question__for_agent"] = { "value": "True", "operation": "eq" }
        }
        setFilterQuery(filter)
        // eslint-disable-next-line
    }, [test_type_key, testTypes[index].no_of_predefined_questions])


    //searchning 
    const search = () => {
        let array_tech = []
        let array_type = []

        selectTechnology && selectTechnology.length > 0 && selectTechnology.map((o) => array_tech.push(o.key));
        selectQuestionType && selectQuestionType.length > 0 && selectQuestionType.map((o) => array_type.push(o.key));

        let filterCondition = filterQuery

        if (selectTechnology && selectTechnology.length > 0) {
            filterCondition = {
                "filter": {
                    ...filterCondition.filter,
                    "question__technology__technology_key": { "value": array_tech, "operation": "in" }
                }
            }
        }

        if (selectQuestionType && selectQuestionType.length > 0) {
            filterCondition = {
                "filter": {
                    ...filterCondition.filter,
                    "question__question_type__question_type_key": { "value": array_type, "operation": "in" }
                }
            }
        }
        filterCondition['skip'] = 0
        filterCondition['limit'] = 10
        // filterCondition['limit'] = Number(formData?.no_of_predefined_questions)
        filterCondition['order_by'] = { "column": '-id' }
        // setLoading(true)
        setFilterQuery(filterCondition)
    }

    const clear = () => {
        setSelectQuestionType([])
        setSelectTechnology([])
        setFilterQuery(filter)
    }

    // add new question section

    const handleChangePredefine = (evt, ind) => {
        const { value } = evt.target || evt
        let checkValue = Number(testTypes[index]['total_no_question']) - Number(testTypes[index]?.random_questions?.no_of_random_question)
        if (value > checkValue) {
            testTypes[index]['no_of_predefined_questions'] = checkValue;
            testTypes[index]['predefined_questions'] = { ...formData.predefined_questions, no_of_predefined_questions: checkValue }
            setFormData({ ...formData, no_of_predefined_questions: checkValue, predefined_questions: { ...formData.predefined_questions, no_of_predefined_questions: checkValue } })

            return toast.error("Number of Predefined Questions exceed number of total question")
        } else {
            testTypes[index]['no_of_predefined_questions'] = value;
            testTypes[index]['predefined_questions'] = { ...formData.predefined_questions, no_of_predefined_questions: value }
            setFormData({ ...formData, no_of_predefined_questions: value, predefined_questions: { ...formData.predefined_questions, no_of_predefined_questions: value } })
        }

    }

    // handle Change for Specification
    const handleChangeSelect = (evt, ind) => {
        const selectedTechnology = evt ? evt.value : null;
        setQuestionObj({ ...questionObj, "technology_key": selectedTechnology })
    }

    const handleChangeQusetionType = (evt) => {
        const { name, value } = evt

        if (name === "question_type_key" && value === "mcq") {
            setAnswerFieldActive(true)
            // setAnswerOptionsArray([...answerOptionsArray, {
            //     answer_option: "", is_correct: false
            // }])

        } else {
            setAnswerOptionsArray([])
            setAnswerFieldActive(false)
        }

        const selectQuestionType = evt ? evt.key : null;
        setQuestionObj({ ...questionObj, "question_type": selectQuestionType })
    }


    const handleChangeQuestions = (evt, i) => {
        const { name, value } = evt.target
        setQuestionObj({ ...questionObj, [name]: value })
    }

    //answer option logic
    const addAnswerOptions = () => {
        setAnswerOptionsArray([...answerOptionsArray, {
            answer_option: "", is_correct: false
        }])
    }

    const removeAnswerOptions = async (i, id = null) => {
        const updateformData = answerOptionsArray.filter((item, index) => index !== i);
        setAnswerOptionsArray(updateformData)
    }

    const handleAnswerOption = (evt, index = null) => {
        let { name, type, value, checked } = evt.target || evt;
        value = type === "checkbox" ? checked : value;
        const valueArray = answerOptionsArray.length > 0 && answerOptionsArray.map((item, i) => i === index ?
            { ...item, [name]: value } : item);

        if (name === 'is_correct') {
            // eslint-disable-next-line
            answerOptionsArray.length > 0 && answerOptionsArray.map((e, i) => { e[index] === i ? e['is_correct'] = true : e['is_correct'] = false })
        }
        index !== null && setAnswerOptionsArray([...valueArray])
    }
    const saveNewQuestion = (type) => {
        if (questionObj.title === "" || questionObj.technology_key === "" || questionObj.question_type === "") {
            return toast.error("Please fill all question details")
        }

        if (questionObj.question_type === "mcq") {
            const isOneCheckboxSelected = answerOptionsArray.some(
                (item) => item.is_correct
            );

            if (!isOneCheckboxSelected) {
                return toast.error("Please select at least one correct answer option");
            }
        }

        setShowTable(true)

        let addvalues = ({
            ...questionObj,
            title: questionObj.title,
            test_type_key: test_type_key,
            answer_option: answerOptionsArray,
            created_by: GetLocalstorage.userDetail().id,
            department_key: department_id,
            industry_key: industry_id,
            question_level_key: level_key,
            question_level_key: level_key,
            for_agent: (for_agent === "false" || for_agent === false) ? false : true
        })

        if (type === "save") {
            setReload('save')
        } else {
            setReload('create')
        }
        dispatch(questionCreate(addvalues))

        // const res = dispatch(questionCreate(addvalues))
        // if (res.status === 200 && res.message === "Success") {
        //     setSelectionModel([...selectionModel, res?.data?.id])
        // }

        setTimeout(() => {
            setShowTable(false)
        }, 200)

    }

    if (reload === 'save' && status === `${STATUSES.SUCCESS}_question_Create` && question_Create && question_Create.message) {
        if (question_Create.status === 200) {
            let array = []
            toast.success(question_Create.message)
            setSelectionModel([...selectionModel, question_Create.data?.id])
            array.push(question_Create.data?.id)
            setQuestionIds([...questionIds, ...array])
            setAnswerOptionsArray([])
            setQuestionObj(initialQuestionData)
            setAnswerFieldActive(false)
            functionSpec()
        } else {
            toast.error(question_Create.message)
            functionSpec()
        }
        setReload(false)
    }

    if (reload === 'create' && status === `${STATUSES.SUCCESS}_question_Create` && question_Create && question_Create.message) {
        if (question_Create.status === 200) {
            toast.success(question_Create.message)
            setSelectionModel([...selectionModel, question_Create.data?.id])
            functionSpec()
            setQuestionObj(initialQuestionData)
            setAnswerOptionsArray([])
            let array = []
            array.push(question_Create.data?.id)
            setQuestionIds([...questionIds, ...array])
            setAnswerOptionsArray([])
            setShowAddForm(false)
            setAnswerFieldActive(false)
        } else {
            toast.error(question_Create.message)
        }
        setReload(false)
    }

    return <>
        <h2>&nbsp;</h2>
        <div className="add-new-user-form">
            <InputFields
                label={'Total No. of Predefined Questions'}
                name="no_of_predefined_questions"
                type="number"
                placeholder="Total No. of Predefined Questions"
                onChange={(evt) => handleChangePredefine(evt, index)}
                defaultValue={testTypes[index].no_of_predefined_questions}
            // formValues={formValues}
            // errStatus={errStatus}
            // formErr={formErrors.nameErr}
            />
        </div>
        {/* {technologyData && technologyData.map((item, tech_index) => {
            return ( */}
        <div className="add-new-user-form">

            {/* search section start */}
            <div className="formInput" style={{ flexBasis: "26%" }}>
                <label> Technology</label>
                <Select
                    isMulti
                    className="basic-single"
                    classNamePrefix="select"
                    placeholder={'Technology'}
                    // onChange={(evt) => handleChangeSelect(evt, index)}
                    isClearable
                    value={selectTechnology}
                    onChange={(evt) => { setSelectTechnology(evt) }}
                    isSearchable={true}
                    name={'technology'}
                    options={technologies && technologies.map((option) => {
                        return {
                            label: option.label,
                            value: option.value,
                            name: 'technology',
                            key: option.key,
                        }
                    })}
                />
            </div>
            <div className="formInput" style={{ flexBasis: "26%" }}>
                <label> Question Type</label>
                <Select
                    isMulti
                    className="basic-single"
                    classNamePrefix="select"
                    // divstyle={{ flexBasis: '100%' }}
                    placeholder={'Question Type'}
                    onChange={(evt) => { setSelectQuestionType(evt) }}
                    value={selectQuestionType}
                    isSearchable={true}
                    name={'question_type'}
                    options={qusetionTypeArray && qusetionTypeArray.map((option) => {
                        return {
                            label: option.question_type,
                            value: option.question_type_key,
                            name: 'question_type_key',
                            key: option.question_type_key,

                        }
                    })}
                />
            </div>
            <div className="formInput" style={{ marginTop: "24px", flexBasis: "43%" }}>
                <Tooltip title="Search">
                    <button className="btn btn-add-new"
                        style={{ padding: "8px 13px" }}
                        onClick={() => search(index)}
                    >Search</button>
                </Tooltip>
                <Tooltip title="Clear">
                    <button className="btn btn-add-new"
                        style={{ padding: "8px 13px", marginLeft: "5px", marginRight: "5px" }}
                        onClick={() => clear(index)}
                    >Clear</button>
                </Tooltip>
                <Tooltip title="Add new question">
                    <button className="btn btn-add-new"
                        style={disabledAddButton === true ? { backgroundColor: "gray", padding: "8px 13px", border: "1px solid" } : { padding: "8px 13px" }}
                        disabled={disabledAddButton === true ? true : false}
                        onClick={() => setShowAddForm(true)}
                    >Add New Question</button>
                </Tooltip>
            </div>
            {/* search section end */}

            <br /> <br />  <br />


            {/* listing section */}
            <div className="formInput" style={showAddForm === true ? { flexBasis: "44%" } : { flexBasis: "100%" }}>
                {showTable === true ? <LoaderForm /> :
                    <QuestionTableList
                        existingData={existingData}
                        rowCount={rowCount}
                        filterQuery={filterQuery}
                        setFilterQuery={setFilterQuery}
                        formData={formData}
                        setFormData={setFormData}
                        setDisabledAddButton={setDisabledAddButton}
                        testTypes={testTypes}
                        parentIndex={index}
                        setShowAddForm={setShowAddForm}
                        selectionModel={questionIds}
                        setSelectionModel={setQuestionIds}
                        showTable={showTable}
                    // technologyData={technologyData}
                    // setTechnologyData={setTechnologyData}
                    // tech_index={tech_index}
                    />
                }
            </div>

            {/* add new qusetion section */}

            {
                showAddForm === true && disabledAddButton === false &&
                <div className="formInput" style={{ flexBasis: "53%" }}>
                    {reload === 'save' || reload === 'create' ?
                        <div className="formInput" style={{ flexBasis: "53%", position: "absolute", top: "150%", right: "20%" }}> <LoaderForm /></div> :
                        <div>
                            <div className="formInput">
                                <h4> Add New Question</h4>
                            </div>
                            <div className="formInput">
                                <label >Technology</label>
                                <Select
                                    className="basic-single"
                                    classNamePrefix="select"
                                    placeholder={'Select Technology'}
                                    name={'technology_key'}
                                    // defaultValue={technologies.length==1 && technologies.map((option) => {
                                    //     return option;
                                    // })}
                                    onChange={(evt) => handleChangeSelect(evt)}
                                    options={technologies && technologies.map((option) => {
                                        return {
                                            label: option.label,
                                            value: option.key,
                                            name: 'technology_key',
                                            key: option.key,
                                        }
                                    })}
                                />
                            </div>
                            {/* } */}
                            <div className="formInput">
                                <label> Question Type</label>
                                <Select
                                    className="basic-single"
                                    classNamePrefix="select"
                                    placeholder={'Question Type'}
                                    onChange={(evt) => handleChangeQusetionType(evt)}
                                    // value={selectQuestionType}
                                    // defaultValue={item.technology}
                                    isSearchable={true}
                                    name={'question_type'}
                                    options={qusetionTypeArray && qusetionTypeArray.map((option) => {
                                        return {
                                            label: option.question_type,
                                            value: option.question_type_key,
                                            name: 'question_type_key',
                                            key: option.question_type_key,
                                        }
                                    })}
                                />
                            </div>
                            <InputFields
                                label={'Question Title'}
                                name="title"
                                type="text"
                                placeholder="Qusetion Title"
                                onChange={(evt) => handleChangeQuestions(evt)}
                            // defaultValue={item.test_name}
                            // formValues={formValues}
                            // errStatus={errStatus}
                            // formErr={formErrors.nameErr}
                            />

                            {/* answer option for mcq */}
                            {answerFieldActive === true &&
                                <div className="formInput">
                                    Answer Options
                                    <Tooltip title="Add More Answer Options">
                                        <AddCircleOutline onClick={() => addAnswerOptions()} style={{ marginLeft: "5px", color: "green", cursor: "pointer" }} />
                                    </Tooltip>
                                </div>
                            }
                            {answerOptionsArray && answerOptionsArray.map((item, i) => {
                                return (
                                    <div className="add-new-user-form" key={i} style={{ display: "flex" }}>

                                        <div className="formInput">
                                            <label >  Answer Option ({i + 1})  </label>
                                            <input
                                                name={"answer_option"}
                                                onChange={(e) => handleAnswerOption(e, i)}
                                                type="text"
                                                placeholder=" Answer Option"
                                                value={item.answer_option}
                                            />
                                        </div>

                                        <div className="formInput" style={{ flexBasis: "35%", marginLeft: "30px" }}>
                                            <label >
                                                Is Correct
                                            </label>
                                            <div style={{
                                                display: "flex",
                                                gap: "20px",
                                                alignItems: "center"
                                            }}>
                                                <Checkbox
                                                    className="checkbox"
                                                    inputProps={{ 'aria-label': 'controlled' }}
                                                    name={"is_correct"}
                                                    checked={item.is_correct}
                                                    onChange={(evt) => handleAnswerOption(evt, i)}
                                                    sx={{
                                                        '& .MuiSvgIcon-root': { fontSize: 30 }
                                                    }}
                                                />
                                                <div className="formInput" style={{
                                                    marginBottom: "0",
                                                    display: "flex",
                                                    alignItems: "center"
                                                }}>
                                                    {
                                                        <Tooltip title="Remove">
                                                            <RemoveCircleOutline style={{ color: "red", cursor: "pointer" }}
                                                                onClick={() => removeAnswerOptions(i, item.id)}
                                                            />
                                                        </Tooltip>
                                                    }
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                )

                            })

                            }

                            <span className="formInput" style={{ marginTop: "24px", }}>
                                <Tooltip title="Create New question">
                                    <button className="btn btn-add-new"
                                        style={{ padding: "8px 13px", marginRight: "10px" }}
                                        disabled={disabledAddButton === true ? true : false}
                                        onClick={() => saveNewQuestion('create')}

                                    >Create</button>
                                </Tooltip>

                                <Tooltip title="Create New question">
                                    <button className="btn btn-add-new"
                                        style={{ padding: "8px 13px", marginRight: "10px" }}
                                        disabled={disabledAddButton === true ? true : false}
                                        onClick={() => saveNewQuestion('save')}

                                    >Save & Create New</button>
                                </Tooltip>

                                <Tooltip title="Cancel">
                                    <button className="btn btn-add-new"
                                        style={{
                                            padding: "8px 13px",
                                            marginRight: "10px",
                                            float: "right",
                                            backgroundColor: "red",
                                            border: "1px solid"
                                        }}
                                        disabled={disabledAddButton === true ? true : false}
                                        onClick={() => {
                                            setShowAddForm(false)
                                            setAnswerFieldActive(false);
                                            setQuestionObj(initialQuestionData);
                                        }}

                                    >Cancel </button>
                                </Tooltip>
                            </span>
                        </div>
                    }
                </div>
            }

        </div>

    </>

}
export default memo(PartThree)  