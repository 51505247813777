import { Button, Tooltip } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react'
import DynamicTable from '../../components/dynamic-table/DynamicTable';
import Navbar from '../../components/navbar/Navbar';
import Sidebar from '../../components/sidebar/Sidebar';
import api from '../../redux/APIS/api'
import ShiftAssignment from './ShiftAssignment';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import InputLabel from '@mui/material/InputLabel';
import Multiselect from 'multiselect-react-dropdown';
import { toast, ToastContainer } from 'react-toastify';
import moment from 'moment';
import dataService from '../../redux/services/data.service';
import Select from 'react-select';
import Confirmation from '../../components/popup/Confirmation';
import { getGridSingleSelectOperators } from '@mui/x-data-grid';
import Filter from '../../components/Filter';
import { Refresh } from '@mui/icons-material';
import { DefaultFilterColumns } from '../../components/common-function/DefaultFilterColumns';
import LocalStorageDataService from '../../components/common-function/GetLocalstorage';
import { useDispatch, useSelector } from 'react-redux';
import { defaultassignshiftColumn } from '../../redux/features/projectSlice';

const default_assign_shift_Columns = LocalStorageDataService.get_default_assign_shift_column()
const { REACT_APP_ENV } = process.env;


const AssignShift = () => {

  const dispatch = useDispatch();
  const [resources, setResources] = useState([])

  const [shifts, setShifts] = useState([]);
  const [shiftsOptions, setShiftsOptions] = useState([]);
  const [pageSize, setPageSize] = useState(25);
  const [currentPage, setCurrentPage] = useState(0);
  const [skip, setSkip] = useState(0);
  const [rowCount, setRowCount] = useState(0);
  const [params, setParams] = useState({})
  const [loading, setLoading] = useState(true)

  const [shiftAssignmentOpen, setshiftAssignmentOpen] = useState(false);
  const [assigningOneResource, setAssigningOneResource] = useState({})

  const [resourcesArray, setResourcesArray] = useState([])
  const [bulkModelOpen, setBulkModelOpen] = useState(false)
  const [assigningBulkResource, setAssigningBulkResource] = useState([])
  const [assigningShift, setAssigningShift] = useState("")
  const [assigningResourceArray, setAssigningResourceArray] = useState([])

  const [roles, setRoles] = useState([]);
  const [departments, setDepartments] = useState([]);

  const [dialogOpen, setDialogOpen] = useState(false)

  // custom filter
  const [fieldName, setFieldName] = useState("")
  const [defaultValue, setDefaultValue] = useState({})
  const [options, setOptions] = useState({})

  const { defaultassignshiftColumns } = useSelector(state => state.projects)

  const [columnVisibilityModel, setColumnVisibilityModel] = useState(default_assign_shift_Columns && default_assign_shift_Columns.all_assign_shift_column ? default_assign_shift_Columns.all_assign_shift_column : {
    id: false,   
    tms_role_id: false, 
    department_id: false,
  
});

const setKeyForList = (obj) => {
  let data;
  if (default_assign_shift_Columns) {
      data = { 'all_assign_shift_column': obj, 'reported_assign_shift_column': default_assign_shift_Columns.reported_assign_shift_column, 'all_projectlist_column': default_assign_shift_Columns.all_projectlist_column, 'task_column': default_assign_shift_Columns.task_column }
  } else {
      data = { 'all_assign_shift_column': obj }
  }
  if (defaultassignshiftColumns.reported_assign_shift_column) {
      data['reported_assign_shift_column'] = defaultassignshiftColumns.reported_assign_shift_column
  }
  if (defaultassignshiftColumns.all_projectlist_column) {
      data['all_projectlist_column'] = defaultassignshiftColumns.all_projectlist_column
  }
  if (defaultassignshiftColumns.task_column) {
      data['task_column'] = defaultassignshiftColumns.task_column
  }
  dispatch(defaultassignshiftColumn(data))
}

  const resourcesColumns = [
    { field: "id", headerName: "ID", width: 70, filterable: false, },
    {
      field: "name",
      headerName: "Name",
      width: 150,
    },
    {
      field: "email",
      headerName: "Email",
      width: 150,
    },
    {
      field: "contact_no",
      headerName: "Contact No",
      width: 150,
    },
    {
      field: "jira_account",
      headerName: "Jira Account",
      width: 150,
    },
    {
      field: "department_id",
      headerName: "Department",
      width: 280,
      // hideable: true,
      filterable: true,
      type: 'singleSelect',
      valueOptions: departments,
      renderCell: (params) => {
        return (
          <div className="cellWithImg">
            <p style={{ padding: "5px" }}> {params.row.department_name} </p>
          </div>
        );
      },
    },
    {
      field: "tms_role_id",
      headerName: "Role",
      width: 150,
      type: 'singleSelect',
      valueOptions: roles,
      renderCell: (params) => {
        return (
          <div className="cellWithImg">
            <p style={{ padding: "5px" }}> {params.row.role_name} </p>
          </div>
        );
      },
    },
    {
      field: "shift_id",
      headerName: "Shift",
      width: 280,

      type: 'singleSelect',
      valueOptions: shiftsOptions,
      renderCell: (params) => {
        return (
          <div className="cellWithImg">
            {
              params.row.assigned_shifts.map((shift, index) => {
                return (
                  <p key={index} style={{ padding: "5px" }}> {shift} ( {moment("10/10/2022 " + params.row.assigned_shifts_array[0].start_time).format('LT')}<span> - {moment("10/10/2022 " + params.row.assigned_shifts_array[0].end_time).format('LT')} )</span>  </p>
                )
              })
            }
          </div>
        );
      },
    },
    {
      field: "status_value",
      headerName: "Status",
      type: 'singleSelect',
      valueOptions: [{ label: "Active", value: "True" }, { label: "Inactive", value: "False" }],
      width: 160,
      renderCell: (params) => {
        return (
          <div className={`cellWithStatus ${params.row.status}`}>
            {params.row.status === true ? "Active" : "Inactive"}
          </div>
        );
      },
    },
  ];

  // const [resourceColumns, setResourseColumns] = useState(resourcesColumns)
  const rtm_user = localStorage.getItem(`${REACT_APP_ENV}_rtms`) && JSON.parse(localStorage.getItem(`${REACT_APP_ENV}_rtms`))
  const userId = rtm_user.user.data.id;


  const getShiftName = async (resourceId, shiftId) => {
    const qParams = { "filter": { "shift_id": { "value": shiftId, "operation": "eq" }, "resource_id": { "value": resourceId, "operation": "eq" }, "status": { "value": "True", "operation": "eq" } }, "no_limit": "True" }
    const res = await api.get(`/resources/shift-resource?where=${JSON.stringify(qParams)}`)
    if (res && res.data && res.data.data && res.data.data.rows) {
      return res.data.data.rows.length ? res.data.data.rows[0].id : undefined;
    } else
      if (res) {
        return false;
      }
  }

  const loadResources = async () => {
    setLoading(true);
    const qParams = { ...params, filter: { "status": { "value": "True", "operation": "eq" }, ...params.filter }, "skip": skip, "limit": pageSize || 25, "columns": DefaultFilterColumns.RESOURCE_SHIFT_LIST_COLUMNS }
    const res = await api.get(`/resources/main?where=${JSON.stringify(qParams)}`)
    if (res && res.data && res.data.data && res.data.data.rows) {
      setRowCount(res.data.data.count)
      const formattedResponseData = res.data.data.rows.map((data) => {
        return {
          ...data,
          department_name: data.department ? data.department.name ? data.department.name : "" : data.department__name,
          department_code: data.department ? data.department.code ? data.department.code : "" : data.department__code,
          role_name: data.tms_role ? data.tms_role.role_name ? data.tms_role.role_name : "" : data.tms_role__role_name,
          role_key: data.tms_role ? data.tms_role.role_key ? data.tms_role.role_key : "" : data.tms_role__role_key,
          assigned_shifts: data.shift ? data.shift.shift_name ? [data.shift.shift_name] : [] : [data.shift__shift_name],
          shift_id: data.shift ? data.shift.id ? data.shift.id : "" : data.shift__id,
          status_value: data.status ? "True" : "False",
          assigned_shifts_array: data.shift ? [data.shift] : [{ end_time: data.shift__end_time, start_time: data.shift__start_time }],
        }
      })

      setResources(formattedResponseData)

    }
    setLoading(false);
  }

  const handleChange = (event) => {
    if (event) {
      setAssigningShift(event.value);
    }
    else {
      setAssigningShift("")
    }
  };

  const handleChangeMulti = (event) => {
    const optArray = event.map((e, i) => e.id);
    setAssigningResourceArray(optArray);
  };

  const onSelectionChange = (selectionArray) => {
    setResourcesArray(selectionArray);
    const data = resources.filter((element) => selectionArray.includes(element.id))
    setAssigningBulkResource(data)
    setAssigningResourceArray(data.map((e) => e.id))

  }

  const onAdditionalAction = async (data, key) => {
    if (key === "assign_shift") {
      if (data.shift && data.shift.id) {
        var resourceShift = await getShiftName(data.id, data.shift.id)
        if (resourceShift) {
          setAssigningOneResource(Object.assign({ ...data, resourceShift: resourceShift }))
          toggleAssignmentOpen()
        } else {
          setAssigningOneResource(data)
          toggleAssignmentOpen();
        }
      } else {
        setAssigningOneResource(data)
        toggleAssignmentOpen();
      }
    }
  }

  // Bulk Shift Assignment 
  const bulkAssignShift = async () => {
    const data = { resource: assigningResourceArray, shift: assigningShift, created_by: userId }
    if (!data.resource.length || !data.shift) {
      toast.error("Please fill the all details!")
      return;
    }
    let res = {}
    await api.post("/resources/shift-resource", data)
      .then(response => res = response)
      .catch(error => console.log(error, "Some error occoured!"))

    if (res && res.data && res.data.status === 200) {
      toast.success(res.data.message)
      loadResources();
      toggleBulkAssignment();
      toggleConfirm();
    }

    if (res && res.data && res.data.status === 400) {
      toast.error(res.data.message)
      loadResources();
      toggleBulkAssignment();
      toggleConfirm();
    }
  }

  const toggleAssignmentOpen = () => {
    setshiftAssignmentOpen(!shiftAssignmentOpen)
  }

  const handleClose1 = () => {
    setshiftAssignmentOpen(false);
  };

  const toggleBulkAssignment = () => {
    setBulkModelOpen(!bulkModelOpen)
    // setAssigningShift("")
  }

  const handleClose2 = () => {
    setBulkModelOpen(false);
  };

  // Confirmation toggle
  const toggleConfirm = () => {
    setDialogOpen(!dialogOpen);
  }

  // Get Department-List
  const getDepartments = async () => {
    const res = await dataService.departments_dropDown()
    let optionsDept = []
    if (res && res.data && res.data.data && res.data.data.rows) {
      let tempArray = res.data.data.rows;
      tempArray.map(e => { return optionsDept.push({ value: e.id, label: e.name }) })
    }
    setDepartments(optionsDept)
    setOptions({ ...options, department_id: optionsDept })
  }


  // Get Roles-List
  const getRoles = async () => {
    const res = await dataService.roles_dropDown()
    if (res && res.data && res.data.data && res.data.data.rows) {
      let tempArray = res.data.data.rows.map(e => { return { value: e.id, label: e.role_name } })
      setRoles(tempArray)
      tempArray.length > 0 && setOptions({ ...options, tms_role_id: tempArray })
    }
  }
  // Get Shifts-List
  const getShifts = async () => {
    const res = await dataService.shifts_dropDown()
    if (res && res.data && res.data.data && res.data.data.rows) {
      let tempArray = res.data.data.rows.map(e => { return { id: e.id, value: e.id, label: e.shift_name, shift_name: e.shift_name, start_time: e.start_time, end_time: e.end_time } })
      setShifts(tempArray)
      setShiftsOptions(res.data.data.rows.map(e => { return { value: e.id, label: e.shift_name } }))
      tempArray.length > 0 && setOptions({ ...options, shift_id: tempArray })
    }
  }

  useEffect(() => {
    loadResources();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [skip, pageSize, params])

  useEffect(() => {
    getDepartments();
    getRoles();
    getShifts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Custom filter
  function CustomFilterInputValue(props) {
    const { item, applyValue } = props;
    const handleFilterChange = (evt) => {
      if (evt) {
        setDefaultValue(evt)
        const { value } = evt;
        applyValue({ ...item, value: value });
      }
    };
    return (
      fieldName === "tms_role_id" ?
        <Filter options={roles} onChange={handleFilterChange} defaultValue={defaultValue} />
        : fieldName === "department_id" ?
          <Filter options={departments} onChange={handleFilterChange} defaultValue={defaultValue} />
          : fieldName === "shift_id" ?
            <Filter options={shifts} onChange={handleFilterChange} defaultValue={defaultValue} />
            : ""
    );
  }

  const columns = useMemo(() =>
    resourcesColumns.map((col) => {
      if (col.field === "tms_role_id" || col.field === "department_id" || col.field === "shift_id") {
        return {
          ...col,
          filterOperators: getGridSingleSelectOperators()
            .filter((operator) => operator.value !== "isAnyOf" && operator.value !== "not")
            .map((operator) => ({
              ...operator,
              InputComponent: operator.InputComponent
                ? CustomFilterInputValue
                : undefined
            }))
        };
      }
      return col;
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }), [resourcesColumns]
  );

  return (
    <div className="new">
      <Sidebar />

      <div className="newContainer">
        <Navbar />

        <div className="top-headings">
          <h3> {true ? "Assign Shift" : ""} </h3>
          <div>
            <button className={resourcesArray.length === 0 ? "btn-add-disabled" : "btn-add-new"} onClick={toggleBulkAssignment} disabled={resourcesArray.length === 0} >Bulk Shift Assignment</button>
            <button onClick={() => loadResources()} className="btn-add-new ">
              <Tooltip title="Reload">
                <Refresh />
              </Tooltip>
            </button>
          </div>
        </div>
        <ToastContainer />
        {
          <>
            {
              <DynamicTable
                tableColumns={columns}
                tableRows={!loading && resources && resources.length ? resources : []}
                paginationMode={"server"}
                rowCount={rowCount}
                loading={loading}
                currentPage={currentPage} pageSize={pageSize} skip={skip} params={params}
                setPageSize={setPageSize} setCurrentPage={setCurrentPage} setSkip={setSkip} setParams={setParams}
                onSelectionChange={onSelectionChange}
                additionalAction={[{ id: 1, key: "assign_shift", title: "Assign Shift" }]}
                onAdditionalAction={onAdditionalAction}
                checkboxSelection
                // columnVisibilityModel={{
                //   id: false,
                // }}
                columnVisibilityModel={defaultassignshiftColumns && defaultassignshiftColumns.all_assign_shift_column ? defaultassignshiftColumns.all_assign_shift_column : columnVisibilityModel}
                onColumnVisibilityModelChange={(newModel) => { setColumnVisibilityModel(newModel); setKeyForList(newModel) }
                }
                setFieldName={setFieldName}
                setDefaultValue={setDefaultValue}
              />
            }
          </>
        }

        <div>
          <Dialog
            fullWidth={"md"}
            maxWidth={"md"}
            open={shiftAssignmentOpen}
            onClose={toggleAssignmentOpen}
            sx={{ '& .MuiDialog-paper': { height: "400px" } }}

          >
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose1}
              aria-label="close"
              style={{ position: "absolute", top: "10px", right: "10px" }}
            >
              <CloseIcon />
            </IconButton>
            <DialogTitle style={{ background: '#2196f3', color: '#fff', marginBottom: "30px" }}>Assign Shift</DialogTitle>
            <DialogContent>
              <ShiftAssignment shifts={shifts} resource={assigningOneResource} toggleModel={toggleAssignmentOpen} loadResources={loadResources} />
            </DialogContent>

          </Dialog>

          <Dialog
            fullWidth={"md"}
            maxWidth={"md"}
            open={bulkModelOpen}
            onClose={toggleBulkAssignment}
            aria-labelledby="Bulk Assignment of Shift"
            aria-describedby="Bulk Assignment of Shift to resources"
            sx={{ '& .MuiDialog-paper': { height: "400px" } }}
          >
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose2}
              aria-label="close"
              style={{ position: "absolute", top: "10px", right: "10px" }}
            >
              <CloseIcon />
            </IconButton>
            <DialogTitle style={{ background: '#2196f3', color: '#fff', marginBottom: "30px" }}>Bulk shift assignment</DialogTitle>
            <DialogContent >
              <div className="assign-shift-modal">
                <InputLabel>Select Resources</InputLabel>
                {
                  <Multiselect
                    isObject={true}
                    onRemove={handleChangeMulti}
                    onSelect={handleChangeMulti}
                    displayValue={"name"}
                    selectedValues={assigningBulkResource}
                    options={resources}
                  />
                }
                <div className="formInput">
                  <InputLabel>Select Shift</InputLabel>
                  <Select
                    className="basic-single"
                    classNamePrefix="select"
                    placeholder="Select shift"
                    defaultValue={assigningShift}
                    isSearchable={true}
                    isClearable={true}
                    options={
                      shifts && shifts.map((option) => {
                        return {
                          label: `${option.shift_name} (${moment("10/10/2022 " + option.start_time).format('LT')} - ${moment("10/10/2022 " + option.end_time).format('LT')})`,
                          value: option.id,
                        }
                      })
                    }
                    onChange={handleChange}
                  />
                </div>

                <Button className="btn-submit" onClick={toggleConfirm} style={{ marginTop: "50px" }}> Bulk Assign Shift </Button>
              </div>

            </DialogContent>

            <Confirmation
              dialogTitle={"Bulk Assignment ?"}
              dialogQuestion={"Are you sure to do Bulk Assignment?"}
              open={dialogOpen}
              handleCancel={toggleConfirm}
              handleClose={toggleConfirm}
              onConfirm={bulkAssignShift}
            />

          </Dialog>
        </div>
      </div>
    </div>
  )
}

export default AssignShift

