import Navbar from "../../components/navbar/Navbar"
import Sidebar from "../../components/sidebar/Sidebar"
import "./master.scss"
import { toast, ToastContainer } from "react-toastify";
import { Dialog, DialogContent, TablePagination, Tooltip } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPurchesdpackage, purchesdpackageCreat, purchesdpackageEdit, purchesdpackageUpdate } from "../../redux/features/masterSlice";
import { getCurrencyList } from "../../redux/features/masterSlice";
import { errorHandling } from "../../redux/features/authLogoutSlice";
import { STATUSES } from "../../redux/common-status/constant";
import CONSTANTS from "../../components/constant/constantComponents";
import * as React from 'react';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import LoaderForm from "../../components/common-function/LoaderForm";
import UcActionComponent from "../../components/common-function/UcActionComponent";
import TablePaginationActions from "../../components/TablePaginationActions";
import { Add, Close } from "@mui/icons-material";
import { InputFields, InputRadioGroupFields, InputTextareaField, SingleDatePickerInputField } from "../../components/common-function/InputFields";
import DeleteDialogModal from "../../components/common-function/DeleteDialogModal";
import CustomNoRowsOverlay from "../../components/CustomEmptyOverlay";
import { RefreshButton } from "../../components/common-function/RefreshButton";
import { DefaultFilterColumns } from "../../components/common-function/DefaultFilterColumns";
import GetLocalstorage from "../../components/common-function/GetLocalstorage";
import Select from 'react-select';

const initialFormData = Object.freeze({
    name: "",
    price: "",
    currency: "",
    valid_upto: "",
    description: "",
    status: "",
});


const formErrData = Object.freeze({
    nameErr: "Name is required",
    priceErr: "Price is required",
    currencyErr: "Currency is required",
    valid_uptoErr: "Valid upto date is required",
    descriptionErr: "Description is required",
    statusErr: "Status is required",
    priceValidErr: "Please enter valid price"
});


const Purchespakage = () => {
    const { REACT_APP_ENV } = process.env;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const [formValues, setFormValues] = useState(initialFormData)
    const [formErr] = useState(formErrData)
    const [errStatus, setErrStatus] = useState(false);
    const [archiveId, setArchiveId] = useState(0)
    const [archiveOpen, setArchiveOpen] = useState(false)
    const { status, error, purchasedPackeges_List, purchasedPackeges_Create, purchasedPackeges_Edit, purchasedPackeges_Update } = useSelector(state => state.master)
    const { currency_List } = useSelector(state => state.master)
    const [currentPage, setCurrentPage] = useState(0);
    const [ID, setID] = useState("");
    const [reload, setReload] = useState(false)
    const [filterQuery, setFilterQuery] = useState(`{"order_by":{"column":"-id"},"skip":0, "limit":25,"columns":[${DefaultFilterColumns.PURCHASED_PACKAGE_LIST_COLUMNS}]}`)
    const [cfilterQuery, setCFilterQuery] = useState(`{"order_by":{"column":"-id"},"skip":0, "limit":25,"columns":[${DefaultFilterColumns.CURRENCY_LIST_COLUMNS}]}`)
    const [pageSize, setPageSize] = useState(25);
    const [limit, setLimit] = useState(25);
    const [columnVisibilityModel, setColumnVisibilityModel] = useState({ id: false });
    const [changeStatus, setChangeStatus] = useState('');

    useEffect(() => {
        dispatch(getCurrencyList(cfilterQuery))
        dispatch(getPurchesdpackage(filterQuery))
        if (status === STATUSES.FAILURE && error) {
            setReload(false)
            errorHandling(error, navigate)
        }
        // eslint-disable-next-line
    }, [])


    if (reload === true && status === `${STATUSES.SUCCESS}_purchasedPackeges_Update` && purchasedPackeges_Update && purchasedPackeges_Update.message) {
        if (purchasedPackeges_Update.status === 200) {
            toast.success(purchasedPackeges_Update.message)
            dispatch(getPurchesdpackage(filterQuery))
        } else {
            toast.error(purchasedPackeges_Update.message)
        }
        setReload(false)
    }

    if (reload === true && status === `${STATUSES.SUCCESS}_purchasedPackeges_Create` && purchasedPackeges_Create && purchasedPackeges_Create.message) {
        if (purchasedPackeges_Create.status === 200) {
            toast.success(purchasedPackeges_Create.message)
            dispatch(getPurchesdpackage(filterQuery))
        } else {
            toast.error(purchasedPackeges_Create.message)
        }
        setReload(false)
    }

    const handleOpen = () => setOpen(true);

    const handleClose = () => {
        if (ID) { setID(0); setFormValues(initialFormData) } setOpen(false); setFormValues(initialFormData);;
        setErrStatus(false);
    }

    const handleChange = (evt) => {
        const { name, value } = evt.target || evt;
        setFormValues({ ...formValues, [name]: value })
           }

    const submitData = (e) => {
        e.preventDefault()
        
        if (formValues.description === "" || formValues.name === "" || formValues.status === "" || formValues.currency === "" || formValues.price === "" || formValues.price < 0 || formValues.valid_upto === "" || formValues.currency === "") {
            setErrStatus(true)
        }

        if (formValues.description !== "" && formValues.name !== "" && formValues.status !== "" && formValues.price >= 0 && formValues.valid_upto !== "" && formValues.currency !== "") {
            const rtm_user = JSON.parse(localStorage.getItem(`${REACT_APP_ENV}_rtms`))
            let userId = rtm_user.user && rtm_user.user.data && rtm_user.user.data.id
            let addvalues = {
                ...formValues, created_by: userId,
                valid_upto: formValues.valid_upto,
                currency: formValues.currency && formValues.currency.label && formValues.currency.label ? formValues.currency.value : formValues.currency ,
                // status:Boolean(formValues.status)
            }
            setErrStatus(false)
            setReload(true)
            handleClose()
            if (ID) {
                dispatch(purchesdpackageUpdate({ ID, addvalues }))
            } else {
                dispatch(purchesdpackageCreat(addvalues))
            }
        }
    }

    if (reload === 'edit_data' && status === `${STATUSES.SUCCESS}_purchasedPackeges_Edit` && purchasedPackeges_Edit && purchasedPackeges_Edit.data) {
        let editData = purchasedPackeges_Edit.data
        setFormValues({
            name: editData.name,
            description: editData.description,
            status: editData.status,
            price: editData.price,
            valid_upto: editData.valid_upto,
            currency: {
                label: editData.currency.currency_name,
                value: editData.currency.id,
                name: "currency"
            }
        })
        setID(editData.id)
        setOpen(true)
        setReload(false)
    }

    const handleClickEdit = (id) => {
        dispatch(purchesdpackageEdit(id))
        setReload('edit_data')
    }

    const handleArchive = (id) => {
        setArchiveId(id)
        setArchiveOpen(true)
        if (id.status === true) {
            setChangeStatus("InActive")
        } else {
            setChangeStatus("Active")
        }
    }

    const handleClickArchive = (data) => {
        setReload(true)
        setArchiveOpen(false)
        let addvalues = {
            name: data.name,
            description: data.description,
            status: !data.status,
            updated_by: GetLocalstorage.userDetail().id,
            price: data.price,
            valid_upto: data.valid_upto,
            currency:data.currency_id           
        }
        let ID = data.id;
        setReload(true)
        dispatch(purchesdpackageUpdate({ ID, addvalues }))
    }

    //custom pagination
    const handlePagination = (data) => {
        setCurrentPage(data)
        let newFilterData = JSON.parse(filterQuery);
        newFilterData['skip'] = data * pageSize
        newFilterData['limit'] = limit
        setLimit(limit)
        setFilterQuery(JSON.stringify(newFilterData))
        dispatch(getPurchesdpackage(JSON.stringify(newFilterData)))
    }

    const handlePageSizeChange = (newPageSize) => {
        setPageSize(newPageSize.target.value)
        setCurrentPage(0)
        setLimit(newPageSize.target.value)
        let newFilterData = JSON.parse(filterQuery);
        newFilterData['skip'] = 0
        newFilterData['limit'] = newPageSize.target.value
        setFilterQuery(JSON.stringify(newFilterData))
        dispatch(getPurchesdpackage(JSON.stringify(newFilterData)))
    }

    function CustomPagination() {
        return (
            <TablePagination
                component="div"
                count={(purchasedPackeges_List && purchasedPackeges_List.data && purchasedPackeges_List.data.rows && purchasedPackeges_List.data.count) || 0}
                page={currentPage}
                rowsPerPage={pageSize}
                onPageChange={() => setCurrentPage(pageSize)}
                onRowsPerPageChange={handlePageSizeChange}
                ActionsComponent={(subProps) =>
                    <TablePaginationActions {...subProps}
                        handlePagination={handlePagination}
                    />}
            />
        );
    }

    // sorting
    const handleSort = (sort) => {
        if (sort.length) {
            let newFilterData = JSON.parse(filterQuery);
            newFilterData['skip'] = 0;
            newFilterData['limit'] = limit;
            newFilterData['order_by'] = {
                "column": sort[0].sort === "desc" ?
                    '-' + sort[0].field : sort[0].field
            }
            setFilterQuery(JSON.stringify(newFilterData))
            dispatch(getPurchesdpackage(JSON.stringify(newFilterData)))
        } else {
            setFilterQuery(`{ "skip": 0, "limit": ${limit}, "order_by": { "column": "-id" },"columns":[${DefaultFilterColumns.PURCHASED_PACKAGE_LIST_COLUMNS}] }`)
        }
    }


    const actionColumn = [
        {
            field: "name",
            headerName: "Name",
            width: 280,
            renderCell: (params) => {
                return (
                    <div>
                        {params.row.name}
                    </div>
                );
            },
        },
        {
            field: "price",
            headerName: "Price",
            width: 280,
            renderCell: (params) => {
                return (
                    <div>
                        {params.row.price}
                    </div>
                );
            },
        },
        {
            field: "Currency FK",
            headerName: "Currency",
            width: 280,
            renderCell: (params) => {
                return (
                    <div>
                        {params.row.currency__currency_name}
                    </div>
                );
            },
        },
        {
            field: "valid",
            headerName: "Valid Upto",
            width: 280,
            renderCell: (params) => {
                return (
                    <div>
                        {params.row.valid_upto}
                    </div>
                );
            },
        },
        {
            field: "description",
            headerName: "Description",
            width: 280,
            renderCell: (params) => {
                return (
                    <div>
                        {params.row.description ? params.row.description : "N/A"}
                    </div>
                );
            },
        },
        {
            field: "status",
            headerName: "Status",
            width: 160,
            renderCell: (params) => {
                return (
                    <div className={`cellWithStatus ${params.row.status}`}>
                        {params.row.status === true ? 'Active' : 'Inactive'}
                    </div>
                );
            },
        },
        {
            field: "action",
            headerName: "Action",
            width: "120",
            hideable: false,
            filterable: false,
            sortable: false,
            renderCell: (params) => {
                return (
                    <div className="cellAction">
                        <UcActionComponent
                            deleteIconName="ArchiveIcon"
                            moduleName="purches_package"
                            rowid={params.row.id}
                            addMoreData=""
                            editLinkUrl=""
                            viewLinkUrl=""
                            isDelete={true}
                            editButton={handleClickEdit}
                            viewButton={false}
                            deleteButton={() => handleArchive(params.row)} />
                    </div>
                );
            },
        },
    ];

    const onFilterChange = (filterModel) => {
        if (filterModel.items && filterModel.items.length > 0 && filterModel.items[0].columnField && filterModel.items[0].value) {
            let operatorValue = filterModel.items[0].operatorValue === "contains" ? "icontains" :
                filterModel.items[0].operatorValue === "startsWith" ? 'istartswith' :
                    filterModel.items[0].operatorValue === "endsWith" ? 'iendswith' : filterModel.items[0].operatorValue
            const filterData = `{"filter":{"${[filterModel.items[0].columnField]}":{"value":"${filterModel.items[0].value}","operation": "${operatorValue}"}},"order_by":{"column":"-id"},"skip":0, "limit":${limit},"columns":[${DefaultFilterColumns.PURCHASED_PACKAGE_LIST_COLUMNS}]}`
            setFilterQuery(filterData)
            setCurrentPage(0)
            dispatch(getPurchesdpackage(filterData))
        }
        if (filterModel.items && filterModel.items.length === 0) {
            setCurrentPage(0)
            //setPageSize(10)
            setFilterQuery(`{"order_by":{"column":"-id"},"skip":0, "limit":${limit},"columns":[${DefaultFilterColumns.PURCHASED_PACKAGE_LIST_COLUMNS}]}`)
            dispatch(getPurchesdpackage(`{"order_by":{"column":"-id"},"skip":0,"limit":${limit},"columns":[${DefaultFilterColumns.PURCHASED_PACKAGE_LIST_COLUMNS}]}`))
        }
    }
    return (
        <div className="jiraStatus">
            <Sidebar />
            <div className="jiraStatusContainer">
                <Navbar />

                <ToastContainer />
                <div className="datatable">
                    <div className="top-headings">
                        <h3>Purchase Packages</h3>
                        <div>
                            <Tooltip title="Add Purchase Package">
                                <button onClick={handleOpen} className="btn-add-new ">
                                    <Add />
                                </button>
                            </Tooltip>
                            <RefreshButton api={getPurchesdpackage} filter={filterQuery} style={{ marginTop: 0 }} />

                        </div>
                    </div>
                    {(status === STATUSES.LOADING || reload === true || reload === 'edit_data') && <LoaderForm />}
                    <DataGrid
                        className="datagrid"
                        columnVisibilityModel={columnVisibilityModel}
                        onColumnVisibilityModelChange={(newModel) =>
                            setColumnVisibilityModel(newModel)
                        }
                        rows={purchasedPackeges_List && purchasedPackeges_List.data && purchasedPackeges_List.data.rows ? purchasedPackeges_List.data.rows : []}
                        columns={actionColumn}
                        pageSize={pageSize}
                        style={{ fontSize: 16 }}
                        onFilterModelChange={onFilterChange}
                        disableSelectionOnClick
                        components={{
                            Pagination: CustomPagination,
                            NoRowsOverlay: CustomNoRowsOverlay
                        }}
                        onSortModelChange={(sort) => handleSort(sort)}
                    />
                </div>

                <Dialog
                    hideBackdrop
                    fullWidth
                    maxWidth={"md"}
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="child-modal-title"
                >
                    <Button
                        edge="start"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                        style={{ position: "absolute", top: "10px", right: "10px" }}>
                        <Close />
                    </Button>
                    <DialogTitle style={{ background: '#2196f3', color: '#fff', marginBottom: "20px" }}>{ID ? 'Edit' : 'Add'} Purchase Type</DialogTitle>

                    <DialogContent>
                        <form>
                            <div className="add-new-user-form">
                                <InputFields
                                    label={"Name "}
                                    name="name"
                                    defaultValue={formValues.name}
                                    type="text"
                                    placeholder="Name"
                                    onChange={handleChange}
                                    errStatus={errStatus}
                                    formErr={formErr.nameErr}
                                />
                                <div className="formInput"> 
                                <InputFields
                                    label={"Price"}
                                    name="price"
                                    defaultValue={formValues.price}
                                    type="number"
                                    placeholder="Price"
                                    onChange={handleChange}
                                    errStatus={errStatus}
                                    formErr={formErr.priceErr}
                                />
                                {/* {errStatus === true && (formValues.price === "") && <p style={{ color: "#96332c", fontSize: 13 }}>{formErr.priceErr}</p>} */}
                                {errStatus === true && (formValues.price < 0) && <p style={{ color: "#96332c", fontSize: 13 }}>{formErr.priceValidErr}</p>}
                                </div>
                               <div className="formInput"> 
                               <label >Select Currency</label>
                                <Select
                                label={"Select Currency"}                                  
                                    classNamePrefix="select"                                  
                                    placeholder={'Select Currency'}
                                    onChange={handleChange}
                                    defaultValue={formValues.currency}
                                    isSearchable={true}
                                    name={'currency'}
                                    options={
                                        currency_List && currency_List.data && currency_List.data.rows.map((option) => {
                                            return {
                                                label: option.currency_name,
                                                value: option.id,
                                                name: "currency"
                                            }
                                        })
                                    }
                                />
                                {errStatus === true && formValues.currency === "" && <p style={{ color: "#96332c", fontSize: 13 }}>{formErr.currencyErr}</p>}
                                </div>
                                <SingleDatePickerInputField
                                    label={'Valid Upto'}
                                    placeholder="Select"
                                    defaultValue={formValues.valid_upto}
                                    name={"valid_upto"}                                    
                                    onChange={(e) => {
                                        handleChange({
                                            name:"valid_upto",
                                            value:e
                                        })                                        
                                    }}
                                    format={'yyyy-MM-dd'}
                                    errStatus={errStatus}
                                    formErr={formErr.valid_uptoErr}
                                />
                                <InputTextareaField
                                    label={"Description"}
                                    name="description"
                                    defaultValue={formValues.description}
                                    type="text"
                                    placeholder="Description"
                                    onChange={handleChange}
                                    errStatus={errStatus}
                                    formErr={formErr.descriptionErr}
                                />
                                <InputRadioGroupFields
                                    label={"Status"}
                                    name="status"
                                    defaultValue={formValues.status}
                                    type="text"
                                    placeholder="Status"
                                    onChange={handleChange}
                                    errStatus={errStatus}
                                    formErr={formErr.statusErr}
                                />
                            </div>
                            <button className="submit-modal" onClick={submitData}>{ID ? CONSTANTS.COMMON_BUTTONS.UPDATE : CONSTANTS.COMMON_BUTTONS.SUBMIT}</button>
                        </form>
                    </DialogContent>
                </Dialog>
                <DeleteDialogModal
                    open={archiveOpen}
                    onClose={setArchiveOpen}
                    heading={changeStatus}
                    paragraph={`Are you sure to ${changeStatus} Purchase Type`}
                    handleArchive={handleClickArchive}
                    id={archiveId}
                />
            </div>
        </div >
    )
}

export default Purchespakage;