import { ArrowBack, Download, MoreHoriz, Refresh } from "@mui/icons-material";
import { FormControl, LinearProgress, List, ListItem, Rating, Skeleton, Stack, TextField, Tooltip, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from 'react-select';
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import CustomNoRowsOverlay from "../../components/CustomEmptyOverlay";
import { DataGridPro, gridClasses } from "@mui/x-data-grid-pro";
import { DefaultFilterColumns } from "../../components/common-function/DefaultFilterColumns";
import { STATUSES } from "../../redux/common-status/constant";
import dataService from "../../redux/services/data.service";
import { candidateOptionList, jobPostOptionList, resourceListOption, scheduleOptionList } from "../../redux/features/optionsSlice";
import MoreFilters from "../../components/moreFilters/MoreFIlters";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DatePicker } from "rsuite";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { useNavigate } from "react-router-dom";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import { isAfter } from "date-fns";

const { REACT_APP_API_BASEURL } = process.env;


export default function DailyInterviewReport() {
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const { status, error } = useSelector(state => state.interview)

    const { scheduleStatusOptions } = useSelector(state => state.dropdownOptions)
    const [limit, setLimit] = useState(25)
    const [filterQuery, setFilterQuery] = useState(`{"filter":{"scheduled_date":{"value":"${moment().format('YYYY-MM-DD')}","operation":"eq"}},"skip":0,"limit":25,"order_by":{"column":"-id"}, "columns" : [${DefaultFilterColumns.INTERVIEW_REPORT_LIST_COLUMNS}]}`)

    // grid states starts
    const [columnVisibilityModel, setColumnVisibilityModel] = useState({ id: false, resource__name: false });
    const [filterModal, setFilterModel] = useState({ items: [], linkOperator: 'and', quickFilterLogicOperator: "and", quickFilterValues: [] });
    const skeletonBase = { resource__name: '_skeleton_', resource_id: '_skeleton_', activity__activity_title: '_skeleton_', project_id: '_skeleton_', work_summary: '_skeleton_', project__project_name: '_skeleton_', from_time: '_skeleton_', to_time: '_skeleton_', activity_id: '_skeleton_', duration_display: '_skeleton_', date: '_skeleton_' };

    const [loading, setLoading] = useState(true)
    const [MAX_ROW_LENGTH, SET_MAX_ROW_LENGTH] = useState(null);
    const [gridRows, setGridRows] = useState([]);
    const [skeletonRows, setSkeletonRows] = useState(Array(25).fill(skeletonBase).map((_, ind) => ({ ..._, id: 'skeleton' + ind })));
    let stopOnRowScrollEnd = useRef(false);
    let clearGridListing = useRef(false);
    const [filterInputs, setFilterInputs] = useState({ scheduled_date: new Date() })
    const [downLoadStatus, setDownLoadStatus] = useState(false)
    const [refreshType, setRefreshType] = useState("")
    const [colSortingPerform, setColSortingPerform] = useState([]);
    const [filterActive, setFilterActive] = useState(false)

    useEffect(() => {
        if (status === STATUSES.FAILURE && error) {
            toast.error(error.message)
            setLoading(false)
        }
    }, [error])
    useEffect(() => {
        dispatch(resourceListOption())
        dispatch(jobPostOptionList())
        dispatch(candidateOptionList())
        dispatch(scheduleOptionList())
        // eslint-disable-next-line
    }, [])

    const apiCallList = async (filterData = null) => {
        setFilterActive(false)
        const res = await dataService.interview_report_list(filterData ? filterData : filterQuery)
        const response = res?.data
        if (response && response?.status === 200) {
            if (response?.data) {
                let listData = response?.data?.rows?.length > 0 ? response?.data?.rows : []
                SET_MAX_ROW_LENGTH(response?.data?.count);
                if (clearGridListing.current === true || refreshType === 'sort' || refreshType === 'search' || refreshType === 'reset') {
                    setRefreshType("")
                    setGridRows(listData);
                    setSkeletonRows([])
                    clearGridListing.current = false;
                } else {
                    setRefreshType("")
                    setGridRows((pre) => [...pre, ...listData]);
                    setSkeletonRows([])
                }
            } else {
                setRefreshType("")
                setSkeletonRows([])
                setGridRows([])
            }
            setTimeout(() => {
                setLoading(false)
            }, 500);
        } else {
            setRefreshType("")
            setLoading(false)
            toast.error(response.message)
        }

    }

    useEffect(() => {
        setLoading(true)
        apiCallList()
        setSkeletonRows(() => Array(JSON.parse(filterQuery).limit).fill(skeletonBase).map((_, ind) => ({ ..._, id: 'skeleton' + ind })))
    }, [filterQuery]);

    const columns = [
        {
            field: 'job__job_title',
            sortable: false,
            filterable: false,
            headerName: <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><p style={{ color: '#1B3764' }}>Job title</p>
                <button style={{ background: 'white', marginTop: '10px' }} onClick={() => handleSort("job__job_title")}>
                    <UnfoldMoreIcon sx={{ cursor: 'pointer', fontSize: '1.35rem', color: '#BCC2CE' }} /></button></div>,
            width: 195,
            renderCell: (params) => {
                return (
                    (params?.row?.job__job_title === "_skeleton_" || params?.row?.name === "_skeleton_") ?
                        params?.row?.job__job_title ?
                            <Skeleton width={"300px"} />
                            : params?.row?.job__job_title
                        : params?.row?.name
                );
            },
        },
        {
            field: 'candidate__candidate_name',
            sortable: false,
            filterable: false,
            headerName: <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><p style={{ color: '#1B3764' }}>Candidate Name</p>
                <button style={{ background: 'white', marginTop: '10px' }} onClick={() => handleSort("candidate__candidate_name")}>
                    <UnfoldMoreIcon sx={{ cursor: 'pointer', fontSize: '1.35rem', color: '#BCC2CE' }} /></button></div>,
            width: 195,
            renderCell: (params) => {
                return (
                    params?.row?.candidate__candidate_name === "_skeleton_" ? <Skeleton width={"300px"} /> : <Typography>{params?.row?.candidate__candidate_name}</Typography>
                );
            },
        },
        {
            field: 'interviewer__display_name',
            sortable: false,
            filterable: false,
            headerName: <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><p style={{ color: '#1B3764' }}>Interviewer Name</p>
                <button style={{ background: 'white', marginTop: '10px' }} onClick={() => handleSort("interviewer__display_name")}>
                    <UnfoldMoreIcon sx={{ cursor: 'pointer', fontSize: '1.35rem', color: '#BCC2CE' }} /></button></div>,
            width: 195,
            renderCell: (params) => {
                return (
                    params?.row?.interviewer__display_name === "_skeleton_" ? <Skeleton width={"300px"} /> : <Typography>{params?.row?.interviewer__display_name}</Typography>
                );
            },
        },
        {
            field: 'agent__display_name',
            sortable: false,
            filterable: false,
            headerName: <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><p style={{ color: '#1B3764' }}>Scheduled By</p>
                <button style={{ background: 'white', marginTop: '10px' }} onClick={() => handleSort("agent__display_name")}>
                    <UnfoldMoreIcon sx={{ cursor: 'pointer', fontSize: '1.35rem', color: '#BCC2CE' }} /></button></div>,
            width: 195,
            renderCell: (params) => {
                return (
                    params?.row?.agent__display_name === "_skeleton_" ? <Skeleton width={"300px"} /> : <Typography>{params?.row?.agent__display_name}</Typography>
                );
            },
        },
        {
            field: 'interview_status__status_name',
            sortable: false,
            headerName: <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><p style={{ color: '#1B3764' }}>Interview Status</p>
                <button style={{ background: 'white', marginTop: '10px' }} onClick={() => handleSort("interview_status__status_name")}>
                    <UnfoldMoreIcon sx={{ cursor: 'pointer', fontSize: '1.35rem', color: '#BCC2CE' }} /></button></div>,
            width: 180,
            renderCell: (params) => {
                return (
                    params?.row?.interview_status__status_name === "_skeleton_" ? <Skeleton width={"300px"} /> : <Typography>{params?.row?.interview_status__status_name}</Typography>
                );
            },
        },
        {
            field: 'feedback_details',
            sortable: false,
            filterable: false,
            headerName: 'Feedback for Candidate',
            width: 250,
            renderCell: (params) => {
                return (
                    params?.row?.feedback_details === "_skeleton_" ? <Skeleton width={"300px"} /> :
                        params?.row?.feedback_details?.length > 0 ?
                            params?.row?.feedback_details.map((itm => {
                                return (
                                    <>
                                        <Stack direction={"column"}>
                                            <Stack direction={"column"}> <Typography>Comment Head : <span>{itm.head_comment_name}</span></Typography></Stack>
                                            <Stack direction={"column"}><Typography>Rating : <Rating precision={0.1} value={itm.rating_value} readOnly /></Typography></Stack>
                                            <Stack direction={"column"}><Typography>Comment : {itm.rating_comment}</Typography></Stack>
                                        </Stack>
                                    </>

                                )
                            }))
                            :
                            <p>-</p>


                );
            },
        },
        {
            field: 'feedback_to_agent',
            sortable: false,
            filterable: false,
            headerName: 'Feedback for Recruiter',
            width: 250,

            renderCell: (params) => {
                return (
                    params?.row?.feedback_to_agent === "_skeleton_" ? <Skeleton width={"300px"} /> :
                        params?.row?.feedback_to_agent ?
                            <>

                                <Stack direction={"column"}>
                                    <Stack direction={"column"}><Typography>Rating : <Rating precision={0.1} value={params?.row?.feedback_to_agent?.rating_value} readOnly /></Typography></Stack>
                                    <Stack direction={"column"}><Typography>Comment : {params?.row?.feedback_to_agent?.rating_comment}</Typography></Stack>
                                </Stack>

                            </>

                            :
                            <Typography>-</Typography>


                );
            },
        },

    ]
    const loadServerRows = (viewPortRowSize) => {
        let updatedFilter = JSON.parse(filterQuery);
        updatedFilter.skip = gridRows.length;
        updatedFilter.limit = gridRows.length >= 25 ? Number(gridRows.length) + Number(limit) : Number(limit)
        setFilterQuery(JSON.stringify(updatedFilter))
        //apiCallList(filterData)
    }

    const handleOnRowScrollEnd = (params) => {
        if (stopOnRowScrollEnd.current) {
            stopOnRowScrollEnd.current = false;
            return;
        }
        if (gridRows.length < MAX_ROW_LENGTH && skeletonRows.length === 0) {
            loadServerRows(params.viewportPageSize);
        }
    };


    const handleSort = (sortingCol) => {
        setRefreshType("sort")
        stopOnRowScrollEnd.current = true;
        let copySorter = [...colSortingPerform];
        let foundElement = copySorter.length > 0 && copySorter.find(item => item.col === sortingCol);
        let newFilterData = JSON.parse(filterQuery);
        if (foundElement) {
            newFilterData['order_by'] = {
                "column": `${foundElement.order === "asc" ? '-' + sortingCol : sortingCol}`
            }
            copySorter.map(item => {
                if (item.id === foundElement.id)
                    item.order = item.order === "asc" ? "desc" : "asc"
            });
            setColSortingPerform(() => copySorter)
        }
        else {

            newFilterData['order_by'] = {
                "column": `${sortingCol}`
            }
            setColSortingPerform((pre) => [...pre, { id: pre.slice(0, -1)[0]?.id ? pre.slice(0, -1)[0]?.id + 1 : 1, col: sortingCol, order: 'asc' }]);
        }

        setFilterQuery(JSON.stringify(newFilterData))

    }


    const downloadCsvFile = async () => {
        setDownLoadStatus(true)
        let newFilterData = JSON.parse(filterQuery);
        newFilterData['for_download'] = "True";
        newFilterData['no_limit'] = "True";
        delete newFilterData['skip'];
        delete newFilterData['limit'];
        const res = await dataService.interview_report_list(JSON.stringify(newFilterData))
        const response = res?.data
        if (response && response?.status === 200) {
            if (response?.data) {
                let api_url = response?.data && response?.data?.download_api_url;
                if (REACT_APP_API_BASEURL && api_url) {
                    let url = REACT_APP_API_BASEURL + api_url;
                    if (url) {
                        let new_url = url?.replace("api//", "api/")
                        window.open(new_url);

                    }
                    setDownLoadStatus(false)

                }
            }
        } else {
            setDownLoadStatus(false)

            toast.error(response.message)
        }

    }

    const FilterComponents = () => {
        return (
            <>
                <List>
                    <ListItem>
                        <FormControl style={{ width: '100%' }}>
                            <LocalizationProvider dateAdapter={AdapterDayjs} style={{ flexBasis: "18%", marginTop: "8px", marginRight: "10px" }} >
                                <DatePicker
                                    shouldDisableDate={date => isAfter(date, new Date())}
                                    value={filterInputs.scheduled_date}
                                    onChange={(newValue) => {
                                        setFilterInputs({ ...filterInputs, scheduled_date: newValue }); setFilterActive(true)
                                    }}
                                    //format="YYYY-MM-DD"
                                    placeholder="Select Date"
                                    renderInput={(params) => <TextField disabled={true} {...params} helperText={null} />}
                                />
                            </LocalizationProvider>
                        </FormControl>
                    </ListItem>
                    <ListItem>
                        <FormControl style={{ width: '100%' }}>
                            <TextField
                                id="outlined-basic"
                                label="Interviewer Name"
                                name="interviewer__display_name"
                                value={filterInputs.interviewer__display_name}
                                onChange={(evt) => { setFilterInputs({ ...filterInputs, interviewer__display_name: evt.target.value }); setFilterActive(true) }}

                            />
                        </FormControl>
                    </ListItem>

                    <ListItem>
                        <FormControl style={{ width: '100%' }}>
                            <TextField
                                id="outlined-basic"
                                label="Candidate Name"
                                name="candidate__candidate_name"
                                value={filterInputs.candidate__candidate_name}
                                onChange={(evt) => { setFilterInputs({ ...filterInputs, candidate__candidate_name: evt.target.value }); setFilterActive(true) }}

                            />
                        </FormControl>
                    </ListItem>

                    <ListItem>
                        <FormControl style={{ width: '100%' }}>
                            <TextField
                                id="outlined-basic"
                                label="Scheduled By"
                                name="agent__display_name"
                                value={filterInputs.agent__display_name}
                                onChange={(evt) => { setFilterInputs({ ...filterInputs, agent__display_name: evt.target.value }); setFilterActive(true) }}

                            />
                        </FormControl>
                    </ListItem>

                    <ListItem>
                        <FormControl style={{ width: '100%',zIndex:1000 }}>

                            <Select
                                name="interview_status__status_name"
                                placeholder="Select Status"
                                value={filterInputs.interview_status__status_name}
                                onChange={(evt) => { setFilterInputs({ ...filterInputs, interview_status__status_name: evt }); setFilterActive(true);}}
                                width='300px'
                                options={scheduleStatusOptions} />
                        </FormControl>
                    </ListItem>

                    <ListItem>
                        <FormControl style={{ width: '100%' }}>
                            <TextField
                                id="outlined-basic"
                                label="Job"
                                name="job__job_title"
                                value={filterInputs.job__job_title}
                                onChange={(evt) => { setFilterInputs({ ...filterInputs, job__job_title: evt.target.value }); setFilterActive(true); }}

                            />
                        </FormControl>
                    </ListItem>

                </List>
            </>
        )
    }

    // advance search - resetHandler
    const handleFilterReset = async () => {
        setRefreshType("reset")
        setFilterInputs({ scheduled_date: new Date(), candidate__candidate_name: "", job__job_title: "", interview_status__status_name: "", interviewer__display_name: "", agent__display_name: "" })
        let filter = `{"filter":{"scheduled_date":{"value":"${moment().format('YYYY-MM-DD')}", "operation":"eq"}},"order_by":{"column":"-id"},"skip":0, "limit":${limit}, "columns" : [${DefaultFilterColumns.INTERVIEW_REPORT_LIST_COLUMNS}]}`
        setFilterQuery(filter)
        //apiCallList(filter)
    }


    // advance search - Searchhandler
    const handleFilterSearch = async (drawerOpen) => {
        let filter = { "filter": { "scheduled_date": { "value": moment().format('YYYY-MM-DD'), "operation": "eq" } }, "order_by": { "column": "-id" }, "skip": 0, "limit": limit, "columns": DefaultFilterColumns.INTERVIEW_REPORT_LIST_COLUMNS_JSON }
        if (filterInputs.scheduled_date) {
            filter['filter']['scheduled_date'] = { "value": moment(filterInputs.scheduled_date).format('YYYY-MM-DD'), "operation": "eq" }
        }
        if (filterInputs.interview_status__status_name) {
            filter['filter']['interview_status__status_name'] = { "value": filterInputs.interview_status__status_name?.label, "operation": "icontains" }

        }
        if (filterInputs.interviewer__display_name) {
            filter['filter']['interviewer__display_name'] = { "value": filterInputs.interviewer__display_name, "operation": "icontains" }

        }
        if (filterInputs.candidate__candidate_name) {
            filter['filter']['candidate__candidate_name'] = { "value": filterInputs.candidate__candidate_name, "operation": "icontains" }

        }
        if (filterInputs.agent__display_name) {
            filter['filter']['agent__display_name'] = { "value": filterInputs.agent__display_name, "operation": "icontains" }

        }
        if (filterInputs.job__job_title) {
            filter['filter']['job__job_title'] = { "value": filterInputs.job__job_title, "operation": "icontains" }

        }
        setRefreshType("search")
        setGridRows([])
        if (filterActive === false) { apiCallList() } else { setFilterQuery(JSON.stringify(filter)) }
        drawerOpen(false);

    }

    const refreshData = () => {
        setLoading(true)
        setRefreshType('reset');
        setGridRows([])
        apiCallList();
    }


    return (
        <div className="listing">
            <Sidebar />
            <div className="listingContainer">
                <Navbar />
                <ToastContainer />
                <div className="datatable">
                    <div className="datatableTitle">
                        <h3>Daily Interview  Schedule Report </h3>
                        <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                            {/* advance filter search start */}
                            <MoreFilters filterComponents={FilterComponents} handleFilterReset={handleFilterReset} handleFilterSearch={handleFilterSearch} isFilterDisabled={loading} inlineStyle={{ marginRight: '5px' }} />
                            {/* advance filter search end */}
                            <Tooltip title={"Download as CSV"}>
                                <button disabled={downLoadStatus} className="btn-add-new" style={downLoadStatus ? { marginRight: "5px", backgrond: '#808080' } : { marginRight: "5px", backgrond: '#0052cc' }} onClick={() => downloadCsvFile()}>
                                    <Download />
                                </button>
                            </Tooltip>
                            <button onClick={() => { refreshData() }} style={{ marginRight: '5px' }} className="btn-add-new ">
                                <Tooltip title="Reload">
                                    <Refresh />
                                </Tooltip>
                            </button>
                            <Tooltip title={"Back"}>
                                <button onClick={() => navigate(-1)} className="btn-add-new ">
                                    <ArrowBack />
                                </button>
                            </Tooltip>
                        </div>
                    </div>

                    {/* listing form  section*/}
                    <DataGridPro
                        rowHeight={100}
                        className="datagrid-drawer"
                        filterMode="server"
                        loading={loading}
                        columnVisibilityModel={columnVisibilityModel}
                        onColumnVisibilityModelChange={(newModel) => setColumnVisibilityModel(newModel)}
                        rows={gridRows?.concat(skeletonRows)}
                        columns={columns}
                        filterModel={filterModal}
                        getRowClassName={(params) => params.indexRelativeToCurrentPage % 2 === 0 ? 'Mui-even' : 'Mui-odd'}
                        components={{
                            ColumnMenuIcon: MoreHoriz,
                            LoadingOverlay: LinearProgress,
                            NoRowsOverlay: CustomNoRowsOverlay,
                        }}
                        // componentsProps={
                        //     { row: { onContextMenu: handleContextMenu } }
                        // }
                        sx={{
                            height: 700,
                            "& .MuiDataGrid-row:hover": {
                                backgroundColor: "#438bf969",
                            },
                            ".actionButton": {
                                display: 'none'
                            },
                            [`& .${gridClasses.row}`]: {
                                "&:hover, &.Mui-hovered": {
                                    ".actionButton": {
                                        display: 'block'
                                    }
                                }
                            }
                        }}
                        onRowsScrollEnd={handleOnRowScrollEnd}
                        hideFooter
                        rowThreshold={.7}
                        //onFilterModelChange={onGridFilterChange}
                        onSortModelChange={(sort) => handleSort(sort)}
                    />
                </div>
            </div>
        </div >


    )
}
