import { memo, useRef, useState } from "react"
// import dataService from "../../../../redux/services/data.service";
import { toast } from "react-toastify";
import { DataGrid } from "@mui/x-data-grid";
import CustomNoRowsOverlay from "../../../CustomEmptyOverlay";
import CustomeTooltip from "../../../common-function/CustomeTooltip";
import { TablePagination } from "@mui/material";
import TablePaginationActions from "../../../TablePaginationActions";

const QuestionTableList = ({ existingData, rowCount, testTypes, parentIndex, setFormData, formData, filterQuery, setFilterQuery, setDisabledAddButton, setShowAddForm, selectionModel, setSelectionModel }) => {

    let totalLimit = formData && formData.no_of_predefined_questions ? Number(formData && formData.no_of_predefined_questions) : 0

    //pagination 
    const [currentPage, setCurrentPage] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [limit, setLimit] = useState(totalLimit);

    //columns visibility
    const [columnVisibilityModel, setColumnVisibilityModel] = useState({ id: false });
    let prevColIds = useRef({}); // to store selected rows b/w renders

    const columns = [
        // {
        //     field:"question_id",
        //     headerName: 'Question Id',
        //     width:100
        // },
        {
            field: 'question__title',
            headerName: 'Question Title',
            width: 400,
            filterable: false,
            sortable: false,
            renderCell: (params) => {
                return (
                    <div className="cellWithStatus">
                        <CustomeTooltip
                            title={params.row.question && params.row.question.title ? params.row.question.title : "-"}
                            data={params.row.question && params.row.question.title ? params.row.question.title : "-"}
                        />
                    </div>
                )
            }
        },
        {
            field: 'question__question_level__level_key',
            headerName: 'Question lavel',
            width: 200,
            filterable: false,
            sortable: false,
            renderCell: (params) => {
                return (
                    <div className="cellWithStatus">
                        {params.row.question && params.row.question.question_level && params.row.question.question_level.level_name ? params.row.question.question_level.level_name : "-"}
                    </div>
                )
            }

        },
        {
            field: 'question__technology__technology_key',
            headerName: 'Technology',
            width: 205,
            filterable: false,
            sortable: false,
            renderCell: (params) => {
                return (
                    <div className="cellWithStatus">
                        {params.row.question && params.row.question.technology && params.row.question.technology.name ? params.row.question.technology.name : "-"}
                    </div>
                )
            }

        },
        {
            field: 'question__qusetion_type__type_key',
            headerName: 'Question Type',
            width: 200,
            filterable: false,
            sortable: false,
            // type: 'singleSelect',
            renderCell: (params) => {
                return (
                    <div className="cellWithStatus">
                        {params.row.question && params.row.question.question_type && params.row.question.question_type.question_type ? params.row.question.question_type.question_type : "-"}
                    </div>
                )
            }
        },

    ];


    const onSelectionChange = (idArr) => {
        prevColIds.current = { ...prevColIds.current, ['page' + currentPage]: idArr }

        //condition
        let evts = [...new Set(Object.values(prevColIds.current).reduce((acc, item) => acc.concat(item), []))]

        let newArray = [...selectionModel, ...evts]
        let finalArray = [...new Set(newArray)]
        setSelectionModel(evts)

        if (Number(testTypes[parentIndex].no_of_predefined_questions) === Number(finalArray.length)) {
            setShowAddForm(false)
            setDisabledAddButton(true)
            testTypes[parentIndex]['predefined_questions'] = {
                ...formData.predefined_questions,
                already_selected_question: finalArray
            }
            setFormData({
                ...formData,
                predefined_questions: {
                    ...formData.predefined_questions,
                    already_selected_question: finalArray
                }
            })
        } else if (Number(testTypes[parentIndex].no_of_predefined_questions) < Number(finalArray.length)) {
            setDisabledAddButton(true)
            toast.error(`Your total no of prdefine question are Number(${testTypes[parentIndex].no_of_predefined_questions}) is required`, {
                position: toast.POSITION.TOP_CENTER
            })
        } else {
            testTypes[parentIndex]['predefined_questions'] = {
                ...formData.predefined_questions,
                already_selected_question: finalArray
            }
            setFormData({
                ...formData,
                predefined_questions: {
                    ...formData.predefined_questions,
                    already_selected_question: finalArray
                }
            })
            setDisabledAddButton(false)
        }

    }

    //custom pagination
    const handlePagination = (data) => {
        // console.log(data, "data")
        let customLimit = Number(testTypes[parentIndex].no_of_predefined_questions)
        setCurrentPage(data)
        let newFilterData = filterQuery
        newFilterData['skip'] = data * pageSize
        newFilterData['limit'] = customLimit
        setLimit(customLimit)
        setFilterQuery({ ...filterQuery, ...newFilterData })
    }

    const handlePageSizeChange = (newPageSize) => {
        setPageSize(newPageSize.target.value)
        setCurrentPage(0)
        setLimit(newPageSize.target.value)
        let newFilterData = filterQuery
        newFilterData['skip'] = 0
        newFilterData['limit'] = newPageSize.target.value
        setFilterQuery({ ...filterQuery, ...newFilterData })
    }

    function CustomPagination() {
        return (
            <TablePagination
                component="div"
                count={(rowCount && rowCount) || 0}
                page={currentPage}
                rowsPerPage={pageSize}
                onPageChange={() => setCurrentPage(pageSize)}
                onRowsPerPageChange={handlePageSizeChange}
                ActionsComponent={(subProps) =>
                    <TablePaginationActions {...subProps}
                        handlePagination={handlePagination}
                    />}
            />
        );
    }

    // filter
    const onFilterChange = (filterModel) => {
        if (filterModel.items && filterModel.items.length > 0 && filterModel.items[0].columnField && filterModel.items[0].value) {
            let operatorValue = filterModel.items[0].operatorValue === "contains" ? "icontains" :
                filterModel.items[0].operatorValue === "equals" ? 'ieq' :
                    filterModel.items[0].operatorValue === "is" ? 'eq' :
                        filterModel.items[0].operatorValue === "startsWith" ? 'istartswith' :
                            filterModel.items[0].operatorValue === "endsWith" ? 'iendswith' :
                                filterModel.items[0].operatorValue;
            const filterData = { "filter": { [filterModel.items[0].columnField]: { "value": filterModel.items[0].value, "operation": operatorValue } }, "skip": 0, "limit": limit }
            setFilterQuery(filterData)
            setCurrentPage(0)
        }
        if (filterModel.items && filterModel.items.length === 0) {
            setCurrentPage(0)
            // setPageSize(10)
            setFilterQuery(filterQuery)
        }
    }

    return <>
        <div className="add-new-user-form" style={{ width: "100%", alignItems: "center" }}>

            <DataGrid
                // className="datagrid"
                columnVisibilityModel={columnVisibilityModel}
                onColumnVisibilityModelChange={(newModel) =>
                    setColumnVisibilityModel(newModel)
                }
                rows={existingData}
                columns={columns}
                style={{ fontSize: 16 }}
                onFilterModelChange={onFilterChange}
                components={{
                    Pagination: CustomPagination,
                    NoRowsOverlay: CustomNoRowsOverlay
                }}
                pageSize={pageSize}
                checkboxSelection
                selectionModel={selectionModel}
                onSelectionModelChange={onSelectionChange}
                disableSelectionOnClick
                filterMode="server"
                className="style-datatable-existing-qusetion"

            />

        </div>

    </>
}
export default memo(QuestionTableList)