import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { STATUSES } from "../common-status/constant";
import dataService from "../services/data.service";
import LocalStorageDataService from "../../components/common-function/GetLocalstorage";
const { REACT_APP_ENV } = process.env;
const user = LocalStorageDataService.userDetail()

export const getCredentialHead = createAsyncThunk(
    "get/credentials-head",
    async (data) => {
        const res = await dataService.credentialHeadList(data);
        const resp = res.data;
        return resp;
    }
);


export const allResources = createAsyncThunk("all/resource", async () => {
    const res = await dataService.resource_list_status();
    return res.data;
});

export const allcredentialHeads = createAsyncThunk("all/credential-head", async () => {
    const res = await dataService.credential_list_status();
    return res.data;
});

export const getAllCredentials = createAsyncThunk(
    "get/all-credentials",
    async (data) => {
        const res = await dataService.resourceCredentialList(data);
        const resp = res.data;
        return resp;
    }
);
export const getMyCredentials = createAsyncThunk(
    "get/my-credentials",
    async (data) => {
        const res = await dataService.myResourceCredentialList(data);
        const resp = res.data;
        return resp;
    }
);

//Third Party Credential Head Mapping
export const getThirdPartyCredentialHead = createAsyncThunk("get/third-party-credentials", async (data) => {
    const res = await dataService.third_party_credential(data);
    const resp = res.data;
    return resp;
});

export const defaultresourceColumn = createAsyncThunk("resouce-credential-column", async (data) => {
    return data;
});


const credentialHeadSlice = createSlice({
    name: "credentialHead",
    initialState: {
        data: [],
        status: 'idle',
        error: '',
        resourceList: [],
        credentialHeadList: [],
        allCredentialsList: [],
        myCredentialsList: [],
        thirdPartyCredentialsList: [],
        defaultresourceColumns:[],


    },
    reducers: {},
    extraReducers(builder) {
        builder
            //get all credendetial head
            .addCase(getCredentialHead.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(getCredentialHead.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.data = action.payload;
                state.error = action.error
            })
            .addCase(getCredentialHead.rejected, (state, action) => {
                state.status = STATUSES.FAILURE;
                state.error = action.error
            })

            // active resorces for filter
            .addCase(allResources.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(allResources.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_resourceList`;
                state.resourceList = action.payload;
                // state.error = action.error
            })
            .addCase(allResources.rejected, (state, action) => {
                state.status = STATUSES.FAILURE;
                state.error = action.error
            })

            // active credential heads for filter
            .addCase(allcredentialHeads.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(allcredentialHeads.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_credentialHeadList`;
                state.credentialHeadList = action.payload;
                state.error = action.error

            })
            .addCase(allcredentialHeads.rejected, (state, action) => {
                state.status = STATUSES.FAILURE;
                state.error = action.error
            })

            //get all credentials
            .addCase(getAllCredentials.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(getAllCredentials.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                let newArray = [];
                let newArray2 = []
                let result = action.payload;
                let res = result && result.data && result.data.rows
                let count = result && result.data && result.data.count
                res.map(e => {
                    return newArray.push({
                        id: e.id,
                        credentails_head_id: e.credentails_head_id,
                        resource_id: e.resource_id,
                        resource: e.resource__name,
                        credentails_head: e.credentails_head__head_name,
                        is_global: e.is_global,
                        status: e.status
                    })
                })
                if (newArray.length > 0) {
                    newArray2 = { "data": { "rows": newArray, "count": count } }
                }
                state.allCredentialsList = newArray2 && newArray2.data ? newArray2 : action.payload;
            })
            .addCase(getAllCredentials.rejected, (state, action) => {
                state.status = STATUSES.FAILURE;
                state.error = action.error
            })

            // get My credentials
            .addCase(getMyCredentials.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(getMyCredentials.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                let newArray = [];
                let newArray2 = []
                let result = action.payload;
                let res = result && result.data && result.data.rows
                let count = result && result.data && result.data.count
                res.map(e => {
                    return newArray.push({
                        id: e.id,
                        credentails_head_id: e.credentails_head_id,
                        resource_id: e.resource_id,
                        resource: e.resource__name,
                        credentails_head: e.credentails_head__head_name,
                        is_global: e.is_global,
                        status: e.status
                    })
                })
                if (newArray.length > 0) {
                    newArray2 = { "data": { "rows": newArray, "count": count } }
                }
                state.myCredentialsList = newArray2 && newArray2.data ? newArray2 : action.payload;
            })
            .addCase(getMyCredentials.rejected, (state, action) => {
                state.status = STATUSES.FAILURE;
                state.error = action.error
            })

            //third-party credential head mapping
            .addCase(getThirdPartyCredentialHead.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(getThirdPartyCredentialHead.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_thirdPartyCredentialsList`;
                // state.thirdPartyCredentialsList = action.payload;
                let newArray = [];
                let newArray2 = []
                let result = action.payload;
                let res = result && result.data && result.data.rows
                let count = result && result.data && result.data.count
                res.map(e => {
                    return newArray.push({
                        ...e,
                        third_party: e.third_party__name,
                        credential_head: e.credential_head__head_name,
                    })
                })
                if (newArray.length > 0) {
                    newArray2 = { "data": { "rows": newArray, "count": count } }
                }
                state.thirdPartyCredentialsList = newArray2 && newArray2.data ? newArray2 : action.payload;
            })
            .addCase(getThirdPartyCredentialHead.rejected, (state, action) => {
                state.status = STATUSES.FAILURE;
                state.error = action.error
            })

            .addCase(defaultresourceColumn.fulfilled, (state, action) => {
                localStorage.setItem(`${REACT_APP_ENV}_${user.id}_resourcecredential`, JSON.stringify(action.payload))
                state.defaultresourceColumns = action.payload
            }) 
    },
});


export default credentialHeadSlice.reducer;