import "./navbar.scss"
import React, { useState, useEffect } from 'react'
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import NotificationsNoneOutlined from "@mui/icons-material/NotificationsNoneOutlined";
import ChatBubbleOutlinedIcon from "@mui/icons-material/ChatBubbleOutlineOutlined";
import FormatAlignJustifyIcon from '@mui/icons-material/FormatAlignJustify';
import { Dialog, DialogContent } from "@mui/material";
import { useDispatch, useSelector } from 'react-redux'
import { Link } from "react-router-dom";
import { navbartoggle } from "../../redux/features/uislice";
import LocalStorageDataService from '../common-function/GetLocalstorage';
import moment from "moment";
import { logout } from '../../redux/features/authSlice'
// import { useNavigate } from 'react-router-dom'
import { STATUSES } from "../../redux/common-status/constant";
import dataService from "../../redux/services/data.service";
import AddIcon from '@mui/icons-material/Add';
import PhonecallProgressbar from "../phone-call-status/PhonecallProgressbar";
//import { toast, ToastContainer } from "react-toastify";
// import Featured from "../../components/featured/Featured"
import Punchinout from "../punchinout/PunchinOut";
import { Tooltip } from "react-bootstrap";
const role = LocalStorageDataService.userDetail() && LocalStorageDataService.userDetail().tms_role && LocalStorageDataService.userDetail().tms_role.role_name

const timeOutInterval = 10000;
const CUTTING_EXPRESSION = /\s+[^\s]*$/;
const createShortcut = (text, limit) => {
  if (text.length > limit) {
    const part = text.slice(0, limit - 3);
    if (part.match(CUTTING_EXPRESSION)) {
      return part.replace(CUTTING_EXPRESSION, ' ...');
    }
    return part + '...';
  }
  return text;
};

const Component = ({ text, limit }) => {
  const shortcut = createShortcut(text, limit);
  return (
    <span className="notification-head ellips-css-data">{shortcut}</span>
  );
};

const Navbar = () => {
  let timer = undefined;
  //const events = ['click', 'scroll', 'load', 'keydown']
  const events = ['click']
  const eventHandler = (eventType) => {
    if (timer) {
      // startTimer();
    }
  };
  const dispatchtheme = useDispatch()
  // const navigate = useNavigate()
  const { status } = useSelector((state) => state.authUser)
  const [toggle, settoggle] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [userData, setUserData] = useState([])
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };


  const [anchorEl1, setAnchorEl1] = useState(null);
  // const [anchorEl1m, setAnchorEl1m] = useState(null);
  const [setAnchorEl1m] = useState(null);
  const [openModal, setOpenModal] = useState(false)
  const [errMessage, setErrMessage] = useState("Session Expired!")
  const [remainderList, setRemainderList] = useState([])

  const openmessage = Boolean(anchorEl1);
  // const openmessages = Boolean(anchorEl1m);

  const messageClick = (event) => {
    setAnchorEl1(event.currentTarget);
  };
  const messageClick1 = (event) => {
    setAnchorEl1m(event.currentTarget);
  };

  const messageClose = () => {
    setAnchorEl1(null);
  };



  const navbartoggles = () => {
    settoggle(toggle => !toggle);
    dispatchtheme(navbartoggle(toggle));

  }

  useEffect(() => {
    addEvents();
    ckeckUserRemainder()
    return (() => {

      removeEvents();
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const ckeckUserAccessToken = async () => {
    let token = LocalStorageDataService.userToken()
    if (token) {
      await dataService.userLogin(token).then(response => {
        if (response.data && response.status === 200) {
          //startTimer();
          setUserData(response.data.data)
        }
      }).catch((error) => {
        if (error.response) {
          let err = error.response
          if (err.status === 500) {
            //toast.info(err.statusText)
            // setErrMessage(err.statusText)
            // setOpenModal(true)
          }
          if (err.status === 401) {
            err.data && err.data.message && setErrMessage(err.data.message)
            setOpenModal(true)
          }
        }
      });
    }
  }

  const ckeckUserRemainder = async () => {
    let filter = LocalStorageDataService.userDetail() && `{ "filter":{"is_viewed":{"value":False, "operation":"eq" },"created_by":{"value":${LocalStorageDataService.userDetail().id} , "operation":"eq"} },"no_limit":True}`
    if (filter) {
      await dataService.get_reminder_list(filter).then(response => {
        if (response.data && response.status === 200) {
          //startTimer();
          setRemainderList(response.data.data)
        }
      }).catch((error) => {
        if (error.response) {
          let err = error.response
          if (err.status === 500) {
            //toast.info(err.statusText)
            // setErrMessage(err.statusText)
            // setOpenModal(true)
          }
          if (err.status === 401) {
            err.data && err.data.message && console.log(err.data.message)
            //setOpenModal(true)
          }
        }
      });
    }
  }

  const startTimer = () => {
    const timeOut = 3600 * 1000 * 8
    const lastInteractionTime = LocalStorageDataService.lastInteractionTime()
    const diff = moment.duration(moment().diff(moment(lastInteractionTime)));
    timer = setTimeout(() => {
      if (diff._milliseconds < timeOut) {
        //startTimer();
        ckeckUserAccessToken()
        ckeckUserRemainder()
      } else {
        ckeckUserAccessToken()
        ckeckUserRemainder()
        //setOpenModal(true)
      }
    }, timeOutInterval)


  }

  const logout_user = () => {
    setOpenModal(false)
    dispatchtheme(logout());
    window.location.reload('/')
  }

  const addEvents = () => {

    events.forEach(eventName => {
      window.addEventListener(eventName, eventHandler)
    })

    startTimer();
  }

  const removeEvents = () => {
    events.forEach(eventName => {
      window.removeEventListener(eventName, eventHandler)
    })
  };

  if (status === STATUSES.LOADING) {
    //window.location.reload('/')
  }

  var lettername = "";
  if (LocalStorageDataService.userDetail() && LocalStorageDataService.userDetail().display_name) {
    let displayName = LocalStorageDataService.userDetail().display_name;

    let arr = displayName.split(" ");
    lettername = arr && arr.length > 0 ? arr.length >= 2 ? arr[0].charAt(0) + arr[1].charAt(0) : arr[0].charAt(0) : '';

  }


  //const { dispatch } = useContext(DarkModeContext)
  return (
    <>
      <div className="navbar">
        <div className="wrapper">
          <div className="toggle-navigation">
            <FormatAlignJustifyIcon className="icon" onClick={navbartoggles} />
            {role && !role.match("Agent") && <Link to={"/task/add"} className="btn btn-create-task"><AddIcon /> Create Task</Link>}

          </div>

          <div className="phone-call-progress-bar" style={{ width: "35%" }}>
            {
              userData && userData.agent_call_progress_bar &&
              <div>
                <PhonecallProgressbar details={userData.agent_call_progress_bar} />
              </div>
            }
          </div>

          {/* <div className="item">
            <Featured />
          </div> */}


          {/* <div className="search">
          <input type="text" placeholder="Search...." />
          <SearchOutlinedIcon />
        </div> */}
          <div className="items">
            {/* <div className="item">
            <LanguageOutlinedIcon className="icon" />
            English
          </div> */}
            {/* <div className="item">
            <DarkModeOutlined className="icon" onClick={() => dispatch({ type: "TOGGLE" })} />
          </div> */}
            {/* <div className="item">
            <FullScreenExitOutlinedIcon className="icon" />
          </div> */}
            <div className="item">
              <Tooltip title="notification">
                <NotificationsNoneOutlined className="icon"
                  onClick={handleClick}
                  size="small"
                  sx={{ ml: 2 }}
                  aria-controls={open ? 'notification-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? 'true' : undefined}
                />
              </Tooltip>
              <div className="counter">{remainderList.count ? remainderList.count : 0}</div>

            </div>
            <Menu
              anchorEl={anchorEl}
              className="MuiMenu-list-dropdown"
              id="notification-menu"
              open={open}
              onClose={handleClose}
              onClick={handleClose}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: 'visible',
                  filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                  mt: 1.5,

                  '& .MuiAvatar-root': {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  '&:before': {
                    content: '""',
                    display: 'block',
                    position: 'absolute',
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: 'background.paper',
                    transform: 'translateY(-50%) rotate(45deg)',
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{ horizontal: 'right', vertical: 'top' }}
              anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
              {remainderList && remainderList.rows && remainderList.rows.map((item, index) => {
                return (
                  <MenuItem key={index}>
                    <Component text={item.comment} limit={60} />
                    <span className="notification-date">{moment(item.notification_date + ' ' + item.notification_time).format('DD/MM/YYYY HH:mm A')}</span>
                  </MenuItem>
                )
              })}

              <MenuItem>
                <Link to={"/notification"} className="see-all-notifiation">All Notification</Link>
              </MenuItem>


            </Menu>
            <div className="item">
              <Tooltip title="message">
                <ChatBubbleOutlinedIcon className="icon"
                  onClick={messageClick1}
                  size="small"
                  sx={{ ml: 2 }}
                  aria-controls={open ? 'message-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? 'true' : undefined}
                />
              </Tooltip>
              <div className="counter">1</div>
            </div>
            <Menu
              anchorEl={anchorEl1}
              className="MuiMenu-list-dropdown"
              id="message-menu"
              // open={openmessages}
              onClose={messageClose}
              onClick={messageClose}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: 'visible',
                  filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                  mt: 1.5,

                  '& .MuiAvatar-root': {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  '&:before': {
                    content: '""',
                    display: 'block',
                    position: 'absolute',
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: 'background.paper',
                    transform: 'translateY(-50%) rotate(45deg)',
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{ horizontal: 'right', vertical: 'top' }}
              anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
              <MenuItem>
                <span className="notification-head">Post like by someone bored last friday</span>
                <span className="notification-date">26/09/2022 09:01 AM</span>
              </MenuItem>
              <MenuItem>
                <span className="notification-head">Post like by someone bored last friday</span>
                <span className="notification-date">26/09/2022 09:01 AM</span>
              </MenuItem>

              <MenuItem>
                <span className="notification-head">Post like by someone bored last friday</span>
                <span className="notification-date">26/09/2022 09:01 AM</span>
              </MenuItem>

              <MenuItem>
                <span className="notification-head">Post like by someone bored last friday</span>
                <span className="notification-date">26/09/2022 09:01 AM</span>
              </MenuItem>

              <MenuItem>
                <Link to={"/all-massages"} className="see-all-notifiation">All Messages</Link>
              </MenuItem>



            </Menu>


            <div className="item">
              <span className="avatar"
                onClick={messageClick}
                size="small"
                sx={{ ml: 2 }}
                aria-controls={open ? 'message-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}><h4>{lettername}</h4></span>
            </div>
            <Menu
              anchorEl={anchorEl1}
              className="MuiMenu-list-dropdown"
              id="message-menu"
              open={openmessage}
              onClose={messageClose}
              // onClick={messageClose}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: 'visible',
                  filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                  mt: 1.5,

                  '& .MuiAvatar-root': {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  '&:before': {
                    content: '""',
                    display: 'block',
                    position: 'absolute',
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: 'background.paper',
                    transform: 'translateY(-50%) rotate(45deg)',
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{ horizontal: 'right', vertical: 'top' }}
              anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
              <MenuItem>
                <Punchinout />
              </MenuItem>

            </Menu>


            {/* <div className="item">
              <span className="avatar"
                onClick={profileClick}
                size="small"
                sx={{ ml: 2 }}
                aria-controls={open ? 'message-menu1' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}><h4>{lettername}</h4></span>
              
            </div>
            <Menu
              anchorEl={anchorEl1}
              className="MuiMenu-list-dropdown"
              id="message-menu1"
              open={openprofile}
              onClose={profileClose}
              onClick={profileClose}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: 'visible',
                  filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                  mt: 1.5,

                  '& .MuiAvatar-root': {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  '&:before': {
                    content: '""',
                    display: 'block',
                    position: 'absolute',
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: 'background.paper',
                    transform: 'translateY(-50%) rotate(45deg)',
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{ horizontal: 'right', vertical: 'top' }}
              anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
              <MenuItem>
                <Punchinout />
              </MenuItem>
            </Menu> */}

            {/* <div className="item">
              <span className="avatar" onClick={profileClick} aria-controls={open ? 'message-menu1' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}><h4>{lettername}</h4></span>
            </div>
            <Menu
              anchorEl1={anchorEl11}
              className="MuiMenu-list-dropdown"
              id="message-menu1"
              open={openprofile}
              onClose={profileClose}
              // onClick={profileClose}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: 'visible',
                  filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                  mt: 1.5,

                  '& .MuiAvatar-root': {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  '&:before': {
                    content: '""',
                    display: 'block',
                    position: 'absolute',
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: 'background.paper',
                    transform: 'translateY(-50%) rotate(45deg)',
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{ horizontal: 'right', vertical: 'top' }}
              anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
              <MenuItem>
                <div className="profile-dropdown">
                  <span><Punchinout /></span>
                </div>
              </MenuItem>
            </Menu> */}

          </div>
          <Dialog
            open={openModal}
            onClose={() => setOpenModal(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">

            <DialogContent>
              <div className="popup-confirming">
                <h3>Alert</h3>
                <p>{errMessage}</p>
                <button className="yes" onClick={() => logout_user()}>LOG-OUT</button>
              </div>
            </DialogContent>
          </Dialog>
        </div>
      </div>


    </>
  )
}

export default Navbar
