import Navbar from "../../components/navbar/Navbar"
import Sidebar from "../../components/sidebar/Sidebar"
import "./report.scss"
import { toast, ToastContainer } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { STATUSES } from "../../redux/common-status/constant";
import LoaderForm from "../../components/common-function/LoaderForm";
import { Link, useNavigate } from "react-router-dom";
import { errorHandling } from "../../redux/features/authLogoutSlice";
import { Tooltip, FormControl, Table, TableBody, TableContainer, TableHead, TableRow, Paper, Dialog, Button, DialogTitle, DialogContent } from "@mui/material";
import { ArrowBack, Close } from "@mui/icons-material";
import { getResumeUploadReportList, getResumeUploadReportLink, getResumeDownloadLink } from "../../redux/features/calendarSlice";
import moment from "moment";
import { DateRangePicker } from 'rsuite';
import { CustomTableBody, CustomTableHeader } from "../../components/common-function/TableHeaderBody";
import ShareModule from "../../components/common-function/ShareModule"
import Select from "react-select";
import { epicOption } from '../../redux/features/optionsSlice';
//import { getFileUploadList } from "../../redux/features/taskSlice";
import { DataGrid } from "@mui/x-data-grid";
import LocalStorageDataService from "../../components/common-function/GetLocalstorage";
import { RefreshButton } from "../../components/common-function/RefreshButton";


const { REACT_APP_API_BASEURL } = process.env;


const ResumeUploadReports = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate()
    //const location = useLocation()
    //const [loading, setLoading] = useState(false)
    // const [fromDate, setFromDate] = useState([null])
    // const [toDate, setToDate] = useState([null])
    const [downliadFile, setDownloadFile] = useState(false)
    const [downloadResumeFile, setDownloadResumeFile] = useState(false)
    const [dateAndTime, setDateAndTime] = useState([])
    const [showShareBox, setShowShareBox] = useState(false)
    const [activeSearch, setActiveSearch] = useState(false)
    const [resouceInput, setResouceInput] = useState("")
    const [epicInput, setEpicInput] = useState("")
    const [resumeModal, setResumeModal] = useState(false)
    const [filterQuery, setFilterQuery] = useState({ "order_by": { "column": "-uploaded_date" }, "no_limit": 'True' })
    const { resumeUploadReportList, status, error, resumeUploadDownloadLink, resumeDownloadLink } = useSelector(state => state.calender)
    const { resources, epics } = useSelector(state => state.dropdownOptions)
    const { uploadFileList } = useSelector(state => state.task)


    useEffect(() => {
        let date = new Date()
        let monthDays = new Date(date.getFullYear(), date.getMonth() + 1, 0);
        let from_date = `${moment().format('YYYY')}-${moment().format('MM')}-1`
        let to_date = `${moment().format('YYYY')}-${moment().format('MM')}-${moment(monthDays).format('DD')}`
        //setFromDate(`${moment().format('YYYY')}-${moment().format('MM')}-1`)
        //setToDate(`${moment().format('YYYY')}-${moment().format('MM')}-${monthDays}`)
        // if (status === STATUSES.IDLE) {
        //     dispatch(resourceList(`{"no_limit":True}`))
        setFilterQuery({ "filter": { "uploaded_date": { "value": [`${from_date}`, `${to_date}`], "operation": "range" } }, "order_by": { "column": "-uploaded_date" }, "no_limit": 'True' })
        dispatch(getResumeUploadReportList(`{"filter":{"uploaded_date":{"value":["${from_date}", "${to_date}"],"operation":"range"}},"order_by":{"column":"-uploaded_date"},"no_limit": True}`))
        //}
        dispatch(epicOption(`{"order_by":{"column":"-id"}, "no_limit":True}`))

        if (status === STATUSES.FAILURE) {
            errorHandling(error, navigate)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    const clear = () => {
        //setLoading(true)
        setDateAndTime([])
        setResouceInput("")
        setEpicInput("")
        setActiveSearch(false)
        let date = new Date()
        let monthDays = new Date(date.getFullYear(), date.getMonth() + 1, 0);
        let from_date = `${moment().format('YYYY')}-${moment().format('MM')}-1`
        let to_date = `${moment().format('YYYY')}-${moment().format('MM')}-${moment(monthDays).format('DD')}`
        //setFromDate(`${moment().format('YYYY')}-${moment().format('MM')}-1`)
        //setToDate(`${moment().format('YYYY')}-${moment().format('MM')}-${monthDays}`)
        setFilterQuery({ "filter": { "uploaded_date": { "value": [`${from_date}`, `${to_date}`], "operation": "range" } }, "order_by": { "column": "-uploaded_date" }, "no_limit": 'True' })
        dispatch(getResumeUploadReportList(`{"filter":{"uploaded_date":{"value":["${from_date}", "${to_date}"],"operation":"range"}},"order_by":{"column":"-uploaded_date"},"no_limit": True}`))
    }

    const handleClick = (url) => {
        setDownloadFile(false)
        setDownloadResumeFile(false)
        window.open(url);
    }

    if (downliadFile === true && resumeUploadDownloadLink && resumeUploadDownloadLink.data) {
        let filter = filterQuery
        delete filter['for_download']
        let api_url = resumeUploadDownloadLink && resumeUploadDownloadLink.data.download_api_url
        if (REACT_APP_API_BASEURL && api_url) {
            let url = REACT_APP_API_BASEURL + api_url;
            if (url) {
                handleClick(url.replace("api//", "api/"))
            }
        }
        // setTimeout(() => {
        //     dispatch(getResumeUploadReportList(JSON.stringify(filterQuery)))
        // }, 500);

    }
    const downloadCsvFile = () => {
        setDownloadFile(true)
        let filter = filterQuery
        filter['for_download'] = 'True';
        dispatch(getResumeUploadReportLink(JSON.stringify(filter)))
    }


    const closeModal = () => {
        setShowShareBox(false)
    }

    const onchangeDateTime = (evt) => {
        setDateAndTime(evt)
        let filter = filterQuery

        if (evt && evt.length > 1) {
            filter['filter']['uploaded_date'] = { "value": [`${moment(evt[0]).format('YYYY-MM-DD')}`, `${moment(evt[1]).format('YYYY-MM-DD')}`], "operation": "range" }
        } else {
            delete filter['filter']['uploaded_date']
        }

        setFilterQuery(filter)
        dispatch(getResumeUploadReportList(JSON.stringify(filter)))
    }

    const resourceSearch = (evt) => {
        setResouceInput(evt)
        let filter = filterQuery
        if (evt && evt.value) {
            filter['filter']['created_by'] = { "value": evt.value, "operation": "eq" }
        } else {
            delete filter['filter']['created_by']
        }
        setFilterQuery(filter)
        dispatch(getResumeUploadReportList(JSON.stringify(filter)))
    }

    const search = (evt) => {
        setEpicInput(evt)
        let filter = filterQuery
        if (evt && evt.value) {
            filter['filter']['epic_id'] = { "value": evt.value, "operation": "eq" }
        } else {
            delete filter['filter']['epic_id']
        }
        setFilterQuery(filter)
        dispatch(getResumeUploadReportList(JSON.stringify(filter)))

    }
    const activeSearchBox = (type) => {
        if (type === 'arrow') {
            let filter = filterQuery
            if (!filter['order_by']) {
                filter['order_by'] = { "column": "-uploaded_date" }
            } else {
                delete filter['order_by']
            }
            setFilterQuery(filter)
            dispatch(getResumeUploadReportList(JSON.stringify(filter)))
        } else {
            setActiveSearch(type)
        }
    }

    const DownloadResumeFile = (id) => {
        let url = REACT_APP_API_BASEURL + 'recruitment/resume/download/' + id;
        if (url) {
            handleClick(url)
        }
        // setDownloadResumeFile(true)
        // dispatch(getResumeDownloadLink(id))
    }
    if (downloadResumeFile === true && resumeDownloadLink && resumeDownloadLink.message) {
        if (resumeDownloadLink.data) {
            let api_url = resumeDownloadLink && resumeDownloadLink.data.download_api_url
            if (REACT_APP_API_BASEURL && api_url) {
                let url = REACT_APP_API_BASEURL + api_url;
                if (url) {
                    handleClick(url.replace("api//", "api/"))
                }
            }
        }
        if (resumeDownloadLink && resumeDownloadLink.status !== 200) {
            toast.error(resumeDownloadLink.message)
            setDownloadResumeFile(false)
        }
    }
    const activeLinkDiv = (item, data) => {
        let status = 1;
        let from_date = `${moment().format('YYYY')}-${moment().format('MM')}-1`
        if (item === 'uploaded') {
            status = 0
        }
        if (item === 'parse_inprogress') {
            status = 1
        }
        if (item === 'story_creation_inprogress') {
            status = 5
        }
        if (item === 'total_no_failed_story') {
            status = 6
        }
        if (item === 'total_story_created') {
            status = 3
        }
        if (item === 'total_no_resume_failed_to_parse') {
            status = 4
        }
        if (item === 'total_parsed_resume') {
            status = 2
        }
        if (item === 'story_created_but_not_parsed') {
            status = 9
        }
        if (item === 'story_exist_in_same_epic') {
            status = 900
        }
        if (data.uploaded_date) {
            let change_date = (data.uploaded_date).replace('Total For Date -', '')
            let split_date = change_date.split("/")
            from_date = `${split_date[2]}-${split_date[1]}-${split_date[0]}`
        }

        let filter = {
            "filter": {
                //"epic_id": { "value": data.epic, "operation": "eq" },
                "status": { "value": `${status}`, "operation": "eq" },
                "uploaded_date": { "value": [from_date, from_date], "operation": "range" },
                //"created_by": { "value": data.created_by, "operation": "eq" }
            }, "order_by": { "column": "-id" }, "no_limit": 'True'
        }

        // let filter = { "filter": { "epic_id": { "value": data.epic, "operation": "eq" }, "status": { "value": status, "operation": "eq" }, "created_on": { "value": data.created_on, "operation": "contains" }, "created_by": { "value": data.created_by, "operation": "eq" } }, "order_by": { "column": "-id" }, "no_limit": 'True' }
        if (item === 'total_no_of_uploaded_resume') {
            delete filter['filter']['status']
        }
        if (dateAndTime && dateAndTime.length > 0) {
            filter['filter']['uploaded_date'] = { "value": [`${moment(dateAndTime[0]).format('YYYY-MM-DD')}`, `${moment(dateAndTime[1]).format('YYYY-MM-DD')}`], "operation": "range" }
        }
        if (epicInput && epicInput.value) {
            filter['filter']['epic_id'] = { "value": epicInput.value, "operation": "eq" }
        }
        if (resouceInput && resouceInput.value) {
            filter['filter']['created_by'] = { "value": resouceInput.value, "operation": "eq" }
        }
        // dispatch(getFileUploadList(JSON.stringify(filter)))
        // setResumeModal(true)
        setDownloadResumeFile(true)
        dispatch(getResumeDownloadLink(JSON.stringify(filter)))
    }

    const closeResumeModal = () => {
        setResumeModal(false)
    }

    const columnsFile = [
        {
            field: 'id', headerName: 'RTMS Key', width: 90, filterable: false,
            renderCell: (params) => {
                return (
                    <Tooltip title="Go to Epic">
                        <div style={{ color: 'blue' }} className="cellWithStatus">

                            {params.row.epic_id ? <Link to={`/epic-task/${params.row.epic_id}`}>{params.row.id}</Link> : params.row.id}

                        </div>
                    </Tooltip>
                )
            }
        },
        {
            field: 'file_name',
            headerName: 'File Name',
            width: 300,
            renderCell: (params) => {
                return (
                    <Tooltip title="Download Resume">
                        <div onClick={() => DownloadResumeFile(params.row.id)} style={{ color: 'blue' }} className="cellWithStatus">

                            {params.row.file_name}

                        </div>
                    </Tooltip>
                )
            }
        },
        {
            field: 'status',
            headerName: 'Status',
            width: 270,
            type: 'singleSelect',
            valueOptions: LocalStorageDataService.get_file_status(),
            renderCell: (params) => {
                return (
                    <div className="cellWithStatus">
                        {LocalStorageDataService.get_file_status().map(e => { return e.value === params.row.status && e.label })}
                    </div>
                )
            }

        },
        {
            field: 'created_on',
            headerName: 'Date',
            //sortable: false,
            width: 200,
            type: 'date',
            renderCell: (params) => {
                return (
                    <div className="cellWithStatus">
                        {moment(params.row.created_on).format('DD-MM-YYYY')}
                    </div>
                )
            }
        },
    ];

    return (
        <div className="report">
            <Sidebar />
            <div className="reportContainer">
                <Navbar />
                <div className="datatable">
                    <div className="top-headings">
                        <h3>Upload Resume Report</h3>
                        <div>
                            <RefreshButton api={getResumeUploadReportList} filter={JSON.stringify(filterQuery)} />
                            <Tooltip title={"Back"}>
                                <button onClick={() => navigate(-1)} className="btn-add-new ">
                                    <ArrowBack />
                                </button>
                            </Tooltip>
                        </div>
                    </div>
                    <div style={{ display: "flex", width: '100%', marginTop: '30px', marginBottom: '30px', justifyContent: 'space-between' }}>

                        <div>
                            {((activeSearch === 'uploaded_date') || (dateAndTime && dateAndTime.length > 0)) &&
                                <FormControl style={{ marginRight: "10px" }}>
                                    <DateRangePicker
                                        placeholder="dd-mm-yyyy ~ dd-mm-yyyy"
                                        appearance="default"
                                        value={dateAndTime}
                                        onChange={(evt) => onchangeDateTime(evt)}
                                    />
                                </FormControl>
                            }
                            {(activeSearch === 'resource_name' || resouceInput) &&
                                <FormControl style={{ marginRight: "10px" }}>
                                    <Select
                                        value={resouceInput}
                                        onChange={resourceSearch}
                                        isClearable
                                        name={activeSearch}
                                        placeholder={'Search resource'}
                                        options={resources} />

                                </FormControl>
                            }
                            {(activeSearch === 'epic_id' || epicInput) &&
                                <FormControl >
                                    <Select
                                        placeholder="Select Epic"
                                        isClearable
                                        value={epicInput}
                                        onChange={search}
                                        width='300px'
                                        options={epics} />

                                    {/* <input type="text" defaultValue={searchInput && searchInput.epic_id} name={activeSearch} onChange={search} placeholder={'Search epic'} style={{ padding: "6.5px 10px", border: "1px solid #e5e5ea", borderRadius: "4px" }} /> */}
                                </FormControl>
                            }
                        </div>
                        <div>
                            {/* <button disabled={(status === `${STATUSES.LOADING}_resumeUploadReportList`) ? true : false} className="filter-btn-primary" onClick={() => search()} >Search</button> */}
                            <button disabled={(status === `${STATUSES.LOADING}_resumeUploadReportList`) ? true : false} className="filter-btn-primary" onClick={() => clear()} >Clear</button>
                            <button disabled={(status === `${STATUSES.LOADING}_resumeUploadReportList`) ? true : false} className="filter-btn-primary" onClick={() => setShowShareBox(true)} >Share</button>
                            <button disabled={(status === `${STATUSES.LOADING}_resumeUploadReportList`) ? true : false} className="filter-btn-primary" onClick={() => downloadCsvFile()} > Download as CSV</button>
                        </div>
                    </div>


                    {(status === `${STATUSES.LOADING}_resumeUploadReportList`) && <LoaderForm />}
                    <ToastContainer />
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                            {resumeUploadReportList && resumeUploadReportList.data && resumeUploadReportList.data.length > 0 &&
                                resumeUploadReportList.data.map((item, index) => {
                                    return (
                                        (item.is_header === true) ?
                                            <TableHead
                                                index={index}
                                                style={{ marginBottom: 10 }}>

                                                <TableRow>
                                                    <CustomTableHeader
                                                        headerColumn={
                                                            [
                                                                { key: "uploaded_date", showIcon: index === 0 ? true : false, label: item.uploaded_date, action: activeSearchBox, style: { color: '#000', backgroundColor: '#ddd', fontWeight: 'bold', fontSize: '13px' } },
                                                                { key: "epic_id", showIcon: index === 0 ? true : false, label: item.epic_id, action: activeSearchBox, style: { color: '#000', backgroundColor: '#ddd', fontWeight: 'bold', fontSize: '13px' } },
                                                                { key: "parse_inprogress", showIcon: index === 0 ? true : false, label: item.parse_inprogress, action: "", style: { color: '#000', backgroundColor: '#ddd', fontWeight: 'bold', fontSize: '13px' } },
                                                                { key: "resource_name", showIcon: index === 0 ? true : false, label: item.resource_name, action: activeSearchBox, style: { color: '#000', backgroundColor: '#ddd', fontWeight: 'bold', fontSize: '13px' } },
                                                                { key: "story_creation_inprogress", showIcon: index === 0 ? true : false, label: item.story_creation_inprogress, action: "", style: { color: '#000', backgroundColor: '#ddd', fontWeight: 'bold', fontSize: '13px' } },
                                                                { key: "total_no_failed_story", showIcon: index === 0 ? true : false, label: item.total_no_failed_story, action: "", style: { color: '#000', backgroundColor: '#ddd', fontWeight: 'bold', fontSize: '13px' } },
                                                                { key: "total_no_of_uploaded_resume", showIcon: index === 0 ? true : false, label: item.total_no_of_uploaded_resume, action: "", style: { color: '#000', backgroundColor: '#ddd', fontWeight: 'bold', fontSize: '13px' } },
                                                                { key: "total_no_resume_failed_to_parse", showIcon: index === 0 ? true : false, label: item.total_no_resume_failed_to_parse, action: "", style: { color: '#000', backgroundColor: '#ddd', fontWeight: 'bold', fontSize: '13px' } },
                                                                { key: "total_parsed_resume", showIcon: index === 0 ? true : false, label: item.total_parsed_resume, action: "", style: { color: '#000', backgroundColor: '#ddd', fontWeight: 'bold', fontSize: '13px' } },
                                                                { key: "total_story_created", showIcon: index === 0 ? true : false, label: item.total_story_created, action: "", style: { color: '#000', backgroundColor: '#ddd', fontWeight: 'bold', fontSize: '13px' } },
                                                                { key: "uploaded", showIcon: index === 0 ? true : false, label: item.uploaded, action: "", style: { color: '#000', backgroundColor: '#ddd', fontWeight: 'bold', fontSize: '13px' } },
                                                                { key: "story_created_but_not_parsed", showIcon: index === 0 ? true : false, label: item.story_created_but_not_parsed, action: "", style: { color: '#000', backgroundColor: '#ddd', fontWeight: 'bold', fontSize: '13px' } },
                                                                { key: "story_exist_in_same_epic", showIcon: index === 0 ? true : false, label: item.story_exist_in_same_epic, action: "", style: { color: '#000', backgroundColor: '#ddd', fontWeight: 'bold', fontSize: '13px' } }
                                                            ]
                                                        }
                                                    />
                                                </TableRow>
                                            </TableHead>
                                            :
                                            (item.uploaded_date.match('Totals')) ?
                                                <TableHead
                                                    index={index}
                                                    style={{ marginBottom: 10 }}>
                                                    <TableRow>
                                                        <CustomTableHeader

                                                            headerColumn={
                                                                [
                                                                    { key: "uploaded_date", showIcon: index === 0 ? true : false, label: item.uploaded_date, action: activeSearchBox, style: { color: '#000', backgroundColor: '#ddd', fontWeight: 'bold', fontSize: '13px' } },
                                                                    { key: "epic_id", showIcon: index === 0 ? true : false, label: item.epic_id, action: "", style: { color: '#000', backgroundColor: '#ddd', fontWeight: 'bold', fontSize: '13px' } },
                                                                    { key: "parse_inprogress", showIcon: index === 0 ? true : false, label: item.parse_inprogress, action: "", style: { color: '#000', backgroundColor: '#ddd', fontWeight: 'bold', fontSize: '13px' } },
                                                                    { key: "resource_name", showIcon: index === 0 ? true : false, label: item.resource_name, action: "", style: { color: '#000', backgroundColor: '#ddd', fontWeight: 'bold', fontSize: '13px' } },
                                                                    { key: "story_creation_inprogress", showIcon: index === 0 ? true : false, label: item.story_creation_inprogress, action: "", style: { color: '#000', backgroundColor: '#ddd', fontWeight: 'bold', fontSize: '13px' } },
                                                                    { key: "total_no_failed_story", showIcon: index === 0 ? true : false, label: item.total_no_failed_story, action: "", style: { color: '#000', backgroundColor: '#ddd', fontWeight: 'bold', fontSize: '13px' } },
                                                                    { key: "total_no_of_uploaded_resume", showIcon: index === 0 ? true : false, label: item.total_no_of_uploaded_resume, action: "", style: { color: '#000', backgroundColor: '#ddd', fontWeight: 'bold', fontSize: '13px' } },
                                                                    { key: "total_no_resume_failed_to_parse", showIcon: index === 0 ? true : false, label: item.total_no_resume_failed_to_parse, action: "", style: { color: '#000', backgroundColor: '#ddd', fontWeight: 'bold', fontSize: '13px' } },
                                                                    { key: "total_parsed_resume", showIcon: index === 0 ? true : false, label: item.total_parsed_resume, action: "", style: { color: '#000', backgroundColor: '#ddd', fontWeight: 'bold', fontSize: '13px' } },
                                                                    { key: "total_story_created", showIcon: index === 0 ? true : false, label: item.total_story_created, action: "", style: { color: '#000', backgroundColor: '#ddd', fontWeight: 'bold', fontSize: '13px' } },
                                                                    { key: "uploaded", showIcon: index === 0 ? true : false, label: item.uploaded, action: "", style: { color: '#000', backgroundColor: '#ddd', fontWeight: 'bold', fontSize: '13px' } },
                                                                    { key: "story_created_but_not_parsed", showIcon: index === 0 ? true : false, label: item.story_created_but_not_parsed, action: "", style: { color: '#000', backgroundColor: '#ddd', fontWeight: 'bold', fontSize: '13px' } },
                                                                    { key: "story_exist_in_same_epic", showIcon: index === 0 ? true : false, label: item.story_exist_in_same_epic, action: "", style: { color: '#000', backgroundColor: '#ddd', fontWeight: 'bold', fontSize: '13px' } }
                                                                ]
                                                            }
                                                        />
                                                    </TableRow>
                                                </TableHead>
                                                :
                                                (item.uploaded_date.match('Total')) ?
                                                    <TableHead
                                                        index={index}
                                                        style={{ marginBottom: 10 }}>
                                                        <TableRow>
                                                            <CustomTableHeader

                                                                headerColumn={
                                                                    [
                                                                        { key: "uploaded_date", showIcon: index === 0 ? true : false, label: item.uploaded_date, action: activeSearchBox, style: { color: '#000', backgroundColor: '#eaedef40', fontWeight: 'bold', fontSize: '13px' } },
                                                                        { key: "epic_id", showIcon: index === 0 ? true : false, label: item.epic_id, action: "", style: { color: '#000', backgroundColor: '#eaedef40', fontWeight: 'bold', fontSize: '13px' } },
                                                                        { key: "parse_inprogress", showIcon: index === 0 ? true : false, label: item.parse_inprogress, action: "", style: { color: '#000', backgroundColor: '#eaedef40', fontWeight: 'bold', fontSize: '13px' } },
                                                                        { key: "resource_name", showIcon: index === 0 ? true : false, label: item.resource_name, action: "", style: { color: '#000', backgroundColor: '#eaedef40', fontWeight: 'bold', fontSize: '13px' } },
                                                                        { key: "story_creation_inprogress", showIcon: index === 0 ? true : false, label: item.story_creation_inprogress, action: "", style: { color: '#000', backgroundColor: '#eaedef40', fontWeight: 'bold', fontSize: '13px' } },
                                                                        { key: "total_no_failed_story", showIcon: index === 0 ? true : false, label: item.total_no_failed_story, action: "", style: { color: '#000', backgroundColor: '#eaedef40', fontWeight: 'bold', fontSize: '13px' } },
                                                                        { key: "total_no_of_uploaded_resume", showIcon: index === 0 ? true : false, label: item.total_no_of_uploaded_resume, action: "", style: { color: '#000', backgroundColor: '#eaedef40', fontWeight: 'bold', fontSize: '13px' } },
                                                                        { key: "total_no_resume_failed_to_parse", showIcon: index === 0 ? true : false, label: item.total_no_resume_failed_to_parse, action: "", style: { color: '#000', backgroundColor: '#eaedef40', fontWeight: 'bold', fontSize: '13px' } },
                                                                        { key: "total_parsed_resume", showIcon: index === 0 ? true : false, label: item.total_parsed_resume, action: "", style: { color: '#000', backgroundColor: '#eaedef40', fontWeight: 'bold', fontSize: '13px' } },
                                                                        { key: "total_story_created", showIcon: index === 0 ? true : false, label: item.total_story_created, action: "", style: { color: '#000', backgroundColor: '#eaedef40', fontWeight: 'bold', fontSize: '13px' } },
                                                                        { key: "uploaded", showIcon: index === 0 ? true : false, label: item.uploaded, action: "", style: { color: '#000', backgroundColor: '#eaedef40', fontWeight: 'bold', fontSize: '13px' } },
                                                                        { key: "story_created_but_not_parsed", showIcon: index === 0 ? true : false, label: item.story_created_but_not_parsed, action: "", style: { color: '#000', backgroundColor: '#eaedef40', fontWeight: 'bold', fontSize: '13px' } },
                                                                        { key: "story_exist_in_same_epic", showIcon: index === 0 ? true : false, label: item.story_exist_in_same_epic, action: "", style: { color: '#000', backgroundColor: '#eaedef40', fontWeight: 'bold', fontSize: '13px' } }
                                                                    ]
                                                                }
                                                            />
                                                        </TableRow>
                                                    </TableHead>

                                                    :
                                                    <TableBody
                                                        index={index}
                                                    >
                                                        <TableRow>
                                                            <CustomTableBody
                                                                headerBody={
                                                                    [
                                                                        { key: "uploaded_date", label: item.uploaded_date, action: "", style: { color: 'blue' } },
                                                                        { key: "epic_id", label: item.epic_id, action: "", style: {} },
                                                                        { key: "parse_inprogress", label: item.parse_inprogress, action: "", style: { color: "#0052cc" }, details: item },
                                                                        { key: "resource_name", label: item.resource_name, action: "", style: {} },
                                                                        { key: "story_creation_inprogress", label: item.story_creation_inprogress, action: "", style: { color: "#0052cc" }, details: item },
                                                                        { key: "total_no_failed_story", label: item.total_no_failed_story, action: item.total_no_failed_story > 0 ? activeLinkDiv : "", style: (item.uploaded_date).match('Total For Date') ? { backgroundColor: "#e1ebe81f", cursor: 'pointer' } : {}, details: item },
                                                                        { key: "total_no_of_uploaded_resume", label: item.total_no_of_uploaded_resume, action: "", style: { color: "#0052cc" }, details: item },
                                                                        { key: "total_no_resume_failed_to_parse", label: item.total_no_resume_failed_to_parse, action: item.total_no_resume_failed_to_parse > 0 ? activeLinkDiv : "", style: (item.uploaded_date).match('Total For Date') ? { backgroundColor: "#e1ebe81f", cursor: 'pointer' } : {}, details: item },
                                                                        { key: "total_parsed_resume", label: item.total_parsed_resume, action: "", style: { color: "#0052cc" }, details: item },
                                                                        { key: "total_story_created", label: item.total_story_created, action: "", style: { color: "#0052cc" }, details: item },
                                                                        { key: "uploaded", label: item.uploaded, action: "", style: { color: "#0052cc" }, details: item },
                                                                        { key: "story_created_but_not_parsed", label: item.story_created_but_not_parsed, action: "", style: { color: "#0052cc" }, details: item },
                                                                        { key: "story_exist_in_same_epic", label: item.story_exist_in_same_epic, action: "", style: { color: "#0052cc" }, details: item }
                                                                    ]
                                                                }
                                                            />
                                                        </TableRow>
                                                    </TableBody>

                                    )
                                })}
                        </Table>


                    </TableContainer>
                </div>
                {/* {/ popup for share page /} */}
                <ShareModule
                    open={showShareBox}
                    close={closeModal}
                    filter={filterQuery}
                    apiServiceName={getResumeUploadReportList}
                    apiServiceDownload={getResumeUploadReportLink}
                    dowunloadResponse={resumeUploadDownloadLink}
                    subject={"Upload Resume Report"}
                />
                {/* popup for resume list  */}
                <Dialog
                    hideBackdrop
                    fullWidth
                    maxWidth={"lg"}
                    open={resumeModal}
                    onClose={closeResumeModal}
                    aria-labelledby="child-modal-title"
                    aria-describedby="child-modal-description"
                >
                    <Button
                        edge="start"
                        color="inherit"
                        onClick={() => closeResumeModal()}
                        aria-label="close"
                        style={{ position: "absolute", top: "10px", right: "10px" }}>
                        <Close />
                    </Button>
                    <DialogTitle style={{ background: '#2196f3', color: '#fff', marginBottom: "15px" }}>Resume List</DialogTitle>

                    <DialogContent>
                        <DataGrid
                            rows={(uploadFileList && uploadFileList.data && uploadFileList.data.rows) || []}
                            columns={columnsFile}
                            pageSize={10}
                            rowsPerPageOptions={[5]}
                        />
                    </DialogContent>
                </Dialog>
            </div>
        </div>
    )
}

export default ResumeUploadReports