import React, { useEffect, useState } from 'react'
import "./login.scss";
import Logo from "../../images/logo.png"
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { login, userlogin } from "../../redux/features/authSlice";
// import Button from '../../components/button/Button';
import { toast, ToastContainer } from "react-toastify";
import LoaderForm from '../../components/common-function/LoaderForm';
import { STATUSES } from '../../redux/common-status/constant';
import { getAsigneRoleMenuList } from '../../redux/features/masterSlice';
import LocalStorageDataService from "../../components/common-function/GetLocalstorage";
import { SetCandidateToken } from '../../components/common-function/TableHeaderBody';
import { InputAdornment, IconButton } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
const user = LocalStorageDataService.userDetail();
const tmsRole = user.tms_role_id
const tmsRole_key = user.tms_role && user.tms_role.role_key

const roleWiseFilter = tmsRole ? `{"filter":{"status":{"value":"True","operation": "eq"},"role_id":{"value":${tmsRole},"operation": "eq"}},"no_limit":True}` : ""

const Login = () => {
  const { REACT_APP_ENV } = process.env;

  const dispatch = useDispatch();
  const { authData, status, token, authLoggedIn, error, loginError } = useSelector((state) => state.authUser)
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [formErrors, setFormErrors] = useState('');
  const [loader, setLoader] = useState(false)
  const navigate = useNavigate();
  const [afterUserApiLoader, setAfterUserApiLoader] = useState(false)
  const [validateErr, setValidateErr] = useState("")
  const [errStatus, setErrStatus] = useState(false);
  const [showPassword, setShowPassword] = useState(false);


  const validate = (email, empCode) => {
    const errors = {}

    if (email !== null && empCode === null) {
      if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
        errors.email = 'Please enter valid email address'
      } else {
        errors.email = true
      }
    }
    var pattern = /^[a-zA-Z0-9-/_]*$/;
    if (empCode !== null && email === null) {
      if (!pattern.test(empCode)) {
        errors.empCode = "Please enter valid employee Id";
      } else {
        errors.empCode = true
      }
    }

    return errors
  }

  const HandleLogin = (e) => {
    e.preventDefault();

    let data;

    if (!email) {
      setFormErrors({ 'emailErr': 'Email address or employee Id is required' })
      setValidateErr("")
    } else
      if (!password) {
        setFormErrors({ 'passwordErr': 'Password is required' })
        setValidateErr("")
      } else
        if (!email && !password) {
          setFormErrors({ 'Err': 'Email address or employee Id and password is required' })
          setValidateErr("")
        } else
          if (email && password) {
            setFormErrors('');
            const checkEmail = email.includes('@')
            let validateCheck
            if (checkEmail === true) {
              data = {
                email: email,
                password: password
              }
              validateCheck = validate(email, null)
            } else {
              data = {
                email: email,
                password: password
              }
              validateCheck = validate(null, email)
            }
            if (validateCheck.email === true || validateCheck.empCode === true) {
              setLoader(true)
              SetCandidateToken()
              dispatch(login(data, navigate));
              setValidateErr("")
              setErrStatus(false)
            } else {
              // setFormErrors({ 'emailErr': 'Invalid email address' })
              setErrStatus(true)
              setValidateErr(validateCheck)
            }
          }
  }

  if (loader === true && token && token.token) {
    setLoader(false)
    setAfterUserApiLoader(true)
    dispatch(userlogin(token.token));

  }

  if (authLoggedIn === false && error && error.status !== 200 && afterUserApiLoader === true) {
    setAfterUserApiLoader(false)
    toast.error(error.message)
  }

  if (authLoggedIn === true && authData) {
    roleWiseFilter && dispatch(getAsigneRoleMenuList(roleWiseFilter))
    if (authData.data && authData.data.tms_role && authData.data.tms_role.role_key.match('agent')) {
      let filter = { "filter": { "status": { "value": "True", "operation": "eq" }, "role_id": { "value": authData.data.tms_role_id, "operation": "eq" } }, "no_limit": "True" }
      let tokens = token.token
      dispatch(getAsigneRoleMenuList({ tokens, filter }))
      navigate("/my-task");
      window.location.reload();
    }
    else {
      navigate("/dashboard");
      window.location.reload();
    }
  }

  if (status === STATUSES.FAILURE && authLoggedIn === false && error && error.message !== "Success" && loader === true) {
    toast.error(error.message)
    setLoader(false)
  }

  if (status === STATUSES.FAILURE && loginError && loginError.message && loginError.message !== "Success" && loader === true && error === "") {
    toast.error(loginError.message)
    setLoader(false)
  }
  useEffect(() => {
    if (localStorage.getItem(`${REACT_APP_ENV}_rtms`)) {
      if (tmsRole_key && tmsRole_key.match("agent")) {
        navigate("/my-task");
      } else {
        navigate('/dashboard')
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  return (

    <div className="signin">
      <div className="sign-in-row">
        <div className="signin-col-1">
          <img src={Logo} alt={Logo} className="left-logo-icon" />
        </div>
        <div className="signin-col-2">
          <img src={Logo} alt={Logo} />
          <h4>Sign in</h4>
          <ToastContainer />
          <form onSubmit={HandleLogin}>
            <div className="formInput">
              <label >
                Email Address or Employee Id
              </label>
              <input
                type="text"
                placeholder="Email Address or Employee Id"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              {formErrors && formErrors.emailErr && validateErr === "" && <p style={{ color: "#96332c", fontSize: 13 }}>{formErrors.emailErr}</p>}
              {errStatus === true && validateErr && validateErr.email !== true && <p style={{ color: "#96332c", fontSize: 13 }}>{validateErr && validateErr.email}</p>}
              {errStatus === true && validateErr && validateErr.empCode !== true && <p style={{ color: "#96332c", fontSize: 13 }}>{validateErr && validateErr.empCode}</p>}
            </div>


            <div className="formInput" >
              <label >
                Password
              </label>
              <div style={{ position: 'relative' }}>
                <input
                  // type="password"
                  type={showPassword ? 'text' : 'password'}
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <InputAdornment position="end" style={{
                  position: 'absolute',
                  right: '10px',
                  top: '50%',
                  transform: 'translateY(-50%)',
                  cursor: 'pointer'
                }}>
                  <IconButton
                    onClick={() => setShowPassword(!showPassword)}
                    edge="end"
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              </div>
              {formErrors && formErrors.passwordErr && <p style={{ color: "#96332c", fontSize: 13 }}>{formErrors.passwordErr}</p>}
              {formErrors && formErrors.Err && <p style={{ color: "#96332c", fontSize: 13 }} >{formErrors.Err}</p>}
              {/* {status === 'error' && <p style={{ color: "#96332c", fontSize: 13 }}>{error.message}</p>} */}
            </div>

            <p className="text-right"><Link to={"/forgot-password"} className="forgot-password-link">Forgot Password</Link></p>
            <button type="submit" className="btn btn-submit">Submit</button>
            {/* <Button 
              // loading={loader} 
              disabled={loader} 
              type={"submit"} 
              title={"Submit"}
            /> */}
            {loader && <LoaderForm />}
          </form>
        </div>
      </div>
    </div>
  )
}

export default Login
