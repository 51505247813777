import React, { useEffect, useState } from "react";
import Navbar from "../../components/navbar/Navbar"
import Sidebar from "../../components/sidebar/Sidebar"
import "./resource.scss";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import LoaderForm from "../../components/common-function/LoaderForm";
import { employTypeList, departmentTypeList, tmsRoleList, resourceCreate, resourceUpdateById, allResourcesListEdit, imageUpload } from "../../redux/features/resourceSlice";
import { domainList } from "../../redux/features/domainSlice"
import { getIndustryDepartmentList, getIndustryList, getStateList, getCountryList, getLocationList } from "../../redux/features/masterSlice";
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import DatePicker from 'react-datepicker';
import Dropzone from 'react-dropzone';
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment'
import DataService from "../../redux/services/data.service";
import { toast, ToastContainer } from "react-toastify";
import CONSTANTS from "../../components/constant/constantComponents";
import { STATUSES } from "../../redux/common-status/constant";
import { Checkbox, FormGroup, Tooltip } from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Select from 'react-select';
import { skilOption } from "../../redux/features/optionsSlice"
import Confirmation from "../../components/popup/Confirmation";
// import CreatableSelect from "react-select/dist/declarations/src/Creatable";
import CreatableSelect from 'react-select/creatable';
import { DefaultFilterColumns } from "../../components/common-function/DefaultFilterColumns";
import { blue } from "@mui/material/colors";

const { REACT_APP_API_BASEURL } = process.env;

const maritalStatusArray = [
    { value: "unmarried", label: "Unmarried" },
    { value: "married", label: "Married" },
    { value: "divorced", label: "Divorced" },
    { value: "widowed", label: "Widowed" },
    { value: "separated", label: "Separated" },
]

const initialFormData = Object.freeze({
    r_name: "",
    display_name: "",
    email: "",
    contact_no: "",
    //date_of_birth: new Date(),
    tms_role: "",
    department: "",
    //jira_account: "",
    alternternate_contact: "",
    //doj: new Date(),
    address: "",
    employment_type: "",
    industry: "",
    domain: "",
    gender: "",
    emp_code: "",
    password: "",
    reporting_manager: "",
    agent_id: "",
    cug_ph_no: "",
    status: true,
    avatar_path: null,
    is_interviewer: false,
    is_approver: false,
    remove_already_approver: false,
    marital_status: "unmarried",
    date_of_marriage: "",
});

const formErrData = Object.freeze({
    r_nameErr: "Name is required",
    display_nameErr: "Display name is required",
    emailErr: 'Email is required',
    alternternate_contact: "Alternative Contact No",
    contact_noErr: "Contact is required",
    tms_roleErr: "Role is required",
    departmentErr: "Department is required",
    addressErr: "Address is required",
    employment_typeErr: "Employee type is required",
    genderErr: "Gender is required",
    emp_codeErr: "Emp code is required",
    passwordErr: "Password is required",
    reporting_managerErr: "Manager is required",
    selectTechnologyErr: "Skills is required",
    cug_ph_noErr: "cug number is required",
    industryErr: "Industry is required",
    domainErr: "Domain is required",
    agent_idErr: "Agent Id is required",
    matritalErr: "Marital Status is required"
});

const ResourceEdit = ({ resId }) => {

    const dispatch = useDispatch()
    const { resourceid } = useParams()
    const navigate = useNavigate()
    const { view_data, status, error, empType, deptType, tmsRoles, createResource, updateResource, activeResourceList, profilePhoto } = useSelector(state => state.resource)
    const { industry_List, department_List, country_List, state_List, location_List } = useSelector(state => state.master)
    const { skils } = useSelector(state => state.dropdownOptions)
    const { domains } = useSelector(state => state.domain)
    const [formValues, setFormValues] = useState(initialFormData)
    const [dob, setDobDate] = useState(new Date());
    const [doj, setDojDate] = useState(new Date());
    const [loader, setLoader] = useState(false)
    const [formErr] = useState(formErrData)
    // const resourceid = resId ? resId : (location && location.state && location.state.resourceid) ? location.state.resourceid : "";
    const [errSatus, setErrSatus] = useState(false);
    const [validateErr, setValidateErr] = useState("")

    const [isInterViewer, setIsInterViewer] = useState(false)
    const [selectTechnology, setSelectTechnology] = useState("")
    const [isJobApprover, setIsJobApprover] = useState(false)
    const [isTmsJobApprover, setIsTmsJobApprover] = useState(false)
    const [isOpen, setIsOpen] = useState(false)
    const [removeAlreadyApprover, setRemoveAlreadyApprover] = useState(false);
    const [dateOfMarriage, setDateOfMarriage] = useState(new Date());
    const [formData, setFormData] = useState({})
    const [reset, setReset] = useState(Math.random())
    const [updatedStateList, setUpdatedStateList] = useState([])
    const [countryId, setCountryId] = useState(null)
    const [updatedCityList, setUpdatedCityList] = useState([])

    const [countryfilterQuery, setCountryFilterQuery] = useState(`{"filter":{"status":{'value':"True","operation": "eq"}},"order_by":{"column":"-id"},"no_limit":"True","columns":[${DefaultFilterColumns.COUNTRY_LIST_COLUMNS}]}`)




    const loadData = async () => {
        const res = await DataService.resource_view(resourceid)
        if (res && res.data && res.data.data) {
            const editData = res.data.data

            setFormValues({
                r_name: editData && editData.name,
                display_name: editData && editData.display_name,
                email: editData && editData.email,
                contact_no: editData && editData.contact_no,
                // tms_role: editData && editData.tms_role && editData.tms_role.id,
                tms_role: { label: editData.tms_role && editData.tms_role.role_name, value: editData && editData.tms_role_id },
                // department: editData && editData.department && editData.department.id,
                department: { label: editData.department && editData.department.name, value: editData && editData.department_id },

                //jira_account: editData && editData.jira_account,
                alternternate_contact: editData && editData.alternternate_contact,
                address: editData && editData.address,
                // employment_type: editData && editData.employment_type && editData.employment_type.id
                employment_type: { label: editData && editData.employment_type && editData.employment_type.employment_type, value: editData && editData.employment_type_id },
                gender: editData && editData.gender,
                emp_code: editData && editData.emp_code,
                password: editData && editData.password,
                user: editData && editData.user_id,
                avatar_path: editData && editData.avatar_path,
                // reporting_manager: editData && editData.reporting_manager
                reporting_manager: { label: editData && editData.reporting_manager_name, value: editData && editData.reporting_manager },
                agent_id: editData && editData.agent_id,
                cug_ph_no: editData && editData.cug_ph_no,
                status: (editData.status === 'true' || editData.status === true) ? true : false,
                is_interviewer: (editData.is_interviewer === 'false' || editData.is_interviewer === false) ? false : true,
                industry: {
                    label: editData?.industry?.industry_name,
                    value: editData?.industry?.id,
                    name: "industry"
                },
                domain: {
                    label: editData?.domain?.name,
                    value: editData?.domain?.id,
                    name: "domain"
                },
                marital_status: editData.marital_status,
                // country: editData?.country?.id,
                // state: editData?.state?.id,
                // city: editData?.city?.id,
                country: {
                    label: editData?.country?.name,
                    value: editData?.country?.id,
                    name: "country"
                },
                state: {
                    label: editData?.state?.name,
                    value: editData?.state?.id,
                    name: "state"
                },
                city: {
                    label: editData?.city?.city_name,
                    value: editData?.city?.id,
                    name: "city"
                },

            })
            let new_dob_date = editData && editData.date_of_birth
            let date_of_birth = new_dob_date ? moment.utc(new_dob_date) : ""
            let new_doj_date = editData && editData.doj
            let date_of_join = new_doj_date ? moment.utc(new_doj_date) : ""

            let new_dom_date = editData && editData.doj
            let date_of_marriage = new_dom_date ? moment.utc(new_dom_date) : ""

            if (date_of_join) {
                setDobDate(date_of_birth._d)
            }
            if (date_of_join) {
                setDojDate(date_of_join._d)
            }
            if (date_of_marriage) {
                setDateOfMarriage(date_of_marriage._d)
            }

            if (editData && editData.is_interviewer === true) {
                setIsInterViewer(true)
            }
            if (editData && editData.is_approver === true) {

                setIsTmsJobApprover(editData.is_approver)

            }
            if (editData && editData.tms_role && (editData.tms_role.role_key.match("Admin") || editData.tms_role.role_key.match("admin"))) {
                setIsJobApprover(true)

            }
            setFormData(editData)
        }
    }
    const [selectedImage, setSelectedImage] = useState(null);
    const [fileErr, setFileErr] = useState("");
    const [flag, setFlag] = useState(false);
    const [invalidExtension, setInvalidExtension] = useState("");
    const [uploadPic, setUploadPic] = useState(false);
    const onFileChange = (event) => {
        setInvalidExtension("");
        let file = event[0];
        const fileType = file.name.split(".")[1];
        let image_extension = ["jpg", "jpeg", "png"]
        if (image_extension.includes(fileType.toLowerCase())) {
            setFileErr("")
            setSelectedImage(file);
            setFlag(true)
        } else {
            setFlag(true)
            setSelectedImage(file);
            setFileErr("You can only upload jpg, jpeg, png files");
        }
    }

    useEffect(() => {
        if (formData && formData.technologies && skils && skils.length) {
            let technologyArray = []
            formData.technologies.forEach((i) => {
                let obj = skils.find(s => i === s.id)
                if (obj) {
                    technologyArray.push({
                        label: obj.label, value: obj.value, name: "technology"
                    })
                }
            })

            setSelectTechnology(technologyArray);
            setReset(Math.random())
        }
    }, [formData, skils, setSelectTechnology])

    useEffect(() => {
        dispatch(getLocationList(`{"filter":{"status":{'value':"True","operation": "eq"}},"order_by":{"column":"-id"},"no_limit":"True"}`))

        dispatch(getIndustryList(`{ "filter": {"status": {"value": "True", "operation": "eq" } },"no_limit": True}`))
        dispatch(getStateList(`{"filter":{"status":{'value':"True","operation": "eq"}},"no_limit":"True","order_by":{"column":"-id"},"columns":[${DefaultFilterColumns.STATE_LIST_COLUMNS}]}`))
        dispatch(getCountryList(countryfilterQuery))
    }, [])

    useEffect(() => {
        if (resourceid) {
            loadData()
        }
        dispatch(employTypeList())
        dispatch(departmentTypeList())
        dispatch(tmsRoleList())
        dispatch(allResourcesListEdit())
        if (view_data && view_data.data) {
            setFormValues(loadEditResourceData)

        }
        dispatch(skilOption())
        dispatch(domainList())
        // eslint-disable-next-line
    }, [dispatch])
    const handleChangeIndustry = (evt) => {

        const { name, value } = evt.target || evt;
        if (evt && evt.__isNew__) {
            setFormValues({ ...formValues, industry: { value: evt.value, new: true }, department: "" })

        } else if (evt) {
            if (name === 'industry') {

                // setIndustryId(value)
                dispatch(getIndustryDepartmentList(`{"filter":{"industry_id":{"value":${value},"operation": "eq"},"status":{"value":True,"operation": "eq"}},"order_by":{"column":"-id"},"skip":0, "limit":10}`))
                setFormValues({ ...formValues, [name]: value, department: "" })

            }

            else {
                setFormValues({ ...formValues, [name]: value });
            }
        }
    }

    const handleCalendarClose = () => { }
    const handleCalendarOpen = () => { }

    const handleChange = (evt) => {
        const { name, value } = evt.target || evt;


        if (evt && evt.__isNew__) {
            setFormValues({ ...formValues, "employment_type": { value: evt.value, new: true } })

        } else
            if (evt) {
                setFormValues({ ...formValues, [name]: value });

            }
    }
    const handleChangeNumber = (evt) => {
        const { name, value } = evt.target || evt;
        const trimValue = value.trim()
        if (evt) {
            setFormValues({ ...formValues, [name]: trimValue });

        }
    }

    const handleDepartmentChange = (evt) => {


        if (evt && evt.__isNew__) {
            setFormValues({ ...formValues, department: { value: evt.value, new: true }, });
        } else if (evt) {

            setFormValues({ ...formValues, department: evt });

        }


    };

    const handleChangeOnTechnology = (evt) => {
        let finalArray = []
        let finalarray1 = []
        if (evt.length > 0) {

            evt.map((item) => {
                if (item && item.__isNew__) {
                    finalArray.push(item.value)
                } else {
                    finalarray1.push(item)
                }

            })
            setFormValues({ ...formValues, "new_technologies": finalArray });

            setSelectTechnology(finalarray1)
        } else {
            setIsInterViewer(false)
            setSelectTechnology("")
        }
    }
    const handleCountryChange = (e) => {
        const statelist = state_List?.data?.rows?.filter((options) => options.country === e.value)
        setUpdatedStateList(statelist)
        setCountryId(e.value)
        setFormValues({ ...formValues, "country": e.value })

    }
    const handleStateChange = (e) => {
        const citylist = location_List?.data?.rows.map((options) => {
            if (e.value == options?.state_id && options?.country_id == countryId) {
                return options
            } else {
                return [];
            }
        }).flat()
        setUpdatedCityList(citylist ? citylist : [])
        setFormValues({ ...formValues, "state": e.value })

    }


    // const handleChangeOnTechnology = (evt) => {
    //     // let technologyArr = []
    //     if (evt.length > 0) {
    //         // evt.map(e => {
    //         //     return technologyArr.push(e.value)
    //         // })

    //         setSelectTechnology(evt)
    //     } else {
    //         setIsInterViewer(false)
    //         setSelectTechnology("")
    //     }
    // }
    const handleChangeOnTms = (evt) => {
        setIsTmsJobApprover(false)
        if (evt && evt.__isNew__) {
            setFormValues({ ...formValues, "tms_role": { value: evt.value, new: true } })

        } else
            if (evt) {
                setFormValues({ ...formValues, "tms_role": evt })

            }


        if (evt && evt.label && (evt.label.match("Admin") || evt.label.match("admin"))) {
            setIsJobApprover(true)
        } else {
            setIsJobApprover(false)

        }
    }


    const handleChangeOnJobApprover = (evt) => {
        const { value } = evt.target
        if (value === "true") {
            let approverUsers = activeResourceList && activeResourceList.data && activeResourceList.data.rows &&
                activeResourceList.data.rows.length > 0 && activeResourceList.data.rows.filter((ele) => ele.is_approver && ele.is_approver === true)
            setIsTmsJobApprover(evt.target.value);
            if (approverUsers.length > 0) {
                setIsOpen(true)
                setRemoveAlreadyApprover(true)
            }
        } else {
            setIsTmsJobApprover(evt.target.value);
        }

    }

    const validate = (email, contact, alternternate_contact, cug_ph_no) => {
        const errors = {}

        if (!email) {
            errors.email = 'Required'
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
            errors.email = 'Invalid email address'
        } else {
            errors.email = true
        }

        // if (!jira_account) {
        //     errors.jira_account = true
        // } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(jira_account)) {
        //     errors.jira_account = 'Invalid email address'
        // } else {
        //     errors.jira_account = 
        // }

        var pattern = new RegExp(/^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i);
        if (!pattern.test(contact)) {
            errors.contact = "Please Enter Number Only";
        } else if (contact.length !== 10) {
            errors.contact = "Please enter valid  Mobile Number.";
        } else {
            errors.contact = true
        }

        if (!alternternate_contact) {
            errors.alternternate_contact = true
        } else if (!pattern.test(alternternate_contact)) {
            errors.alternternate_contact = "Please Enter Number Only";
        } else if (alternternate_contact.length !== 10) {
            errors.alternternate_contact = "Please enter valid  Mobile Number.";
        } else if (alternternate_contact === contact) {
            errors.alternternate_contact = "Contact and Alternate Number are the Same";
        } else {
            errors.alternternate_contact = true
        }

        if (!cug_ph_no) {
            errors.cug_ph_no = true
        } else if (!pattern.test(cug_ph_no)) {
            errors.cug_ph_no = "Please Enter Number Only";
        } else if (cug_ph_no.length !== 10) {
            errors.cug_ph_no = "Please enter valid  cug phone number.";
        } else if (cug_ph_no === contact) {
            errors.cug_ph_no = "Contact and Cug Number are the Same";
        } else if (cug_ph_no === alternternate_contact) {
            errors.cug_ph_no = "Contact and alternate Number are the Same";
        } else {
            errors.cug_ph_no = true
        }



        return errors
    }

    const submitData = (e) => {
        e.preventDefault()
        let techArray = []
        if (selectTechnology && selectTechnology.length > 0) {
            selectTechnology.map(e => e.value ? techArray.push(e.value) : techArray.push(e))
        }
        if ((formValues.r_name === "" || formValues.display_name === "" || formValues.address === "" || formValues.department === "" || selectTechnology === "" || selectTechnology.length === 0 ||
            formValues.email === "" || formValues.gender === "" || formValues.emp_code === "" || formValues.tms_role === "" || formValues.password === "" || formValues.reporting_manager === "") && formValues.address === "") {
            setErrSatus(true)
        }
        if (formValues.domain !== "" && formValues.r_name !== "" && formValues.display_name !== "" && formValues.address !== "" && formValues.department !== "" &&
            (selectTechnology !== "" || selectTechnology.length !== 0) && formValues.email !== "" && formValues.gender !== "" && formValues.emp_code !== "" && formValues.tms_role !== "" && formValues.password !== "" && formValues.reporting_manager !== "") {
            const isValidate = validate(formValues.email, formValues.contact_no, formValues.alternternate_contact, formValues.cug_ph_no)
            if (isValidate.email === true && isValidate.contact === true && isValidate.cug_ph_no === true) {
                if (formValues.alternternate_contact !== '') {
                    if (isValidate.alternternate_contact !== true) {
                        setErrSatus(true)
                        setValidateErr(isValidate)
                    } else {
                        let addvalues = {
                            ...formValues, doj: moment(doj).format('YYYY-MM-DD'), date_of_birth: moment(dob).format('YYYY-MM-DD'), name: formValues.r_name,
                            tms_role: formValues.tms_role.new ? formValues.tms_role : formValues.tms_role.value ? formValues.tms_role.value : formValues.tms_role.label === null ? null : formValues.tms_role,
                            department: formValues.department.new ? formValues.department : formValues.department.value ? formValues.department.value : formValues.department.label === null ? null : formValues.department,
                            employment_type: formValues.employment_type.new ? formValues.employment_type : formValues.employment_type.value ? formValues.employment_type.value : formValues.employment_type.label === null ? null : formValues.employment_type,
                            reporting_manager: formValues.reporting_manager.value ? formValues.reporting_manager.value : formValues.reporting_manager.label === null ? null : formValues.reporting_manager,
                            is_interviewer: isInterViewer === "true" || isInterViewer === true ? true : false,
                            is_approver: (isTmsJobApprover === false || isTmsJobApprover === "false") ? false : true,
                            remove_already_approver: removeAlreadyApprover,
                            industry: formValues.industry.new ? formValues.industry : formValues.industry.value ? formValues.industry.value : formValues.industry.label === null ? null : formValues.industry,
                            domain: formValues.domain.value ? formValues.domain.value : formValues.domain.label === null ? null : formValues.domain,
                            country: formValues.country.value ? formValues.country.value : formValues.country.label === null ? null : formValues.country,
                            state: formValues.state.value ? formValues.state.value : formValues.state.label === null ? null : formValues.state,
                            city: formValues.city.value ? formValues.city.value : formValues.city.label === null ? null : formValues.city
                        }
                        delete addvalues.r_name;
                        setErrSatus(false)
                        setValidateErr("")
                        // if (isInterViewer === true || isInterViewer === "true") {
                        addvalues['technologies'] = techArray
                        // addvalues['date_of_marriage'] = moment(dateOfMarriage).format('YYYY-MM-DD')
                        addvalues['date_of_marriage'] = dateOfMarriage !== null ? moment(dateOfMarriage).format('YYYY-MM-DD') : null

                        // }
                        if (resourceid) {
                            setLoader("edit")
                            dispatch(resourceUpdateById({ id: resourceid, addvalues: addvalues }))
                        } else {
                            setLoader(true)
                            dispatch(resourceCreate(addvalues))
                        }
                    }
                } else {
                    //let addvalues = { ...formValues, doj: moment(doj).format('YYYY-MM-DD'), date_of_birth: moment(dob).format('YYYY-MM-DD'), name: formValues.r_name }
                    let addvalues = {
                        ...formValues, doj: moment(doj).format('YYYY-MM-DD'), date_of_birth: moment(dob).format('YYYY-MM-DD'), name: formValues.r_name,
                        tms_role: formValues.tms_role.value ? formValues.tms_role.value : formValues.tms_role,
                        department: formValues.department.value ? formValues.department.value : formValues.department,
                        employment_type: formValues.employment_type.value ? formValues.employment_type.value : formValues.employment_type,
                        reporting_manager: formValues.reporting_manager.value ? formValues.reporting_manager.value : formValues.reporting_manager,
                        is_interviewer: isInterViewer === "true" || isInterViewer === true ? true : false,
                        is_approver: (isTmsJobApprover === false || isTmsJobApprover === "false") ? false : true,
                        remove_already_approver: removeAlreadyApprover,
                        industry: formValues.industry.value ? formValues.industry.value : formValues.industry.label === null ? null : formValues.industry,
                        domain: formValues.domain.value ? formValues.domain.value : formValues.domain.label === null ? null : formValues.domain,
                        country: formValues.country.value ? formValues.country.value : formValues.country.label === null ? null : formValues.country,
                        state: formValues.state.value ? formValues.state.value : formValues.state.label === null ? null : formValues.state,
                        city: formValues.city.value ? formValues.city.value : formValues.city.label === null ? null : formValues.city
                    }
                    delete addvalues.r_name;
                    setErrSatus(false)
                    setValidateErr("")
                    setLoader(true)
                    // if (isInterViewer === true || isInterViewer === "true") {
                    addvalues['technologies'] = techArray
                    addvalues['date_of_marriage'] = dateOfMarriage !== null ? moment(dateOfMarriage).format('YYYY-MM-DD') : null

                    // addvalues['date_of_marriage'] = moment(dateOfMarriage).format('YYYY-MM-DD')
                    if (resourceid) {
                        setLoader("edit")
                        dispatch(resourceUpdateById({ id: resourceid, addvalues: addvalues }))
                    } else {
                        setLoader(true)
                        dispatch(resourceCreate(addvalues))
                    }
                }

            } else {
                setErrSatus(true)
                setValidateErr(isValidate)
            }


        }


    }

    const getFormData = (obj) => {
        const formData = new FormData();
        Object.keys(obj).forEach(key => formData.append(key, obj[key]));

        return formData;
    }

    if (loader === true && status === STATUSES.FAILURE && error && error.message) {
        setLoader(false)
        toast.error(error.message);
    }

    if (loader === true && status === STATUSES.SUCCESS && createResource && createResource.message) {

        if (createResource.status === 200) {
            toast.success(createResource.message);

            if (flag) {
                let obj = {
                    id: createResource.data.id,
                    file: selectedImage,
                    display_name: selectedImage.name.split(".")[0],
                    upload_type: 'resource'
                }
                dispatch(imageUpload(getFormData(obj)))
                setFlag(false);
                setSelectedImage(null);
                setUploadPic(true);

            }
        } else {
            toast.error(createResource.message);
        }
        setLoader(false)
        setTimeout(() => {
            navigate('/all-resource')
        }, 1000)
    }

    if (loader === "edit" && updateResource && updateResource.message) {
        if (updateResource.status === 200) {
            toast.success(updateResource.message);
            if (flag) {
                let obj = {
                    id: updateResource.data.id,
                    file: selectedImage,
                    display_name: selectedImage.name.split(".")[0],
                    upload_type: 'resource'
                }

                dispatch(imageUpload(getFormData(obj)))
                setFlag(false);
                setUploadPic(true);
                setSelectedImage(null);
                setFileErr("")
            }
            setUploadPic(true);
            setTimeout(() => {
                // loadData()
                navigate('/all-resource')
            }, 1000)
        }

        else {
            toast.error(updateResource.message);
        }

        //}
        setLoader(false)

    }

    if (Object.keys(profilePhoto).length !== 0 && profilePhoto.message && uploadPic === true) {
        if (profilePhoto && profilePhoto.status === 400) {
            toast.error(profilePhoto.message);
            setInvalidExtension(profilePhoto.message);

        }
        if (profilePhoto.status === 200) {
            toast.success(profilePhoto.message);

        }
        setUploadPic(false);

    }

    const goback = () => {
        if (resId) {
            navigate('/profile')
        } else {
            navigate('/all-resource')
        }
    }

    const loadEditResourceData = () => {
        const data = view_data.data
        const editFormValues = {
            r_name: data && data.name,
            display_name: data && data.display_name,
            email: data && data.email,
            contact_no: data && data.contact_no,
            tms_role: data && data.tms_role && data.tms_role.id,
            department: data && data.department && data.department.id,
            //jira_account: data && data.jira_account,
            alternternate_contact: data && data.alternternate_contact,
            address: data && data.address,
            employment_type: data && data.employment_type && data.employment_type.id,
            gender: data && data.gender,
            emp_code: data && data.emp_code,
            password: data && data.password,
            dob: data && moment(data.date_of_birth),
            doj: data && moment(data.doj),
            reporting_manager: data && data.reporting_manager,
            status: data.status === 'true' || data.status === true ? true : false,
            is_interviewer: (data.is_interviewer === 'false' || data.is_interviewer === false) ? true : false,
            is_approver: (data.is_approver === 'false' || data.is_approver === false) ? true : false,

        }
        return editFormValues
    }
    const changeApprover = () => {
        setIsTmsJobApprover(true)
        setTimeout(() => {
            setIsOpen(false)
        }, 500)
    }


    return (
        <div className="resource">
            <Sidebar />
            <div className="resourceContainer">
                <Navbar />
                <div className="top-headings">
                    <h3>{resId ? "Edit Profile" : resourceid ? "Edit Resource" : "Add new Resource"}</h3>

                    <Tooltip title="Back">
                        <button onClick={goback} className="btn btn-add-new"><ArrowBackIcon /></button>
                    </Tooltip>
                </div>
                <ToastContainer />
                {loader === true && <LoaderForm />}
                {status === STATUSES.SUCCESS || status === STATUSES.FAILURE ?
                    <div>
                        {(formValues.email || !resourceid) ?
                            <form>
                                <div>
                                    <div className="add-new-user-form" style={{
                                        background: "#2684fc", color: "white", fontSize: "14px", height: "32px", alignItems: "center",
                                        paddingLeft: "5px"
                                    }}> Personal Details</div>
                                    <div className="add-new-user-form">
                                        <div className="formInput" style={{ flexBasis: "30%" }}>
                                            <label>Name </label>
                                            <input
                                                name="r_name"
                                                defaultValue={formValues.r_name}
                                                type="text"
                                                placeholder="Name"
                                                onChange={handleChange}
                                            />
                                            {errSatus === true && formValues && formValues.r_name === "" && <p style={{ color: "#96332c", fontSize: 13 }}>{formErr.r_nameErr}</p>}
                                        </div>

                                        <div className="formInput" style={{ flexBasis: "18%" }}>
                                            <label> Display Name</label>
                                            <input
                                                name="display_name"
                                                defaultValue={formValues.display_name}
                                                type="text"
                                                placeholder="Display Name"
                                                onChange={handleChange}
                                            />
                                            {errSatus === true && formValues && formValues.display_name === "" && <p style={{ color: "#96332c", fontSize: 13 }}>{formErr.display_nameErr}</p>}
                                        </div>

                                        <div className="formInput" style={{ flexBasis: "15%" }}>
                                            <label> Date of Birth</label>
                                            <DatePicker
                                                dateFormat="yyyy-MM-dd"
                                                name="date_of_birth"
                                                selected={dob}
                                                onChange={(dob) => setDobDate(dob)}
                                                peekNextMonth
                                                dropdownMode="select"
                                                showMonthDropdown
                                                showYearDropdown
                                                onCalendarClose={handleCalendarClose}
                                                onCalendarOpen={handleCalendarOpen}
                                                maxDate={new Date()}
                                            />
                                        </div>


                                        <div className="formInput" style={{ flexBasis: "14%" }}>
                                            <label> Marital Status</label>
                                            <select
                                                name={"marital_status"}
                                                onChange={handleChange}
                                                value={formValues.marital_status}
                                            >
                                                {maritalStatusArray.map((e, i) => {
                                                    return (<option value={e.value} key={i}>{e.label}</option>)
                                                })}
                                            </select>
                                            {errSatus === true && formValues && formValues.marital_status === "" && <p style={{ color: "#96332c", fontSize: 13 }}>{formErr.matritalErr}</p>}

                                        </div>


                                    </div>

                                    <div className="add-new-user-form">

                                        {formValues.marital_status === 'married' &&
                                            <div className="formInput">
                                                <label> Date of Marriage</label>
                                                <DatePicker
                                                    dateFormat="yyyy-MM-dd"
                                                    name="date_of_marriage"
                                                    selected={dateOfMarriage}
                                                    onChange={(dom) => setDateOfMarriage(dom)}
                                                    peekNextMonth
                                                    dropdownMode="select"
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    onCalendarClose={handleCalendarClose}
                                                    onCalendarOpen={handleCalendarOpen}
                                                />
                                            </div>
                                        }

                                        <div className="formInput">
                                            <label>Email </label>
                                            <input
                                                name="email"
                                                type="email"
                                                defaultValue={formValues.email}
                                                placeholder="Email"
                                                onChange={handleChange}
                                            />
                                            {errSatus === true && formValues && formValues.email === "" && <p style={{ color: "#96332c", fontSize: 13 }}>{formErr.emailErr}</p>}
                                            {errSatus === true && validateErr && validateErr.email !== true && <p style={{ color: "#96332c", fontSize: 13 }}>{validateErr && validateErr.email}</p>}
                                        </div>


                                        <div className="formInput">
                                            <FormControl>
                                                <FormLabel id="demo-row-radio-buttons-group-label">Status</FormLabel>
                                                <RadioGroup
                                                    value={formValues.status}
                                                    row
                                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                                    name="status"
                                                    onChange={handleChange}
                                                >
                                                    <FormControlLabel value={true} control={<Radio />} label="Active" />
                                                    <FormControlLabel value={false} control={<Radio />} label="Inactive" />

                                                </RadioGroup>
                                            </FormControl>
                                        </div>

                                        <div className="formInput">
                                            <FormControl>
                                                <FormLabel id="demo-row-radio-buttons-group-label">Gender</FormLabel>
                                                <RadioGroup
                                                    value={formValues.gender}
                                                    row
                                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                                    name="gender"
                                                    onChange={handleChange}
                                                >
                                                    <FormControlLabel value="F" control={<Radio />} label="Female" />
                                                    <FormControlLabel value="M" control={<Radio />} label="Male" />
                                                    <FormControlLabel value="O" control={<Radio />} label="Other" />

                                                </RadioGroup>
                                            </FormControl>
                                            {errSatus === true && formValues && formValues.gender === "" && <p style={{ color: "#96332c", fontSize: 13 }}>{formErr.genderErr}</p>}
                                        </div>
                                        <div className="formInput" style={{ flexBasis: "30%" }} >
                                            <label >
                                                Profile Photo&nbsp;
                                            </label>

                                            {formValues.avatar_path && formValues.avatar_path !== null && <div> <img src={REACT_APP_API_BASEURL + formValues.avatar_path.split("").slice(1).join("")}
                                                alt="" className="itemImg" style={{ width: "94px", height: "94px" }} /> </div>}
                                            <div className="file-dropzone-tabs">
                                                <Dropzone onDrop={acceptedFiles => onFileChange(acceptedFiles)} maxFiles={1}>
                                                    {({ getRootProps, getInputProps }) => (
                                                        <section>
                                                            <div {...getRootProps()}>
                                                                <input {...getInputProps()} />
                                                                <p>{selectedImage !== null ? selectedImage.name : "Drag 'n' drop photo here, or click to upload photo"}</p>
                                                            </div>
                                                        </section>
                                                    )}
                                                </Dropzone>
                                            </div>
                                            {/* //pls check we need to handle error */}
                                            {/* {errStatus === true && formValues && formValues.file === "" && <p style={{ color: "#96332c", fontSize: 13 }}>{formErr.fileErr}</p>} */}
                                            {fileErr !== "" && <p style={{ color: "#96332c", fontSize: 13 }}>{fileErr}</p>}
                                            {invalidExtension !== "" && <p style={{ color: "#96332c", fontSize: 13 }}>{invalidExtension}</p>}
                                        </div>
                                    </div>


                                    <div className="add-new-user-form" style={{
                                        background: "#2684fc", color: "white", fontSize: "14px", height: "32px", alignItems: "center",
                                        paddingLeft: "5px"
                                    }}> Contact Details</div>
                                    <div className="add-new-user-form">
                                        <div className="formInput" style={{ flexBasis: "30%" }}>
                                            <label> Contact No</label>
                                            <input
                                                name="contact_no"
                                                defaultValue={formValues.contact_no}
                                                type="text"
                                                placeholder="Contact No"
                                                onChange={handleChangeNumber}
                                            />
                                            {errSatus === true && formValues && formValues.contact_no === "" && <p style={{ color: "#96332c", fontSize: 13 }}>{formErr.contact_noErr}</p>}
                                            {errSatus === true && validateErr && validateErr.contact !== true && <p style={{ color: "#96332c", fontSize: 13 }}>{validateErr && validateErr.contact}</p>}
                                        </div>
                                        <div className="formInput" style={{ flexBasis: "15%" }}>
                                            <label> Alternative Contact No </label>
                                            <input
                                                name="alternternate_contact"
                                                defaultValue={formValues.alternternate_contact}
                                                type="text"
                                                placeholder="Alt. Phone"
                                                onChange={handleChangeNumber}
                                            />
                                            {errSatus === true && formValues && formValues.alternternate_contact === "" && <p style={{ color: "#96332c", fontSize: 13 }}>{formErr.alternternate_contact}</p>}
                                            {errSatus === true && validateErr && validateErr.alternternate_contact !== true && <p style={{ color: "#96332c", fontSize: 13 }}>{validateErr && validateErr.alternternate_contact}</p>}

                                        </div>
                                        <div className="formInput">
                                            <label >Select Country</label>
                                            <Select
                                                // className="basic-single"
                                                classNamePrefix="select"
                                                placeholder={'Select Country'}
                                                name={'country'}
                                                defaultValue={formValues?.country}
                                                onChange={(e) => {
                                                    handleChange(e)
                                                    handleCountryChange(e)
                                                }}
                                                isSearchable={true}
                                                options={
                                                    country_List && country_List.data && country_List.data.rows.map((option) => {
                                                        return {
                                                            label: option.name,
                                                            value: option.id,
                                                            name: "country"
                                                        }
                                                    })
                                                }
                                            />
                                            {errSatus === true && formValues.country === "" && <p style={{ color: "#96332c", fontSize: 13 }}>{formErr.countryErr}</p>}
                                        </div>
                                        <div className="formInput" style={{ flexBasis: "30%" }}>
                                            <label >Select State</label>
                                            <Select
                                                classNamePrefix="select"
                                                label={"Select State"}
                                                placeholder={'Select State'}
                                                name={'state'}
                                                onChange={(e) => {
                                                    handleChange(e)
                                                    handleStateChange(e)
                                                }}
                                                defaultValue={formValues.state}
                                                isSearchable={true}
                                                options={
                                                    updatedStateList && updatedStateList.map((option) => {
                                                        return {
                                                            label: option.name,
                                                            value: option.id,
                                                            name: "state"
                                                        }
                                                    })
                                                }
                                            />
                                            {errSatus === true && formValues.state === "" && <p style={{ color: "#96332c", fontSize: 13 }}>{formErr.stateErr}</p>}
                                        </div>
                                        <div className="formInput" >
                                            <label >Select City</label>
                                            <Select
                                                classNamePrefix="select"
                                                label={"City"}
                                                placeholder={'Select City'}
                                                name={'city'}
                                                onChange={handleChange}
                                                defaultValue={formValues.city}
                                                isSearchable={true}
                                                options={
                                                    updatedCityList && updatedCityList.map((option) => {
                                                        return {
                                                            label: option.city_name,
                                                            value: option.id,
                                                            name: "city"
                                                        }
                                                    })
                                                }
                                            />

                                            {errSatus === true && formValues.city === "" && <p style={{ color: "#96332c", fontSize: 13 }}>{formErr.CityErr}</p>}
                                        </div>

                                    </div>
                                    <div className="add-new-user-form" style={{
                                        background: "#2684fc", color: "white", fontSize: "14px", height: "32px", alignItems: "center",
                                        paddingLeft: "5px"
                                    }}> Professional Details</div>

                                    <div className="add-new-user-form">
                                        <div className="formInput" style={{ flexBasis: "30%" }}>

                                            <label> TMS Role</label>
                                            <CreatableSelect
                                                className="basic-single"
                                                classNamePrefix="select"
                                                placeholder="Select  TMS Role"
                                                defaultValue={formValues.tms_role}
                                                isSearchable={true}
                                                name={"tms_role"}
                                                formatCreateLabel={(inputValue) => `Creating new : ${inputValue}`}

                                                options={
                                                    tmsRoles && tmsRoles.data && tmsRoles.data.rows.map((option) => {
                                                        return { label: option.role_name, value: option.id, name: "tms_role" }
                                                    })
                                                }
                                                onChange={handleChangeOnTms}
                                            />
                                            {errSatus === true && formValues && formValues.tms_role === "" && <p style={{ color: "#96332c", fontSize: 13 }}>{formErr.tms_roleErr}</p>}
                                        </div>
                                        <div className="formInput">
                                            <label >
                                                Select Domain
                                            </label>
                                            <Select
                                                className="basic-single"
                                                classNamePrefix="select"
                                                divstyle={{ flexBasis: '100%' }}
                                                placeholder={'Select Domain'}
                                                onChange={handleChange}
                                                defaultValue={formValues.domain}
                                                isSearchable={true}
                                                name={'domain'}
                                                options={
                                                    domains && domains.map((option) => {
                                                        return {
                                                            label: option.name,
                                                            value: option.id,
                                                            name: 'domain'
                                                        }
                                                    })
                                                }
                                            />
                                            {errSatus === true && formValues && formValues.industry === "" && <p style={{ color: "#96332c", fontSize: 13 }}>{formErr.industryErr}</p>}
                                        </div>
                                        <div className="formInput">
                                            <label >Employment Type</label>
                                            <CreatableSelect
                                                className="basic-single"
                                                classNamePrefix="select"
                                                placeholder="Select  Employment Type"
                                                defaultValue={formValues.employment_type}
                                                isSearchable={true}
                                                name={"employment_type"}
                                                options={
                                                    empType && empType.data && empType.data.rows.map((option) => {
                                                        return { label: option.employment_type, value: option.id, name: "employment_type" }
                                                    })
                                                }
                                                onChange={handleChange}
                                            />
                                            {errSatus === true && formValues && formValues.employment_type === "" && <p style={{ color: "#96332c", fontSize: 13 }}>{formErr.employment_typeErr}</p>}
                                        </div>
                                        <div className="formInput">
                                            <label> Employee Code </label>
                                            <input
                                                name="emp_code"
                                                type="text"
                                                defaultValue={formValues.emp_code}
                                                placeholder="Emp. Code"
                                                onChange={handleChangeNumber}
                                            />
                                            {errSatus === true && formValues && formValues.emp_code === "" && <p style={{ color: "#96332c", fontSize: 13 }}>{formErr.emp_codeErr}</p>}
                                        </div>
                                        <div className="formInput">
                                            <label>Manager</label>
                                            <Select
                                                className="basic-single"
                                                classNamePrefix="select"
                                                placeholder="Select Manager"
                                                defaultValue={formValues.reporting_manager}
                                                isSearchable={true}
                                                name={"reporting_manager"}
                                                options={
                                                    activeResourceList && activeResourceList.data && activeResourceList.data.rows.map((option) => {
                                                        return { label: option.display_name + "(" + option.email + ")", value: option.id, name: "reporting_manager" }
                                                    })
                                                }
                                                onChange={handleChange}
                                            />
                                            {errSatus === true && formValues && formValues.reporting_manager === "" && <p style={{ color: "#96332c", fontSize: 13 }}>{formErr.reporting_managerErr}</p>}
                                        </div>
                                        <div className="formInput">
                                            <label>Password</label>
                                            <input
                                                name="password"
                                                defaultValue={formValues.password}
                                                type="password"
                                                placeholder="Password"
                                                onChange={handleChangeNumber}
                                            />
                                            {errSatus === true && formValues && formValues.password === "" && <p style={{ color: "#96332c", fontSize: 13 }}>{formErr.passwordErr}</p>}
                                        </div>

                                    </div>
                                    {/* {isJobApprover === true ?
                                        <div className="formInput">
                                            <FormControl>
                                                <FormLabel id="demo-row-radio-buttons-group-label">Is Job Approver</FormLabel>
                                                <RadioGroup
                                                    value={isTmsJobApprover}
                                                    row
                                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                                    name="is_approver"
                                                    onChange={(evt) => { handleChangeOnJobApprover(evt) }}

                                                >
                                                    <FormControlLabel value={true} control={<Radio />} label="Yes" />
                                                    <FormControlLabel value={false} control={<Radio />} label="No" />

                                                </RadioGroup>
                                            </FormControl>
                                        </div> : " "

                                    } */}
                                    <div className="add-new-user-form" style={{
                                        background: "#2684fc", color: "white", fontSize: "14px", height: "32px", alignItems: "center",
                                        paddingLeft: "5px"
                                    }}> Agent Details</div>
                                    <div className="add-new-user-form">
                                        <div className="formInput" style={{ flexBasis: "30%" }}>
                                            <label>Agent ID </label>
                                            <input
                                                name="agent_id"
                                                defaultValue={formValues.agent_id}
                                                type="text"
                                                placeholder="Agent ID"
                                                onChange={handleChangeNumber}
                                            />
                                            {errSatus === true && formValues && formValues.agent_id === "" && <p style={{ color: "#96332c", fontSize: 13 }}>{formErr.agent_idErr}</p>}

                                        </div>

                                        <div className="formInput">
                                            <label>Cug Phone Number </label>
                                            <input
                                                name="cug_ph_no"
                                                defaultValue={formValues.cug_ph_no}
                                                type="text"
                                                placeholder="Cug Phone Number"
                                                onChange={handleChange}

                                            />
                                            {errSatus === true && formValues && formValues.cug_ph_no === "" && <p style={{ color: "#96332c", fontSize: 13 }}>{formErr.cug_ph_noErr}</p>}

                                        </div>

                                        <div className="formInput">
                                            <label >Date of Joining </label>
                                            <DatePicker
                                                dateFormat="yyyy-MM-dd"
                                                name="doj"
                                                selected={doj}
                                                onChange={(doj) => setDojDate(doj)}
                                                onCalendarClose={handleCalendarClose}
                                                onCalendarOpen={handleCalendarOpen}
                                                peekNextMonth
                                                dropdownMode="select"
                                                showMonthDropdown
                                                showYearDropdown
                                            />

                                        </div>
                                    </div>
                                </div>
                                <div className="add-new-user-form" style={{
                                    background: "#2684fc", color: "white", fontSize: "14px", height: "32px", alignItems: "center",
                                    paddingLeft: "5px"
                                }}> Technical Details</div>
                                <div className="add-new-user-form" style={{ display: 'flex' }}>
                                    <div style={{ flexBasis: "30%" }}>
                                        <div className="formInput">
                                            <label>Select Industry</label>
                                            <CreatableSelect
                                                className="basic-single"
                                                classNamePrefix="select"
                                                placeholder="Select Industry"
                                                onChange={handleChangeIndustry}
                                                defaultValue={formValues.industry}
                                                isSearchable={true}
                                                name="industry"
                                                formatCreateLabel={(inputValue) => `Creating new : ${inputValue}`}
                                                options={
                                                    industry_List && industry_List.data && industry_List.data.rows.map((option) => {
                                                        return {
                                                            label: option.industry_name,
                                                            value: option.id,
                                                            name: 'industry'
                                                        };
                                                    })
                                                }
                                            />
                                            {errSatus === true && formValues && formValues.industry === "" && <p style={{ color: "#96332c", fontSize: 13 }}>{formErr.industryErr}</p>}
                                        </div>
                                    </div>

                                    <div className="formInput">
                                        <label>Cug Phone Number </label>
                                        <input
                                            name="cug_ph_no"
                                            defaultValue={formValues.cug_ph_no}
                                            type="text"
                                            placeholder="Cug Phone Number"
                                            onChange={handleChange}

                                        />
                                        {errSatus === true && formValues && formValues.cug_ph_no === "" && <p style={{ color: "#96332c", fontSize: 13 }}>{formErr.cug_ph_noErr}</p>}
                                        {errSatus === true && validateErr && validateErr.cug_ph_no !== true && <p style={{ color: "#96332c", fontSize: 13 }}>{validateErr && validateErr.cug_ph_no}</p>}
                                    </div>

                                    <div style={{ flexBasis: "30%" }}>
                                        <div className="formInput">
                                            <label>Department</label>
                                            <CreatableSelect
                                                className="basic-single"
                                                classNamePrefix="select"
                                                placeholder="Select Department"
                                                defaultValue={formValues.department}
                                                isSearchable={true}
                                                name="department"
                                                options={department_List.map((option) => {
                                                    return { label: option.label, value: option.value, name: "department" };
                                                })}
                                                formatCreateLabel={(inputValue) => `Creating new : ${inputValue}`}
                                                onChange={handleDepartmentChange}
                                            />
                                            {errSatus === true && formValues && formValues.department === "" && (
                                                <p style={{ color: "#96332c", fontSize: 13 }}>{formErr.departmentErr}</p>
                                            )}
                                        </div>
                                    </div>

                                    <div style={{ flexBasis: "30%" }}>
                                        <div className="formInput">
                                            <label>Skills</label>
                                            <CreatableSelect
                                                isMulti
                                                className="basic-single"
                                                classNamePrefix="select"
                                                placeholder="Select Skills"
                                                defaultValue={selectTechnology}
                                                isSearchable={true}
                                                name="technology"
                                                key={reset}
                                                options={skils && skils.map((option) => {
                                                    return { label: option.label, value: option.value, name: "technology" };
                                                })}
                                                onChange={handleChangeOnTechnology}
                                                formatCreateLabel={(inputValue) => `Creating new skill: ${inputValue}`}
                                            />
                                            {errSatus === true && formValues && (selectTechnology === "" || selectTechnology.length === 0) && (
                                                <p style={{ color: "#96332c", fontSize: 13 }}>{formErr.selectTechnologyErr}</p>
                                            )}
                                        </div>
                                    </div>
                                    {selectTechnology ?
                                        <div className="formInput">
                                            <FormControl>
                                                <FormLabel id="demo-row-checkbox-group-label">Is Interviewer</FormLabel>
                                                <FormGroup row aria-labelledby="demo-row-checkbox-group-label">
                                                    <FormControlLabel
                                                        control={<Checkbox checked={isInterViewer}
                                                            onChange={() => setIsInterViewer(!isInterViewer)}
                                                        />}
                                                        label="Is Interviewer"
                                                    />
                                                </FormGroup>
                                            </FormControl>
                                        </div> : ''
                                    }


                                    <div className="formInput" style={{ flexBasis: "100%" }}>
                                        <label> Address </label>

                                        <textarea defaultValue={formValues.address} name="address"
                                            onChange={handleChangeNumber} rows="3" placeholder="Full Address here"></textarea>

                                        {errSatus === true && formValues && formValues.address === "" && <p style={{ color: "#96332c", fontSize: 13 }}>{formErr.addressErr}</p>}
                                    </div>


                                </div>
                                {error && <p style={{ color: "#96332c", fontSize: 13 }}> {error.message}</p>}


                                <button
                                    onClick={submitData}
                                    className="btn btn-submit"
                                    disabled={loader === true ? true : false}

                                >
                                    {resourceid ? CONSTANTS.COMMON_BUTTONS.UPDATE : CONSTANTS.COMMON_BUTTONS.SUBMIT}
                                </button>
                            </form>
                            : ""}

                    </div>
                    :
                    <LoaderForm />
                }
                {/* Isapprover confirmation pop up section */}

                <Confirmation
                    open={isOpen}
                    handleClose={() => { setIsOpen(!isOpen) }}
                    handleCancel={() => { setIsOpen(!isOpen); setIsTmsJobApprover(false) }}
                    onConfirm={changeApprover}
                    dialogTitle={"Confirm"}
                    dialogQuestion={"We already have an approver, would you like to revoke him and use this resource as an approver?"}
                />


            </div>
        </div >

    )

}

export default ResourceEdit