import { useState, useEffect, useRef } from "react";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import Select from "react-select";
import './test.scss';

import { useDispatch, useSelector } from "react-redux";
import {
  getCallLogDetailById,
  getCallLogDetails,
  getCommentId,
  getTaskHistory,
  getZoomMeetingDetails,
  postCommentCreate,
  resourceProjectList,
  taskAssigneeUpdateById,
  taskStatusUpdate,
  taskViewById,
  getTeamMemberList,
  createRemindStory,
  getEmailLogsDetails
} from "../../redux/features/taskSlice";
import { Tab, Tabs, Avatar, Autocomplete, TextField, Box, Tooltip, Button, MenuItem, Checkbox, Grow, Popper, Paper, ClickAwayListener, MenuList, Rating, Divider } from "@mui/material";
import LocalStorageDataService from "../../components/common-function/GetLocalstorage";
import LoaderForm from "../../components/common-function/LoaderForm";
import { STATUSES } from "../../redux/common-status/constant";
import moment from "moment";
import { candidateCreate, createAttachment, getBatchList, getQuestionLevelList, getIndustryList, getInterviewerFeedbackList, getIndustryListCES } from "../../redux/features/masterSlice";
import { resourceListForTaskEdit } from "../../redux/features/resourceSlice";
import { CKEditor } from "ckeditor4-react";
import { Mail, AttachFile, Close, ZoomOutMap, ZoomInMap, KeyboardArrowDown, KeyboardArrowLeft, KeyboardArrowRight, Phone, InsertInvitation, AddRounded, LocalPhoneRounded, KeyboardArrowUp, CoPresent, Download } from "@mui/icons-material";
// import CallingComponent from "../../components/common-function/CallingComponent";
import EmailTemplet from "../../components/email-template/EmailTemplate";
import { toast } from "react-toastify";
import InsertCommentIcon from "@mui/icons-material/InsertComment";
import { PhoneCalling } from "../phone-calling/PhoneCalling";
import IconButton from "@mui/material/IconButton";
import TaskBreadCrumb from "../../components/breadcrumb/TaskBreadCrumb";
import ReactAudioPlayer from "react-audio-player";
import { RemindModal } from "./RemindMeModal";
import { DefaultDataGrid } from "../../components/common-function/DefaultDataGrid";
import { ContentParser } from "./ContentParser";
import AddCandidatePhoneNumber from "../../components/add-candidate-phone-no/AddCandidatePhoneNumber";
import FileViewer from "react-file-viewer";
import { callCreate, updateCandidateContactNumber } from "../../redux/features/callingSlice";
import CustomeTooltip from "../../components/common-function/CustomeTooltip";
import DefaultScreeningForm from "../master/candidate-screening-form/DefaultScreeningForm";
// import {  jobScreeningFormEdit } from "../../redux/features/candidateScreeningFormSlice";
import { ExamSchedulForm } from "./ExamScheduleForm";
import CryptoJS from 'crypto-js';

// import { getByIdStoryScreeningForm, jobScreeningFormEdit } from "../../redux/features/candidateScreeningFormSlice";
import dataService from "../../redux/services/data.service";
import { completeCandidateExamPost, getScreeningTest, getStatusScheduleExam } from "../../redux/features/screenTestStory";
import { SingleDatePickerInputField, SingleTimePickerInputField } from "../../components/common-function/InputFields";
import ScreeningQuestion from "./ScreeningQuestion";
import ViewScreeningTest from "./ViewScreeningTest";
import Skip from "./Skip";
import AddCandidateEmail from "../../components/add-candidate-email/AddCandidateEmail";
import { updateCandidateEmail } from "../../redux/features/emailSlice";
const domain_data = LocalStorageDataService.get_domain_data()

function stringToColor(string) {
  let hash = 0;
  let i;
  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }
  let color = "#";
  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */
  return color;
}

function firstLetterWord(str) {
  let result = "";
  // Traverse the string.
  let v = true;
  for (let i = 0; i < str.length; i++) {
    // If it is space, set v as true.
    if (str[i] === " ") {
      v = true;
    }

    // Else check if v is true or not.
    // If true, copy character in output
    // string and set v as false.
    else if (str[i] !== " " && v === true) {
      result += str[i];
      v = false;
    }
  }
  return result;
}

function stringAvatar(name) {
  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children: firstLetterWord(name),
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`horizontal-tabpanel-${index}`}
      aria-labelledby={`horizontal-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  );
}
const { REACT_APP_API_BASEURL, REACT_APP_ENCRPTION_KEY_FOR_IV, REACT_APP_ENCRPTION_KEY_FOR_UI } = process.env;
const formErrData = Object.freeze({
  dateErr: " Date  is required",
  timeErr: "Time is required",
  commentErr: "Comment is required",
  default_time_in_minsErr: "Default Time  is required"
});
// const docs = [
//   { uri: 'https://stage-tm.mightcode.com/api/' + "recruitment/resume/download/7478" }
// ];

// make clickable link
function makeUrlsClickable(text) {
  let transformedText;
  const zoomText = /(style="@import)/g;
  const match = text.match(zoomText);
  const urlRegex = /(http[s]?:\/\/[^\s<]+)/g;
  if (match) {
    transformedText = text.replace(zoomText, ' ');
  } else {
    transformedText = text.replace(urlRegex, '<a href="$&" target="_blank" rel="noopener noreferrer">$&</a>');
  }

  return transformedText;
}

export const StoryTaskCall = ({ api, filters, epicId, hideDiv, activeDivs, setActiveClass, activeClass,
  activeCallDivs, setActiveCallDivs, showAddPhone, setShowAddPhone, phoneNumberByList, setPhoneNumberByList,
  jobIdByList, statusDataByList, statusNameByList, setStatusNameByList, setStatusDataByList
}) => {
  const dispatch = useDispatch();
  const [value, setValue] = useState(0);
  const [file, setFile] = useState(null);
  const [apiLoading, setApiLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formValues, setFormValues] = useState({});
  const [selected, setSelected] = useState([]);
  const [statusData, setStatusData] = useState([]);
  const [memberLoader, setMemberLoader] = useState(true);
  const [, setMember_List] = useState([]);
  const [selectedMember, setSelectedMember] = useState([]);
  const [selectedMemberLoader, setSelectedMemberLoader] = useState(false);
  const [assignee_name_default, setAssignee_name_default] = useState("");
  const [commentActive, setCommentActive] = useState(false);
  const [comment, setComment] = useState("");
  const [multipleEmail, setMultipleEmail] = useState({})

  const [defaultActivemail, setdefaultActivemail] = useState(false);
  //const [ setTeamMembersStatus] = useState(false)
  const [showEmailModel, setShowEmailModel] = useState(false);

  //start calling related states
  const phoneBtnRef = useRef(null);
  const [showAddCandidatePhoneBtn, setShowAddCandidatePhoneBtn] = useState(false);
  const [reload, setReload] = useState(false);

  const [agentCustomerCallObj, setAgentCustomerCallObj] = useState({
    agent_name: "",
    candidate_name: "",
    agent_phone_no: "",
    candidate_phone_no: "",
    call_status: null,
  });
  const [callCreateApiLoading, setCallCreateApiLoading] = useState(false);
  const [callId, setCallId] = useState("");
  const [callStatus, setCallStatus] = useState(null);
  const [callDetailApiLoading, setcallDetailApiLoading] = useState(false);
  const [isCallInitiated, setIsCallInitiated] = useState(false);

  const [callDisconnect, setCallDisconnect] = useState(false);
  const [phoneDetails, setPhoneDetails] = useState({ destination_number: "", story_id: epicId, });
  const [contactNumbers, setContactNumbers] = useState([])
  //end calling related states

  const [activeRemindModal, setActiveRemindModal] = useState(false)
  const [startRemindDate, setStartRemindDate] = useState({ date: "", time: "", comment: '', default_time_in_mins: 10 });
  const [reminderLoading, setReminderLoading] = useState(false)
  const [formErr] = useState(formErrData)
  const [errStatus, setErrStatus] = useState(false)

  const [anchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const [activeTab, setActiveTab] = useState(0);

  const [anchorEl1, setAnchorEl1] = useState(null);
  const emailBtnRef = useRef(null);
  const [anchorEl2, setAnchorEl2] = useState(null);
  const [attachments, setAttachments] = useState([])
  const [descriptionType, setDescriptionType] = useState("")
  const [activeExamScheduleModal, setActiveExamScheduleModal] = useState(false)
  const { createCandidate } = useSelector(state => state.master)
  const [candidateID, setCandidateID] = useState("")
  const [candidateExamList, setCandidateExamList] = useState([])
  const [ces] = useState(LocalStorageDataService.cesCandidateDetails())
  const [examCandidateInfo, setExamCandidateInfo] = useState("")
  const openmessage = Boolean(anchorEl1);
  const openphone = Boolean(anchorEl2);

  const dropdownClick = (event) => {
    setAnchorEl1(event.currentTarget);
  };
  const dropdownPhoneClick = (event) => {
    setAnchorEl2(event.currentTarget);
  };

  const dropdownClose = () => {
    setAnchorEl1(null);
  };
  const dropdownPhoneClose = () => {
    setAnchorEl2(null);
  };
  const [emailDetails, setEmailDetails] = useState({ email: "", });
  const [showAddEmailBtn, setShowAddEmailBtn] = useState(false);
  const [showAddEmailModal, setShowAddEmailModal] = useState(false);

  // file uploading states
  const uploadInputRef = useRef(null);

  //satrt screening form related states
  const [, setJobIdForScrrening] = useState(jobIdByList ? jobIdByList : 0)
  const [, setScreeningStatus] = useState(false)
  const [editStoryScreeningData, setEditStoryScreeningData] = useState({})
  const [questionsData, setQuetionsData] = useState([])
  const [openScreeningSection, setOpenScreeningSection] = useState(false)
  const [screeningId, setScreeningId] = useState("")
  const [otherQuestion, setOtherQuestion] = useState(false)
  const [loadingScreeningData, setLoadingScreeningData] = useState(false)
  const [screeningCommentData, setScreeningCommentData] = useState([])
  const [examTabvalue, setExamTabvalue] = useState(false);

  const [openInterviewerFeedbackSection, setOpenInterviewerFeedbackSection] = useState(false)

  // const [modelOpen, setModelOpen] = useState(false)
  // const [modelData, setModelData] = useState({})

  //end screening form related states

  //start screening test form related states
  const [showScreeningTest, setShowScreeningTest] = useState(false)
  const [loadingScreeningTest, setLoadingScreeningTest] = useState(false)
  const { screen_status, screeningTest_List, examResultData, exam_status } = useSelector(state => state.screeningTestSlice)
  const [screeningTestData, setScreeningTestData] = useState([])
  const [disabledEmail, setDisabledEmail] = useState(false)
  const [openScreenDetails, setOpenScreenDetails] = useState(true)
  const [openScreenDetailsIndex, setOpenScreenDetailsIndex] = useState([])
  const [selectedScheduledDate, setSelectedScheduledDate] = useState(moment().format('YYYY-MM-DD'))
  const [selectedScheduledTime, setSelectedScheduledTime] = useState(null)
  const [rendarQuestion, setRendarQuestion] = useState(false)
  const [questionData, setQuestionData] = useState([])
  const [loaderExamApi, setLoaderExamApi] = useState(false)
  const [openViewQueetion, setOpenViewQuestion] = useState(false)
  const [openSkipModal, setOpenSkipModal] = useState(false)
  const [skipRowData, setSkipRowData] = useState({})
  const [skipformValues, setSkipFormValues] = useState({
    email: "",
    password: "",
    remark: "",
  })

  const [viewQuestionData, setViewQuestionData] = useState([])

  //end screening test form related states

  const [jobApproverStatus, setJobApproverStatus] = useState("");
  const {
    teamMemberStatus,
    status,
    defaultLoader,
    taskData,
    memberList,
    commentById,
    zoomMeetingDetailList,
    callLogDetailList,
    taskHistoryList,
    teamMemberList,
    callDetailById,
    createReminder,
    emailLogDetailList
  } = useSelector((state) => state.task);
  const { create_Attachment, candidateCreateError, interviewerFeedbackList } = useSelector((state) => state.master);


  //const { resourceListForTsk } = useSelector(state => state.resource)
  //const { call_disconnect } = useSelector(state => state.callingSlice)

  //const resource_data_list = resourceListForTsk && resourceListForTsk.data && resourceListForTsk.data.rows ? resourceListForTsk.data.rows : [];
  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (newValue === 0) {
      setActiveTab(0);
      dispatch(getCommentId(epicId));
    }
    if (newValue === 1) {
      setActiveTab(1);
      dispatch(getTaskHistory(epicId));
    }

    if (newValue === 2) {
      setActiveTab(2);
      dispatch(
        getCallLogDetails(
          `{"filter":{"story_id":{"value":${epicId}, "operation":"eq"}}, "order_by":{"column":"-id"}, "no_limit":True}`
        )
      );
    }
    if (newValue === 3) {
      setActiveTab(3)
      dispatch(getEmailLogsDetails(`{"filter":{"jira_rtms_issue":{"value":${epicId}, "operation":"eq"}}, "order_by":{"column":"-id"}, "no_limit":True}`))

    }
    if (newValue === 4) {
      setActiveTab(4);
      dispatch(
        getZoomMeetingDetails(
          `{"filter":{"story_id":{"value":${epicId}, "operation":"eq"}}, "order_by":{"column":"-id"}, "no_limit":True}`
        )
      );
    }
  };

  if (activeTab === 0 && commentById && commentById.data) {
    setActiveTab(false);
  }
  if (activeTab === 1 && taskHistoryList && taskHistoryList.data) {
    setActiveTab(false);
  }
  if (activeTab === 2 && callLogDetailList && callLogDetailList.data) {
    setActiveTab(false);
  }
  if (activeTab === 3 && emailLogDetailList && emailLogDetailList.data) {
    setActiveTab(false)
  }
  if (activeTab === 4 && zoomMeetingDetailList && zoomMeetingDetailList.data) {
    setActiveTab(false);
  }

  const assignToMe = (e, type) => {
    e.preventDefault();
    let userId = LocalStorageDataService.userDetail().id;
    setLoading(true);
    if (type === "unassign") {
      setAssignee_name_default("");
      dispatch(taskAssigneeUpdateById({ issue_id: epicId, resource_id: 0, assign_to_me: 0 }));
    } else {
      setAssignee_name_default({
        key: userId,
        label: LocalStorageDataService.userDetail().display_name,
        value: userId,
        name: "assignee",
      });
      dispatch(
        taskAssigneeUpdateById({ issue_id: epicId, resource_id: userId, assign_to_me: 0 })
      );
    }
  };

  const changeCallActiveStatus = () => {
    let statusIs = (formValues && formValues.status_name) || statusNameByList;
    if (statusIs.match("BACKLOG") || statusIs.match("backlog") || statusIs.match("Backlog")) {
      const data = statusDataByList && statusDataByList.length > 0 ? statusDataByList : statusData
      let filterStatus = data.filter((x) =>
        x.status_name === "In Progress" ? x : ""
      );
      if (filterStatus.length > 0) {
        let value = filterStatus[0].status_id;
        setLoading(true);
        const transition = filterStatus;
        dispatch(
          taskStatusUpdate({
            issue_id: epicId,
            from_status_id: formValues.status_id ? formValues.status_id : value,
            to_status_id: value,
            transition_id:
              transition.length > 0 ? transition[0].transition_id : null,
          })
        );
      }
    }
  };

  const changeStatus = (evt) => {
    if (evt) {
      const { name, value } = evt.target || evt;

      if (name === "status_name") {
        setLoading(true);
        const transition = statusData.filter((x) =>
          x.status_id.toString() === value ? x : ""
        );
        dispatch(
          taskStatusUpdate({
            issue_id: epicId,
            from_status_id: formValues.status_id ? formValues.status_id : value,
            to_status_id: value,
            transition_id:
              transition.length > 0 ? transition[0].transition_id : null,
          })
        );
      }
      if (name === "assignee") {
        setLoading(true);
        dispatch(
          taskAssigneeUpdateById({ issue_id: epicId, resource_id: value, assign_to_me: 0 })
        );
      }
      if (name === "lables") {
        setSelected(value);
        setFormValues({ ...formValues, labels: value });
      }
    }
  };

  //start screening section
  const decryptMessage = (Base64CBC) => {
    var iv = CryptoJS.enc.Utf8.parse(REACT_APP_ENCRPTION_KEY_FOR_IV);
    var key = REACT_APP_ENCRPTION_KEY_FOR_UI;
    key = CryptoJS.enc.Utf8.parse(key);
    var decrypted = CryptoJS.AES.decrypt(Base64CBC, key, { iv: iv, mode: CryptoJS.mode.CBC });
    decrypted = decrypted.toString(CryptoJS.enc.Utf8);
    return decrypted;
  };

  const storyScreeningApiCall = async () => {
    setOpenScreeningSection(true)
    setScreeningStatus(false)
    setQuetionsData([])
    setScreeningCommentData([])
    setLoadingScreeningData(true)
    // screening data by storyid
    const res = await dataService.get_story_screeningForm_byId(epicId)
    const response = res.data
    if (response && response.status === 200) {

      if (response.data && response.data.rows && response.data.rows.length > 0) {
        let formData = response.data.rows.length > 0 ? response.data.rows : []
        setEditStoryScreeningData(formData[0])
        let quesArray = []
        let commentArray = []
        // let newAnswer;
        // formData && formData.length > 0 && formData.map(item => {
        formData && formData.length > 0 && formData.forEach(item => {
          item.story_screening_form_data && item.story_screening_form_data.length > 0 && item.story_screening_form_data.map(e => {
            // newAnswer = decryptMessage(e.screening_answer)
            return quesArray.push({
              screening_question: e.screening_question,
              screening_answer: e.screening_answer ? decryptMessage(e.screening_answer) === "NA" ? "" : e.screening_answer : "NA",
              question_key: e?.question_key ?? undefined,
              disabled: e.screening_answer && decryptMessage(e.screening_answer) === "NA" ? false : true,
              id: e.id ? e.id : ""
            })
          })

          let comment_date = item.created_on ? moment(item.created_on).format('DD-MM-YYYY') : "N/A";
          let comment_time = item.created_on ? moment(item.created_on).format('HH:mm:ss') : "N/A";

          return commentArray.push({
            comment: item.comment,
            disabled: true,
            date: comment_date,
            time: comment_time
          })
        })

        formData && setScreeningId(formData[0].id)
        setQuetionsData(quesArray)
        setScreeningCommentData(commentArray)
        setTimeout(() => {
          setLoadingScreeningData(false)
        }, 500)

      } else {
        // screening data by jobid
        // jobIdByList > 0 && dispatch(jobScreeningFormEdit(`{"filter":{"job_id":{"value":${jobIdByList},"operation":"eq"}}}`))
        if (jobIdByList > 0) {
          const res = await dataService.jobpost_screeningForm_edit(`{"filter":{"job_id":{"value":${jobIdByList},"operation":"eq"}}}`)
          const response = res.data
          if (response && response.status === 200) {
            if (response.data && response.data.rows && response.data.rows.length > 0) {
              let formData = response.data.rows.length > 0 ? response.data.rows[0] : []
              setEditStoryScreeningData(formData)
              formData.form_data && formData.form_data.length > 0 && setQuetionsData(formData.form_data)
            }
            setScreeningId("")
            setOtherQuestion(true)
          } else {
            toast.error(response.message)
          }
        }
        setTimeout(() => {
          setLoadingScreeningData(false)
        }, 500)
        //screening
      }
    } else {
      toast.error(response.message)
      setLoadingScreeningData(false)
    }

  }
  //end screening section

  // start screening test section

  const screeningTestApi = () => {
    setShowScreeningTest(true)
    // 11503
    setLoadingScreeningTest(true)
    dispatch(getScreeningTest(`{"filter":{"story_id":{"value":${epicId},"operation": "eq"}}, "order_by":{"column":"sequence_number"}}`))
  }

  if (loadingScreeningTest === true && screen_status === `${STATUSES.SUCCESS}_screeningTest_List` && screeningTest_List && screeningTest_List.message) {
    if (screeningTest_List.status === 200) {
      setScreeningTestData(screeningTest_List.data?.rows)
      let flag_status = false
      // eslint-disable-next-line
      screeningTest_List.data?.rows.map((item, i) => {
        if (item.hiring_story_screenflow_status === 0) {
          flag_status = true
        } else if (item.hiring_story_screenflow_status === 2 && item.is_passed === false) {
          flag_status = true
        } else if (item.hiring_story_screenflow_status === 3) {
          flag_status = false
        } else if (item.hiring_story_screenflow_status === 1) {
          flag_status = true
        }
      })

      if (flag_status === true) {
        setDisabledEmail(true)
      } else {
        setDisabledEmail(false)
      }

    } else {
      toast.error(screeningTest_List.message)
      setScreeningTestData([])
      setDisabledEmail(true)
    }
    setLoadingScreeningTest(false)
  }
  // end screening test section

  useEffect(() => {
    storyScreeningApiCall()
    screeningTestApi()
    if (epicId && activeDivs === `story_${epicId}`) {
      setApiLoading(true);
      dispatch(taskViewById(epicId));
    }
    // if (epicId && activeDivs !== `story_${epicId}`) {
    //   let newEpicId = activeDivs.replace('story_', '')
    //   setApiLoading(true);
    //   dispatch(taskViewById(newEpicId));
    // }
    setOpenScreeningSection(false)
    dispatch(resourceListForTaskEdit());
    setSelectedMember([]);
    setAttachments([])
    setContactNumbers([])
    setdefaultActivemail(false);

    dispatch(getInterviewerFeedbackList(epicId))
    //screening test
    setScreeningTestData([])
    setShowScreeningTest(false)
    setOpenScreenDetails([])
    setOpenScreenDetailsIndex(false)
    setSelectedScheduledDate(moment().format('YYYY-MM-DD'))
    setSelectedScheduledTime(null)
    setRendarQuestion(false)
    setOpenViewQuestion(false)
    setViewQuestionData([])
    setOpenSkipModal(false)
    setSkipRowData([])

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeDivs]);

  if (memberLoader === true && memberList && memberList.data) {
    let array = [];
    memberList.data.rows.map((e) => {
      return array.push({
        display_name: e.resource.display_name,
        assignee_id: e.resource_id,
        jira_rtms_issue_id: e.jira_rtms_issue_id,
        actual_id: e.id,
      });
    });
    setMember_List(array);
    setMemberLoader(false);
    setApiLoading(false);
  }

  if (loading === true && file && file[0].name) {
    setFile(null);
    setLoading(false);
    setApiLoading(true);
    dispatch(taskViewById(epicId));
    dispatch(getTaskHistory(epicId));
  }

  if (loading === true && status === `${STATUSES.SUCCESS}_taskStatusData`) {
    setLoading(false);
    setApiLoading(true);
    dispatch(taskViewById(epicId));
    dispatch(api(filters))
    dispatch(getTaskHistory(epicId));
  }
  if (loading === true && status === `${STATUSES.SUCCESS}_taskAssigneeUpdate`) {
    setLoading(false);
    setApiLoading(true);
    dispatch(taskViewById(epicId));
    dispatch(getTaskHistory(epicId));
  }
  if (loading === "comment_true" && status === `${STATUSES.SUCCESS}_createComment`) {
    setComment("");
    setLoading(false);
    setActiveTab(0);
    dispatch(getCommentId(epicId));
  }
  if (apiLoading === true && status === `${STATUSES.SUCCESS}_taskHistoryList`) {
    setApiLoading(false);
  }
  if (apiLoading === true && status === ` ${STATUSES.SUCCESS}_commentById`) {
    setApiLoading(false);
  }
  if (apiLoading === true && status === ` ${STATUSES.SUCCESS}_zoomMeetingDetailList`) {
    setApiLoading(false);
  }
  if (apiLoading === true && status === ` ${STATUSES.SUCCESS}_callLogDetailList`) {
    setApiLoading(false);
  }
  if (apiLoading === true && status === ` ${STATUSES.SUCCESS}_emailLogDetailList`) {
    setApiLoading(false)
  }
  if (selectedMemberLoader === true && teamMemberStatus === `${STATUSES.SUCCESS}_teamMemberList`) {
    let array = [];
    if (teamMemberList && teamMemberList.data && teamMemberList.data.rows && teamMemberList.data.rows.length > 0) {
      teamMemberList.data.rows.map((e) => {
        if (e.status === true) {
          array.push({
            label: e.resource.display_name,
            value: e.resource.id,
          });
        }
        return array;
      });
    }
    setSelectedMemberLoader(false);
    setSelectedMember(array);
  }
  if (apiLoading === true && status === `${STATUSES.SUCCESS}_taskData`) {
    let data = taskData.data;

    if (taskData.status === 400) {
      toast.error(taskData.message);
    }
    setApiLoading(false);
    setFormValues(data);

    let job_approver_status = data && data.epic_details && data.epic_details.job_status
    setJobApproverStatus(job_approver_status)

    if (data && data.attachments && data.attachments.length > 0) {

      if (attachments.length === 0) {
        let attachmentIs = []
        data.attachments.map(e => {
          let fileIs = e.file_name.split(".")
          let lengthIs = fileIs.length - 1
          return attachmentIs.push({ uri: REACT_APP_API_BASEURL.replace("api/", "api") + e.api_url, type: fileIs[lengthIs] })
        })
        setAttachments(attachmentIs)
      }

    }
    let descriptionTypes = data && data.description && (data.description).match("{") ? ContentParser(JSON.parse(data.description)) : data && data.description
    setDescriptionType(descriptionTypes)
    if (data && data.status_data) {
      setStatusData(data.status_data);
    }
    if (data && data.assignee) {
      setAssignee_name_default({
        label: data.assignee.display_name,
        value: data.assignee.id,
      });
    }
    if (data && data.epic_id) {
      setSelectedMember([]);
      setTimeout(() => {
        setSelectedMemberLoader(true);
      }, 500);
      dispatch(
        getTeamMemberList(
          `{"filter":{"jira_rtms_issue_id":{"value":${data.epic_id}, "operation":"eq"}}, "order_by":{"column":"-id"}, "no_limit":True}`
        )
      );
    }
    if (data && data.candidate_info) {
      const get_candidate_name = data && data.candidate_info && data.candidate_info.length > 0 && data.candidate_info.map((ele) => {
        return ele.candidate_name;
      });

      let defaultPhoneNumber = ''
      if (data && data.candidate_phone && data.candidate_phone.length > 0) {
        let checkPhoneNumber = data.candidate_phone.filter((item) => item.phone_no === phoneNumberByList)
        if (checkPhoneNumber.length > 0) {
          defaultPhoneNumber = phoneNumberByList
        } else {
          checkPhoneNumber = data.candidate_phone.filter((item) => item.phone_no === phoneDetails.destination_number)
          if (checkPhoneNumber.length > 0) {
            defaultPhoneNumber = phoneDetails.destination_number
          } else {
            defaultPhoneNumber = data.candidate_phone[0].phone_number
          }
        }
      }


      let phonDetailsObj = {
        agent_name: data && data.assignee && data.assignee.name,
        agent_phone_no: data && data.assignee && data.assignee.cug_ph_no,
        candidate_name: get_candidate_name[0],
        // candidate_phone_no: phoneDetails.destination_number,
        candidate_phone_no: defaultPhoneNumber,
        call_status: callStatus,
      }

      setAgentCustomerCallObj(phonDetailsObj)
      // setAgentCustomerCallObj({
      //   ...agentCustomerCallObj,
      //   agent_name: data && data.assignee && data.assignee.name,
      //   agent_phone_no: data && data.assignee && data.assignee.cug_ph_no,
      //   candidate_name: get_candidate_name[0],
      //   // candidate_phone_no: phoneDetails.destination_number,
      //   candidate_phone_no: phoneNumberByList ? phoneNumberByList : phoneDetails.destination_number,
      //   call_status: callStatus,
      // });
    }

    if (data && data.candidate_phone && data.candidate_phone.length) {
      if (contactNumbers.length === 0) {
        let contacts = [{ id: "add-new-number", phone_number: "Add new number" }];
        data.candidate_phone.map((e) => {
          return contacts.unshift(e)
        })

        setContactNumbers(contacts.sort((a, b) => Number(b.id) - Number(a.id)))
      }
      if (activeCallDivs && activeCallDivs !== false) {
        setPhoneDetails({
          ...phoneDetails,
          destination_number: phoneNumberByList ? phoneNumberByList : data.candidate_phone[0].phone_number,
        });
      }
    }

    if (data && data.candidate_phone && data.candidate_phone.length === 0) {
      setPhoneDetails({ destination_number: "", story_id: "" });
      setShowAddCandidatePhoneBtn(true)
    }

    if (data && data.candidate_email && data.candidate_email.length === 1) {
      setdefaultActivemail(true);
      setEmailDetails({
        ...emailDetails,
        email: [data.candidate_email[0].email],
      });
    }

    if (data && data.candidate_email && data.candidate_email.length === 0) {
      setEmailDetails({
        email: "",
      });
      setdefaultActivemail(false)
      setShowAddEmailBtn(true)
    }

    if (data && data.project) {
      // setTeamMembersStatus(true)
      dispatch(
        resourceProjectList(
          `{"filter":{"project":{"value":${data.project.id},"operation":"eq"}}, "no_limit":True}`
        )
      );
    }

    dispatch(getCommentId(epicId));
    dispatch(getTaskHistory(epicId));
  }


  const inputHandler = (evt) => {
    var newContent = evt.editor.getData();
    setComment(newContent);
    // Define your onSubmit function here
    // ...
    // for example, setData() here
  };

  const editCommentChanges = (name) => {
    setCommentActive(false);
    const parser = new DOMParser();
    const doc = parser.parseFromString(comment, "text/html");

    // might be a more efficient way to do this. just querying for anchors
    // and filtering out the ones that don't have a <sup> child
    const anchors = [...doc.querySelectorAll("a")];

    let data = {
      jira_issue_id: formValues.ticket_jira_key,
      rtms_issue: epicId,
      comment_url: anchors && anchors.length > 0 ? anchors[0].innerText : "",
      is_jira_attachment: false,
      body: comment,
      created_resource: LocalStorageDataService.userDetail().id,
      status: true,
      created_by: LocalStorageDataService.userDetail().id,
    };
    setLoading("comment_true");
    dispatch(postCommentCreate(data));
    //dispatch(taskUpdateById({ id: taskId, addvalues: { [name]: value } }))
  };


  const changeEmail = (evt) => {
    if (evt) {
      setEmailDetails({ ...emailDetails, 'email': evt });
      dropdownClose()
      setShowEmailModel(true);
    } else {
      setEmailDetails({ email: "", });
    }
  };

  const calculateDays = (startDate) => {
    if (new Date() === new Date(startDate)) {
      return "Toady";
    }
    const diffInMs = new Date() - new Date(startDate);
    const diffInDays = diffInMs / (1000 * 60 * 60 * 24);
    return parseInt(diffInDays) + "  days ago";
  };
  const disabledAssigne = () => {
    let statusIs = false;
    if (selectedMember && selectedMember.length > 0) {
      selectedMember.map((e) => {
        if (e.value === LocalStorageDataService.userDetail().id) {
          statusIs = true;
        }
        return statusIs;
      });
    }
    return statusIs;
  };

  const [show, setShow] = useState(false);

  // attach file code here
  const onFileChange = (event) => {
    let file = event.target.files;
    const formData = new FormData();
    formData.append("file", file[0]);
    formData.append("id", `${epicId}`);
    formData.append("upload_type", `task`);
    formData.append("display_name", file[0].name);

    setLoading("upload_attachment");
    dispatch(createAttachment(formData));
  };

  if (loading === "upload_attachment" && create_Attachment && create_Attachment.status) {
    setLoading(false);
    if (create_Attachment.status === 200) {
      toast.success(create_Attachment.message);
      setApiLoading(true);
      dispatch(taskViewById(epicId));
      dispatch(getTaskHistory(epicId));
    } else {
      toast.error(create_Attachment.message);
    }
  }
  //download file 
  const fileDownload = (url) => {
    let ApIURL = REACT_APP_API_BASEURL + url;
    window.open(ApIURL.replace("api//", "api/"));
  };
  const columnsCall = [
    {
      field: "id",
      headerName: "ID",
      width: 90,
      filterable: false,
      hideable: false,
    },
    {
      field: "direction",
      headerName: "Call Type",
      width: 150,
      type: "singleSelect",
      valueOptions: [
        { value: 0, label: "In-bound" },
        { value: 1, label: "Out-bound" },
      ],
      renderCell: (params) => {
        return (
          <div className="cellWithStatus">
            {params.row.direction === 0 ? "In-bound" : "Out-bound"}
          </div>
        );
      },
    },
    {
      field: "destination_number",
      headerName: "Destination Number",
      width: 200,
    },
    {
      field: "call_date",
      headerName: "Call Date",
      width: 150,
      type: "date",
      renderCell: (params) => {
        return (
          <div className="cellWithStatus">
            {moment(params.row.call_date).format("DD-MM-YYYY")}
          </div>
        );
      },
    },

    {
      field: "call_duration",
      headerName: "Call Duration(in seconds)",
      width: 260,
      renderCell: (params) => {
        return (
          <div className="cellWithStatus">
            {params.row.call_duration && params.row.call_duration}
          </div>
        );
      },
    },

    {
      field: "recording_url",
      headerName: "Call Recording Link",
      width: 350,
      renderCell: (params) => {
        return (
          <div className="cellWithStatus">
            {formValues &&
              formValues.assignee_id ===
              LocalStorageDataService.userDetail().id ? (
              <ReactAudioPlayer
                src={params.row.recording_url}
                autoPlay={false}
                controls
                style={{ height: "30px" }}
              />
            ) : (
              params.row.recording_url
            )}
          </div>
        );
      },
    },
  ];
  const columnsZoom = [
    {
      field: "id",
      headerName: "ID",
      width: 90,
      filterable: false,
      hideable: false,
    },
    {
      field: "agenda",
      headerName: "Agenda",
      //sortable: false,
      width: 300,
    },
    {
      field: "topic",
      headerName: "Topic",
      //sortable: false,
      width: 200,
    },
    {
      field: "start_time",
      headerName: "Start Date",
      //sortable: false,
      width: 200,
      type: "date",
      renderCell: (params) => {
        return (
          <div className="cellWithStatus">
            {/* {moment(params.row.start_time).format("DD-MM-YYYY HH:SS")} */}
            <p>{(params.row.start_time).replace(/[TZ]/g, ' ')}</p>
          </div>
        );
      },
    },
    {
      field: "duration",
      headerName: "Duration",
      //sortable: false,
      width: 100,
    },
    {
      field: "join_url",
      headerName: "Zoom Url",
      //sortable: false,
      width: 400,
      renderCell: (params) => {
        return (
          <div className="cellWithStatus">
            <p>
              Join Url :
              {formValues &&
                formValues.assignee_id ===
                LocalStorageDataService.userDetail().id ? (
                <a
                  href={params.row.join_url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {params.row.join_url}
                </a>
              ) : (
                params.row.join_url
              )}
            </p>
            <p>Meeting Id :{params.row.zoom_meeting_id}</p>
            <p>Password :{params.row.password}</p>
          </div>
        );
      },
    },
    {
      field: "recording_details",
      headerName: "Recording",
      //sortable: false,
      width: 350,
      renderCell: (params) => {
        return (
          <div className="cellWithStatus">
            {params.row.recording_details && params.row.recording_url && (
              <p>
                Url :
                <ReactAudioPlayer
                  src={params.row.recording_url}
                  autoPlay={false}
                  controls
                  style={{ height: "30px" }}
                />
              </p>
            )}
            {params.row.recording_details && params.row.recording_passcode && (
              <p>Passcode :{params.row.recording_passcode}</p>
            )}
          </div>
        );
      },
    },
  ];
  const columnsEmail = [
    { field: 'id', headerName: 'ID', width: 90, filterable: false, hideable: false },
    {
      field: 'from_resource',
      headerName: 'From',
      width: 250,
      renderCell: (params) => {
        return (
          <div className="cellWithCompaniesName">
            {params.row.from_resource ?
              <CustomeTooltip title={params.row.from_resource} data={params.row.from_resource} />
              : "-"
            }
          </div>
        );
      },

    },
    {
      field: 'to_resource',
      headerName: 'To',
      width: 320,
      renderCell: (params) => {
        return (
          <div className={`cellWithStatus`}>
            {/* {params.row.to_resource && JSON.stringify(params.row.to_resource)} */}
            {params.row.to_resource && typeof (params.row.to_resource) === 'string' ? params.row.to_resource : params.row.to_resource.length > 0 ? params.row.to_resource.map((e, index) => `${index ? ', ' : ''}${(e).trim()}`) : ""}

          </div>
        );
      }
    },
    {
      field: 'cc',
      headerName: 'CC',
      width: 320,
      renderCell: (params) => {
        return (
          <div className={`cellWithStatus`}>
            {params.row.cc && params.row.cc.length > 0 && params.row.cc.map((e, index) => e && `${index ? ', ' : ''}${(e).trim()}`)}
          </div>
        );
      }
    },
    {
      field: 'subject',
      headerName: 'Subject',
      width: 200,
    },
    {
      field: 'body',
      headerName: 'Body',
      width: 300,
      renderCell: (params) => {
        return (
          <div className="cellWithCompaniesName">
            {params.row.body ?
              <CustomeTooltip
                title={<span dangerouslySetInnerHTML={{ __html: params.row.body }} />}
                data={<span dangerouslySetInnerHTML={{ __html: (params.row.body).substring(0, 30) + "..." }} />}
              />
              : "-"
            }
          </div>
        );
      }
    },
    {
      field: "email_sent_status", headerName: "Email Sent Status", width: 200,
      renderCell: (params) => {
        return (
          <div className={`cellWithStatus ${params.row.email_sent_status}`}>
            {params.row.email_sent_status === true ? 'Active' : 'Inactive'}
          </div>
        );
      }
    },
  ];

  const setMultipleEmailOption = (email) => {
    if (multipleEmail[email]) {
      delete multipleEmail[email]
      setMultipleEmail({ ...multipleEmail })
    } else {
      setMultipleEmail({ ...multipleEmail, [email]: email })
    }
  }

  const closeEmailModel = () => {
    setShowEmailModel(false)
    setMultipleEmail({})
    // setEmailDetails({ email: "" })
  }

  const changeStartRemindDate = (evt, type) => {
    setStartRemindDate({ ...startRemindDate, [type]: evt })
  }

  const closeReminderModal = () => {
    setReminderLoading(false)
    setActiveRemindModal(false)
    setStartRemindDate({ date: "", time: "", comment: '', default_time_in_mins: 10 })
  }
  const onSubmitReminder = () => {
    if (startRemindDate.comment && startRemindDate.default_time_in_mins && startRemindDate.time && startRemindDate.date) {
      setErrStatus(false)
      let data = {
        "resource": formValues.assignee_id,
        "comment": startRemindDate.comment,
        "notification_date": moment(startRemindDate.date).format('YYYY-MM-DD'),
        "notification_time": moment(startRemindDate.time).format('HH:mm:ss'),
        "default_time_in_mins": startRemindDate.default_time_in_mins,
        "story": epicId,
        "epic": formValues.epic_id ? formValues.epic_id : formValues.job_id,
        "project": formValues.project_id,
        "is_viewed": false,
        "email_sent_status": 0,
        "no_of_time_email_sent": 0,
        "created_by": LocalStorageDataService.userDetail().id
      }
      dispatch(createRemindStory(data))
      setReminderLoading(true)
    } else {
      setErrStatus(true)
    }

  }

  if (status === `${STATUSES.SUCCESS}_createReminder` && reminderLoading === true) {
    if (createReminder.status === 200) {
      toast.success(createReminder.message)
      closeReminderModal()
    } else {
      toast.error(createReminder.message)
    }
    setReminderLoading(false)
  }

  // start add candidate phone number section 
  const addCandidatePhoneNumber = () => {
    dropdownPhoneClose()
    setShowAddPhone(true)
  }

  const handleCloseAddCandidate = () => {
    setShowAddPhone(false)
    setShowAddEmailModal(false)
  }

  const { updateCandidateContactNo, updatePhoneStatus } = useSelector(state => state.callingSlice)

  if (reload === 'submit' && updatePhoneStatus === `${STATUSES.SUCCESS}_updateCandidateContactNo` && updateCandidateContactNo && updateCandidateContactNo.message) {
    if (updateCandidateContactNo.status === 200) {
      dispatch(api(filters))
      let phone_no = updateCandidateContactNo && updateCandidateContactNo.data && updateCandidateContactNo.data.phone_number
      let IDD = updateCandidateContactNo && updateCandidateContactNo.data && updateCandidateContactNo.data.id
      setActiveCallDivs(`true_${epicId}`)
      setPhoneDetails({ destination_number: phone_no, story_id: epicId })
      setPhoneNumberByList(phone_no)
      handleCloseAddCandidate()
      setShowAddCandidatePhoneBtn(false)
      dropdownPhoneClose()

      let contacts = [{ id: IDD, phone_number: phone_no }, { id: "add-new-number", phone_number: "Add new number" },];
      if (formValues && formValues.candidate_phone && formValues.candidate_phone.length > 0) {
        formValues.candidate_phone.map((e) => {
          return contacts.unshift(e)
        })
      }
      setContactNumbers(contacts)

      setAgentCustomerCallObj({
        ...agentCustomerCallObj,
        candidate_phone_no: phone_no,
      });
    } else if (updateCandidateContactNo.status === 400) {
      dispatch(updateCandidateContactNumber('type'))
      toast.error(updateCandidateContactNo.message)
      setActiveClass(false)
      handleCloseAddCandidate()
    } else {

    }
    setReload(false)
  }
  // end add candidate phone number section 

  //start calling section 

  // start call Details section 
  if (callDetailApiLoading === true && status === `${STATUSES.SUCCESS}_callDetailById`) {
    setcallDetailApiLoading(false);
    const callDetailData = callDetailById && callDetailById.data && callDetailById.data.rows[0];
    callDetailData && setCallStatus(callDetailData.status);
    if (callDisconnect === true || (callDetailData && (callDetailData.status === 1 ||
      callDetailData.status === 2 || callDetailData.status === 4 || callDetailData.status === 5 ||
      callDetailData.status === 7 || callDetailData.status === 8 || callDetailData.status === 9))) {
      setcallDetailApiLoading(false);
      setCallDisconnect(false);
      setCallId((prev) => { return ""; });
      setIsCallInitiated(false);
      setCallStatus((prev) => { return null; });
      setActiveClass(false)

    } else {
      setTimeout(() => {
        dispatch(
          getCallLogDetailById(
            `{"filter":{"story_id":{"value":${epicId}, "operation":"eq"},"id":{"value":${callId}, "operation":"eq"}}}`
          )
        );
        setcallDetailApiLoading(true);
      }, 4000);
    }
    setAgentCustomerCallObj({
      ...agentCustomerCallObj,
      call_status: callDetailData && callDetailData.status,
    });
  }


  useEffect(() => {
    if (isCallInitiated === true && callId !== "") {
      dispatch(
        getCallLogDetailById(
          `{"filter":{"story_id":{"value":${epicId}, "operation":"eq"},"id":{"value":${callId}, "operation":"eq"}}}`
        )
      );
      setcallDetailApiLoading(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callId]);
  // end call Details section 

  // start create call  section 
  const { call_Create, createPhoneStatus, error } = useSelector(state => state.callingSlice)

  if (callCreateApiLoading === true && createPhoneStatus === `${STATUSES.SUCCESS}_call_Create` && call_Create && call_Create.message) {
    setCallCreateApiLoading(false)
    setPhoneNumberByList("")
    dispatch(updateCandidateContactNumber('type'))
    setOpenScreeningSection(true)
    if (call_Create.status === 200) {
      toast.success(call_Create.message)
      setCallId(call_Create.data.id)
    } else {
      toast.error(call_Create.message)
      setIsCallInitiated(false)
      setActiveClass(false)
      setJobIdForScrrening(0)
    }
  }
  // end create call  section 

  //error handling call
  if (callCreateApiLoading === true && createPhoneStatus === STATUSES.FAILURE && error) {
    setCallCreateApiLoading(false)
    toast.error(error.message)
    setIsCallInitiated(false)
    setActiveClass(false)
    setPhoneNumberByList("")
    dispatch(updateCandidateContactNumber('type'))
    //screening
    setJobIdForScrrening(0)
  }

  //dropdown calling section
  const callInitiate = (phoneNo) => {
    dropdownPhoneClose();
    let callObject = {
      agent_name: agentCustomerCallObj.agent_name,
      candidate_name: agentCustomerCallObj.candidate_name,
      agent_phone_no: agentCustomerCallObj.agent_phone_no,
      candidate_phone_no: phoneNo,
      call_status: agentCustomerCallObj.call_status,
    }
    setAgentCustomerCallObj(callObject);
    setShow(true)
    setActiveClass(true)
    setCallCreateApiLoading(true)
    setIsCallInitiated(true)
    dispatch(callCreate({ destination_number: phoneNo, story_id: epicId }))
    setStatusDataByList([])
    setStatusNameByList("")
    changeCallActiveStatus()
    setCallStatus(0)
    setPhoneNumberByList("")
  }

  // direct calling section
  if (activeCallDivs && activeCallDivs !== false && callCreateApiLoading === false) {
    setCallCreateApiLoading(true)
    setActiveCallDivs(false)
    setShow(true)
    setActiveClass(true)
    setIsCallInitiated(true)
    setCallStatus(0)
    let details = { destination_number: phoneNumberByList, story_id: epicId }
    dispatch(callCreate(details))
    setTimeout(() => {
      changeCallActiveStatus()
    }, 2000);

  }
  // end calling section 

  // crate ces user for schedule exam
  const createCESUser = async () => {
    dispatch(getBatchList(`{"filter":{"status":{"value":True, "operation":"eq"}},"order_by":{"column":"-id"},"no_limit":'True'}`))
    dispatch(getQuestionLevelList(`{"filter":{"status":{"value":True, "operation":"eq"}},"order_by":{"column":"-id"},"no_limit":'True'}`))
    // dispatch(getTechnologyList(`{"filter":{"status":{"value":True, "operation":"eq"}},"order_by":{"column":"-id"},"no_limit":'True'}`))
    dispatch(getIndustryListCES(`{ "filter": {"status": {"value": "True", "operation": "eq" } },"no_limit": True}`))
    const resquestRole = await dataService.get_candidate_roles(`{"filter":{"role_key":{"value":"candidate", "operation":"eq"}}, "columns":["id"]}`)
    const resultRole = resquestRole.data;
    let data = {
      first_name: formValues.candidate_info && formValues.candidate_info[0] && formValues.candidate_info[0].candidate_name,
      last_name: '',
      tms_role: resultRole && resultRole.data && resultRole.data.rows && resultRole.data.rows[0].id,
      email: formValues.candidate_email && formValues.candidate_email[0] ? formValues.candidate_email[0].email : "",
      contact_no: formValues.candidate_phone && formValues.candidate_phone[0] ? formValues.candidate_phone[0].phone_number : "",
      password: formValues.candidate_email && formValues.candidate_email[0] ? formValues.candidate_email[0].email : "",
      status: true,
      registration_status: 0,
      created_by: ces && ces.id,
      resume_file: null
    }
    let email = formValues.candidate_email && formValues.candidate_email.length > 0 ? formValues.candidate_email[0].email : ""
    let emails = [];
    formValues.candidate_email && formValues.candidate_email.length > 0 && formValues.candidate_email.map(item => emails.push(item.email))
    let contact_no = formValues.candidate_phone && formValues.candidate_phone.length > 0 ? formValues.candidate_phone[0].phone_number : ""
    const resquest = await dataService.get_candidate(JSON.stringify({ "filter": { "email": { "value": emails, "operation": "in" } } }))
    const result = resquest.data
    if (result && result.data && result.data.rows && result.data.rows.length > 0) {
      setCandidateID(result.data.rows[0])
      data['user'] = result.data.rows[0].user_id;
      let candidates = [];
      result.data && result.data.rows && result.data.rows.length > 0 && result.data.rows.map(item => candidates.push(item.id))
      const request = await dataService.get_candidate_exam_schedule_list(`{"filter":{"candidate":{"value":[${candidates}], "operation":"in"}, "schedule_status":{"value":0, "operation":"eq"}}, "column":{"order_by":"-id"}, "no_limit":True}`)
      const examlist = request.data;
      if (examlist.data && examlist.data.rows && examlist.data.rows.length > 0) {
        setCandidateExamList(examlist.data.rows)
      } else {
        setCandidateExamList([])
        const requests = await dataService.get_candidate_exam_result_list(`{"filter":{"candidate":{"value":[${candidates}], "operation":"in"}}, "no_limit":True}`)
        const examlists = requests.data;
        if (examlists.data && examlists.data.rows && examlists.data.rows.length > 0) {
          setExamTabvalue(true)
        }
      }
    } else {
      setCandidateID("")
      setCandidateExamList([])
    }
    if (email === "" || contact_no === "") {
      setActiveExamScheduleModal(true)
    } else if (email && contact_no) {
      setActiveExamScheduleModal(true)
    } else {
      setActiveExamScheduleModal("create")
      dispatch(candidateCreate(data))
    }
    setExamCandidateInfo(data)
  }

  if (createCandidate && createCandidate.status && activeExamScheduleModal === 'create') {
    if (createCandidate.status === 200) {
      setActiveExamScheduleModal(true)
    } else {
      toast.error(createCandidate.message)
      setActiveExamScheduleModal(false)
    }
  }
  const checkExsistNumber = async () => {
    let contact_no = formValues.candidate_phone && formValues.candidate_phone[0] ? formValues.candidate_phone[0].phone_number : ""
    const resquest = await dataService.get_candidate(`{"filter":{"contact_no":{"value":"${contact_no}", "operation":"eq"}}, "columns":["id", "created_by", "user_id"]}`)
    const results = resquest.data;
    if (results.status === 200 && results.data && results.data.rows && results.data.rows.length > 0) {
      toast.error("candidate registration with this contact no already exists.")
    } else {
      toast.error(candidateCreateError.message)
    }
  }
  if (candidateCreateError && activeExamScheduleModal === 'create') {
    //toast.error(candidateCreateError.message)
    setActiveExamScheduleModal(true)
    checkExsistNumber()

  }


  const closeExamScheduleModal = () => {
    setActiveExamScheduleModal(false)
    setCandidateID("")
    setCandidateExamList([])
    setExamCandidateInfo("")
  }

  const nothing = () => { }

  // const handleModelClose = () => {
  //   setModelOpen(false);
  // }
  // const handleModelOpen = (data) => {
  //   setModelOpen(true)
  //   setModelData({
  //     interviewerName: data?.interview_details?.interviewer?.display_name,
  //     candidateName: data?.interview_details?.candidate?.candidate_name,
  //     feedbackByInterviewer: data?.interview_details?.feedback_by_interviewer,
  //     feedbackName: data?.interview_feedback_head?.head_name,
  //     feedbackHead: data?.interview_feedback_head?.description,
  //     ratings: data?.rating_value,
  //     ratingsComment: data?.rating_comment,
  //   })
  // }

  // save schedule data after clicking on start
  const createSchedueforCandidate = async (data) => {
    let candidateData = formValues?.candidate_info
    let candidateMobile = formValues?.candidate_phone
    let candidateEmail = formValues?.candidate_email

    let addvalues = {
      id: data.id,
      story_id: data.story_id,
      ces_set_id: data.ces_set_id,
      ces_schedule_id: data.ces_schedule_id,
      is_for_agent_panel: data.is_for_agent_panel,
      is_screening_test: data.is_screening_test,
      test_type_id: data.test_type_id,
      batch: null,
      exam_schedule: null,
      status: true,
      candidate: {
        name: candidateData[0]?.candidate_name,
        mobile: candidateMobile[0]?.phone_number,
        email: candidateEmail[0]?.email
      }
    }

    if (data.is_for_agent_panel === true) {
      addvalues['scheduled_date'] = moment().format('YYYY-MM-DD')
      addvalues['scheduled_time'] = moment().format('hh:mm:ss')
    } else {
      addvalues['scheduled_date'] = selectedScheduledDate
      addvalues['scheduled_time'] = selectedScheduledTime
    }

    let res;
    let result;
    // let res = await dispatch(saveCandidateScheduleData(addvalues))
    if (data.ces_candidate_schedule_id) {
      res = await dataService.get_qusetion_details_by_id(data.ces_candidate_schedule_id).catch(error => {
        return toast.error(error.message)
      })
      result = await res.data

    } else {
      res = await dataService.save_candidate_schedule_data(addvalues).catch(error => {
        return toast.error(error.message)
      })
      result = await res.data
    }

    if (result?.status === 200) {
      setQuestionData(result)
      if (data?.is_for_agent_panel === true) {
        setRendarQuestion(true)
      } else {
        setSelectedScheduledDate(moment().format('YYYY-MM-DD'))
        setSelectedScheduledTime(null)
      }
      toast.success(result?.message)
      screeningTestApi()
    } else {
      toast.error(result?.message)
      setQuestionData([])
    }

  }

  const submitQuestionData = async (data) => {
    setLoaderExamApi('submit')
    //clearInterval(Ref.current)
    dispatch(completeCandidateExamPost(data))
  }

  if (loaderExamApi === 'submit' && exam_status === `${STATUSES.SUCCESS}_examResultData` && examResultData && examResultData.data) {
    toast.success(examResultData.message)
    setLoaderExamApi(false)
    screeningTestApi()
    setRendarQuestion(false)
    setOpenViewQuestion(false)
    setShowScreeningTest(true)
    setOpenScreenDetails(false)
    setQuestionData([])
    setViewQuestionData([])
    dispatch(getStatusScheduleExam({ exam_schedule_id: examResultData?.data?.candidate_exam_schedule, schedule_status: 7 }))

  }

  if (loaderExamApi === 'submit' && status === `${STATUSES.FAILURE}` && error) {
    setLoaderExamApi(false)
    alert(error.message)
  }

  const viewTestData = async (data, id) => {
    setSkipRowData(data)
    setOpenScreenDetails(false);
    let res;
    let result;
    res = await dataService.exam_get_data(id).catch(error => {
      return toast.error(error.message)
    })
    result = await res.data
    if (result?.status === 200) {
      setViewQuestionData(result)
      setOpenViewQuestion(true)
      toast.success(result?.message)
    } else {
      toast.error(result?.message)
      setViewQuestionData([])
    }
  }

  const skipCallApi = (data) => {
    setOpenSkipModal(true)
    setSkipRowData(data)
  }

  const submitSkipData = async (data) => {
    let Id = skipRowData.id
    let res;
    let result;
    res = await dataService.save_skip_data(Id, data).catch(error => {
      setLoading(false)
      return toast.error(error.message)
    })
    setLoading(false)

    result = await res.data
    if (result?.status === 200) {
      toast.success(result?.message)
      screeningTestApi()
      setShowScreeningTest(true)

      setTimeout(() => {
        setOpenSkipModal(false)
        setSkipRowData([])
        setSkipFormValues({
          email: "",
          password: "",
          remark: "",
        })
      }, 1000);
    } else {
      toast.error(result?.message)
      setViewQuestionData([])
    }
  }
  // screening test section end

  // add email section start

  const { updateCandidateEmailId, updateEmailStatus } = useSelector(state => state.emailSlice)

  if (reload === 'email_submit' && updateEmailStatus === `${STATUSES.SUCCESS}_updateCandidateEmailId` && updateCandidateEmailId && updateCandidateEmailId.message) {
    if (updateCandidateEmailId.status === 200) {
      dispatch(api(filters))
      let email = updateCandidateEmailId && updateCandidateEmailId.data && updateCandidateEmailId.data.email
      setEmailDetails({ email: email })
      toast.success(updateCandidateEmailId.message)
      handleCloseAddCandidate()
      setShowAddEmailBtn(false)
      setApiLoading(true);
      dispatch(taskViewById(epicId));
      dispatch(getTaskHistory(epicId));
    } else if (updateCandidateEmailId.status === 400) {
      dispatch(updateCandidateEmail('type'))
      toast.error(updateCandidateEmailId.message)
      handleCloseAddCandidate()
    } else {

    }
    setReload(false)
  }
  // add email section end

  return (
    <div className="ss-datatable style-table" style={{ marginTop: '32px' }}>
      {/* <ToastContainer /> */}

      <div className="row">


        <div className="col ss-top-icon" style={{ textAlign: "end" }}>
          {LocalStorageDataService.userDetail().agent_id && formValues && (formValues.assignee_id === LocalStorageDataService.userDetail().id) ?
            <>
              {/* add candidate phone_no section */}
              {jobApproverStatus === 4 && isCallInitiated === false && showAddCandidatePhoneBtn === true && (formValues && formValues.candidate_phone && formValues.candidate_phone.length === 0) &&
                <Tooltip title="Call">
                  <Button
                    style={{
                      marginTop: "3px",
                      backgroundColor: "orangered",
                      color: "#fff",
                    }}
                    onClick={() => addCandidatePhoneNumber(formValues)}
                  >
                    <Phone /></Button>
                </Tooltip>
              }

              {/* calling section start */}

              {jobApproverStatus === 4 && isCallInitiated === false && contactNumbers.length > 0 && (
                <Button id="phone"
                  style={{
                    marginTop: "3px",
                    backgroundColor: "green",
                    color: "#fff",
                    height: '30px'
                  }}
                  ref={phoneBtnRef}
                  onClick={dropdownPhoneClick}
                  disabled={isCallInitiated === true ? true : false}
                >
                  <div className="phoneicon">

                    <Phone
                      style={{ marginLeft: '0px', marginTop: '6px' }}
                      size="small"
                      sx={{ ml: 2 }}
                      aria-controls={open ? 'message-menu' : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? 'true' : undefined} />
                    <KeyboardArrowDown />
                  </div>
                </Button>
              )}

              {/* <Menu
                anchorEl={anchorEl2}
                style={isCallInitiated === true ? { display: 'none' } : {}}
                className="MuiMenu-list-dropdown"
                id="message-menu"
                open={openphone}
                onClose={dropdownPhoneClose}
                onClick={dropdownPhoneClose}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: "visible",
                    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                    mt: 1.5,

                    "& .MuiAvatar-root": {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                    "&:before": {
                      content: '""',
                      display: "block",
                      position: "absolute",
                      top: 0,
                      right: 14,
                      width: 10,
                      height: 10,
                      bgcolor: "background.paper",
                      transform: "translateY(-50%) rotate(45deg)",
                      zIndex: 0,
                    },
                  },
                }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
              >
                {
                  contactNumbers.length > 0 && contactNumbers.map((option, i) => {
                    return (
                      <MenuItem
                        onClick={() => option.id === 'add-new-number' ? setShowAddPhone(true) : callInitiate(option.phone_number)}
                        key={option.id}>
                        <span className="notification-head">
                          <span className="ss-noti">{option.id === 'add-new-number' ? <AddRounded /> : <LocalPhoneRounded />}</span>
                          <span> {option.phone_number}</span>
                        </span>
                      </MenuItem>
                      

                    )
                  })
                }
              </Menu> */}

              <Popper
                open={openphone}
                style={{ zIndex: 9999 }}
                anchorEl={phoneBtnRef.current}
                role={undefined}
                placement="bottom-start"
                transition
                disablePortal
              >
                {({ TransitionProps, placement }) => (
                  <Grow {...TransitionProps}>
                    <Paper>
                      <ClickAwayListener
                        onClickAway={dropdownPhoneClose}>
                        <MenuList
                          autoFocusItem={open}
                          id="composition-menu"
                          aria-labelledby="composition-button"
                        >
                          {
                            contactNumbers.length > 0 && contactNumbers.map((option, i) => {
                              return (
                                <MenuItem
                                  onClick={() => option.id === 'add-new-number' ? [dropdownPhoneClose(), setShowAddPhone(true)] : callInitiate(option.phone_number)}
                                  key={option.id}>
                                  <span className="notification-head">
                                    <button className="ss-noti-newmail-icon">{option.id === 'add-new-number' ? <AddRounded /> : <LocalPhoneRounded />}</button>
                                    <span> {option.phone_number}</span>
                                  </span>
                                </MenuItem>

                              )
                            })
                          }
                        </MenuList>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper>

              {/* email section start */}
              {jobApproverStatus === 4 && showAddEmailBtn === true && (formValues && formValues.candidate_email && formValues.candidate_email.length === 0) &&
                <Tooltip title="Email">
                  <Button
                    style={{
                      marginTop: "3px",
                      backgroundColor: (disabledEmail === true) ? '#606266' : "orangered",
                      color: "#fff",
                    }}
                    onClick={() => setShowAddEmailModal(true)}
                  >
                    <Mail /></Button>
                </Tooltip>
              }

              {jobApproverStatus === 4 && defaultActivemail === false && formValues &&
                formValues.candidate_email && formValues.candidate_email.length > 1 &&
                <Button id="email"
                  style={{
                    marginTop: "3px",
                    backgroundColor: (disabledEmail === true) ? '#606266' : "green",
                    color: "#fff", height: '30px'
                  }}
                  disabled={disabledEmail === true ? true : false}
                  onClick={dropdownClick}
                  ref={emailBtnRef}
                >
                  <div className="phoneicon">
                    <Mail
                      style={{ marginLeft: '0px', marginTop: '6px' }}
                      size="small"
                      sx={{ ml: 2 }}
                      aria-controls={open ? 'message-menu' : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? 'true' : undefined} />
                    <KeyboardArrowDown />
                  </div>
                </Button>
              }
              {jobApproverStatus === 4 && emailDetails.email && defaultActivemail === true && (
                <Tooltip title={`Mail - ${emailDetails.email}`}>
                  <Button
                    disabled={disabledEmail === true ? true : false}
                    onClick={() => setShowEmailModel(true)}
                    className=""
                    style={{
                      marginTop: "3px", marginLeft: "3px",
                      backgroundColor: (disabledEmail === true) ? '#606266' : "green",
                      color: "#fff"
                    }}

                  >
                    <Mail />
                  </Button>
                </Tooltip>
              )}
              {showEmailModel && (
                <EmailTemplet
                  setShowEmailModel={closeEmailModel}
                  to_email={Object.keys(multipleEmail).length > 0 ? Object.values(multipleEmail) : emailDetails.email}
                  project_id={
                    formValues && formValues.project && formValues.project.id
                  }
                  data={formValues}
                  story_id={epicId}
                />
              )}

              {/* <Menu
                anchorEl={anchorEl1}
                className="MuiMenu-list-dropdown"
                id="message-menu"
                open={openmessage}
                onClose={dropdownClose}
                //onClick={dropdownClose}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
              >
                {
                  formValues.candidate_email && formValues.candidate_email.length && formValues.candidate_email.map((option, i) => {
                    return (
                      <MenuItem >
                        <span className="notification-head">
                          <Checkbox checked={multipleEmail[option.email] === option.email ? true : false} onChange={(evt) => { setMultipleEmailOption(option.email) }} />
                          <span className="ss-noti">< Mail onClick={() => { changeEmail(option.email) }} /></span>
                          <span onClick={() => changeEmail(option.email)}> {option.email}</span>
                        </span>
                      </MenuItem>
                    )
                  })
                }
              </Menu> */}

              <Popper
                open={openmessage}
                style={{ zIndex: 9999 }}
                anchorEl={emailBtnRef.current}
                role={undefined}
                placement="bottom-start"
                transition
                disablePortal
              >
                {({ TransitionProps, placement }) => (
                  <Grow {...TransitionProps}>
                    <Paper>
                      <ClickAwayListener
                        onClickAway={dropdownClose}>
                        <MenuList
                          autoFocusItem={openmessage}
                          id="composition-menu"
                          aria-labelledby="composition-button"
                        >
                          {
                            formValues.candidate_email && formValues.candidate_email.length && formValues.candidate_email.map((option, i) => {
                              return (
                                <MenuItem >
                                  <span className="notification-head">
                                    <Checkbox checked={multipleEmail[option.email] === option.email ? true : false} onChange={(evt) => { setMultipleEmailOption(option.email) }} />
                                    <button className="ss-noti-newmail-icon">< Mail onClick={() => { changeEmail(option.email) }} /></button>
                                    <span onClick={() => changeEmail(option.email)}> {option.email}</span>
                                  </span>
                                </MenuItem>
                              )
                            })
                          }
                        </MenuList>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper>
              {/* email section end */}

              <Tooltip title="Comment">
                <Button
                  style={{
                    marginTop: "3px",
                    backgroundColor: "#0052cc",
                    color: "#fff",
                    height: '30px',
                    alignItems: 'flex-start'
                  }}
                >
                  <a
                    style={{ color: "#fff" }}
                    href='#ss-comment-scroll'
                    onClick={() => {
                      handleChange("tset", 0);
                      setShow(false);
                      setCommentActive(true);
                    }}
                  >
                    <InsertCommentIcon />
                  </a>
                </Button>
              </Tooltip>

              {jobApproverStatus === 4 &&
                <Tooltip title="set reminder">
                  <Button
                    style={{
                      marginTop: "3px",
                      backgroundColor: (disabledEmail === true) ? '#606266' : "#0052cc",
                      color: "#fff",
                    }}
                    disabled={disabledEmail === true ? true : false}
                    onClick={() => setActiveRemindModal(true)}
                  ><InsertInvitation /></Button>
                </Tooltip>
              }
              {/* {formValues && formValues.candidate_email && formValues.candidate_email.length > 0 && */}
              {jobApproverStatus === 4 &&
                <Tooltip title="Exam Schedule">
                  <Button
                    style={{
                      marginTop: "3px",
                      backgroundColor: ((activeExamScheduleModal === 'create' || activeExamScheduleModal === true) || disabledEmail === true) ? '#606266' : "#0052cc",
                      color: "#fff",
                    }}
                    disabled={disabledEmail === true ? true : false}
                    onClick={() => ((activeExamScheduleModal === 'create' || activeExamScheduleModal === true) || disabledEmail === true) ? nothing() : createCESUser()}
                  ><CoPresent /></Button>
                </Tooltip>
              }
              {/* } */}
            </>
            :
            formValues && (formValues.assignee_id === LocalStorageDataService.userDetail().id) &&
            <>
              <Tooltip title="Comment">
                <Button
                  style={{
                    marginTop: "3px",
                    backgroundColor: "#0052cc",
                    color: "#fff",
                    height: '30px',
                    alignItems: 'flex-start'
                  }}
                >
                  <a
                    style={{ color: "#fff" }}
                    href='#ss-comment-scroll'
                    onClick={() => {
                      handleChange("tset", 0);
                      setShow(false);
                      setCommentActive(true);
                    }}
                  >
                    <InsertCommentIcon />
                  </a>
                </Button>
              </Tooltip>
              {
                jobApproverStatus === 4 &&
                <>
                  <Tooltip title="set reminder">
                    <Button
                      style={{
                        marginTop: "3px",
                        backgroundColor: (disabledEmail === true) ? '#606266' : "#0052cc",
                        color: "#fff",
                      }}
                      disabled={disabledEmail === true ? true : false}
                      onClick={() => setActiveRemindModal(true)}
                    ><InsertInvitation /></Button>
                  </Tooltip>

                  <Tooltip title="Exam Schedule">
                    <Button
                      style={{
                        marginTop: "3px",
                        backgroundColor: ((activeExamScheduleModal === 'create' || activeExamScheduleModal === true) || disabledEmail === true) ? '#606266' : "#0052cc",
                        color: "#fff",
                      }}
                      disabled={disabledEmail === true ? true : false}
                      onClick={() => ((activeExamScheduleModal === 'create' || activeExamScheduleModal === true) || disabledEmail === true) ? nothing() : createCESUser()}
                    ><CoPresent /></Button>
                  </Tooltip>
                </>
              }
            </>

          }


          {isCallInitiated !== true && <Tooltip title="Close">
            <Button
              style={{
                marginTop: "3px",
                backgroundColor: "rgb(201 201 201)",
                color: "rgb(110 110 110)"
              }}
              onClick={() => hideDiv(false)}
            >
              <Close />
            </Button>
          </Tooltip>}


          {isCallInitiated !== true && !activeClass === true ? (
            <Tooltip title="Zoom Out">
              <Button
                style={{
                  marginTop: "3px",
                  backgroundColor: "rgb(201 201 201)",
                  color: "rgb(110 110 110)"
                }}
                onClick={() => setActiveClass(!activeClass)}
              >
                <ZoomOutMap />
              </Button>
            </Tooltip>
          ) : (
            isCallInitiated !== true && <Tooltip title="Zoom In">
              <Button
                style={{
                  marginTop: "3px",
                  backgroundColor: "rgb(201 201 201)",
                  color: "rgb(110 110 110)"
                }}
                onClick={() => setActiveClass(!activeClass)}
              >
                <ZoomInMap />
              </Button>
            </Tooltip>
          )}

        </div>



      </div>
      <div className="top-headings">
        {/* <h3>Story Task</h3> */}
        <div>
          {formValues &&
            formValues.project &&
            formValues.project.project_name ? (
            <TaskBreadCrumb formValues={formValues} />
          ) : (
            <p>Project</p>
          )}

          <button style={{ borderWidth: 0, backgroundColor: "#fff" }}>
            <h3 className="story-epic-summary">
              {formValues && formValues.summary}
            </h3>
          </button>
        </div>
        {apiLoading === true && <LoaderForm />}
      </div>

      <div className="row">
        <Tooltip title={!show ? "Open Right Side Details" : "Close Right Side Details"}>
          <span style={{ float: "right" }}>
            <IconButton className="ss-arrowicon"
              onClick={() => {
                setShow(!show);
              }}
              aria-label="delete"
            >
              {!show === true ? (
                <KeyboardArrowLeft />
              ) : (
                <KeyboardArrowRight />
              )}
            </IconButton>
          </span>
        </Tooltip>
      </div>

      <div className="edit-details-row ss-detail-row">

        <div
          className={`edit-details-col-left ${show ? "show-hide-left-side-colum2" : ""
            }`}
        >
          <div className="left-description">
            {domain_data === true && formValues && formValues.show_resume === true ? '' : <h6>Description</h6>}
            {domain_data === false ?
              <p style={{ marginBottom: 20 }}>
                {" "}
                {formValues && formValues.discription &&
                  <span dangerouslySetInnerHTML={{ __html: formValues && descriptionType }} />}
              </p>
              :
              formValues && formValues.show_resume && formValues.show_resume === true ?
                <p style={{ marginBottom: 20 }}>

                  {attachments.length > 0 &&
                    attachments.map(e => {
                      return (
                        e.type === 'doc' ?
                          <iframe className="google-docs iframe" src={`https://docs.google.com/viewer?url=${e.uri}&embedded=true`} frameborder="0"
                            scrolling="no" marginHeight="0" marginWidth="0" role="document" aria-label="PDF document" title="PDF document" style={{ borderStyle: 'none', width: '100%', height: '800px' }}></iframe>
                          :
                          <FileViewer fileType={e.type} filePath={e.uri} />
                      )
                    })

                  }
                  {/* {attachments.length > 0 &&
                    <DocViewer
                      pluginRenderers={DocViewerRenderers}
                      documents={attachments}
                      style={{ height: 800, width: "100%" }}
                      config={{
                        header: {
                          disableHeader: true,
                          disableFileName: false,
                          retainURLParams: false,
                        }
                      }}
                    />
                  } */}
                </p>
                :
                formValues && formValues.description &&
                <p style={{ marginBottom: 20 }}>
                  {" "}
                  <span dangerouslySetInnerHTML={{ __html: formValues && descriptionType }} />
                </p>
            }
            {/* {formValues?.description ? <RichTextEditor data={JSON.parse(formValues.description )} /> : ""} */}
          </div>

          {formValues && formValues.assignee_id ===
            LocalStorageDataService.userDetail().id && (
              <h4 style={{ display: "flex" }}>
                Attachments(
                {formValues &&
                  formValues.attachments &&
                  formValues.attachments.length}

                )
                <div className="">
                  <div className="file-attchment-icon-btn">
                    <input
                      id="fileUpload"
                      ref={uploadInputRef}
                      type="file"
                      multiple
                      // accept='application/pdf, image/png,image/jpeg'
                      style={{ display: "none" }}
                      onChange={onFileChange}
                      name="file"
                    />
                    <Tooltip title="Add Attachment">
                      <span style={{ cursor: "pointer" }}>
                        <AttachFile
                          className={`attach_icon`}
                          onClick={() =>
                            uploadInputRef.current &&
                            uploadInputRef.current.click()
                          }
                        />
                      </span>
                    </Tooltip>
                  </div>
                </div>
              </h4>
            )}
          {formValues && formValues.assignee_id ===
            LocalStorageDataService.userDetail().id && (
              <div className="attchment-all-top-list">
                {formValues &&
                  formValues.attachments &&
                  formValues.attachments.length > 0 ? (
                  formValues.attachments.map((item, i) => {
                    return (
                      <div key={i} className="attchment-name-image">
                        <Tooltip title={item.file_name}>
                          <div className="attchment-file-name">
                            {item.file_name}
                          </div>
                        </Tooltip>
                        <div className="action-attechment-btn">
                          <Tooltip title="Download">
                            <Download
                              className="download-icon"
                              onClick={() => fileDownload(item.api_url)}
                            />
                          </Tooltip>
                          {/* <DeleteOutline className="delete-icon" /> */}
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <p>No Attachment</p>
                )}
              </div>
            )}

          {formValues && formValues.assignee_id === LocalStorageDataService.userDetail().id && <h4>Activity:</h4>}
          {formValues && formValues.assignee_id === LocalStorageDataService.userDetail().id && (
            <div className="left-description">
              <div className="epik-tabs">
                <Tabs
                  value={value}
                  onChange={handleChange}
                  variant="scrollable"
                  scrollButtons={false}
                  aria-label="scrollable prevent tabs example"
                >
                  <Tab label="Comments" />
                  <Tab label="History" />
                  <Tab label="Call Log" />
                  <Tab label="Email Log" />
                  <Tab label="Zoom Meeting Log" />
                </Tabs>

                <TabPanel
                  value={value}
                  index={0}
                  className="custom-loader-tabs"
                  id="ss-comment-scroll"
                >
                  {activeTab === 0 && <LoaderForm />}
                  <div className="bottom-comments-row">
                    <div className="bottom-commrnt-col-left">
                      <span style={{ textTransform: "capitalize" }}>
                        <Avatar
                          {...stringAvatar(
                            LocalStorageDataService.userDetail().display_name
                          )}
                        />
                      </span>
                    </div>
                    {commentActive === false && (
                      <div className="bottom-commrnt-col-right">
                        <input
                          type="text"
                          className="comment-single-text-box"
                          placeholder="Add a Comment..."
                          onClick={() => setCommentActive(true)}
                        />
                        {/* <p style={{ marginBottom: 20 }} ><span dangerouslySetInnerHTML={{ __html: formValues.comment }} /></p> */}
                      </div>
                    )}
                    {commentActive === true && (
                      <div className="bottom-commrnt-col-right">
                        <CKEditor
                          name="comment"
                          initData={comment}
                          config={{
                            toolbar: [
                              ["Source"],
                              ["Styles", "Format", "Font", "FontSize"],
                              ["Bold", "Italic"],
                              ["Undo", "Redo"],
                              ["EasyImageUpload"],
                            ],
                          }}
                          onChange={(e) => inputHandler(e)}
                        />
                        <button
                          onClick={() => editCommentChanges("comment")}
                          className="save-btn"
                        >
                          Save
                        </button>
                        <button
                          onClick={() => {
                            setCommentActive(false);
                            setComment("");
                          }}
                          className="cancel-btn"
                        >
                          Cancel
                        </button>
                      </div>
                    )}
                  </div>

                  {commentById && commentById.data && commentById.data.rows && commentById.data.rows.length > 0 &&
                    commentById.data.rows.map((item, index) => {
                      return (
                        <div className="bottom-comments-row">
                          <div className="bottom-commrnt-col-left">
                            {item.created_resource &&
                              item.created_resource.display_name && (
                                <span style={{ textTransform: "capitalize" }}>
                                  <Avatar
                                    {...stringAvatar(
                                      item.created_resource.display_name
                                    )}
                                  />
                                </span>
                              )}
                          </div>
                          <div className="bottom-commrnt-col-right">
                            {item.created_resource && item.created_resource.display_name && (
                              <h6 style={{ textTransform: "capitalize" }}>
                                {item.created_resource.display_name}
                                {/* {item.rtms_issue && item.rtms_issue && item.rtms_issue.assignee && item.rtms_issue.assignee &&
                                                                    <span>{item.rtms_issue.assignee && calculateDays(item.rtms_issue.assignee.created_on)}</span>
                                                                } */}
                              </h6>
                            )}
                            {/* <a href={item.comment_url}>Commit Link : {item.comment_url}</a> */}
                            <p>
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: makeUrlsClickable(item.body && item.body),
                                }}
                              />
                            </p>
                            <p>
                              Comment Date :{" "}
                              {item &&
                                moment(item.created_on).format(
                                  "YYYY-MM-DD"
                                )}
                            </p>
                          </div>
                        </div>
                      );
                    })}
                </TabPanel>

                {/* history section start */}
                <TabPanel value={value} index={1} className="custom-loader-tabs"  >
                  {activeTab === 1 && <LoaderForm />}
                  {taskHistoryList && taskHistoryList.data && taskHistoryList.data.rows && taskHistoryList.data.rows.length > 0 ?
                    taskHistoryList.data.rows.map((item, index) => {
                      return (
                        <div key={index} className="bottom-comments-row">
                          <div className="bottom-commrnt-col-left">
                            {item.assigned_by_resource_name && (
                              <span>  <Avatar
                                {...stringAvatar(
                                  item?.assigned_by_resource_name
                                )}
                              />
                              </span>
                            )}
                          </div>

                          <div className="bottom-commrnt-col-right">
                            <p><b>{item.assigned_by_resource_name} </b>  &nbsp; changed the &nbsp;
                              <b>{item.change_type === `description` ? ' Description '
                                : item.change_type === 'assignee' ? ' Assignee '
                                  : item.change_type === 'status' ? ' Status '
                                    : item.change_type === 'email' ? ' Email '
                                      : item.change_type === 'phone' ? ' Phone '
                                        : item.change_type === 'schedule' ? ' Schedule '
                                          : ""
                              }
                              </b>&nbsp;
                              <span>
                                {item.created_on &&
                                  moment(item.created_on).format(
                                    "YYYY-MM-DD hh:mm"
                                  )}
                              </span>
                            </p>

                            {item.change_type === `description` ?
                              <div className="col-assignee-history-1">
                                <h5 className="assignee">{item.assigned_by_resource_name}</h5>
                              </div>
                              : item.change_type === 'assignee' ?
                                <div className="row-assignnee-history">
                                  <div className="col-assignee-history-1">
                                    <Tooltip title={item?.assigned_by_resource_name}>
                                      <span>
                                        {item.assigned_by_resource_name ? (
                                          <Avatar
                                            {...stringAvatar(
                                              item?.assigned_by_resource_name
                                            )}
                                          />
                                        ) : (<Avatar
                                          {...stringAvatar(
                                            'Unassigne'
                                          )}
                                        />)
                                        }
                                        {item?.assigned_by_resource_name ? item?.assigned_by_resource_name : 'Unassigne'}
                                      </span>
                                    </Tooltip>
                                  </div>

                                  <div className="col-assignee-history-2">
                                    <ArrowRightAltIcon />
                                  </div>

                                  <div className="col-assignee-history-1">
                                    <Tooltip title={item?.assigned_to_resource_name}>
                                      <span>
                                        {item.assigned_to_resource_name ? (
                                          <Avatar
                                            {...stringAvatar(
                                              item?.assigned_to_resource_name
                                            )}
                                          />
                                        ) : (<Avatar
                                          {...stringAvatar(
                                            'Unassigne'
                                          )}
                                        />)}
                                        {item?.assigned_to_resource_name ? item?.assigned_to_resource_name : 'Unassigne'}
                                      </span>
                                    </Tooltip>
                                  </div>
                                </div>
                                : item.change_type === 'status' ?
                                  <div className="row-assignnee-history">

                                    <div className="col-assignee-history-1">
                                      <h5 className="assignee">{item.assign_from_status_name}</h5>
                                    </div>

                                    <div className="col-assignee-history-2">
                                      <ArrowRightAltIcon />
                                    </div>

                                    <div className="col-assignee-history-1">
                                      <h5 className="assignee">{item.assign_to_status_name}</h5>
                                    </div>
                                  </div>
                                  : item.change_type === 'email' ?
                                    <div className="row-assignnee-history">

                                      <div className="col-assignee-history-1">
                                        <h5 className="assignee">{item.assigned_by_resource_email}</h5>
                                      </div>

                                      <div className="col-assignee-history-2">
                                        <ArrowRightAltIcon />
                                      </div>

                                      <div className="col-assignee-history-1">
                                        <h5 className="assignee">{item.assigned_to_resource_email}</h5>
                                      </div>
                                    </div>
                                    : item.change_type === 'schedule' ?
                                      <div className="row-assignnee-history">
                                        <div className="">
                                          <p className=""><b style={{ color: "#6c6b6b" }}>Test Name</b> :- {item.test_name}</p>
                                          <p className=""><b style={{ color: "#6c6b6b" }}>Result </b> :- {item.is_passed === true ? 'Pass' : 'Fail'}</p>
                                        </div>
                                      </div>
                                      : ""
                            }

                          </div>
                        </div>
                      );
                    }) : <p>No history found !</p>
                  }
                </TabPanel>
                {/* history section end */}

                <TabPanel value={value} index={2} className="custom-loader-tabs">
                  {/* {activeTab === 2 && <LoaderForm />} */}
                  <p className="related-stories-head">Call Log</p>
                  <DefaultDataGrid
                    style={{ marginTop: "20px", height: "unset" }}
                    list={(callLogDetailList && callLogDetailList.data && callLogDetailList.data.rows) || []}
                    count={(callLogDetailList && callLogDetailList.data && callLogDetailList.data.count) || 0}
                    columns={columnsCall}
                    defaultApi={getCallLogDetails}
                    loading={defaultLoader}
                    defaultFilter={{ "filter": { "story_id": { "value": epicId, "operation": "eq" } }, "order_by": { "column": "-id" }, "skip": 0, "limit": 25 }}
                  />

                </TabPanel>
                <TabPanel value={value} index={3} className="custom-loader-tabs">
                  {/* {activeTab === 3 && <LoaderForm />} */}
                  <p className="related-stories-head">Email Log</p>
                  <DefaultDataGrid
                    style={{ marginTop: "20px", height: "unset" }}
                    list={(emailLogDetailList && emailLogDetailList.data && emailLogDetailList.data.rows) || []}
                    count={(emailLogDetailList && emailLogDetailList.data && emailLogDetailList.data.count) || 0}
                    columns={columnsEmail}
                    defaultApi={getEmailLogsDetails}
                    loading={defaultLoader}
                    defaultFilter={{ "filter": { "jira_rtms_issue": { "value": epicId, "operation": "eq" } }, "order_by": { "column": "-id" }, "skip": 0, "limit": 25 }}
                  />
                </TabPanel>

                <TabPanel value={value} index={4} className="custom-loader-tabs">
                  {activeTab === 4 && <LoaderForm />}
                  <p className="related-stories-head">Zoom Meeting Log</p>
                  <DefaultDataGrid
                    style={{ marginTop: "20px", height: "unset" }}
                    list={(zoomMeetingDetailList && zoomMeetingDetailList.data && zoomMeetingDetailList.data.rows) || []}
                    count={(zoomMeetingDetailList && zoomMeetingDetailList.data && zoomMeetingDetailList.data.count) || 0}
                    columns={columnsZoom}
                    defaultApi={getZoomMeetingDetails}
                    loading={defaultLoader}
                    defaultFilter={{ "filter": { "story_id": { "value": epicId, "operation": "eq" } }, "order_by": { "column": "-id" }, "skip": 0, "limit": 25 }}
                  />

                </TabPanel>

              </div>
            </div>
          )}
        </div>

        <div className={`edit-details-col-right  ${show ? "show-hide-right-side-colum1 ss-task-summry-right" : ""}`} style={{ marginTop: "16px" }} >
          {isCallInitiated && (
            <PhoneCalling
              callDetail={agentCustomerCallObj}
              callId={callId}
              callStatus={callStatus}
              isCallInitiated={isCallInitiated}
              setCallDisconnect={setCallDisconnect}
              phoneNumberByList={phoneNumberByList}
            />
          )}

          {/* Default screening question details section start */}

          <div className="add-new-user-form-default">
            <div className="formInput">
              <div style={{ marginBottom: "14px" }} onClick={() => {
                setOpenScreeningSection(!openScreeningSection);
              }}><h6 className="screening-question-heading">Screening Data</h6>
                <Tooltip title={!openScreeningSection ? "View screening questions" : "Close View screening questions"}>
                  <IconButton style={{ float: "right", marginTop: "-2px" }}>
                    {!openScreeningSection === true ? (
                      <KeyboardArrowDown />
                    ) : (
                      <KeyboardArrowUp />
                    )}
                  </IconButton>
                </Tooltip>
              </div>
              <hr />
            </div>
          </div>
          {
            openScreeningSection === true ?
              <DefaultScreeningForm
                title='story'
                editStoryScreeningData={editStoryScreeningData}
                storyData={formValues}
                questionData={questionsData}
                setOpenScreeningSection={setOpenScreeningSection}
                openScreeningSection={openScreeningSection}
                setQuetionsData={setQuetionsData}
                storyId={epicId}
                screeningId={screeningId}
                setScreeningId={setScreeningId}
                otherQuestion={otherQuestion}
                setOtherQuestion={setOtherQuestion}
                loadingScreeningData={loadingScreeningData}
                screeningCommentData={screeningCommentData}
                setScreeningCommentData={setScreeningCommentData}
              />
              : ""
          }

          {/* Default screening qusestion details section end */}

          {/* screening test section start */}

          {formValues && formValues.candidate_email && formValues.candidate_email.length > 0
            && contactNumbers && contactNumbers.length > 0 &&

            <div className="add-new-user-form-default">
              <div className="formInput">
                <div style={{ marginBottom: "14px" }} onClick={() => {
                  setShowScreeningTest(!showScreeningTest);
                  setOpenScreenDetails(false)
                  setRendarQuestion(false)
                  setOpenViewQuestion(false)
                }}><h6 className="screening-question-heading">Screening Test </h6>
                  <Tooltip title={!showScreeningTest ? "View Screening Test" : "Close Screening Test"}>
                    <IconButton style={{ float: "right", marginTop: "-2px" }}>
                      {!showScreeningTest === true ? (
                        <KeyboardArrowDown />
                      ) : (
                        <KeyboardArrowUp />
                      )}
                    </IconButton>
                  </Tooltip>
                </div>
                <hr />
              </div>
            </div>
          }

          {showScreeningTest === true ?
            <div style={{ marginBottom: "5px" }}>
              {
                rendarQuestion === true ?
                  <ScreeningQuestion
                    questionData={questionData}
                    submitQuestionData={submitQuestionData}
                  />
                  : openViewQueetion === true ?
                    <ViewScreeningTest
                      viewQuestionData={viewQuestionData}
                      setOpenViewQuestion={setOpenViewQuestion}
                      setOpenScreenDetails={setOpenScreenDetails}
                      skipRowData={skipRowData}
                    /> :
                    <table style={{ width: "100%" }}>
                      <thead></thead>
                      <tbody>
                        {
                          screeningTestData && screeningTestData.length > 0 ? screeningTestData?.map((item, i) => {
                            return <>
                              <tr key={i}>
                                <td style={{ fontSize: "18px !important", cursor: "pointer !important" }}>
                                  {i === 0 ?
                                    <span onClick={() => {
                                      setOpenScreenDetailsIndex(!openScreenDetailsIndex)
                                      setOpenScreenDetails({ [i]: { value: !openScreenDetailsIndex } })
                                      setSelectedScheduledDate(moment().format('YYYY-MM-DD'))
                                      setSelectedScheduledTime(null)
                                      setRendarQuestion(false)

                                      item.is_for_agent_panel === true && item.hiring_story_screenflow_status === 2 && viewTestData(item, item.ces_candidate_schedule_id)
                                      item.is_for_agent_panel === true && item.ces_candidate_schedule_id !== null && item.hiring_story_screenflow_status === 3 && item.obtained_marks_percent !== null && viewTestData(item, item.ces_candidate_schedule_id)

                                    }}>
                                      <b>{item?.test_name ? item?.test_name?.substring(0, 60) + "..." : "N/A"}</b><br />
                                      {item.is_for_agent_panel === true ? "(Managed by agent)" : ""}
                                    </span>
                                    :
                                    <span onClick={() => {
                                      i > 0 && ((screeningTestData[i - 1].hiring_story_screenflow_status === 2 && screeningTestData[i - 1].is_passed === true) || screeningTestData[i - 1].hiring_story_screenflow_status === 3) &&
                                        setOpenScreenDetails({ [i]: { value: !openScreenDetailsIndex } })

                                      setOpenScreenDetailsIndex(!openScreenDetailsIndex)
                                      setSelectedScheduledDate(moment().format('YYYY-MM-DD'))
                                      setSelectedScheduledTime(null)
                                      setRendarQuestion(false)
                                      item.is_for_agent_panel === true && item.hiring_story_screenflow_status === 2 && viewTestData(item.ces_candidate_schedule_id)
                                      // item.is_for_agent_panel === false && item.ces_candidate_schedule_id !== null && item.hiring_story_screenflow_status === 2 && viewTestData(item.ces_candidate_schedule_id)

                                    }}>
                                      <b>{item?.test_name ? item?.test_name?.substring(0, 60) + "..." : "N/A"}</b><br />
                                      {item.is_for_agent_panel === true ? "(Managed by agent)" : ""}
                                    </span>
                                  }

                                </td>

                                <td>
                                  <Tooltip title={item.is_passed === true ? "Pass" : "Fail"}>
                                    <span style={item.is_passed === true ? { color: "green" } : { color: "red" }}>
                                      {item.obtained_marks_percent ?
                                        item.is_passed === true ? " Pass " : " Fail " : " "}
                                    </span>&nbsp;&nbsp;&nbsp;&nbsp;

                                    <span style={item.is_passed === true ? { color: "green" } : { color: "red" }}>
                                      {item.obtained_marks_percent ? item.obtained_marks_percent + "%" : " "} </span>
                                  </Tooltip>
                                  &nbsp;&nbsp;&nbsp;&nbsp;

                                  <span style={item.is_passed === true ? { color: "green" } : { color: "red" }}
                                    onClick={() => skipCallApi(item)}
                                  >
                                    {item.is_passed === false && item.hiring_story_screenflow_status === 2 ? " Skip " : " "}
                                  </span>

                                  {/* for not managed by agent */}
                                  {item.is_for_agent_panel === false && item.ces_candidate_schedule_id !== null ?
                                    <span
                                      style={item.hiring_story_screenflow_status === 0 ? { color: 'skyblue' } :
                                        item.hiring_story_screenflow_status === 1 ? { color: 'orange' } :
                                          item.hiring_story_screenflow_status === 2 ? { color: 'green' } :
                                            item.hiring_story_screenflow_status === 3 ? { color: 'red' } :
                                              { color: '#000' }
                                      }
                                    >
                                      {item.hiring_story_screenflow_status === 0 ? " Pending" :
                                        item.hiring_story_screenflow_status === 1 ? " Inprogress" :
                                          item.hiring_story_screenflow_status === 2 ? " Completed" :
                                            item.hiring_story_screenflow_status === 3 ? " Skipped" : " "}
                                    </span>
                                    : ""}
                                </td>
                              </tr>

                              {openScreenDetails && openScreenDetails[i]?.value === true ?
                                // item.hiring_story_screenflow_status !== 2 ?
                                <div>
                                  {item.is_for_agent_panel === false && item.ces_candidate_schedule_id === null ?
                                    <div style={{ display: "flex" }}>
                                      <div style={{ flexBasis: "45%", margin: "10px" }}>
                                        <SingleDatePickerInputField
                                          label={'Schedule Date'}
                                          placeholder="Select"
                                          defaultValue={selectedScheduledDate ? selectedScheduledDate : moment().format('YYYY-MM-DD')}
                                          name={"scheduled_date"}
                                          onChange={setSelectedScheduledDate}
                                          format={'yyyy-MM-dd'}
                                          minDate={moment().format('YYYY-MM-DD')}
                                        />
                                      </div>

                                      <div style={{ flexBasis: "45%", margin: "10px" }}>
                                        <SingleTimePickerInputField
                                          label={'Schedule Time'}
                                          placeholder="Select"
                                          defaultValue={selectedScheduledTime ? selectedScheduledTime : moment().format('YYYY-MM-DD')}
                                          name={"scheduled_time"}
                                          onChange={setSelectedScheduledTime}
                                          format={'yyyy-MM-dd'}
                                        />
                                      </div>
                                    </div>

                                    // not for agent case
                                    : item.is_for_agent_panel === false && item.ces_candidate_schedule_id !== null ?
                                      ""
                                      // for skipped case
                                      : item.hiring_story_screenflow_status === 3 && item.is_skipped === true ?
                                        <tr key={i}>
                                          <td style={{ color: "revert", width: "100%" }}>
                                            This test is skipped by  <strong>{item?.skipped_by?.name}</strong><br />
                                            <strong>Skipped Comment : </strong> {item?.remark}
                                          </td>
                                          <td></td>
                                        </tr>
                                        // for agent case
                                        : <tr key={i}>
                                          <td className="start-link-span"
                                            style={{ color: "#2642d6", width: "100%" }}
                                            onClick={() => createSchedueforCandidate(item)}
                                          >
                                            Start</td>
                                          <td className="start-link-span" style={{ color: "red" }}
                                            onClick={() => skipCallApi(item)}
                                          >Skip</td>
                                        </tr>
                                  }

                                  {selectedScheduledTime && item.is_for_agent_panel === false &&
                                    <tr key={i}>
                                      <td className="start-link-span"
                                        style={{ color: "#2642d6", width: "100%" }}
                                        onClick={() => createSchedueforCandidate(item)}
                                      >
                                        Share Test</td>
                                      <td className="start-link-span" style={{ color: "red" }}
                                        onClick={() => skipCallApi(item)}
                                      >Skip</td>
                                    </tr>}
                                </div>
                                // : <span onClick={() => { viewTestData(item.ces_candidate_schedule_id) }}>View Test</span>
                                : ""}
                            </>
                          })
                            : <tr>
                              <td>
                                <span style={{ marginBottom: '15px', color: "red", fontSize: "16px" }}>
                                  Screening Test are not available
                                </span>
                              </td>
                            </tr>
                        }
                      </tbody>
                    </table>
              }
            </div>
            : ""
          }

          {/* screening tset section end */}


          {/* interview feedback section start */}

          <div className="add-new-user-form-default">
            <div className="formInput">
              <div style={{ marginBottom: "14px" }} onClick={() => {
                setOpenInterviewerFeedbackSection(!openInterviewerFeedbackSection);
              }}><h6 className="screening-question-heading">Interviewer Feedback </h6>
                <Tooltip title={!openInterviewerFeedbackSection ? "View Interviewer Feedback" : "Close Interviewer Feedback"}>
                  <IconButton style={{ float: "right", marginTop: "-2px" }}>
                    {!openInterviewerFeedbackSection === true ? (
                      <KeyboardArrowDown />
                    ) : (
                      <KeyboardArrowUp />
                    )}
                  </IconButton>
                </Tooltip>
              </div>
              <hr />
            </div>
          </div>
          {
            openInterviewerFeedbackSection === true ?
              <div>
                {
                  interviewerFeedbackList && interviewerFeedbackList?.data ? interviewerFeedbackList?.data.map((feedback, i) => (
                    <div className="widget" style={{ marginBottom: "16px", width: "488px", height: "auto" }} key={i}>
                      <div className="left">
                        <Tooltip title="Interviewer Name">
                          <span className="counter" style={{ fontSize: "20px" }}>{feedback?.interviewer_name || ""}</span>
                        </Tooltip>
                        <Tooltip title="Interview Date">
                          <span className="title">{feedback?.interview_date || ""}</span>
                        </Tooltip>
                        <Tooltip title="Interviewer Feedback">
                          <span className="title">{feedback?.feedback || ""}</span>
                        </Tooltip>
                        <Tooltip title={"Overall Average Rating :" + feedback?.avg_rating}>
                          <span className="counter">
                            <Rating
                              value={feedback?.avg_rating || 0}
                              name={'avg_rating'}
                              precision={0.1}
                              readOnly
                            />
                          </span>
                        </Tooltip>
                        <Divider />
                        <br />
                        {feedback && feedback.feedback_details && feedback.feedback_details.length > 0 && feedback.feedback_details.map((item) => {
                          return <>
                            <span className="title" style={{ fonstSize: "14px", fontWeight: "bold" }}>{item?.head_comment_name || ""}
                              <Rating
                                value={item?.rating_value || 0}
                                name={'rating_value'}
                                readOnly
                                precision={0.1}

                              />
                            </span>
                            <span className="title">{item?.rating_comment || ""}</span>
                          </>
                        })}
                      </div>
                    </div>
                  ))
                    : <h5>FeedBack Not Found!</h5>
                }
              </div>
              : ""
          }

          {/* interview feedback section end */}



          <select
            name="status_name"
            disabled={
              formValues &&
                formValues.assignee_id === LocalStorageDataService.userDetail().id
                ? false
                : true
            }
            value={
              formValues && formValues.status_id ? formValues.status_id : ""
            }
            onChange={changeStatus}
            className="select-status-ticket"
          >
            <option>SELECT</option>
            {statusData &&
              statusData.length > 0 &&
              statusData.map((item, index) => {
                return (
                  <option key={index} value={item.status_id}>
                    {item.status_name}
                  </option>
                );
              })}
          </select>
          {/* <p style={{marginTop:'3%'}}><a href='#ss-comment-scroll' style={{padding:'12px', backgroundColor:'#0052cc', color:'#fff', borderRadius:'4px'}} onClick={()=>{handleChange("tset", 0); setShow(false); setCommentActive(true);}}>ADD COMMENT</a></p> */}

          <div className="right-side-details">
            {/* <h4>Create page for edit task</h4> */}
            <div className="right-and-left-name-lable-first">
              <h6 style={{ fontSize: "14px", color: "#42526e", fontWeight: "400" }}>Assignee</h6>
              <div>
                <Autocomplete
                  disabled={
                    formValues &&
                      formValues.assignee_id ===
                      LocalStorageDataService.userDetail().id
                      ? false
                      : true
                  }
                  className="custom-select-name"
                  sx={{ width: 300 }}
                  autoHighlight
                  value={assignee_name_default}
                  renderOption={(props, option) => (
                    <Box
                      key={props.id}
                      component="li"
                      sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                      {...props}
                    >
                      <span className="name-and-short">
                        <Avatar
                          style={{
                            marginRight: "10px",
                            textTransform: "uppercase",
                            height: "30px",
                            width: "30px",
                            fontSize: "16px",
                          }}
                          {...stringAvatar(option.label)}
                        />
                        {"   "}{" "}
                        <span
                          className="short-cut-full-name"
                          style={{
                            fontSize: "16px",
                            textTransform: "capitalize",
                          }}
                        >
                          {option.label}
                        </span>
                      </span>
                    </Box>
                  )}
                  onChange={(event, newValue) => {
                    changeStatus(newValue);
                  }}
                  options={
                    selectedMember && selectedMember.length > 0
                      ? selectedMember.map((option, index) => {
                        return {
                          key: index,
                          label: option.label,
                          value: option.value,
                          name: "assignee",
                        };
                        //return { key: option.id, value: option.id, label: option.name + "(" + option.email + ")", name: "assignee" }
                      })
                      : [
                        {
                          label: "Not Available",
                          value: "",
                          name: "assignee",
                        },
                      ]
                  }
                  renderInput={(params) => (<TextField {...params} label={assignee_name_default === "" ? "Unassigned" : "Assigned"} />
                  )}
                />
                {formValues && formValues.assignee_id === LocalStorageDataService.userDetail().id && (
                  <p style={{ color: "blue" }} onClick={(e) => assignToMe(e, "unassign")} >
                    <u>Unassigned</u>
                  </p>
                )}

                {/* {assignee_name_default && LocalStorageDataService.userDetail().id === formValues && formValues.assignee_id && <p style={{ color: 'blue' }} onClick={(e) => assignToMe(e, 'unassign')} ><u>Unassigned</u></p>} */}
                {formValues && !formValues.assignee_id && disabledAssigne() === true && (
                  <p style={{ color: "blue" }} onClick={(e) => assignToMe(e, "assign")} >
                    <u>Assign to me</u>
                  </p>
                )}

                {/* {checkAssignedfield() === false && assignee_name_default === "" && <p style={{ color: 'blue' }} onClick={(e) => assignToMe(e, 'assign')} ><u>Assign to me</u></p>} */}
              </div>
            </div>
            <div className="right-and-left-name-lable">
              <h6 style={{ fontSize: "14px", color: "#42526e", fontWeight: "400" }}>Reporter</h6>
              <input
                type="text"
                value={
                  formValues && formValues.reported_by
                    ? formValues.reported_by.display_name
                    : ""
                }
                disabled={true}
              />
            </div>

            <div className="right-and-left-name-lable">
              <h6 style={{ fontSize: "14px", color: "#42526e", fontWeight: "400" }}>Label</h6>
              <Select
                isMulti
                isDisabled={formValues && formValues.assignee_id === LocalStorageDataService.userDetail().id ? false : true}
                defaultValue={selected}
                width="100%"
                options={LocalStorageDataService.labels_list().map((e, i) => {
                  return { label: e, value: e, name: "lables", key: i };
                })}
              />
            </div>

            {/* <div className="right-and-left-name-lable">
              <h6 style={{ fontSize: "14px", color: "#42526e", fontWeight: "400" }}>Story Point</h6>
              <input
                type="text"
                disabled={formValues && formValues.assignee_id === LocalStorageDataService.userDetail().id ? false : true}
                value={formValues && formValues.story_point ? formValues.story_point : ""}
              />
            </div>

            <div className="right-and-left-name-lable">
              <h6 style={{ fontSize: "14px", color: "#42526e", fontWeight: "400" }}>Sprint</h6>
              <input
                type="text"
                disabled={formValues && formValues.assignee_id === LocalStorageDataService.userDetail().id ? false : true}
                value={formValues && formValues.sprint ? formValues.sprint : ""}
              />
            </div>

            <div className="right-and-left-name-lable">
              <h6 style={{ fontSize: "14px", color: "#42526e", fontWeight: "400" }}>Priority</h6>
              <Select
                isDisabled={formValues && formValues.assignee_id === LocalStorageDataService.userDetail().id ? false : true}
                defaultValue={formValues && formValues.priority ? formValues.priority : ""}
                onChange={changeStatus}
                width="300px"
                options={LocalStorageDataService.priority_list().map((e, i) => {
                  return { label: e, value: e, name: "priority", key: i };
                })}
              />
            </div> */}
          </div>
        </div>
      </div >
      <RemindModal
        openModal={activeRemindModal}
        setOpenModal={closeReminderModal}
        title={"Set Reminder"}
        formtype="form-data"
        setStartRemindDate={changeStartRemindDate}
        startRemindDate={startRemindDate}
        onSubmit={onSubmitReminder}
        loading={reminderLoading}
        formErr={formErr}
        errSatus={errStatus}
      />
      {
        showAddPhone === true &&
        <AddCandidatePhoneNumber
          handleClose={handleCloseAddCandidate}
          showPhone={showAddPhone}
          storyId={epicId}
          setReload={setReload}
          setPhoneNumberByList={setPhoneNumberByList}
        />
      }
      {/* {activeExamScheduleModal !== false && */}
      <ExamSchedulForm
        open={activeExamScheduleModal}
        handleClose={closeExamScheduleModal}
        candidateData={(createCandidate.data && createCandidate.data) || candidateID}
        candidateExamList={candidateExamList}
        setCandidateExamList={setCandidateExamList}
        examCandidateInfo={examCandidateInfo}
        setExamCandidateInfo={setExamCandidateInfo}
        storyId={epicId}
        emails={taskData.data && taskData.data.candidate_email && taskData.data.candidate_email.length > 0 ? taskData.data.candidate_email : ''}
        contacts={taskData.data && taskData.data.candidate_phone && taskData.data.candidate_phone.length > 0 ? taskData.data.candidate_phone : ""}
        setApiLoading={setApiLoading}
        mytasklistapi={api}
        filters={filters}
        examTabvalue={examTabvalue}
        setExamTabvalue={setExamTabvalue}
      />
      {/* } */}

      {/* skip model for screening Test*/}
      <Skip
        open={openSkipModal}
        setOpen={setOpenSkipModal}
        skipRowData={skipRowData}
        skipformValues={skipformValues}
        setSkipFormValues={setSkipFormValues}
        submitData={submitSkipData}
        setLoading={setLoading}
        loading={loading}
      />

      {/* add new email id */}
      {
        showAddEmailModal === true &&
        <AddCandidateEmail
          handleClose={handleCloseAddCandidate}
          showEmail={showAddEmailModal}
          storyId={epicId}
          setReload={setReload}
        />
      }

    </div >
  );
};
