import Navbar from "../../components/navbar/Navbar"
import Sidebar from "../../components/sidebar/Sidebar"
import "./master.scss"
import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import { getAllMenuList, getMenuList, getRoleMenuList, postAssignMenuRole, postBaseMenu } from '../../redux/features/masterSlice';
import { menuListColumn } from "../../components/datatable-coulms/tableCoulmns";
import LoaderForm from "../../components/common-function/LoaderForm";
import { STATUSES } from "../../redux/common-status/constant";
import { Button, Dialog, DialogContent, DialogTitle, FormControlLabel, Radio, RadioGroup, Checkbox, TablePagination } from "@mui/material";
import { Close } from "@mui/icons-material";
import Select from 'react-select';
import { getRole } from '../../redux/features/roleSlice';
import { toast, ToastContainer } from 'react-toastify';
import GetLocalstorage from "../../components/common-function/GetLocalstorage";
import UcActionComponent from "../../components/common-function/UcActionComponent";
import dataService from "../../redux/services/data.service";
import TablePaginationActions from "../../components/TablePaginationActions";
import { Tooltip } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import AssignmentIcon from '@mui/icons-material/Assignment';
import CustomNoRowsOverlay from "../../components/CustomEmptyOverlay";
import { RefreshButton } from "../../components/common-function/RefreshButton";
import { DefaultFilterColumns } from "../../components/common-function/DefaultFilterColumns";


const formErrData = Object.freeze({
  titleErr: "Title is required",
  url_slugErr: "Slug is required",
  descriptionErr: 'Description required',
  class_nameErr: "Icon name is required",
  statusErr: "Status is required",
});

const MenuList = () => {
  const dispatch = useDispatch();
  const { menuList, status, createMenu, createRoleMenu, createAssignRoleMenu, allMenuList } = useSelector(state => state.master)
  const { data } = useSelector(state => state.role)
  //pagination 
  const [currentPage, setCurrentPage] = useState(0);
  const [filterQuery, setFilterQuery] = useState(`{"skip":0, "limit":25,"order_by":{"column":"-id"},"columns":[${DefaultFilterColumns.MENU_LIST_COLUMNS}]}`)
  const [modalView, setModelView] = useState(false)
  const [formValues, setFormValues] = useState({
    title: "", description: "", class_name: "", url_slug: "", status: "", parent_id: null, sort_by: 0
  })
  const [errStatus, setErrStatus] = useState(false);
  const [loading, setLoading] = useState(false)
  const [roleModalView, setRoleModalView] = useState(false)
  const [selectedIds, setSelectedIds] = useState("")
  const [seletedMenuList, setSeletedMenuList] = useState([])
  const [roleMenu, setRoleMenu] = useState([])
  const [selectedCheckbox, setSelectedCheckbox] = useState([])
  const [pageSize, setPageSize] = useState(25)
  const [limit, setLimit] = useState(25);

  useEffect(() => {
    dispatch(getRoleMenuList(`{"no_limit":True, "order_by":{"column":"-id"}}`))
    dispatch(getMenuList(filterQuery))
    dispatch(getRole(`{'filter':{'status':{'value':True,'operation':'eq'}},"no_limit":True,"columns":[${DefaultFilterColumns.ROLE_DROUPDOWN_COLUMNS}]}`))
    dispatch(getAllMenuList(`{'filter':{'status':{'value':True,'operation':'eq'}},"no_limit":True,"order_by":{"column":"-id"},"columns":[${DefaultFilterColumns.MENU_DROUPDOWN_COLUMNS}]}`))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  //pagination 
  const handlePagination = (data) => {
    setCurrentPage(data)
    let newFilterData = JSON.parse(filterQuery);
    newFilterData['skip'] = data * pageSize
    newFilterData['limit'] = limit
    setLimit(limit)
    setFilterQuery(JSON.stringify(newFilterData))
    dispatch(getMenuList(JSON.stringify(newFilterData)))
  }

  const handlePageSizeChange = (newPageSize) => {
    setPageSize(newPageSize.target.value)
    setCurrentPage(0)
    setLimit(newPageSize.target.value)
    let newFilterData = JSON.parse(filterQuery);
    newFilterData['skip'] = 0
    newFilterData['limit'] = newPageSize.target.value
    setFilterQuery(JSON.stringify(newFilterData))
    dispatch(getMenuList(JSON.stringify(newFilterData)))
  }
  function CustomPagination() {
    return (
      <TablePagination
        component="div"
        count={(menuList && menuList.data && menuList.data.count) || 0}
        page={currentPage}
        rowsPerPage={pageSize}
        onPageChange={() => setCurrentPage(pageSize)}
        onRowsPerPageChange={handlePageSizeChange}
        ActionsComponent={(subProps) =>
          <TablePaginationActions {...subProps}
            handlePagination={handlePagination}
          />}
      />
    );
  }


  if (loading === true && status === STATUSES.SUCCESS && menuList && menuList.data) {
    setLoading(false)
  }
  if (loading === "update_role_menu" && status === `${STATUSES.SUCCESS}_createAssignRoleMenu` && createAssignRoleMenu && createAssignRoleMenu.message) {
    if (createAssignRoleMenu.status === 200) {
      dispatch(getRoleMenuList(`{"no_limit":True, "order_by":{"column":"-id"}}`))
      dispatch(getMenuList(filterQuery))
      setLoading(true)
    } else {
      toast.error(createAssignRoleMenu.message)
      setLoading(false)
    }
  }
  if (loading === "update_role_menu" && status === `${STATUSES.SUCCESS}_createRoleMenu` && createRoleMenu && createRoleMenu.message) {
    if (createRoleMenu.status === 200) {
      dispatch(getRoleMenuList(`{"no_limit":True, "order_by":{"column":"-id"}}`))
      dispatch(getMenuList(filterQuery))
      setLoading(true)
    } else {
      toast.error(createRoleMenu.message)
      setLoading(false)
    }
  }

  const closeModal = () => {
    setModelView(false)
    setFormValues({
      title: "", description: "", class_name: "", url_slug: "", status: "", parent_id: null, sort_by: 0
    })
    setErrStatus(false)
  }
  const closeRoleModal = () => {
    setRoleModalView(false)
  }

  const handleChange = (evt) => {
    const { name, value } = evt.target || evt;
    setFormValues({ ...formValues, [name]: value });
  }
  // const handleSelect = (data) => {
  //   setSelectedOptions(data);
  // }

  const submitData = (e) => {
    e.preventDefault()
    if (formValues.title === "" || formValues.status === "" || formValues.url_slug === "" || formValues.class_name === "" || formValues.description === "") {
      setErrStatus(true)
    } else {
      setErrStatus(false)
      let statusIs = (formValues.status === 'true' || formValues.status === true) ? true : (formValues.status === 'false' || formValues.status === false) && false;
      if (formValues.id > 0) {
        setLoading(`true_updateMenu`)
        let formdata = ({ ...formValues, status: statusIs, parent_id: formValues.parent_id && formValues.parent_id.value ? formValues.parent_id.value : formValues.parent_id })
        dispatch(postBaseMenu(formdata))
      } else {
        setLoading(`true_createMenu`)
        let formdata = ({ ...formValues, created_by: GetLocalstorage.userDetail().id, status: statusIs })
        dispatch(postBaseMenu(formdata))
      }

    }
  }

  if ((status === `${STATUSES.SUCCESS}_createMenu` || status === `${STATUSES.FAILURE}_createMenu`) && loading === 'true_createMenu') {
    if (createMenu.status === 200) {
      toast.success(createMenu.message)
      dispatch(getMenuList(filterQuery))
      closeModal()
    } else {
      toast.success(createMenu.message)
    }
    setLoading(false)
  }

  if ((status === `${STATUSES.SUCCESS}_createMenu` || status === `${STATUSES.FAILURE}_createMenu`) && loading === 'true_updateMenu') {
    if (createMenu.status === 200) {
      toast.success(createMenu.message)
      dispatch(getMenuList(filterQuery))
      closeModal()
    } else {
      toast.success(createMenu.message)
    }
    setLoading(false)
  }
  const editMode = (params) => {
    setFormValues({
      title: params.title,
      description: params.description,
      class_name: params.class_name,
      url_slug: params.url_slug,
      status: params.status,
      parent_id: selectValueInLable(params.parent_id),
      sort_by: params.sort_by,
      id: params.id
    })
    setModelView(true)
  }

  const selectValueInLable = (id) => {
    let data = menuList.data && menuList.data.rows;
    let newObject = null
    data && data.length > 0 && data.map(x => {
      return (x.id === id) && (newObject = { value: x.id, label: x.title })

    })
    return newObject
  }

  const onSelectionChange = (selectionArray) => {
    if (selectionArray.length > 0) {
      let array = []
      let array2 = []
      let array3 = []
      let array4 = []
      setSelectedIds(selectionArray)
      data && data.data && data.data.rows.map(e => {
        return array2.push({
          role_id: e.id,
        })
      })
      selectionArray.map(e => {
        // return menuList && menuList.data && menuList.data.rows.map((option) => {
          return menuList && menuList.data && menuList.data.rows.forEach((option) => {
          if (e === option.id) {
            array.push({ all_role: array2, menu_id: e, option })

            if (option.roles && option.roles.length > 0) {
              // option.roles.map(r => {
                option.roles.forEach(r => {
                array3.push({ role_id: r.id, menu_id: e, status: r.status, parent_id: option.parent_id })
                array4.push({ [`check_${e}_${r.id}`]: r.status })
              })
            }

          }
        })
      })
      if (array.length > 0) {
        // array.map(e => {
        //   e.all_role && e.all_role.length > 0 && e.all_role.map(e1 => {
          array.forEach(e => {
            e.all_role && e.all_role.length > 0 && e.all_role.forEach(e1 => {
            e1[`check_${e.menu_id}_${e1.role_id}`] = array3 && array3.length > 0 && array3.filter(x => {
              return (x.role_id === e1.role_id && x.menu_id === e.menu_id && x.status === true) && e1
            })
          })

        })
        setSeletedMenuList(array)
      }
      if (array3.length > 0) {
        setRoleMenu(array3)
        setSelectedCheckbox(array4)
      }

    } else {
      setSelectedIds("")
      setSeletedMenuList([])
    }
  }
  function arrayRemove(arr, value) {

    return arr.filter(function (ele) {
      return ele !== value;
    });
  }
  function removeDuplicates(arr) {
    return arr.filter((item,
      index) => arr.indexOf(item) === index);
  }
  const checkBoxFunction = async (selectedRolesMenu) => {
    if (selectedRolesMenu) {
      let roleArray = []
      const response = await dataService.getRoleMenu(`{"filter":{"menu_id":{"value":${selectedRolesMenu.menu_id}, 'operation' :'eq'}},"no_limit":True}`)
      const result = response && response.data
      const rows = result && result.data && result.data.rows
      if (result.status === 200) {
        if (rows && rows.length > 0) {
          rows.map(el => el.status === true && roleArray.push(el.role_id))
        }
      }

      if (roleArray && roleArray.length > 0) {
        if (selectedRolesMenu.status === false) {
          let results = arrayRemove(roleArray, selectedRolesMenu.role_id);
          roleArray = results
        }

        if (selectedRolesMenu.status === true) {
          roleArray.push(selectedRolesMenu.role_id)
        }

      } else {
        roleArray.push(selectedRolesMenu.role_id)
      }

      let roleMenuArray = removeDuplicates(roleArray)
      let data = {
        "roles": roleMenuArray,
        "menu": selectedRolesMenu.menu_id,
        "created_by": GetLocalstorage.userDetail().id
      }

      dispatch(postAssignMenuRole(data))
      setLoading("update_role_menu")
    }

  }

  const addCheckBoxes = (event, role, menu) => {
    setSelectedCheckbox({ ...selectedCheckbox, [event.target.name]: event.target.checked })
    // seletedMenuList.map(e => {
    //   e.all_role && e.all_role.length > 0 && e.all_role.map(e1 => {
      seletedMenuList.forEach(e => {
        e.all_role && e.all_role.length > 0 && e.all_role.forEach(e1 => {
        e1[event.target.name] = event.target.checked
      })
    })
    setSeletedMenuList(seletedMenuList)
    let object = { role_id: role, menu_id: menu, status: event.target.checked }
    if (roleMenu && roleMenu.length > 0) {
      var filteredRoleMenus = roleMenu.filter(function (el) {
        return (el.role_id === role && el.menu_id === menu);
      });
      if (filteredRoleMenus.length === 0) {
        checkBoxFunction(object)
      }
      if (filteredRoleMenus.length > 0) {
        checkBoxFunction(object)
      }
    } else {
      checkBoxFunction(object)
    }

  }


  const onFilterChange = (filterModel) => {
    if (filterModel.items && filterModel.items.length > 0 && filterModel.items[0].columnField && filterModel.items[0].value) {
      let operatorValue = filterModel.items[0].operatorValue === "contains" ? "icontains" :
        filterModel.items[0].operatorValue === "startsWith" ? 'istartswith' :
          filterModel.items[0].operatorValue === "is" ? 'eq' :
            filterModel.items[0].operatorValue === "endsWith" ? 'iendswith' : filterModel.items[0].operatorValue
      const filterData = `{"filter":{"${[filterModel.items[0].columnField]}":{"value":"${filterModel.items[0].value}","operation": "${operatorValue}"}},"skip":0, "limit":${limit},"order_by":{"column":"-id"},"columns":[${DefaultFilterColumns.MENU_LIST_COLUMNS}]}`
      setFilterQuery(filterData)
      setCurrentPage(0)
      dispatch(getMenuList(filterData))
    }
    if (filterModel.items && filterModel.items.length === 0) {
      setCurrentPage(0)
      // setPageSize(10)
      setFilterQuery(`{"skip":0, "limit":${limit},"order_by":{"column":"-id"},"columns":[${DefaultFilterColumns.MENU_LIST_COLUMNS}]}`)
      dispatch(getMenuList(`{"skip":0, "limit":${limit},"order_by":{"column":"-id"},"columns":[${DefaultFilterColumns.MENU_LIST_COLUMNS}]}`))
    }
    setLoading(true)
  }

  // sorting
  const handleSort = (sort) => {
    if (sort.length) {
      let newFilterData = JSON.parse(filterQuery);
      newFilterData['skip'] = 0;
      newFilterData['limit'] = limit;
      newFilterData['order_by'] = {
        "column": sort[0].sort === "desc" ?
          '-' + sort[0].field : sort[0].field
      }
      setFilterQuery(JSON.stringify(newFilterData))
      dispatch(getMenuList(JSON.stringify(newFilterData)))
    } else {
      setFilterQuery(`{ "skip": 0, "limit": ${limit}, "order_by": { "column": "-id" },"columns":[${DefaultFilterColumns.MENU_LIST_COLUMNS}] }`)
    }
  }


  const actionColumn = [
    {
      field: "action",
      headerName: "Action",
      width: 120,
      hideable: false,
      filterable: false,
      sortable: false,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            <UcActionComponent
              moduleName="menubar"
              rowid={params.row.id}
              addMoreData={params.row}
              editLinkUrl={false}
              viewLinkUrl={false}
              isDelete={false}
              deleteIconName="ArchiveIcon"
              editButton={editMode}
              viewButton={false}
              deleteButton={false}
            />
          </div>
        );
      },
    },
  ]

  return (
    <div className="master">
      <Sidebar />
      <div className="masterContainer">
        <Navbar />
        <div className="datatable">
          <div className="top-headings">
            <h3>Menu List</h3>
            <div>
              <Tooltip title="Assign Roles">
                <button onClick={() => selectedIds && setRoleModalView(true)} className={selectedIds ? "btn btn-add-new" : "btn-add-disabled"}><AssignmentIcon /></button>
              </Tooltip>

              <Tooltip title="Add ">
                <button onClick={() => setModelView(true)} className="btn btn-add-new">
                  <AddIcon />
                </button>
              </Tooltip>
              <RefreshButton api={getMenuList} filter={filterQuery} style={{ marginTop: 0 }} />
            </div>
          </div>
          <ToastContainer />
          <DataGrid
            className="datagrid"
            loading={status === STATUSES.LOADING}
            columnVisibilityModel={{
              id: false
            }}
            rows={menuList && menuList.data && menuList.data.rows ? menuList.data.rows : []}
            columns={menuListColumn.concat(actionColumn)}
            pageSize={pageSize}
            style={{ fontSize: 16 }}
            checkboxSelection
            onSelectionModelChange={onSelectionChange}
            disableSelectionOnClick
            //onCellClick={(cell) => { cell.field !== "action" && doubleClickFunction(cell, count + 1) }}
            onFilterModelChange={onFilterChange}
            components={{
              Pagination: CustomPagination,
              NoRowsOverlay: CustomNoRowsOverlay
            }}
            onSortModelChange={(sort) => handleSort(sort)}
          />
        </div>
        <Dialog
          hideBackdrop
          fullWidth
          maxWidth={"lg"}
          open={modalView}
          onClose={closeModal}
          aria-labelledby="child-modal-title"
          aria-describedby="child-modal-description"
        >
          <Button
            edge="start"
            color="inherit"
            onClick={closeModal}
            aria-label="close"
            style={{ position: "absolute", top: "10px", right: "10px" }}>
            <Close />
          </Button>
          <DialogTitle style={{ background: '#2196f3', color: '#fff', marginBottom: "10px" }}>Create Menu</DialogTitle>
          {(loading === 'true_createMenu' || loading === 'true_updateMenu') && <LoaderForm />}
          <DialogContent style={{ height: "500px" }}>
            <div>
              <form className="regulazation-form">
                <div className="add-new-user-form">
                  <div className="formInput" style={{ flexBasis: "32%" }}>
                    <label >
                      Parent Menu
                    </label>
                    <Select
                      className="basic-single"
                      classNamePrefix="select"
                      placeholder="Select"
                      defaultValue={formValues.parent_id}
                      isSearchable={true}
                      name={"parent_id"}
                      options={
                        allMenuList && allMenuList.data && allMenuList.data.rows.map((option) => {
                          return { label: option.title, value: option.id, name: "parent_id" }
                        })
                      }
                      onChange={handleChange}
                    />
                  </div>
                  <div className="formInput" style={{ flexBasis: "32%" }}>
                    <label >
                      Title*
                    </label>
                    <input
                      name="title"
                      type="text"
                      defaultValue={formValues.title}
                      placeholder="Menu"
                      onChange={handleChange}
                    />
                    {errStatus === true && formValues && formValues.title === "" && <p style={{ color: "#96332c", fontSize: 13 }}>{formErrData.titleErr}</p>}

                  </div>
                  <div className="formInput" style={{ flexBasis: "32%" }}>
                    <label >
                      Url Slug*
                    </label>
                    <input
                      name="url_slug"
                      type="text"
                      defaultValue={formValues.url_slug}
                      placeholder="master(master is Parent)-menu-list(menu-list is Chlid)"
                      onChange={handleChange}
                    />
                    {errStatus === true && formValues && formValues.url_slug === "" && <p style={{ color: "#96332c", fontSize: 13 }}>{formErrData.url_slugErr}</p>}

                  </div>
                  <div className="formInput" style={{ flexBasis: "32%" }}>
                    <label >
                      Icon Name*
                    </label>
                    <input
                      name="class_name"
                      type="text"
                      defaultValue={formValues.class_name}
                      placeholder="Icon Name"
                      onChange={handleChange}
                    />
                    {errStatus === true && formValues && formValues.parent_id !== "" && formValues.class_name === "" && <p style={{ color: "#96332c", fontSize: 13 }}>{formErrData.class_nameErr}</p>}

                  </div>
                  <div className="formInput" style={{ flexBasis: "32%" }}>
                    <label >
                      Sort By
                    </label>
                    <input
                      name="sort_by"
                      type="number"
                      defaultValue={formValues.sort_by}
                      placeholder="Sort by position"
                      onChange={handleChange}
                    />
                  </div>
                  <div className="formInput" style={{ flexBasis: "32%" }}>
                    <label >
                      Status*
                    </label>
                    <RadioGroup
                      value={formValues.status}
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="status"
                      onChange={handleChange}
                    >
                      <FormControlLabel value={true} control={<Radio />} label="Active" />
                      <FormControlLabel value={false} control={<Radio />} label="Inactive" />
                    </RadioGroup>
                    {errStatus === true && formValues && formValues.status === "" && <p style={{ color: "#96332c", fontSize: 13 }}>{formErrData.statusErr}</p>}

                  </div>

                  <div className="formInput" style={{ flexBasis: '100%' }}>
                    <label >
                      Description*
                    </label>
                    <textarea style={{ flexBasis: "100%" }} defaultValue={formValues.description} name="description" onChange={handleChange} rows="3" placeholder="Description"></textarea>
                    {errStatus === true && formValues && formValues.description === "" && <p style={{ color: "#96332c", fontSize: 13 }}>{formErrData.descriptionErr}</p>}
                  </div>
                </div>
                {loading === false && <button onClick={submitData} className="btn btn-submit">Submit</button>}
              </form>
            </div>
          </DialogContent>
        </Dialog >

        <Dialog
          hideBackdrop
          fullWidth
          maxWidth={"xl"}
          open={roleModalView}
          onClose={closeRoleModal}
          aria-labelledby="child-modal-title"
          aria-describedby="child-modal-description"
        >
          <Button
            edge="start"
            color="inherit"
            onClick={closeRoleModal}
            aria-label="close"
            style={{ position: "absolute", top: "10px", right: "10px" }}>
            <Close />
          </Button>
          <DialogTitle style={{ background: '#2196f3', color: '#fff', marginBottom: "10px" }}>Menu Role Assign</DialogTitle>
          {(loading === 'true_updateMenuRole') && <LoaderForm />}
          <DialogContent style={{ height: "600px" }}>
            <div>
              <table style={{ width: '100%' }}>
                <thead>
                  <th></th>
                  {data && data.data && data.data.rows.map((item, index) => {
                    return (
                      <th key={index}>{item.role_name}</th>
                    )
                  })}
                </thead>
                <tbody className="master-table-body">
                  {seletedMenuList.map((i, key) => {
                    return (
                      <tr key={key}>
                        <td>{i.option.title}</td>
                        {i.all_role && i.all_role.length > 0 && i.all_role.map((itm, idx) => {
                          return (
                            <td key={idx}><Checkbox name={`check_${i.menu_id}_${itm.role_id}`}
                              checked={itm[`check_${i.menu_id}_${itm.role_id}`].length === 0 ? false : itm[`check_${i.menu_id}_${itm.role_id}`] === false ? false : true}
                              onChange={(e) => addCheckBoxes(e, itm.role_id, i.menu_id)} /></td>
                          )
                        })}
                      </tr>
                    )
                  })}


                </tbody>
              </table>
            </div>
          </DialogContent>
        </Dialog>
      </div >

    </div >
  )
}


export default MenuList