import React, { useEffect, useState } from "react";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import "./hiring.scss";
import { DataGrid } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import {
  getHiringRequestList,
  getAllLocationList,
  getAllMoreSpecificationList,
  hiringRequestUpdate,
  getAllQualificationList,
} from "../../redux/features/masterSlice";
import { toast, ToastContainer } from "react-toastify";
import { TablePagination } from "@mui/material";
import LoaderForm from "../../components/common-function/LoaderForm";
import UcActionComponent from "../../components/common-function/UcActionComponent";
import { STATUSES } from "../../redux/common-status/constant";
import TablePaginationActions from "../../components/TablePaginationActions";
import CustomNoRowsOverlay from "../../components/CustomEmptyOverlay";
import { RefreshButton } from "../../components/common-function/RefreshButton";
import { DefaultFilterColumns } from "../../components/common-function/DefaultFilterColumns";
import CustomeTooltip from "../common-function/CustomeTooltip";
import { skilOption } from "../../redux/features/optionsSlice";
import GetLocalstorage from "../common-function/GetLocalstorage";
import DeleteDialogModal from "../common-function/DeleteDialogModal";
import dataService from "../../redux/services/data.service";
import JobPost from "./AddJobPost";

const user = GetLocalstorage.userDetail()
const userId = user && user.id
const tmsRole = user && user.tms_role

function MyHiringRequests() {
  const dispatch = useDispatch();

  const {
    status,
    error,
    hiring_request_List,
    location_option_List,
    specificationOptionList,
    hiring_request_Update,
    qualification_AllList,
  } = useSelector((state) => state.master);

  //pagination
  const [currentPage, setCurrentPage] = useState(0);
  const [filterQuery, setFilterQuery] = useState(
    `{"filter":{"created_by":{"value":${userId} , "operation":"eq"}},"skip":0,"limit":25,"order_by":{"column":"-id"}, "columns":[${DefaultFilterColumns.HIRING_REQUEST_LIST_COLUMNS}]}`
  );
  const [pageSize, setPageSize] = useState(25);
  const [limit, setLimit] = useState(25);
  //columns visibility
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({
    id: false,
  });
  const [filterTechnologyList, setFilterTechnologyList] = useState([]);
  const [filterSkillList, setFilterSkillList] = useState([]);
  const [filterLocationList, setFilterLocationList] = useState([]);
  const [filterSpecificationList, setFilterSpecificationList] = useState([]);
  const [options, setOptions] = useState({
    status: [
      { value: "True", label: "Active" },
      { value: "False", label: "Inactive" },
    ],
  });
  const { skils } = useSelector((state) => state.dropdownOptions);
  const [archiveId, setArchiveId] = useState(0)
  const [archiveOpen, setArchiveOpen] = useState(false)
  const [statusHiringRequest, setStatusHiringRequest] = useState('');
  const [reload, setReload] = useState(false)
  const [open, setOpen] = useState("")
  const [, setHiringReq] = useState("")
  const [getJobData, setGetJobData] = useState({})
  const [, setSpecialisationSkills] = useState("")
  const [, setSkillData] = useState("")


  //error handling
  useEffect(() => {
    if (status === STATUSES.FAILURE && error) {
      toast.error(error.message);
    }
    // eslint-disable-next-line
  }, [error]);

  useEffect(() => {
    dispatch(getHiringRequestList(filterQuery));
    dispatch(skilOption());
    dispatch(getAllLocationList());
    dispatch(getAllMoreSpecificationList());
    dispatch(getAllQualificationList())
    if (status === "error") {
      console.log("error");
    }
    // eslint-disable-next-line
  }, []);

  const handleClickOpen = async (data) => {
    const res = await dataService.hiring_request_edit(data.id)
    if (res && res.data && res.data.data) {
      const editData = structuredClone(res.data.data)
      let status = editData.status
      let addvalues = {
        status: !status,
        add_more_specifications: editData.add_more_specifications,
        expected_ETA_in_months: editData.expected_ETA_in_months,
        expected_ETA_in_years: editData.expected_ETA_in_years,
        job_description: editData.job_description,
        job_responsibilities: editData.job_responsibilities,
        number_of_vacancies: editData.number_of_vacancies,
        preferred_location: editData.preferred_location,
        relevant_experience_expected: editData.relevant_experience_expected,
        relevant_experience_expected_in_months: editData.relevant_experience_expected_in_months,
        relevant_experience_expected_in_years: editData.relevant_experience_expected_in_years,
        required_skill_set: editData.required_skill_set,
        role: editData.role,
        technologies: editData.technologies,
        type_of_vacancy: editData.type_of_vacancy_id,
        urgency: editData.urgency_id,
        working_mode: editData.working_mode_id,

      }
      setReload('edit')
      dispatch(hiringRequestUpdate({ ID: data.id, addvalues }))
    }

  }
  if (reload === 'edit' && hiring_request_Update && hiring_request_Update.status) {
    if (hiring_request_Update.status === 200) {
      setArchiveOpen(false)
      toast.success(hiring_request_Update.message)
      dispatch(hiringRequestUpdate('type'))
      dispatch(getHiringRequestList(filterQuery))
    } else {
      hiring_request_Update.message ? toast.error(hiring_request_Update.message) : toast.error('Server issue!')
    }
    setReload(false)
  }

  const loadDataById = async (id) => {
    setReload(true)
    const res = await dataService.hiring_request_edit(id)
    let technologyArray = []
    let addMoreSpecificationArray = []
    let skillDataArray = []

    if (res && res.data && res.data.data) {
      const editData = res.data.data

      if (editData && editData.skills_tech_data) {
        editData.skills_tech_data.length > 0 && editData.skills_tech_data.map(e => {
          return technologyArray.push({
            label: e.technology && e.technology.name,
            value: e.technology && e.technology.id,
            name: "specialistation_id"
          })
        })

        editData.skills_tech_data.length > 0 && editData.skills_tech_data.map(e => {
          return skillDataArray.push({
            technology_id: e.technology && e.technology.id,
            skill_data: e.skills.map((item) => {
              return {
                label: item.skill_name,
                value: item.id,
                technology_id: e.technology.id,
                name: "skill_data"
              }
            })
          })
        })
        const result = skillDataArray.reduce((obj, itm) => ({ ...obj, [itm.technology_id]: itm }), {})
        setSpecialisationSkills(technologyArray)
        setSkillData(result)
      }

      if (editData && editData.add_more_specifications_data) {
        editData.add_more_specifications_data.length > 0 && editData.add_more_specifications_data.map(e => {
          return addMoreSpecificationArray.push({
            label: e.specification_name_key,
            value: e.id
          })
        })
      }

      let array1 = []

      if (editData.education_qualification) {
        let qualification = qualification_AllList && qualification_AllList.data && qualification_AllList.data.rows ? qualification_AllList.data.rows : []
        editData.education_qualification.map(e => {
          let filterId = qualification.filter(x => x.id === e);
          if (filterId.length > 0) {
            array1.push(filterId[0])
          }
          return array1
        })
      }

      if (editData) {

        setGetJobData({
          // step 1
          client: { label: editData.client && editData.client.name, value: editData.client_id },
          industry: { label: editData.industry && editData.industry.industry_name, value: editData.industry_id },
          department: { label: editData.department && editData.department.name, value: editData.department_id },
          specialistation_id: technologyArray,
          hiring_request: id,
          project_id: "",

          //step 2
          client_budget_from: editData.client_budget_from,
          client_budget_to: editData.client_budget_to,
          currency: { label: editData.currency && editData.currency.currency_name, value: editData.currency_id },
          payment_type: { label: editData.payment_type && editData.payment_type.name, value: editData.payment_type_id },
          hiring_mode: { label: editData.hiring_mode && editData.hiring_mode.name, value: editData.hiring_mode_id },

          // step 3
          job_title: editData.role,
          number_of_vacancies: editData.number_of_vacancies,
          type_of_vacancy: { label: editData.type_of_vacancy && editData.type_of_vacancy.vacancy_type, value: editData.type_of_vacancy_id },
          contract_type: { label: editData.contract_type && editData.contract_type.name, value: editData.contract_type_id },
          hours_per_day: editData.hours_per_day ? editData.hours_per_day : null,
          expected_duration_in_months: editData.expected_duration_in_months ? editData.expected_duration_in_months : null,

          work_mode: { label: editData.working_mode && editData.working_mode.working_mode, value: editData.working_mode_id },
          urgency: { label: editData.urgency && editData.urgency.urgency_type, value: editData.urgency_id },
          job_location: { label: editData.job_location && editData.job_location.job_location, value: editData.job_location_id },
          job_experience: editData && editData.relevant_experience_expected,

          job_responsibilities: editData.job_responsibilities,
          job_description: editData.job_description,
          expected_ETA_in_months: editData.expected_ETA_in_months,
          expected_ETA_in_days: editData.expected_ETA_in_days,
          add_more_specifications: addMoreSpecificationArray,
          education_qualification: array1,
          attachment: editData.attachment,
          job_attachments: editData.job_attachments,
          status: editData.status,
          job_description: editData.job_description,
          hiring_status: editData.hiring_status,

          relevant_experience_expected_from: editData.relevant_experience_expected_from,
          relevant_experience_expected_to: editData.relevant_experience_expected_to,
          total_experience_to: editData.total_experience_to,
          total_experience_from: editData.total_experience_from,
          expected_ETA: editData.expected_ETA,
        })
      }
      setOpen(true);

      // if (editData.job_description) { setDescription(editData.job_description) }
      setReload(false)

    }
  }



  const handleOpen = (hiringReq) => {
    setHiringReq(hiringReq)
    loadDataById(hiringReq.id)
  };

  const getUnique = (arr, index) => {
    const unique = arr
      .map((e) => e[index])

      // store the keys of the unique objects
      .map((e, i, final) => final.indexOf(e) === i && i)

      // eliminate the dead keys & store unique objects
      .filter((e) => arr[e])
      .map((e) => arr[e]);

    return unique;
  };

  if (filterTechnologyList && filterTechnologyList.length === 0 && skils) {
    let array = [];
    skils &&
      skils.map((e) => {
        return array.push({ value: e.id, label: e.skill_name });
      });
    array.length > 0 && setFilterTechnologyList(array);
    array.length > 0 && setOptions({ ...options, technology_id: array });
  }

  if (filterSkillList && filterSkillList.length === 0 && skils) {
    let array = [];
    skils &&
      skils.map((e) => {
        return array.push({ value: e.id, label: e.skill_name });
      });
    array.length > 0 && setFilterSkillList(array);
    array.length > 0 &&
      setOptions({ ...options, required_skill_set_id: array });
  }

  if (
    filterLocationList &&
    filterLocationList.length === 0 &&
    location_option_List
  ) {
    let array = [];
    location_option_List &&
      location_option_List.map((e) => {
        return array.push({ value: e.id, label: e.city_name });
      });
    array.length > 0 && setFilterLocationList(array);
    array.length > 0 &&
      setOptions({ ...options, preferred_location_id: array });
  }

  if (
    filterSpecificationList &&
    filterSpecificationList.length === 0 &&
    location_option_List
  ) {
    let array = [];
    specificationOptionList &&
      specificationOptionList.map((e) => {
        return array.push({ value: e.id, label: e.specification_name });
      });
    array.length > 0 && setFilterSpecificationList(array);
    array.length > 0 &&
      setOptions({ ...options, preferred_location_id: array });
  }

  const actionColumn = [
    { field: "id", headerName: "ID", width: 70, filterable: false },
    { field: "role", headerName: "Position/Role Name", width: 230 },
    {
      field: "number_of_vacancies",
      headerName: "No. of vacancies",
      width: 230,
    },
    {
      field: "type_of_vacancy__vacancy_type",
      headerName: "Type of vacancy",
      width: 230,
    },
    {
      field: "working_mode__working_mode",
      headerName: "Working mode",
      width: 230,
    },
    {
      field: "technologies",
      headerName: "Technologies",
      width: 230,
      type: "singleSelect",
      valueOptions: filterTechnologyList,
      renderCell: (params) => {
        return (
          <div className="cellWithStatus">
            <CustomeTooltip
              title={params.row.skills_tech_data && params.row.skills_tech_data.length > 0 ? params.row.skills_tech_data.map((element) => element.technology.name).filter((name) => name).join(', ') : "-"}
              data={params.row.skills_tech_data && params.row.skills_tech_data.length > 0 ? params.row.skills_tech_data.map((element) => element.technology.name).filter((name) => name).join(', ') : "-"}
            />
          </div>
        );
      },
    },
    {
      field: "job_description",
      headerName: "Description",
      width: 230,
      renderCell: (params) => {
        return (
          <div className="cellWithStatus">
            {params.row.job_description ? (
              <span
                dangerouslySetInnerHTML={{
                  __html: params.row.job_description.substring(0, 15) + "...",
                }}
              />
            ) : (
              "N/A"
            )}
          </div>
        );
      },
    },
    {
      field: "add_more_specifications",
      headerName: "Specifications",
      width: 230,
      type: "singleSelect",
      valueOptions: filterSpecificationList,
      renderCell: (params) => {
        return (
          <div className="cellWithStatus">
            <CustomeTooltip
              title={
                params.row.add_more_specifications_data &&
                  params.row.add_more_specifications_data.length > 0
                  ? getUnique(
                    params.row.add_more_specifications_data,
                    "id"
                  ).map(
                    (e, index) =>
                      `${index ? ", " : ""}${e.specification_name.trim()}`
                  )
                  : "-"
              }
              data={
                params.row.add_more_specifications_data &&
                  params.row.add_more_specifications_data.length > 0
                  ? getUnique(
                    params.row.add_more_specifications_data,
                    "id"
                  ).map(
                    (e, index) =>
                      `${index ? ", " : ""}${e.specification_name.trim()}`
                  )
                  : "-"
              }
            />
          </div>
        );
      },
    },
    {
      field: "status", headerName: "Status", width: 120, type: 'singleSelect',
      valueOptions: [{ label: "Active", value: 1 }, { label: "In Draft", value: 0 }],
      renderCell: (params) => {
        return (
          <div className={`cellWithStatus ${params.row.hiring_status}`}>
            {params.row.hiring_status === 1 ? 'Active' : 'In Draft'}
          </div>
        );
      },
    },
    {
      field: "action",
      headerName: "Action",
      width: "120",
      hideable: false,
      filterable: false,
      sortable: false,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            <UcActionComponent
              extraButton={() => handleOpen(params.row)}
              additionalAction={(tmsRole && tmsRole.role_key === 'hr' && params.row.job_id === null && params.row.hiring_status === 1) ? [{ title: "Create Job", key: 'epic' }] : ""}
              addMoreData={params.row.id}
              additionalActionIcon1="AddBoxRoundedIcon"
              moduleName="hiring"
              rowid={params.row.id}
              editLinkUrl={params.row.hiring_status == 0 ? `/edit-hiring-request/${params.row.id}` : ""}
              viewLinkUrl={`/view-hiring-request/${params.row.id}`}
              // isDelete={true}
              editButton={false}
              viewButton={false}
              statusUpdate={true}
              cloneButton={params.row.hiring_status === 1 ? `/clone-hiring-request/${params.row.id}` : ""}
            // deleteIconName="ArchiveIcon"
            // deleteButton={() => handleArchive(params.row)}
            />
          </div>

        );
      },
    },
  ];

  //custom pagination
  const handlePagination = (data) => {
    setCurrentPage(data);
    let newFilterData = JSON.parse(filterQuery);
    newFilterData["skip"] = data * pageSize;
    newFilterData["limit"] = limit;
    setLimit(limit);
    dispatch(getHiringRequestList(JSON.stringify(newFilterData)));
  };

  const handlePageSizeChange = (newPageSize) => {
    setPageSize(newPageSize.target.value);
    setCurrentPage(0);
    setLimit(newPageSize.target.value);
    let newFilterData = JSON.parse(filterQuery);
    newFilterData["skip"] = 0;
    newFilterData["limit"] = newPageSize.target.value;
    dispatch(getHiringRequestList(JSON.stringify(newFilterData)));
  };

  function CustomPagination() {
    return (
      <TablePagination
        component="div"
        count={
          (hiring_request_List &&
            hiring_request_List.data &&
            hiring_request_List.data.rows &&
            hiring_request_List.data.count) ||
          0
        }
        page={currentPage}
        rowsPerPage={pageSize}
        onPageChange={() => setCurrentPage(pageSize)}
        onRowsPerPageChange={handlePageSizeChange}
        ActionsComponent={(subProps) => (
          <TablePaginationActions
            {...subProps}
            handlePagination={handlePagination}
          />
        )}
      />
    );
  }

  // filter
  const onFilterChange = (filterModel) => {
    if (
      filterModel.items &&
      filterModel.items.length > 0 &&
      filterModel.items[0].columnField &&
      filterModel.items[0].value
    ) {
      let operatorValue =
        filterModel.items[0].operatorValue === "contains"
          ? "eq"
          : filterModel.items[0].operatorValue === "equals"
            ? "ieq"
            : filterModel.items[0].operatorValue === "is"
              ? "eq"
              : filterModel.items[0].operatorValue === "startsWith"
                ? "istartswith"
                : filterModel.items[0].operatorValue === "endsWith"
                  ? "iendswith"
                  : filterModel.items[0].operatorValue;

      let filterData;

      if (
        filterModel.items[0].columnField === "technologies" ||
        filterModel.items[0].columnField === "required_skill_set" ||
        filterModel.items[0].columnField === "preferred_location" ||
        filterModel.items[0].columnField === "add_more_specifications"
      ) {
        filterData = `{"filter":{"${[filterModel.items[0].columnField]}"
        :
        {"value":[${filterModel.items[0].value}],"operation": "contains"},"created_by":{"value":${userId} , "operation":"eq"}},"skip":0, "limit":${limit},"order_by":{"column":"-id"}, "columns":[${DefaultFilterColumns.HIRING_REQUEST_LIST_COLUMNS}]}`;
      } else {
        filterData = `{"filter":{"${[filterModel.items[0].columnField,]}"
        :
        {"value":"${filterModel.items[0].value}","operation": "${operatorValue}"},"created_by":{"value":${userId} , "operation":"eq"}},"skip":0, "limit":${limit},"order_by":{"column":"-id"}, "columns":[${DefaultFilterColumns.HIRING_REQUEST_LIST_COLUMNS}]}`;
      }
      setFilterQuery(filterData);
      setCurrentPage(0);
      dispatch(getHiringRequestList(filterData));
    }

    if (filterModel.items && filterModel.items.length === 0) {
      setCurrentPage(0);
      // setPageSize(25)
      setFilterQuery(
        `{"filter":{"created_by":{"value":${userId} , "operation":"eq"}},"skip":0,"limit":${limit},"order_by":{"column":"-id"}, "columns":[${DefaultFilterColumns.HIRING_REQUEST_LIST_COLUMNS}]}`
      );
      dispatch(
        getHiringRequestList(
          `{"filter":{"created_by":{"value":${userId} , "operation":"eq"}},"skip":0,"limit":${limit},"order_by":{"column":"-id"}, "columns":[${DefaultFilterColumns.HIRING_REQUEST_LIST_COLUMNS}]}`
        )
      );
    }
  };

  // sorting
  const handleSort = (sort) => {
    if (sort.length) {
      // console.log("filter",typeof(filterQuery),filterQuery)
      let newFilterData = JSON.parse(filterQuery);
      newFilterData["skip"] = 0;
      newFilterData["limit"] = limit;
      newFilterData["order_by"] = {
        column: sort[0].sort === "desc" ? "-" + sort[0].field : sort[0].field,
      };
      setFilterQuery(JSON.stringify(newFilterData));
      dispatch(getHiringRequestList(JSON.stringify(newFilterData)));
    } else {
      setFilterQuery(
        `{"filter":{"created_by":{"value":${userId},"operation":"eq"}},"skip": 0,"limit": ${limit},"order_by":{ "column": "-id" },"columns":[${DefaultFilterColumns.HIRING_REQUEST_LIST_COLUMNS}] }`
      );
    }
  };

  return (
    <div className="new">
      {open && <JobPost getJobData={getJobData} open={open} setOpen={setOpen} />}
      <Sidebar />
      <div className="newContainer">
        <Navbar />
        <div className="datatable">
          <div className="top-headings">
            <h3>My Hiring Requests</h3>
            <div>
              <RefreshButton
                api={getHiringRequestList}
                filter={filterQuery}
                style={{ marginTop: 0 }}
              />
            </div>
          </div>

          <ToastContainer />
          {status === STATUSES.LOADING && <LoaderForm />}
          <DataGrid
            filterMode="server"
            className="datagrid"
            columnVisibilityModel={columnVisibilityModel}
            onColumnVisibilityModelChange={(newModel) =>
              setColumnVisibilityModel(newModel)
            }
            rows={
              hiring_request_List &&
                hiring_request_List.data &&
                hiring_request_List.data.rows
                ? hiring_request_List.data.rows
                : []
            }
            columns={actionColumn}
            pageSize={pageSize}
            style={{ fontSize: 16 }}
            onFilterModelChange={onFilterChange}
            components={{
              Pagination: CustomPagination,
              NoRowsOverlay: CustomNoRowsOverlay,
            }}
            onSortModelChange={(sort) => handleSort(sort)}
          />
        </div>

        {/* for delete popup */}
        <DeleteDialogModal
          open={archiveOpen}
          onClose={setArchiveOpen}
          heading={`${statusHiringRequest}`}
          paragraph={`Are you sure to ${statusHiringRequest} hiring request`}
          handleArchive={handleClickOpen}
          id={archiveId}
        />
      </div>
    </div>
  );
}
export default MyHiringRequests;
