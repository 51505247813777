import React, { useState, useEffect } from "react";
import "./departments.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { Link, useNavigate, useParams } from "react-router-dom";
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import dataService from "../../redux/services/data.service";
import { toast, ToastContainer } from "react-toastify";
import LoaderForm from "../../components/common-function/LoaderForm";
import LocalStorageDataService from "../../components/common-function/GetLocalstorage";
import { Tooltip } from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { InputFields } from "../../components/common-function/InputFields";

const formErrData = Object.freeze({
    nameErr: 'Department name is required',
    codeErr: "Code is required",
    descErr: "Description is required",
    statusErr: "Status is required",
});

const initialFormData = Object.freeze({
    name: "",
    code: "",
    description: "",
    status: "",
    created_by: LocalStorageDataService.userDetail() && LocalStorageDataService.userDetail().id,
});

function AddDepartment({ title }) {

    const { id } = useParams()
    const navigate = useNavigate();

    const [showloader, setShowLoader] = useState(false)
    const [formData, setFormData] = useState(initialFormData)
    const [formErrors, setFormErrors] = useState(formErrData);
    const [errStatus, setErrStatus] = useState(false);

    // const handleChange = (evt) => {
    //     const { name, value } = evt.target;
    //     setFormData({ ...formData, [name]: value });
    // }
    const handleChange = (evt) => {
        const { name, value } = evt.target;
        //for blank space and special character
        if (name === 'name') {
            if (/^[a-zA-Z0-9 ]*$/.test(value)) {
                setFormData({ ...formData, [name]: value });
            }
        }
        //for blank space and special character
        else if (name === 'description') {
            if (!/\s/.test(value)) {
                setFormData({ ...formData, [name]: value });
            }
        }
        //for blank space and special character
        else if (name === 'code') {
            if (/^[a-zA-Z0-9]*$/.test(value)) {
                setFormData({ ...formData, [name]: value });
            }
        }
        else {
            setFormData({ ...formData, [name]: value });
        }
    }
    

    const loadDataById = async () => {
        setShowLoader(true)
        const response = await dataService.departmentById(id).catch(error => {
            setShowLoader(false)
            return error;
        })
        if (response && response.data && response.data.data) {
            setFormData(response.data.data)
            setShowLoader(false)
        } else {
            setShowLoader(false)
        }
    }

    useEffect(() => {
        id && loadDataById()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id])

    const onSubmit = async (e) => {
        e.preventDefault();
        const request = formData
        
        if (!formData.name || !formData.code || !formData.description || formData.status === "") {
            setErrStatus(true)
            toast.error("Please fill in all fields before submitting")
        }
        if (formData.name !== "" && formData.code !== "" && formData.description !== "" && formData.status !== "") {
            setErrStatus(false)
            setFormErrors("")

            const response = !id ? await dataService.addDepartment(request).catch(error => {
                setShowLoader(false)
                return error;
            }) : await dataService.updateDepartment(id, request);

            if (response && response.data && response.data.status === 200) {
                toast.success(response.data.message);
                setTimeout(() => {
                    navigate('/all-department')
                }, 800)
            } else {
                toast.error(response.data.message)
            }
        }
    }

    return (
        <div className="new">
            <Sidebar />
            <div className="newContainer">
                <Navbar />
                <div className="top-headings">
                    <h3>{title}</h3>
                    <Tooltip title="Back">
                        <Link to={"/all-department"} className="btn btn-add-new"><ArrowBackIcon /></Link>
                    </Tooltip>
                </div>
                <ToastContainer />

                {showloader ? <LoaderForm /> :
                    <form onSubmit={onSubmit}>
                        <div className="add-new-user-form">

                            <div className="formInput">
                                <InputFields
                                    label={'Department Name'}
                                    name="name"
                                    defaultValue={formData.name}
                                    type="text"
                                    placeholder="Department name"
                                    onChange={handleChange}
                                    errStatus={errStatus}
                                    formValues={formData}
                                    formErr={formErrors.nameErr}
                                />
                            </div>

                            <div className="formInput">
                                <InputFields
                                    label={'Code'}
                                    name="code"
                                    defaultValue={formData.code}
                                    type="text"
                                    placeholder="Code"
                                    onChange={handleChange}
                                    errStatus={errStatus}
                                    formValues={formData}
                                    formErr={formErrors.codeErr}
                                />
                            </div>

                            <div className="formInput">
                                <InputFields
                                    label={'Description'}
                                    name="description"
                                    defaultValue={formData.description}
                                    type="text"
                                    placeholder="Description"
                                    onChange={handleChange}
                                    errStatus={errStatus}
                                    formValues={formData}
                                    formErr={formErrors.descErr}
                                />
                            </div>

                            <div className="formInput">
                                <FormControl>
                                    <FormLabel id="demo-row-radio-buttons-group-label">Status</FormLabel>
                                    <RadioGroup
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="status"
                                        value={formData.status}
                                        onChange={(e) => handleChange(e)}
                                    >
                                        <FormControlLabel value={true} control={<Radio />} label="Active" />
                                        <FormControlLabel value={false} control={<Radio />} label="Inactive" />
                                    </RadioGroup>
                                    {errStatus === true && formData && formErrors && formErrors.statusErr && <p style={{ color: "#96332c", fontSize: 13 }}>{formData.status === "" ? formErrors.statusErr : ""}</p>}

                                </FormControl>
                            </div>
                        </div>
                        <button type="submit" className="btn btn-submit">Submit</button>

                    </form>
                }
            </div>
        </div>
    )
}
export default AddDepartment