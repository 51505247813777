
import "../../pages/master/master.scss"
import { toast } from "react-toastify";
import { Dialog, DialogContent } from "@mui/material";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { jobBudgetReviseCreate } from "../../redux/features/jobPostSlice";
import { STATUSES } from "../../redux/common-status/constant";
import * as React from 'react';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import { Close } from "@mui/icons-material";
import { InputFields } from "../../components/common-function/InputFields";

const initialFormData = Object.freeze({
    from_salary_per_annum: "",
    to_salary_per_annum: "",
    reason_for_revise: "",
});

const formErrData = Object.freeze({
    from_salary_budgetErr: 'From Salary Budget is required',
    to_salary_budgetErr: " To Salary Budget is required",
    reason_for_reviseErr: "Reason for revise budget is required"
});

const RevisedSalaryJob = ({ open, setOpen, jobData }) => {
    const dispatch = useDispatch();
    const [formValues, setFormValues] = useState({ ...initialFormData, client_budget_from: jobData.client_budget_from, client_budget_to: jobData.client_budget_to })
    const [formErr] = useState(formErrData)
    const [errStatus, setErrStatus] = useState(false);
    const { status, error, revised_Salary_Create } = useSelector(state => state.jobpost)
    const [reload, setReload] = useState(false)

    React.useEffect(() => {
        if (status === STATUSES.FAILURE && error) {
            toast.error(error.message)
            setReload(false)
        }
        // eslint-disable-next-line
    }, [error])

    if (reload === true && status === `${STATUSES.SUCCESS}_revised_Salary_Create` && revised_Salary_Create && revised_Salary_Create.message) {
        console.log("====", revised_Salary_Create)
        if (revised_Salary_Create.status === 200) {
            toast.success(revised_Salary_Create.message)
            setOpen(false);
            setFormValues(initialFormData);
        } else {
            toast.error(revised_Salary_Create.message)
        }
        setReload(false)
    }

    const handleClose = () => {
        setOpen(false);
        setErrStatus(false);
        setFormValues(initialFormData);
    };

    const handleChangeText = (evt) => {
        const { name, value } = evt.target || evt;
        setFormValues({ ...formValues, [name]: value });
    }

    const submitData = (e) => {
        e.preventDefault()

        if (formValues.from_salary_per_annum === "" || formValues.to_salary_per_annum === "" || formValues.reason_for_revise === "") {
            setErrStatus(true)
        }

        if (formValues.from_salary_per_annum !== "" && formValues.to_salary_per_annum !== "" && formValues.reason_for_revise !== "") {
            setErrStatus(false)
            // handleClose()
            setReload(true)
            let addvalues = {
                job: jobData.id,
                approval_status: jobData.approver_status,
                from_salary_per_annum: +formValues.from_salary_per_annum,
                to_salary_per_annum: +formValues.to_salary_per_annum,
                reason_for_revise: formValues.reason_for_revise
            }

            dispatch(jobBudgetReviseCreate(addvalues))
        }

    }

    return (
        <div className="jiraStatus">
            <Dialog
                hideBackdrop
                fullWidth
                maxWidth={"xl"}
                open={open}
                onClose={handleClose}
                aria-labelledby="child-modal-title"
                aria-describedby="child-modal-description"
            >
                <Button
                    edge="start"
                    color="inherit"
                    onClick={handleClose}
                    aria-label="close"
                    style={{ position: "absolute", top: "10px", right: "10px" }}>
                    <Close />
                </Button>
                <DialogTitle style={{ background: '#2196f3', color: '#fff', marginBottom: "20px" }}>Revise Salary Budget</DialogTitle>
                <DialogContent>
                    <form>
                        <div className="add-new-user-form">

                            <React.Fragment>
                                <div className="formInput">
                                    <InputFields
                                        label={'Previous Salary Budget From'}
                                        name="client_budget_from"
                                        defaultValue={formValues.client_budget_from}
                                        type="number"
                                        placeholder="Previous Salary Budget From"
                                        disabled={true}
                                        errStatus={errStatus}
                                        formValues={formValues.client_budget_from}
                                        formErr={formErr.budgetFormErr}
                                    />
                                </div>

                                <div className="formInput">
                                    <InputFields
                                        label={'Previous Salary Budget To'}
                                        name="client_budget_to"
                                        defaultValue={formValues.client_budget_to}
                                        type="number"
                                        placeholder="Previous Salary Budget To"
                                        disabled={true}
                                        onChange={handleChangeText}
                                        errStatus={errStatus}
                                        formValues={formValues.client_budget_to}
                                        formErr={formErr.budgetToErr}
                                    />
                                </div>
                                <div className="formInput">
                                    <InputFields
                                        label={'Salary Budget From'}
                                        name="from_salary_per_annum"
                                        defaultValue={formValues.from_salary_per_annum}
                                        type="number"
                                        placeholder="Salary Budget From"
                                        onChange={handleChangeText}
                                        errStatus={errStatus}
                                        formValues={formValues.from_salary_per_annum}
                                        formErr={formErr.from_salary_budgetErr}
                                    />
                                </div>
                                <div className="formInput">
                                    <InputFields
                                        label={'Salary Budget To'}
                                        name="to_salary_per_annum"
                                        defaultValue={formValues.to_salary_per_annum}
                                        type="number"
                                        placeholder="Salary Budget To"
                                        onChange={handleChangeText}
                                        errStatus={errStatus}
                                        formValues={formValues.to_salary_per_annum}
                                        formErr={formErr.to_salary_budgetErr}
                                    />
                                </div>
                                <div className='formInput' style={{ flexBasis: "100%" }}>
                                    <label>Reason for revising the budget </label>
                                    <textarea
                                        className="select"
                                        placeholder="Reason for revising the budget"
                                        onChange={handleChangeText}
                                        name="reason_for_revise"
                                    />
                                    {errStatus === true && formValues && formValues.reason_for_revise === "" && <p style={{ color: "#96332c", fontSize: '13px' }}>{formErr.reason_for_reviseErr}</p>}
                                </div>
                            </React.Fragment>

                        </div>
                        <Button style={{ minWidth: '17%' }} className="submit-modal" onClick={submitData}>Submit</Button>
                    </form>
                </DialogContent>
            </Dialog>
        </div >
    )
}

export default RevisedSalaryJob