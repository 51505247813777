import React, { useEffect} from "react";
//import "./UcActionComponent.scss";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import IconButton from '@mui/material/IconButton';
import { ChatBubble, EventNote, Feedback, PersonOff, ScheduleSend } from "@mui/icons-material";

function ActionComponent(props) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open1 = Boolean(anchorEl);
    const handleClickdrop = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClosedrop = () => {
        setAnchorEl(null);
    };

    // const nothing = () => {
    //     //no action will perform
    // }

    useEffect(() => {
        const handleScroll = () => {
          setAnchorEl(null);
        };
        window.addEventListener("scroll", handleScroll);
        return () => {
          window.removeEventListener("scroll", handleScroll);
        };
      }, []);

    return (
        <div className="action-all-buttons">
            <IconButton
                onClick={handleClickdrop}
                size="small"
                aria-controls={open1 ? 'action-dropdown' : undefined}
                aria-haspopup="true"
                aria-expanded={open1 ? 'true' : undefined}
            >
                <MoreVertIcon fontSize="small" />

            </IconButton>
            <Menu
                anchorEl={anchorEl}
                className="MuiMenu-list-dropdown2"
                id="action-dropdown"
                open={open1}
                onClose={handleClosedrop}
                onClick={handleClosedrop}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,

                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                {/* {(props.params.row.agent_id === props.userId && ((!props.params.row.interview_status_id) || (props.params.row.interview_status &&
                    props.params.row.interview_status__status_key !== 'reschedule' && props.params.row.interview_status__status_key !== 'cancel_interview' && props.params.row.interview_status__status_key !== 'confirm_reschedule' && props.params.row.interview_status__status_key !== 'cancel_interview' && props.params.row.interview_status__status_key !== 'done' && props.params.row.interview_status__status_key !== '-t_schedule'))) &&
                    <MenuItem onClick={() => { props.activeModel(props.params.row, 'r') }}>
                        <Schedule style={{ color: '#bd7100' }} /> <span style={{ color: '#bd7100' }}> Re-schedule</span>
                    </MenuItem>
                } */}
                {(props.params.row.interviewer_id === props.userId && ((!props.params.row.interview_status_id) || (props.params.row.interview_status__status_key !== 'cancel_interview' && props.params.row.interview_status__status_key !== 'done' && props.params.row.interview_status__status_key !== 'accept_schedule' && props.params.row.interview_status__status_key !== 'in_progress' && props.params.row.interview_status__status_key !== "reschedule"))) &&
                    <MenuItem onClick={() => props.activeModel(props.params.row, 'rr')}>
                        <ScheduleSend style={{ color: '#945b00' }} /> <span style={{ color: '#945b00' }}> Re-schedule Request</span>
                    </MenuItem>
                }

                {props.params.row.agent_id === props.userId && props.params.row.interview_status__status_key !== 'done' && (props.params.row.interview_status__status_key === 'reschedule_request' || props.params.row.interview_status__status_key !== 'cancel_interview' || props.params.row.interview_status__status_key === 'request_to_reschedule' ) &&
                    <MenuItem onClick={() => props.activeModel(props.params.row, 'cr')}>
                        <EventNote style={{ color: '#3498db' }} /> <span style={{ color: '#3498db' }}> Confirm Reschedule </span>
                    </MenuItem>}
                    {props.params.row.agent_id === props.userId  &&
                    <MenuItem onClick={() =>window.location.href = `/my-task?story_id=${props.params.row.story_id}`}>
                        <EventNote style={{ color: '#3498db' }} /> <span style={{ color: '#3498db' }}> Redirect to task list </span>
                    </MenuItem>}
                {/* {(props.activeColumn === false && props.params.row.agent_id === props.userId && props.params.row.interview_status && props.params.row.interview_status__status_key === 'reschedule_request') &&
                     <MenuItem onClick={() => props.activeModel(props.params.row, 'cr')}>
                        <EventNote style={{ color: '#3498db' }} /> <span style={{ color: '#3498db' }}> Confirm Re-schedule </span>
                    </MenuItem>
                } */}
                {(props.params.row.interviewer_id === props.userId && props.params.row.interview_status__status_key && (props.params.row.interview_status__status_key !== 'accept_schedule'&& props.params.row.interview_status__status_key !== 'cancel_interview' && props.params.row.interview_status__status_key !== 'in_progress' && props.params.row.interview_status__status_key !== 'done' && props.params.row.interview_status__status_key !== "reschedule_request" && (props.params.row.interview_status__status_key !== "reschedule" || props.params.row.interview_status__status_key === "confirm_reschedule" || props.params.row.interview_status__status_key === "pending"))) &&
                    <MenuItem onClick={() => props.activeModel(props.params.row, 'as')}>
                        <EventNote style={{ color: 'green' }} /> <span style={{ color: 'green' }}> Accept Schedule</span>
                    </MenuItem>
                }

                {/* {(props.params.row.interviewer_id === props.userId && props.params.row.interview_status && props.params.row.interview_status__status_key === 'accept_schedule') &&
                    <MenuItem onClick={() => props.activeModel(props.params.row, 'd')}>
                        <EventNote style={{ color: 'blue' }} /> <span style={{ color: 'blue' }}> Interview Done</span>
                    </MenuItem>
                } */}
                {(props.params.row.interviewer_id === props.userId && props.params.row.interview_status__status_key === 'accept_schedule') &&
                    <MenuItem onClick={() => props.activeModel(props.params.row, 's')}>
                        <EventNote style={{ color: 'blue' }} /> <span style={{ color: 'blue' }}> Start</span>
                    </MenuItem>
                }
                {(props.params.row.interviewer_id === props.userId && props.params.row.interview_status__status_key === 'accept_schedule') &&
                    <MenuItem onClick={() => props.activeModel(props.params.row, 'rr')}>
                        <EventNote style={{ color: '#3498db' }} /> <span style={{ color: '#3498db' }}> Reschedule Request</span>
                    </MenuItem>
                }
                {/* {(props.params.row.interviewer_id === props.userId && props.params.row.interview_status && props.params.row.interview_status__status_key === 'accept_schedule' && props.params.row.interview_status__status_key !== 'in_progress') &&
                    <MenuItem onClick={() => props.activeModel(props.params.row, 'ci')}>
                        <EventNote style={{ color: 'red' }} /> <span style={{ color: 'red' }}> Cancel Interview</span>
                    </MenuItem>
                } */}
                {((props.params.row.interviewer_id === props.userId || props.params.row.agent_id === props.userId)) && ((!props.params.row.interview_status_id) || (props.params.row.interview_status__status_key !== 'accept_schedule' && props.params.row.interview_status__status_key !== 'done' && props.params.row.interview_status__status_key !== 'cancel_interview' && props.params.row.interview_status__status_key !== 'reschedule_request' && props.params.row.interview_status__status_key !== 'in_progress' && props.params.row.interview_status__status_key !== "reschedule" )) &&
                    <MenuItem onClick={() => props.activeModel(props.params.row, 'ci')}>
                        <PersonOff style={{ color: 'red' }} /> <span style={{ color: 'red' }}> Cancel Interview </span>
                    </MenuItem>
                }


                {(props.params.row.interviewer_id === props.userId && props.params.row.interview_status__status_key === 'in_progress') &&
                    <MenuItem onClick={() => props.activeModel(props.params.row, 'd')}>
                        <EventNote style={{ color: 'blue' }} /> <span style={{ color: 'blue' }}> Done & Add Feedback</span>
                    </MenuItem>
                }
                {(props.params.row.interviewer_id === props.userId && props.params.row.interview_status__status_key === 'in_progress' && props.params.row.interview_status__status_key !== 'reschedule_request') &&
                    <MenuItem onClick={() => props.activeModel(props.params.row, 'i')}>
                        <EventNote style={{ color: 'red' }} /> <span style={{ color: 'red' }}> Interrupted</span>
                    </MenuItem>
                }
                {/* {(props.params.row.interviewer_id === props.userId && props.params.row.interview_status__status_key === 'done' && !props.params.row.feedback_by_interviewer) &&
                    <MenuItem onClick={() => props.activeModel(props.params.row, 'af')}>
                        <Feedback style={{ color: 'orange' }} /> <span style={{ color: 'orange' }}> Add Feedback</span>
                    </MenuItem>
                } */}

                {(props.params.row.interviewer_id === props.userId && props.params.row.interview_status__status_key === 'done' && props.params.row.feedback_by_interviewer) &&
                    <MenuItem onClick={() => props.activeModel(props.params.row, 'vf')}>
                        <Feedback style={{ color: 'green' }} /> <span style={{ color: 'green' }}> View Feedback</span>
                    </MenuItem>
                }

                {((!props.params.row.interview_status_id) || (props.params.row.interview_status__status_key !== 'pending' && props.params.row.interview_status__status_key !== 'accept_schedule' && props.params.row.interview_status__status_key !== 'in_progress'
                )) &&
                    <MenuItem onClick={() => props.activeModel(props.params.row, 'sc')}>



                        <ChatBubble style={{ color: 'darkblue' }} /> <span style={{ color: 'darkblue' }}> Show Comments </span>
                    </MenuItem>


                }
            </Menu>
        </div>

    )
}
export default ActionComponent