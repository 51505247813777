import React, { useState, useEffect, useMemo } from 'react'
import "./manageProject.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { Link, useNavigate } from "react-router-dom";
import api from '../../redux/APIS/api';
import DynamicTable from '../../components/dynamic-table/DynamicTable';
import { toast, ToastContainer } from "react-toastify";
import Sync from '@mui/icons-material/Sync';
import dataService from '../../redux/services/data.service';
import moment from 'moment';
import { getGridSingleSelectOperators } from '@mui/x-data-grid';
import Filter from '../../components/Filter';
import { Tooltip } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import { Refresh } from '@mui/icons-material';
import { DefaultFilterColumns } from '../../components/common-function/DefaultFilterColumns';
import { ExpandMore, ExpandLess } from "@mui/icons-material";
import LocalStorageDataService from "../../components/common-function/GetLocalstorage";
import { useDispatch, useSelector } from 'react-redux';
import { defaultallprojectColumn } from '../../redux/features/projectSlice';

const default_All_Project_Columns = LocalStorageDataService.get_default_allproject_column()

const AllProjects = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate()
  const [projectRows, setProjectRows] = useState([])
  const [pageSize, setPageSize] = useState(25);
  // const [limit, setLimit] = useState(25)
  const [currentPage, setCurrentPage] = useState(0);
  const [skip, setSkip] = useState(0);
  const [rowCount, setRowCount] = useState(0);
  const [loading, setLoading] = useState(false)
  const [params, setParams] = useState({})
  const [optionsObj, setOptionsObj] = useState({})
  const [specialFilter, setspecialFilter] = useState(false);
  const [expandedID, setExpandedID] = useState()

  // custom filter
  const [fieldName, setFieldName] = useState("")
  const [defaultValue, setDefaultValue] = useState({})
  const [resourcesLists, setResourcesLists] = useState([])
  

  const { defaultAllProjectColumns } = useSelector(state => state.projects)

  const [columnVisibilityModel, setColumnVisibilityModel] = useState(default_All_Project_Columns && default_All_Project_Columns.all_project_column ? default_All_Project_Columns.all_project_column : {
    id: false,    
    created_on: false,
  
});

const setKeyForList = (obj) => {
  let data;
  if (default_All_Project_Columns) {
      data = { 'all_project_column': obj, 'reported_projectlist_column': default_All_Project_Columns.reported_projectlist_column, 'all_projectlist_column': default_All_Project_Columns.all_projectlist_column, 'task_column': default_All_Project_Columns.task_column }
  } else {
      data = { 'all_project_column': obj }
  }
  if (defaultAllProjectColumns.reported_projectlist_column) {
      data['reported_projectlist_column'] = defaultAllProjectColumns.reported_projectlist_column
  }
  if (defaultAllProjectColumns.all_projectlist_column) {
      data['all_projectlist_column'] = defaultAllProjectColumns.all_projectlist_column
  }
  if (defaultAllProjectColumns.task_column) {
      data['task_column'] = defaultAllProjectColumns.task_column
  }
  dispatch(defaultallprojectColumn(data))
}

  const projectsCols = [
    { field: "id", headerName: "ID", width: 70, filterable: false },
    {
      field: "project_name",
      headerName: "Project",
      width: 200,
      renderCell: (params) => {
        return (
          <div className="cellWithImg">
            {params.row.project_name}
          </div>
        );
      },
    },
    {
      field: "project_key",
      headerName: "Project Key",
      width: 160,
    },

    {
      field: "is_jira_project_text",
      headerName: "Is Jira Project",
      width: 140,
      // hideable: false,
      // filterable:false,
      sortable: false,

      type: 'singleSelect',
      valueOptions: [{ label: "Yes", value: "Yes" }, { label: "No", value: "No" }],
    },
    {
      field: "projectsResources",
      headerName: "Assignee",
      width: 340,
      hideable: true,
      filterable: true,
      sortable: false,
      type: 'singleSelect',
      valueOptions: optionsObj.projectsResourcesNames,
      renderCell: (params) => {
        return (
          <div style={{ width: '100%' }} >
            <div className="cellWithImg" style={{ width: '90%', display: 'inline-block' }}>
              {params.row.projectsResourcesNames && params.row.projectsResourcesNames.length ?
                params.row.projectsResourcesNames.map((resource, index) => {
                  return (
                    <p key={index} style={{ margin: 0 }}>
                      {index === 0 && resource} {index === 0 && params.row.projectsResourcesNames.length > 1 && ","}
                      {params.row.projectsResourcesNames.length > 1 && index === 1 && resource} {index === 1 && params.row.projectsResourcesNames.length > 2 && ","}
                      {expandedID === params.id && index > 1 ? `${resource} ${index === params.row.projectsResourcesNames.length - 1 ? '' : ','}` : null}
                    </p>
                  )
                })
                : "N/A"
              }
            </div>
            <div style={{ width: '10%', display: 'inline-block' }}>
              {(params.row.projectsResourcesNames && params.row.projectsResourcesNames.length > 2) ?
                expandedID === params.id ?
                  <Tooltip title="View less">
                    <ExpandLess onClick={() => handleExpansion(params.id)} />
                  </Tooltip> :
                  <Tooltip title="View more">
                    <ExpandMore onClick={() => handleExpansion(params.id)} />
                  </Tooltip>
                : ""}
            </div>
          </div>
        );
      },
    },

    {
      field: "created_on",
      headerName: "Created On",
      type: "date",
      width: 120,
      renderCell: (params) => {
        return (
          <div className="cellWithImg">
            {params.row.created_on ? moment(params.row.created_on).format('DD-MM-YYYY') : "N/A"}
          </div>
        );
      },
    },
    {
      field: "status_value",
      headerName: "Status",
      width: 90,
      type: 'singleSelect',
      valueOptions: [{ label: "Active", value: "True" }, { label: "Inactive", value: "False" }],
      renderCell: (params) => {
        return (
          <div className={`cellWithStatus ${params.row.status}`}>
            {params.row.status === 3 ? "Archive" : params.row.status === true ? "Active" : "Inactive"}
          </div>
        );
      },
    },
  ];

  // Load All Projects
  const loadProjects = async () => {
    const resourcesList = resourcesLists.length > 0 ? resourcesLists : await loadProjectResources();
    // const data={...params,  no_limit:"True"}
    const data = specialFilter ? { ...params, filter: { ...params.filter, "status": { "value": "True", "operation": "eq" } }, "order_by": { "column": "-id" }, skip: skip, "limit": pageSize || 25 } : { ...params, filter: { ...params.filter }, "order_by": { "column": "-id" }, "skip": skip, "limit": pageSize || 25 }
    if (resourcesList) {
      const apiEndPoint = specialFilter ? "/resources/resource-project" : "projects/main"
      const res = await api.get(`${apiEndPoint}?where=${JSON.stringify(data)}`)
      setLoading(false);
      if (res && res.data && res.data.data) {
        let resData = res.data.data.rows
        specialFilter ?
          setProjectRows(resData.map((row) => (
            {
              ...row,
              id: row.project.id,
              is_jira_project_text: row.project.is_jira_project ? "Yes" : "No",
              status_text: row.project.status ? "Active" : "Inactive",
              project_name: row.project.project_name,
              project_key: row.project.project_key,
              project_type_name: row.project.project_type_name,
              is_jira_project: row.project.is_jira_project,
              is_private: row.project.is_private,
              status: row.project.status,
              status_value: row.project.status ? "True" : "False",
              // resource:row.resource.id,
              description: row.project.description,
              projectsResources: resourcesList.filter((element) => element.project__id === row.project.id),
              projectsResourcesNames: resourcesList.map((element) => { return element.project__id === row.project__id && element.resource__name }).filter(e => e !== false),

              resource: resourcesList.map((element) => { return element.project__id === row.project.id && element.resource__id }).filter(e => e !== false),
            }
          )))
          :
          setProjectRows(resData.map((row) => (
            {
              ...row,
              is_jira_project_text: row.is_jira_project ? "Yes" : "No",
              status_text: row.status ? "Active" : "Inactive",
              status_value: row.status ? "True" : "False",
              project_type_name: row.project_type.project_type_name,
              resource: null,
              projectsResources: resourcesList.filter((element) => element.project__id === row.id),
              projectsResourcesNames: resourcesList.map((element) => { return element.project__id === row.id && element.resource__name }).filter(e => e !== false),

            }

          )))
        // console.log(row,"row")
        setRowCount(res.data.data.count ? res.data.data.count : 0)
      }
    }
  }

  const handleExpansion = (e_id) => {

    if (e_id === expandedID)
      return setExpandedID(undefined);
    setExpandedID(e_id);

  }

  // Sync Projects
  const syncProjects = async () => {
    const res = await api.get("/jira/get-projects/0/24")
    if (res && res.status === 200) {
      toast.success(res.data.message)
      setLoading(true)
      loadProjects();
    };

  }

  //  Load Project Resources
  const loadProjectResources = async () => {
    //  Load all Project Resources
    const qParam = { "filter": { "status": { "value": "True", "operation": "eq" } }, "no_limit": "True", "columns": DefaultFilterColumns.MY_PROJECT_LIST_COLUMNS };
    const res = await api.get(`/resources/resource-project?where=${JSON.stringify(qParam)}`)
    if (res && res.data && res.data.data && res.data.data.rows) {
      const resourceArray = res.data.data.rows
      setResourcesLists(resourceArray)
      return resourceArray
    }
    return [];

  }

  //  LoadResourceList
  const loadResourceList = async () => {
    const data = { filter: { "status": { "value": "True", "operation": "eq" } }, "order_by": { "column": "name" }, "skip": 0, "no_limit": "True", "columns": DefaultFilterColumns.MAIN_RESOURCE_DROUPDOWN_COLUMNS }
    const response = await dataService.resource_list(JSON.stringify(data))
    response && response.data && response.data.data && response.data.data.rows &&
      setOptionsObj({
        ...optionsObj, projectsResourcesNames: response.data.data.rows.map(e => { return { value: e.id, label: e.name + "(" + e.email + ")" } })
          || []
      });
  }

  // // // Edit Project
  const editProject = (data) => {
    navigate('/edit-project', { state: data })
  }
  // View Project
  const viewProject = async (data) => {
    navigate('/view-project', { state: { id: data.id } })
  }

  useEffect(() => {
    setLoading(true)
    loadProjects();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [skip, pageSize, params])

  useEffect(() => {
    loadResourceList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getRowHeight = (params) => {
    if (params.id === expandedID && params && params.model && Array.isArray(params.model.projectsResourcesNames))
      return 20 * params.model.projectsResourcesNames.length + 20;
    return 52; // Default row height
  }

  // Custom filter
  function CustomFilterInputValue(props) {
    const { item, applyValue } = props;
    const handleFilterChange = (evt) => {
      if (evt) {
        setDefaultValue(evt)
        const { value } = evt;
        applyValue({ ...item, value: value });
      }
    };
    return (
      fieldName === "projectsResources" ?
        <Filter options={optionsObj.projectsResourcesNames} onChange={handleFilterChange} defaultValue={defaultValue} />
        : ""
    );
  }
  const columns = useMemo(() =>
    projectsCols.map((col) => {
      if (col.field === "projectsResources") {
        return {
          ...col,
          filterOperators: getGridSingleSelectOperators()
            .filter((operator) => operator.value !== "isAnyOf" && operator.value !== "not")
            .map((operator) => ({
              ...operator,
              InputComponent: operator.InputComponent
                ? CustomFilterInputValue
                : undefined
            }))
        };
      }
      return col;
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }), [projectsCols]
  );

  return (
    <div className="new">
      <Sidebar />

      <div className="newContainer">
        <Navbar />

        <div className="top-headings">
          <h3>All Projects</h3>
          <div>
            <Tooltip title="Sync"><button className="btn-add-new" onClick={() => syncProjects()} > <Sync /></button ></Tooltip>
            <button onClick={() => { setLoading(true); loadProjects(); }} className="btn-add-new ">
              <Tooltip title="Reload">
                <Refresh />
              </Tooltip>
            </button>
            <Tooltip title="Add New Project ">
              <Link to="/add-new-project" className="btn-add-new ">
                <AddIcon />
              </Link>
            </Tooltip>


          </div>

        </div>
        <ToastContainer />
        {
          <>
            {
              <DynamicTable
                moduleName="allProjects"
                tableColumns={columns}
                tableRows={!loading && projectRows.length ? projectRows : []}
                paginationMode={"server"}
                rowCount={rowCount}
                loading={loading}
                currentPage={currentPage} pageSize={pageSize} skip={skip} params={params}
                setPageSize={setPageSize} setCurrentPage={setCurrentPage} setSkip={setSkip} setParams={setParams}
                onViewDetail={viewProject}
                onEdit={editProject}
                editLink={"/edit-project"}
                // columnVisibilityModel={{
                //   id: false,
                // }}
                columnVisibilityModel={defaultAllProjectColumns && defaultAllProjectColumns.all_project_column ? defaultAllProjectColumns.all_project_column : columnVisibilityModel}
                onColumnVisibilityModelChange={(newModel) => { setColumnVisibilityModel(newModel); setKeyForList(newModel) }
                }
                setspecialFilter={setspecialFilter}
                optionsObj={optionsObj}
                setFieldName={setFieldName}
                setDefaultValue={setDefaultValue}
                getRowHeight={getRowHeight}
              />
            }
          </>
        }
      </div>
    </div>
  )
}

export default AllProjects