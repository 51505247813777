import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import DataService from "../services/data.service";
import { STATUSES } from "../common-status/constant";
import LocalStorageDataService from "../../components/common-function/GetLocalstorage"
const { REACT_APP_ENV } = process.env;
const user = LocalStorageDataService.userDetail()

export const callCreate = createAsyncThunk("phone-call/create", async (data) => {
    const res = await DataService.calling_api(data);
    return res.data;
    // const res = {"data":{"id":7239,"candidate_information":6549,"phone_number":"8736954647","country_code":"91","status":true,"created_on":"2023-03-05T13:49:09.416817Z","created_by":127,"updated_on":"2023-03-05T13:49:09.422533Z","updated_by":null},"message":"Record Saved","status":200}
    // return res;
});

export const getCallLogDetails = createAsyncThunk("call-log/details", async (data) => {
    const res = await DataService.get_call_log(data);
    return res.data;
})
export const callDisconnect = createAsyncThunk("call-disconnect/api", async (id) => {
    const res = await DataService.call_disconnect_api(id);
    return res.data;
})

export const defaultCallFilter = createAsyncThunk("set-call-filter", async (data) => {
    return data;
});
export const getReportCallLogDetails = createAsyncThunk("call-log/report", async (id) => {
    const res = await DataService.call_log_report(id);
    return res.data;
})

export const filterForPagination = createAsyncThunk("set-pagination-where", async (data) => {
    return data;
});

export const getMyCallLogDetails = createAsyncThunk("my-call-log/details", async (data) => {
    const res = await DataService.get_my_call_log_report(data);
    return res.data;
})

export const getAgentWiseResource = createAsyncThunk("agent-wise-resource/list", async (data) => {
    const res = await DataService.get_agent_wise_resource(data);
    return res.data;
})

export const updateCandidateContactNumber = createAsyncThunk("update-candidate/contact-no", async (data) => {
    if (data === 'type') {
        return [];
    }
    const res = await DataService.candidate_phone_add(data);
    return res.data;
});

export const defaultPhoneCallDetailsColumn = createAsyncThunk("phone-calldetails-column", async (data) => {
    return data;
});

export const defaultAllPhoneCallDetailsColumn = createAsyncThunk("All-phone-calldetails-column", async (data) => {
    return data;
});

const callingSlice = createSlice({
    name: "callingSlice",
    initialState: {
        call_Create: [],
        status: STATUSES.IDLE,
        error: '',
        callDetails: [],
        call_disconnect: [],
        defaultCallFilters: {},
        callLogReport: [],
        pagination_filters: {},
        mycallDetails: [],
        agentResourceList: [],

        updateCandidateContactNo: [],
        updatePhoneStatus: false,
        createPhoneStatus: false,
        defaultphoneColumns:[],
        defaultAllphoneColumns:[],

    },
    reducers: {},
    extraReducers(builder) {
        builder
            //call create
            .addCase(callCreate.pending, (state, action) => {
                state.status = STATUSES.LOADING;
                state.createPhoneStatus = true
            })
            .addCase(callCreate.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_call_Create`;
                state.call_Create = action.payload;
                state.createPhoneStatus = `${STATUSES.SUCCESS}_call_Create`
            })
            .addCase(callCreate.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.createPhoneStatus = STATUSES.FAILURE
                state.error = action.error
            })

            //get call details
            .addCase(getCallLogDetails.pending, (state, action) => {
                state.status = `${STATUSES.LOADING}__callDetails`;
            })
            .addCase(getCallLogDetails.fulfilled, (state, action) => {
                let result = action.payload;
                if (result.status === 200) {
                    state.status = `${STATUSES.SUCCESS}_callDetails`;
                    state.callDetails = action.payload;
                } else {
                    state.status = STATUSES.FAILURE
                    state.error = action.payload
                }
            })
            .addCase(getCallLogDetails.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            //call disconnect api
            .addCase(callDisconnect.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(callDisconnect.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_call_disconnect`;
                state.call_disconnect = action.payload;
            })
            .addCase(callDisconnect.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ////add call filetr
            .addCase(defaultCallFilter.fulfilled, (state, action) => {
                localStorage.setItem(`${REACT_APP_ENV}_call_filter`, JSON.stringify(action.payload))
                state.defaultCallFilters = action.payload
            })

            ////add call filetr
            .addCase(getReportCallLogDetails.pending, (state, action) => {
                state.status = `${STATUSES.LOADING}_callLogReport`;
            })
            .addCase(getReportCallLogDetails.fulfilled, (state, action) => {
                let result = action.payload;
                if (result.status === 200) {
                    state.status = `${STATUSES.SUCCESS}_callLogReport`;
                    state.callLogReport = action.payload;
                } else {
                    state.status = STATUSES.FAILURE
                    state.error = action.payload
                }
            })
            .addCase(getReportCallLogDetails.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            // set where clause for pagination
            .addCase(filterForPagination.fulfilled, (state, action) => {
                localStorage.setItem(`${REACT_APP_ENV}_all_call_details_pagiation`, action.payload)
                state.pagination_filters = action.payload
            })

            //get call details
            .addCase(getMyCallLogDetails.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(getMyCallLogDetails.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_callDetails`;
                state.callDetails = action.payload
                let newArray = [];
                let newArray2 = []
                let result = action.payload;
                let status = result.status;
                let message = result.message;
                let res = result && result.data && result.data.rows
                let count = result && result.data && result.data.count
                res && res.length > 0 && res.map(e => {
                    return newArray.push({
                        id: e.id,
                        destination_number: e.destination_number,
                        agent_name: e.agent_name,
                        agent_number: e.agent_number,
                        call_duration: e.call_duration,
                        answer_duration: e.answer_duration,
                        call_date: e.call_date,
                        call_time: e.call_time,
                        direction: e.direction,
                        call_type: e.direction,
                        state: e.state,
                        description: e.description,
                        project_name: e.project__project_name,
                        project: e.project_id,
                        story_name: e.story__summary,
                        story: e.story_id,
                        resource_name: e.resource && e.resource.name,
                        resource: e.resource && e.resource.id,
                        hangup_cause: e.hangup_cause,
                        reason: e.reason,
                        recording_url: e.recording_url,
                        uuid: e.uuid,
                        is_sync: e.is_sync,
                        status: e.status,
                        end_stamp: e.end_stamp,
                        epic_id: e.epic && e.epic.id,
                        ticket_key: e.epic && e.epic.ticket_key,

                    })
                })
                if (newArray.length > 0) {
                    newArray2 = { "data": { "rows": newArray, "count": count }, status: status, message: message }
                }
                state.mycallDetails = newArray2 && newArray2.data ? newArray2 : action.payload;
            })
            .addCase(getMyCallLogDetails.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })


            //agent resource list
            .addCase(getAgentWiseResource.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(getAgentWiseResource.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_agentResourceList`;
                state.agentResourceList = action.payload;
            })
            .addCase(getAgentWiseResource.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ///update candidate contact no
            .addCase(updateCandidateContactNumber.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(updateCandidateContactNumber.fulfilled, (state, action) => {
                state.updatePhoneStatus = `${STATUSES.SUCCESS}_updateCandidateContactNo`;
                state.updateCandidateContactNo = action.payload;
            })
            .addCase(updateCandidateContactNumber.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            // default phone column
            .addCase(defaultPhoneCallDetailsColumn.fulfilled, (state, action) => {
                localStorage.setItem(`${REACT_APP_ENV}_${user.id}_my_phoneCallDetails`, JSON.stringify(action.payload))
                state.defaultphoneColumns = action.payload
            })
            .addCase(defaultAllPhoneCallDetailsColumn.fulfilled, (state, action) => {
                localStorage.setItem(`${REACT_APP_ENV}_${user.id}_allphoneCallDetails`, JSON.stringify(action.payload))
                state.defaultAllphoneColumns = action.payload
            })


    },
});

export default callingSlice.reducer;