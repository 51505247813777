import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { STATUSES } from "../common-status/constant";
import dataService from "../services/data.service";

// get all menu list
export const getMenuList = createAsyncThunk(
    "get/menu-list",
    async (data) => {
        const res = await dataService.menuList(data);
        const resp = res.data;
        return resp;
    });
export const postBaseMenu = createAsyncThunk(
    "post/base-menu",
    async (data) => {
        const res = await dataService.postmenu(data);
        const resp = res.data;
        return resp;
    });

export const getRoleMenuList = createAsyncThunk(
    "get/role-menu-list",
    async (data) => {
        const res = await dataService.getRoleMenu(data);
        const resp = res.data;
        return resp;
    });
// base/menu-role
export const postSingleBaseMenu = createAsyncThunk(
    "post-single/base-menu",
    async (data) => {
        const res = await dataService.postSingleBaseMenu(data);
        const resp = res.data;
        return resp;
    });
export const postAssignMenuRole = createAsyncThunk(
    "post-multipe/base-menu",
    async (data) => {
        const res = await dataService.postAssignMenuRole(data);
        const resp = res.data;
        return resp;
    });
//get all menu for parent
export const getAllMenuList = createAsyncThunk("get/all-menu-list", async (data) => {
    const res = await dataService.menuList(data);
    const resp = res.data;
    return resp;
});

export const getAsigneRoleMenuList = createAsyncThunk("get/assigne-role-menu-list", async (data) => {
    if (data.tokens) {
        const res = await dataService.getRoleMenus(data);
        const resp = res.data;
        return resp;
    }
    const res = await dataService.getRoleMenu(data);
    const resp = res.data;
    return resp;
});

export const postUploadResume = createAsyncThunk("post/upload-resume", async (data) => {
    const res = await dataService.postResume(data);
    const resp = res.data;
    return resp;
});

//////skill apis///////
export const getAllSkillList = createAsyncThunk("skill/all-list", async (data) => {
    const res = await dataService.all_skill_list(data);
    return res.data;
});

export const getSkillList = createAsyncThunk("skill/list", async (data) => {
    const res = await dataService.skill_list(data);
    return res.data;
});

export const skillCreate = createAsyncThunk("skill/create", async (data) => {
    const res = await dataService.skill_create(data);
    return res.data;
});

export const skillEdit = createAsyncThunk("skill/edit", async (id) => {
    const res = await dataService.skill_edit(id);
    return res.data;
});

export const skillUpdate = createAsyncThunk("skill/update", async (data) => {
    const res = await dataService.skill_update(data.ID, data.addvalues);
    return res.data;
});

export const skillArchive = createAsyncThunk("skill/archive", async (id) => {
    const res = await dataService.skill_archive(id);
    return res.data;
});

//////Interview Schedule Status API//////

export const getInterviewScheduleStatusList = createAsyncThunk("interview-schedule-status/list", async (data) => {
    const res = await dataService.interview_schedule_status_list(data);
    return res.data;
});

export const interviewScheduleStatusCreate = createAsyncThunk("interview-schedule-status/create", async (data) => {
    const res = await dataService.interview_schedule_status_create(data);
    return res.data;
});

export const interviewScheduleStatusEdit = createAsyncThunk("interview-schedule-status/edit", async (id) => {
    const res = await dataService.interview_schedule_status_edit(id);
    return res.data;
});

export const interviewScheduleStatusUpdate = createAsyncThunk("interview-schedule-status/update", async (data) => {
    const res = await dataService.interview_schedule_status_update(data.ID, data.addvalues);
    return res.data;
});

// export const interviewScheduleStatusArchive = createAsyncThunk("skill/archive", async (id) => {
//     const res = await dataService.interview_schedule_status_archive(id);
//     return res.data;
// });

//////Industry apis///////
export const getAllIndustryList = createAsyncThunk("industry/all-list", async (data) => {
    const res = await dataService.all_industry_list(data);
    return res.data;
});

export const getIndustryList = createAsyncThunk("industry/list", async (data) => {
    const res = await dataService.industry_list(data);
    return res.data;
});

export const industryCreate = createAsyncThunk("industry/create", async (data) => {
    const res = await dataService.industry_create(data);
    return res.data;
});

export const industryEdit = createAsyncThunk("industry/edit", async (id) => {
    const res = await dataService.industry_edit(id);
    return res.data;
});

export const industryUpdate = createAsyncThunk("industry/update", async (data) => {
    const res = await dataService.industry_update(data.ID, data.addvalues);
    return res.data;
});

export const industryArchive = createAsyncThunk("industry/archive", async (id) => {
    const res = await dataService.industry_archive(id);
    return res.data;
});

//////Qualification apis///////
export const getAllQualificationList = createAsyncThunk("qualification/all-list", async (data) => {
    const res = await dataService.all_qualification_list(data);
    return res.data;
});

export const getQualificationList = createAsyncThunk("qualification/list", async (data) => {
    const res = await dataService.qualification_list(data);
    return res.data;
});

export const qualificationCreate = createAsyncThunk("qualification/create", async (data) => {
    const res = await dataService.qualification_create(data);
    return res.data;
});

export const qualificationEdit = createAsyncThunk("qualification/edit", async (id) => {
    const res = await dataService.qualification_edit(id);
    return res.data;
});

export const qualificationUpdate = createAsyncThunk("qualification/update", async (data) => {
    const res = await dataService.qualification_update(data.ID, data.addvalues);
    return res.data;
});

export const qualificationArchive = createAsyncThunk("qualification/archive", async (id) => {
    const res = await dataService.qualification_archive(id);
    return res.data;
});

export const createAttachment = createAsyncThunk("post/attachment", async (data) => {
    const res = await dataService.create_attachment(data);
    const resp = res.data;
    return resp;
});


//////Report Master apis///////
export const getReportMasterList = createAsyncThunk("report-master/all-list", async (data) => {
    const res = await dataService.all_report_master(data);
    return res.data;
});

export const reportMasterCreate = createAsyncThunk("report-master/create", async (data) => {
    if (data === 'type') {
        return []
    }
    const res = await dataService.report_master_create(data);
    return res.data;
});

export const reportMasterEdit = createAsyncThunk("report-master/edit", async (id) => {
    const res = await dataService.report_master_edit(id);
    return res.data;
});

export const reportMasterUpdate = createAsyncThunk("report-master/update", async (data) => {
    const res = await dataService.report_master_update(data.ID, data.addvalues);
    return res.data;
});

export const getNotificationList = createAsyncThunk("notification/list", async (data) => {
    const res = await dataService.get_notification_list(data);
    return res.data;
});

export const getQuestionLevelList = createAsyncThunk("questionlevel/list", async (data) => {
    const res = await dataService.questionlevel_list(data);
    return res.data;
});

export const getTechnologyList = createAsyncThunk("technology/list", async (data) => {
    const res = await dataService.technology_list(data);
    return res.data;
});

export const getDepartmentListByIndustryId = createAsyncThunk("department-industry/list", async (data) => {
    const res = await dataService.department_list_by_industryId(data);
    return res.data;
});

export const getTechnologyListByIndustryDepartmentId = createAsyncThunk("technology-department-industry/list", async (data) => {
    const res = await dataService.technology_list_by_department_industryId(data);
    return res.data;
});

export const getQuestionSetList = createAsyncThunk("questionset/all-list", async (data) => {
    if (data === 'type') {
        return []
    }
    const res = await dataService.questionSet_list(data);
    return res.data;
});

export const getScheduleExamBatchSetList = createAsyncThunk("exam-schedule/set-batch-list", async (data) => {
    if (data === 'type') {
        return []
    }
    const res = await dataService.exam_schedule_set_batch_list(data);
    return res.data;
});
export const candidateCreate = createAsyncThunk("candidate/create", async (data) => {
    if (data === 'type') {
        return []
    }
    const res = await dataService.create_candidate(data);
    return res.data;
});

export const createCandidateExamSchedule = createAsyncThunk("create/candidate-exam-schedule", async (data) => {
    if (data === 'type') {
        return []
    }
    const res = await dataService.candidate_exam_schedule_create(data);
    const resp = res.data;
    return resp;
});

export const getMangageAvaList = createAsyncThunk("manage-avl/list", async (data) => {
    if (data === 'type') {
        return []
    }
    const res = await dataService.manage_availability(data);
    const resp = res.data;
    return resp;
});

export const getQuestionTypePEList = createAsyncThunk("get/question-type-pe/list", async (data) => {
    if (data === 'type') {
        return []
    }
    const res = await dataService.question_pe_type(data);
    const resp = res.data;
    return resp;
});

export const getCESQuestionTypeList = createAsyncThunk("get/question-type/list", async (data) => {
    if (data === 'type') {
        return []
    }
    const res = await dataService.question_type_list(data);
    const resp = res.data;
    return resp;
});
export const getBatchList = createAsyncThunk("batch/all-list", async (data) => {
    if (data === 'type') {
        return []
    }

    const res = await dataService.ces_batch_list(data);
    return res.data;
});


export const getBatchTechnologyList = createAsyncThunk("batch-tech/filter", async (data) => {
    if (data === 'type') {
        return []
    }

    const res = await dataService.ces_batch_technology_filter_list(data);
    return res.data;
});

//Preferred Location store

export const getAllLocationList = createAsyncThunk("location/all-list", async (data) => {
    const res = await dataService.all_location_list(data);
    return res.data;
});

export const getLocationList = createAsyncThunk("location/list", async (data) => {
    const res = await dataService.location_list(data);
    return res.data;
});

export const locationCreate = createAsyncThunk("location/create", async (data) => {
    const res = await dataService.location_create(data);
    return res.data;
});

export const locationEdit = createAsyncThunk("location/edit", async (id) => {
    const res = await dataService.location_edit(id);
    return res.data;
});

export const locationUpdate = createAsyncThunk("location/update", async (data) => {
    const res = await dataService.location_update(data.ID, data.addvalues);
    return res.data;
});

export const locationArchive = createAsyncThunk("location/archive", async (id) => {
    const res = await dataService.location_archive(id);
    return res.data;
});


//////country apis///////

export const getAllCountryList = createAsyncThunk("country/all-list", async (data) => {
    const res = await dataService.all_country_list(data);
    return res.data;
});

export const getCountryList = createAsyncThunk("country/list", async (data) => {
    const res = await dataService.country_list(data);
    return res.data;
});

export const countryCreate = createAsyncThunk("country/create", async (data) => {
    const res = await dataService.country_create(data);
    return res.data;
});

export const countryEdit = createAsyncThunk("country/edit", async (id) => {
    const res = await dataService.country_edit(id);
    return res.data;
});

export const countryUpdate = createAsyncThunk("coutry/update", async (data) => {
    const res = await dataService.country_update(data.ID, data.addvalues);
    return res.data;
});

export const countryArchive = createAsyncThunk("country/archive", async (id) => {
    const res = await dataService.country_archive(id);
    return res.data;
});

//////vacancy apis///////

export const getAllVacancyList = createAsyncThunk("vacancy/all-list", async (data) => {
    const res = await dataService.all_vacancy_list(data);
    return res.data;
});

export const getVacancyList = createAsyncThunk("vacancy/list", async (data) => {
    const res = await dataService.vacancy_list(data);
    return res.data;
});

export const vacancyCreate = createAsyncThunk("vacancy/create", async (data) => {
    const res = await dataService.vacancy_create(data);
    return res.data;
});

export const vacancyEdit = createAsyncThunk("vacancy/edit", async (id) => {
    const res = await dataService.vacancy_edit(id);
    return res.data;
});

export const vacancyUpdate = createAsyncThunk("vacancy/update", async (data) => {
    const res = await dataService.vacancy_update(data.ID, data.addvalues);
    return res.data;
});

export const vacancyArchive = createAsyncThunk("vacancy/archive", async (id) => {
    const res = await dataService.vacancy_archive(id);
    return res.data;
});

//////State apis///////
export const getAllStateList = createAsyncThunk("state/all-list", async (data) => {
    const res = await dataService.all_state_list(data);
    return res.data;
});

export const getStateList = createAsyncThunk("state/list", async (data) => {
    const res = await dataService.state_list(data);
    return res.data;
});

export const stateCreate = createAsyncThunk("state/create", async (data) => {
    const res = await dataService.state_create(data);
    return res.data;
});

export const stateEdit = createAsyncThunk("state/edit", async (id) => {
    const res = await dataService.state_edit(id);
    return res.data;
});

export const stateUpdate = createAsyncThunk("state/update", async (data) => {
    const res = await dataService.state_update(data.ID, data.addvalues);
    return res.data;
});

export const stateArchive = createAsyncThunk("state/archive", async (id) => {
    const res = await dataService.state_archive(id);
    return res.data;
});

//////Hiring Request apis///////
export const getAllHiringRequestList = createAsyncThunk("hiring-request/all-list", async (data) => {
    const res = await dataService.all_hiring_request_list(data);
    return res.data;
});

export const getHiringRequestList = createAsyncThunk("hiring-request/list", async (data) => {
    const res = await dataService.hiring_request_list(data);
    return res.data;
});

export const hiringRequestCreate = createAsyncThunk("hiring-request/create", async (data) => {
    if (data === 'type') {
        return []
    }
    const res = await dataService.hiring_request_create(data);
    return res.data;
});

export const hiringRequestEdit = createAsyncThunk("hiring-request/edit", async (id) => {
    const res = await dataService.hiring_request_edit(id);
    return res.data;
});

export const hiringRequestUpdate = createAsyncThunk("hiring-request/update", async (data) => {
    if (data === "type") {
        return []
    }
    const res = await dataService.hiring_request_update(data.ID, data.addvalues);
    return res.data;
});

export const hiringRequestArchive = createAsyncThunk("hiring-request/archive", async (id) => {
    const res = await dataService.hiring_request_archive(id);
    return res.data;
});

//////working mode apis///////

export const getAllWorkingModeList = createAsyncThunk("working-mode/all-list", async (data) => {
    const res = await dataService.all_workingmode_list(data);
    return res.data;
});

export const getWorkingModeList = createAsyncThunk("working-mode/list", async (data) => {
    const res = await dataService.workingmode_list(data);
    return res.data;
});

export const WorkingModeCreate = createAsyncThunk("working-mode/create", async (data) => {
    const res = await dataService.workingmode_create(data);
    return res.data;
});

export const workingModeEdit = createAsyncThunk("working-mode/edit", async (id) => {
    const res = await dataService.workingmode_edit(id);
    return res.data;
});

export const WorkingModeUpdate = createAsyncThunk("working-mode/update", async (data) => {
    const res = await dataService.workingmode_update(data.ID, data.addvalues);
    return res.data;
});

export const WorkingModeArchive = createAsyncThunk("working-mode/archive", async (id) => {
    const res = await dataService.workingmode_archive(id);
    return res.data;
});
//////More Specification mode apis///////

export const getAllMoreSpecificationList = createAsyncThunk("specification/all-list", async (data) => {
    const res = await dataService.all_more_specification_list(data);
    return res.data;
});

export const getMoreSpecificationList = createAsyncThunk("specification/list", async (data) => {
    const res = await dataService.more_specification_list(data);
    return res.data;
});

export const MoreSpecificationCreate = createAsyncThunk("specification/create", async (data) => {
    const res = await dataService.more_specification_create(data);
    return res.data;
});

export const MoreSpecificationEdit = createAsyncThunk("specification/edit", async (id) => {
    const res = await dataService.more_specification_edit(id);
    return res.data;
});

export const MoreSpecificationUpdate = createAsyncThunk("specification/update", async (data) => {
    const res = await dataService.more_specification_update(data.ID, data.addvalues);
    return res.data;
});

export const MoreSpecificationArchive = createAsyncThunk("specification/archive", async (id) => {
    const res = await dataService.more_specification_archive(id);
    return res.data;
});

//// urgency type apis///////

export const getAllUrgencyList = createAsyncThunk("urgency-type/all-list", async (data) => {
    const res = await dataService.all_urgencytype_list(data);
    return res.data;
});

export const getUrgencyList = createAsyncThunk("urgency-type/list", async (data) => {
    const res = await dataService.urgencytype_list(data);
    return res.data;
});

export const UrgencyCreate = createAsyncThunk("urgency-type/create", async (data) => {
    const res = await dataService.urgencytype_create(data);
    return res.data;
});

export const UrgencyEdit = createAsyncThunk("urgency-type/edit", async (id) => {
    const res = await dataService.urgencytype_edit(id);
    return res.data;
});

export const UrgencyUpdate = createAsyncThunk("urgency-type/update", async (data) => {
    const res = await dataService.urgencytype_update(data.ID, data.addvalues);
    return res.data;
});

export const UrgencyArchive = createAsyncThunk("urgency-type/archive", async (id) => {
    const res = await dataService.urgencytype_archive(id);
    return res.data;
});

////// Core Settings apis///////

export const getAllCoreSettingsList = createAsyncThunk("core-settings/all-list", async (data) => {
    const res = await dataService.all_coresettings_list(data);
    return res.data;
});

export const getCoreSettingsList = createAsyncThunk("core-settings/list", async (data) => {
    const res = await dataService.coresettings_list(data);
    return res.data;
});

export const CoreSettingsCreate = createAsyncThunk("core-settings/create", async (data) => {
    const res = await dataService.coresettings_create(data);
    return res.data;
});

export const CoreSettingsEdit = createAsyncThunk("core-settings/edit", async (id) => {
    const res = await dataService.coresettings_edit(id);
    return res.data;
});

export const CoreSettingsUpdate = createAsyncThunk("core-settings/update", async (data) => {
    const res = await dataService.coresettings_update(data.ID, data.addvalues);
    return res.data;
});

export const CoreSettingArchive = createAsyncThunk("core-settings/archive", async (id) => {
    const res = await dataService.coresettings_archive(id);
    return res.data;
});

////// hiring attachment ///////

export const hiringAttachFile = createAsyncThunk('attach-file/create', async (data) => {
    const res = await dataService.hiring_attach_file(data);
    return res.data;
})

export const removeHiringAttachFile = createAsyncThunk('attach-file/remove', async (data) => {
    const res = await dataService.hiring_attach_remove(data);
    return res.data;
})

/////// Type of Service ///////

export const getAllServiceTypeList = createAsyncThunk("service-type/all-list", async (data) => {
    const res = await dataService.all_servicetype_list(data);
    return res.data;
});

export const getServiceTypeList = createAsyncThunk("service-type/list", async (data) => {
    const res = await dataService.servicetype_list(data);
    return res.data;
});

export const ServiceTypeCreate = createAsyncThunk("service-type/create", async (data) => {
    const res = await dataService.servicetype_create(data);
    return res.data;
});

export const ServiceTypeEdit = createAsyncThunk("service-type/edit", async (id) => {
    const res = await dataService.servicetype_edit(id);
    return res.data;
});

export const ServiceTypeUpdate = createAsyncThunk("service-type/update", async (data) => {
    const res = await dataService.servicetype_update(data.ID, data.addvalues);
    return res.data;
});

export const ServiceTypeArchive = createAsyncThunk("service-type/archive", async (id) => {
    const res = await dataService.servicetype_archive(id);
    return res.data;
});
//////paymenttype apis///////

export const getpymenttype = createAsyncThunk("paymenttype/list", async (data) => {
    const res = await dataService.pymenttype_list(data);
    return res.data;
});

export const paymentypeCreat = createAsyncThunk("paymenttype/create", async (data) => {
    const res = await dataService.payment_type_create(data);
    return res.data;
});

export const paymenttypeEdit = createAsyncThunk("paymenttype/edit", async (id) => {
    const res = await dataService.payment_type_edit(id);
    return res.data;
});

export const paymenttypeUpdate = createAsyncThunk("paymenttype/update", async (data) => {
    const res = await dataService.payment_type_update(data.ID, data.addvalues);
    return res.data;
});


//////Job Location apis///////

export const getAllJobLocationList = createAsyncThunk("job-location/all-list", async (data) => {
    const res = await dataService.all_job_location_list(data);
    return res.data;
});

export const getJobLocationList = createAsyncThunk("job-location/list", async (data) => {
    const res = await dataService.job_location_list(data);
    return res.data;
});

export const JobLocationCreate = createAsyncThunk("job-location/create", async (data) => {
    const res = await dataService.job_location_create(data);
    return res.data;
});

export const JobLocationEdit = createAsyncThunk("job-location/edit", async (id) => {
    const res = await dataService.job_location_edit(id);
    return res.data;
});

export const JobLocationUpdate = createAsyncThunk("job-location/update", async (data) => {
    const res = await dataService.job_location_update(data.ID, data.addvalues);
    return res.data;
});

export const JobLocationArchive = createAsyncThunk("job-location/archive", async (id) => {
    const res = await dataService.job_location_archive(id);
    return res.data;
});

//////Hiring Mode apis///////

export const getAllHiringModeList = createAsyncThunk("hiring-mode/all-list", async (data) => {
    const res = await dataService.all_hiring_mode_list(data);
    return res.data;
});

export const getHiringModeList = createAsyncThunk("hiring-mode/list", async (data) => {
    const res = await dataService.hiring_mode_list(data);
    return res.data;
});

export const HiringModeCreate = createAsyncThunk("hiring-mode/create", async (data) => {
    const res = await dataService.hiring_mode_create(data);
    return res.data;
});

export const HiringModeEdit = createAsyncThunk("hiring-mode/edit", async (id) => {
    const res = await dataService.hiring_mode_edit(id);
    return res.data;
});

export const HiringModeUpdate = createAsyncThunk("hiring-mode/update", async (data) => {
    const res = await dataService.hiring_mode_update(data.ID, data.addvalues);
    return res.data;
});

export const HiringModeArchive = createAsyncThunk("hiring-mode/archive", async (id) => {
    const res = await dataService.hiring_mode_archive(id);
    return res.data;
});
// type of contract api
export const getAllContractTypeList = createAsyncThunk("contract-type/all-list", async (data) => {
    const res = await dataService.all_contracttype_List(data);
    return res.data;
});

export const getContractTypeList = createAsyncThunk("contract-type/list", async (data) => {
    const res = await dataService.contracttype_List(data);
    return res.data;
});

export const ContractTypeCreate = createAsyncThunk("contract-type/create", async (data) => {
    const res = await dataService.contracttype_create(data);
    return res.data;
});

export const ContractTypeEdit = createAsyncThunk("contract-type/edit", async (id) => {
    const res = await dataService.contracttype_edit(id);
    return res.data;
});

export const ContractTypeUpdate = createAsyncThunk("contract-type/update", async (data) => {
    const res = await dataService.contracttype_update(data.ID, data.addvalues);
    return res.data;
});

export const ContractTypeArchive = createAsyncThunk("contract-type/archive", async (id) => {
    const res = await dataService.contracttype_archive(id);
    return res.data;
});
/////// Currency ///////

export const getAllCurrencyList = createAsyncThunk("currency/all-list", async (data) => {
    const res = await dataService.all_currency_list(data);
    return res.data;
});

export const getCurrencyList = createAsyncThunk("currency/list", async (data) => {
    const res = await dataService.currency_list(data);
    return res.data;
});

export const CurrencyCreate = createAsyncThunk("currency/create", async (data) => {
    const res = await dataService.currency_create(data);
    return res.data;
});

export const CurrencyEdit = createAsyncThunk("currency/edit", async (id) => {
    const res = await dataService.currency_edit(id);
    return res.data;
});

export const CurrencyUpdate = createAsyncThunk("currency/update", async (data) => {
    const res = await dataService.currency_update(data.ID, data.addvalues);
    return res.data;
});

export const CurrencyArchive = createAsyncThunk("currency/archive", async (id) => {
    const res = await dataService.currency_archive(id);
    return res.data;
});

//////Specialisations Mode apis///////

export const getAllSpecialisationsList = createAsyncThunk("specializations/all-list", async (data) => {
    const res = await dataService.all_specialisations_list(data);
    return res.data;
});

export const getSpecialisationsList = createAsyncThunk("specialisations/list", async (data) => {
    const res = await dataService.specialisations_list(data);
    return res.data;
});

export const SpecialisationsCreate = createAsyncThunk("specialisations/create", async (data) => {
    const res = await dataService.specialisations_create(data);
    return res.data;
});

export const SpecialisationsEdit = createAsyncThunk("specialisations/edit", async (id) => {
    const res = await dataService.specialisations_edit(id);
    return res.data;
});

export const SpecialisationsUpdate = createAsyncThunk("specialisations/update", async (data) => {
    const res = await dataService.specialisations_update(data.ID, data.addvalues);
    return res.data;
});

export const SpecialisationsArchive = createAsyncThunk("specialisations/archive", async (id) => {
    const res = await dataService.specialisations_archive(id);
    return res.data;
});

//////Industry - Department apis///////

export const getAllIndustryDepartmentList = createAsyncThunk("industry-department/all-list", async (data) => {
    const res = await dataService.all_industry_department_list(data);
    return res.data;
});

export const getIndustryDepartmentList = createAsyncThunk("industry-department/list", async (data) => {
    const res = await dataService.industry_department_list(data);
    return res.data;
});

export const IndustryDepartmentCreate = createAsyncThunk("industry-department/create", async (data) => {
    const res = await dataService.industry_department_create(data);
    return res.data;
});

export const IndustryDepartmentEdit = createAsyncThunk("industry-department/edit", async (id) => {
    const res = await dataService.industry_department_edit(id);
    return res.data;
});

export const IndustryDepartmentUpdate = createAsyncThunk("industry-department/update", async (data) => {
    const res = await dataService.industry_department_update(data.ID, data.addvalues);
    return res.data;
});

export const IndustryDepartmentArchive = createAsyncThunk("industry-department/archive", async (id) => {
    const res = await dataService.industry_department_archive(id);
    return res.data;
});

//////Purches Packages apis///////

export const getPurchesdpackage = createAsyncThunk("purchesdpackage/list", async (data) => {
    const res = await dataService.purchased_Packeges_list(data);
    return res.data;
});

export const purchesdpackageCreat = createAsyncThunk("purchesdpackage/create", async (data) => {
    const res = await dataService.purchased_Packeges_create(data);
    return res.data;
});

export const purchesdpackageEdit = createAsyncThunk("purchesdpackage/edit", async (id) => {
    const res = await dataService.purchased_Packeges_edit(id);
    return res.data;
});

export const purchesdpackageUpdate = createAsyncThunk("purchesdpackage/update", async (data) => {
    const res = await dataService.purchased_Packeges_update(data.ID, data.addvalues);
    return res.data;
});

//////ScreeningMaster apis///////

export const getAllScreeningMasterList = createAsyncThunk("screening-master/all-list", async (data) => {
    const res = await dataService.all_screening_master_list(data);
    return res.data;
});

export const getScreeningMasterList = createAsyncThunk("screening-master/list", async (data) => {
    const res = await dataService.screening_master_list(data);
    return res.data;
});

export const ScreeningMasterCreate = createAsyncThunk("screening-master/create", async (data) => {
    const res = await dataService.screening_master_create(data);
    return res.data;
});

export const ScreeningMasterEdit = createAsyncThunk("screening-master/edit", async (id) => {
    const res = await dataService.screening_master_edit(id);
    return res.data;
});

export const ScreeningMasterUpdate = createAsyncThunk("screening-master/update", async (data) => {
    const res = await dataService.screening_master_update(data.ID, data.addvalues);
    return res.data;
});

export const ScreeningMasterArchive = createAsyncThunk("screening-master/archive", async (id) => {
    const res = await dataService.screening_master_archive(id);
    return res.data;
});

//////client crud apis///////

export const getClientcrud = createAsyncThunk("clientcrud/list", async (data) => {
    const res = await dataService.client_crud_list(data);
    return res.data;
});

export const ClientcrudCreat = createAsyncThunk("clientcrud/create", async (data) => {
    const res = await dataService.client_crud_create(data);
    return res.data;
});

export const ClientcrudEdit = createAsyncThunk("clientcrud/edit", async (id) => {
    const res = await dataService.client_crud_edit(id);
    return res.data;
});

export const ClientcrudUpdate = createAsyncThunk("clientcrud/update", async (data) => {
    const res = await dataService.client_crud_update(data.ID, data.addvalues);
    return res.data;
});

// industry dep get api

export const getIndustryDepartmentListData = createAsyncThunk("industry-department/list", async (data) => {
    const res = await dataService.industry_department_list_data(data);
    return res.data;
});
// industry dep spec api 

export const getAllIndustryDepSpecList = createAsyncThunk("industry-department-specialisation/all-list", async (data) => {
    const res = await dataService.all_industryDepartmentSpecialisation_list(data);
    return res.data;
});

export const getIndustryDepSpecList = createAsyncThunk("industry-department-specialisation/list", async (data) => {
    const res = await dataService.industryDepartmentSpecialisation_list(data);
    return res.data;
});

export const IndustryDepSpecCreate = createAsyncThunk("industry-department-specialisation/create", async (data) => {
    const res = await dataService.industryDepartmentSpecialisation_create(data);
    return res.data;
});

export const IndustryDepSpecEdit = createAsyncThunk("industry-department-specialisation/edit", async (id) => {
    const res = await dataService.industryDepartmentSpecialisation_edit(id);
    return res.data;
});

export const IndustryDepSpecUpdate = createAsyncThunk("industry-department-specialisation/update", async (data) => {
    const res = await dataService.industryDepartmentSpecialisation_update(data.ID, data.addvalues);
    return res.data;
});

export const IndustryDepSpecArchive = createAsyncThunk("industry-department-specialisation/archive", async (id) => {
    const res = await dataService.industryDepartmentSpecialisation_archive(id);
    return res.data;
});
// industry dep specialisations and skill mapping
export const getIndsDepSpecSkill = createAsyncThunk("indsDepSpecSkill/list", async (data) => {
    const res = await dataService.indus_dep_spec_skill_list(data);
    return res.data;
});

export const indsDepSpecSkillCreat = createAsyncThunk("indsDepSpecSkill/create", async (data) => {
    const res = await dataService.indus_dep_spec_skill_create(data);
    return res.data;
});

export const indsDepSpecSkillEdit = createAsyncThunk("indsDepSpecSkill/edit", async (id) => {
    const res = await dataService.indus_dep_spec_skill_edit(id);
    return res.data;
});

export const indsDepSpecSkillUpdate = createAsyncThunk("indsDepSpecSkill/update", async (data) => {
    const res = await dataService.indus_dep_spec_skill_update(data.ID, data.addvalues);
    return res.data;
});

export const getIndusDepSpecListData = createAsyncThunk("industry-department-specialization/list", async (data) => {
    const res = await dataService.industry_depspecialization_list_data(data);
    return res.data;
})
// interviewerfeecback list
export const getInterviewerFeedbackList = createAsyncThunk("interviewerFeedback/list", async (data) => {
    const res = await dataService.interviewer_feedback_list(data);
    return res.data;
});

//////Resume Source apis///////

export const getresumeSource = createAsyncThunk("resume-source/list", async (data) => {
    const res = await dataService.resume_source_list(data);
    return res.data;
});

export const resumeSourceCreat = createAsyncThunk("resume-source/create", async (data) => {
    const res = await dataService.resume_source_create(data);
    return res.data;
});

export const resumeSourceEdit = createAsyncThunk("resume-source/edit", async (id) => {
    const res = await dataService.resume_source_edit(id);
    return res.data;
});

export const resumeSourceUpdate = createAsyncThunk("resume-source/update", async (data) => {
    const res = await dataService.resume_source_update(data.ID, data.addvalues);
    return res.data;
})
//////Resume Format type apis///////

export const getresumeformat = createAsyncThunk("resume-format/list", async (data) => {
    const res = await dataService.resume_format_list(data);
    return res.data;
});

export const resumeformatCreat = createAsyncThunk("resume-format/create", async (data) => {
    const res = await dataService.resume_format_create(data);
    return res.data;
});

export const resumeformatEdit = createAsyncThunk("resume-format/edit", async (id) => {
    const res = await dataService.resume_format_edit(id);
    return res.data;
});

export const resumeformatUpdate = createAsyncThunk("resume-format/update", async (data) => {
    const res = await dataService.resume_format_update(data.ID, data.addvalues);
    return res.data;
});

export const getIndustryListCES = createAsyncThunk("industry/list-ces", async (data) => {
    const res = await dataService.industry_list_ces(data);
    return res.data;
});


export const getDepartmentListCES = createAsyncThunk("department/list-ces", async (data) => {
    const res = await dataService.department_list_ces(data);
    return res.data;
});

const masterSlice = createSlice({
    name: "master",
    initialState: {
        status: 'idle',
        error: '',

        menuList: [],
        createMenu: [],
        createRoleMenu: [],
        roleMenuList: [],
        createAssignRoleMenu: [],
        allMenuList: [],
        assigneMenu: [],

        createUploadResume: [],

        skill_AllList: [],
        skill_Create: [],
        skill_List: [],
        skill_Edit: [],
        skill_update: [],
        skill_Archive: [],

        industry_AllList: [],
        industryOptions: [],
        industry_Create: [],
        industry_List: [],
        industry_Edit: [],
        industry_update: [],
        industry_Archive: [],

        qualification_AllList: [],
        qualification_Create: [],
        qualification_List: [],
        qualification_Edit: [],
        qualification_update: [],
        qualification_Archive: [],

        create_Attachment: [],

        reportMaster_List: [],
        reportMaster_Create: [],
        reportMaster_Edit: [],
        reportMaster_update: [],

        notificationList: [],

        questionset_List: [],
        questionlevel_List: [],
        technology_List: [],
        department_list_by_industryId: [],
        technology_list_by_department_industryId: [],
        createCandidate: [],
        scheduleExamBatchSetList: [],
        candidateExamScheduleCreate: [],
        candidateStatus: 'idle',
        candidateError: "",
        candidateCreateError: "",

        mangageAvalStatus: 'idle',
        mangageAvalError: "",
        mangageAvalList: [],

        interview_schedule_status_Create: [],
        interview_schedule_status_List: [],
        interview_schedule_status_Edit: [],
        interview_schedule_status_update: [],
        interview_schedule_option_List: [],
        // interview_schedule_status_Archive: [],

        batch_List: [],
        batch_technology_filter_List: [],

        country_AllList: [],
        country_Create: [],
        country_List: [],
        country_Edit: [],
        country_Update: [],
        country_Archive: [],

        clientCrud_List: [],
        clientCrud_creat: [],
        clientCrud_Edit: [],
        clientCrud_Update: [],

        industryDeprt_List: [],
        industryDepSpeci_List: [],

        paymenttype_List: [],
        paymenttype_creat: [],
        paymenttype_Edit: [],
        paymenttype_Update: [],

        purchasedPackeges_List: [],
        purchasedPackeges_creat: [],
        purchasedPackeges_Edit: [],
        purchasedPackeges_Update: [],

        indsDepSpecSkill_List: [],
        indsDepSpecSkill_create: [],
        indsDepSpecSkill_Edit: [],
        indsDepSpecSkill_Update: [],
        industryDepSpec_List_data: [],

        vacancy_AllList: [],
        vacancy_Create: [],
        vacancy_List: [],
        vacancy_Edit: [],
        vacancy_Update: [],
        vacancy_Archive: [],

        state_AllList: [],
        state_Create: [],
        state_List: [],
        state_Edit: [],
        state_Update: [],
        state_Archive: [],

        questionTypePEList: [],
        questionTypePEStatus: 'idle',
        questionTypePEError: "",

        cesQuestionTypeList: [],
        cesQuestionTypeStatus: 'idle',
        cesQuestionTypeError: "",

        workingmode_AllList: [],
        workingmode_Create: [],
        workingmode_List: [],
        workingmode_Edit: [],
        workingmode_Update: [],
        workingmode_Archive: [],

        moreSpecification_AllList: [],
        more_specification_Create: [],
        more_specification_List: [],
        specification_count: 0,
        more_specification_Edit: [],
        more_specification_Update: [],
        more_specification_Archive: [],
        specificationOptionList: [],


        location_AllList: [],
        location_Create: [],
        location_List: [],
        location_option_List: [],
        location_Edit: [],
        location_Update: [],
        location_Archive: [],

        hiring_request_AllList: [],
        hiring_request_Create: [],
        hiring_request_List: [],
        hiring_request_Edit: [],
        hiring_request_Update: [],
        hiring_request_Archive: [],
        view_data: [],

        urgencytype_AllList: [],
        urgencyType_AllLists: [],
        urgencytype_Create: [],
        urgencytype_List: [],
        urgencytype_Edit: [],
        urgencytype_Update: [],
        urgencytype_Archive: [],

        coresettings_AllList: [],
        coresettings_Create: [],
        coresettings_List: [],
        coresettings_Edit: [],
        coresettings_Update: [],
        coresettings_Archive: [],

        attach_File: [],
        hiring_attach_file_Remove: [],
        status_Remove: [],

        servicetype_AllList: [],
        servicetype_Create: [],
        servicetype_List: [],
        servicetype_Edit: [],
        servicetype_Update: [],
        servicetype_Archive: [],

        job_location_AllList: [],
        job_location_Create: [],
        job_location_List: [],
        job_location_Edit: [],
        job_location_Update: [],
        job_location_Archive: [],

        hiring_mode_AllList: [],
        hiring_mode_Create: [],
        hiring_mode_List: [],
        hiring_mode_Edit: [],
        hiring_mode_Update: [],
        hiring_mode_Archive: [],

        screening_master_AllList: [],
        screening_master_Create: [],
        screening_master_List: [],
        screening_master_Edit: [],
        screening_master_Update: [],
        screening_master_Archive: [],

        contracttype_AllList: [],
        contracttype_Create: [],
        contracttype_List: [],
        contracttype_Edit: [],
        contracttype_Update: [],
        contracttype_Archive: [],

        specialisations_AllList: [],
        specialisations_Create: [],
        specialisations_List: [],
        specialisations_option_List: [],
        specialisations_Edit: [],
        specialisations_Update: [],
        specialisations_Archive: [],

        currency_AllList: [],
        currency_Create: [],
        currency_List: [],
        currency_Edit: [],
        currency_Update: [],
        currency_Archive: [],

        industryDepartmentSpecialisation_AllList: [],
        industryDepartmentSpecialisation_Create: [],
        industryDepartmentSpecialisation_List: [],
        industryDepartmentSpecialisation_Edit: [],
        industryDepartmentSpecialisation_Update: [],
        industryDepartmentSpecialisation_Archive: [],

        industry_department_AllList: [],
        industry_department_Create: [],
        industry_department_List: [],
        industry_department_Edit: [],
        industry_department_Update: [],
        industry_department_Archive: [],
        department_List: [],

        interviewerFeedbackList: [],
        approval_status_AllList: [],
        approval_status_Create: [],
        approval_status_List: [],
        approval_status_Edit: [],
        approval_status_Update: [],
        approval_status_Archive: [],

        screeningmaster_AllList: [],
        specialisations_All_List_option: [],

        resume_source_List: [],
        resume_source_create: [],
        resume_source_Edit: [],
        resume_source_Update: [],

        resumeformat_type_List: [],
        resumeformat_type_creat: [],
        resumeformat_type_Edit: [],
        resumeformat_type_Update: [],

        department_List_Ces: [],
        industry_List_Ces: [],

    },
    reducers: {},
    extraReducers(builder) {
        builder
            //get menu list 
            .addCase(getMenuList.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(getMenuList.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.menuList = action.payload;

            })
            .addCase(getMenuList.rejected, (state, action) => {
                state.status = STATUSES.FAILURE;
                state.error = action.error

            })
            //post menu  
            .addCase(postBaseMenu.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(postBaseMenu.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_createMenu`;
                state.createMenu = action.payload;

            })
            .addCase(postBaseMenu.rejected, (state, action) => {
                state.status = `${STATUSES.FAILURE}_createMenu`;
                state.error = action.error
            })
            //post and update single menu role
            .addCase(postSingleBaseMenu.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(postSingleBaseMenu.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_createRoleMenu`;
                state.createRoleMenu = action.payload;

            })
            .addCase(postSingleBaseMenu.rejected, (state, action) => {
                state.status = `${STATUSES.FAILURE}_createRoleMenu`;
                state.error = action.error
            })
            //get menu role list
            .addCase(getRoleMenuList.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(getRoleMenuList.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_roleMenuList`;
                state.roleMenuList = action.payload;

            })
            .addCase(getRoleMenuList.rejected, (state, action) => {
                state.status = `${STATUSES.FAILURE}_roleMenuList`;
                state.error = action.error
            })

            //post Assign menu role 
            .addCase(postAssignMenuRole.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(postAssignMenuRole.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_createAssignRoleMenu`;
                state.createAssignRoleMenu = action.payload;
            })
            .addCase(postAssignMenuRole.rejected, (state, action) => {
                state.status = `${STATUSES.FAILURE}_createAssignRoleMenu`;
                state.error = action.error
            })

            // get parent menu
            .addCase(getAllMenuList.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.allMenuList = action.payload;

            })

            //get assigne menu list by role
            .addCase(getAsigneRoleMenuList.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(getAsigneRoleMenuList.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_roleMenuList`;
                state.dataLoader = STATUSES.SUCCESS;
                let newArray = [];
                let newArray2 = []
                let result = action.payload;
                let res = result && result.data && result.data.rows
                let count = result && result.data && result.data.count
                res.map(e => {
                    return newArray.push({
                        id: e.id,
                        created_by: e.created_by,
                        created_on: e.created_on,
                        menu_id: e.menu_id,
                        menu: e.menu && e.menu.url_slug,
                        menu_title: e.menu && e.menu.title,
                        menu_status: e.menu && e.menu.status,
                        parent_menu_id: e.parent_menu_id,
                        parent_menu: e.parent_menu && e.parent_menu.url_slug,
                        parent_menu_status: e.parent_menu && e.parent_menu.status,
                        role_id: e.role_id,
                        updated_by: e.updated_by,
                        updated_on: e.updated_on,
                        status: e.status
                    })
                })
                if (newArray.length > 0) {
                    newArray2 = { "data": { "rows": newArray.filter(e => e.menu_status === true && (e.parent_menu === null || e.parent_menu_status === true)), "count": count } }
                }
                state.assigneMenu = newArray2 && newArray2.data ? newArray2 : action.payload;
                localStorage.setItem('menuData', JSON.stringify(newArray2))
            })
            .addCase(getAsigneRoleMenuList.rejected, (state, action) => {
                state.status = `${STATUSES.FAILURE}_roleMenuList`;
                state.error = action.error
            })

            //post Upload resume
            .addCase(postUploadResume.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(postUploadResume.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_createUploadResume`;
                state.createUploadResume = action.payload;

            })
            .addCase(postUploadResume.rejected, (state, action) => {
                state.status = `${STATUSES.FAILURE}_createUploadResume`;
                state.error = action.error
            })

            //////////skill store section/////////
            ///all skill  list
            .addCase(getAllSkillList.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(getAllSkillList.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.skill_AllList = action.payload;
            })
            .addCase(getAllSkillList.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })
            /// get filterd skill list
            .addCase(getSkillList.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(getSkillList.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.skill_List = action.payload;
            })
            .addCase(getSkillList.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })
            ///skill create
            .addCase(skillCreate.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(skillCreate.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_skill_Create`;
                state.skill_Create = action.payload;
            })
            .addCase(skillCreate.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ///skill edit
            .addCase(skillEdit.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(skillEdit.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.skill_Edit = action.payload;
            })
            .addCase(skillEdit.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ///skill Update
            .addCase(skillUpdate.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(skillUpdate.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_skill_Update`;
                state.skill_Update = action.payload;
            })
            .addCase(skillUpdate.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ///skill archive
            .addCase(skillArchive.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(skillArchive.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_skill_Archive`;
                state.skill_Archive = action.payload;
            })
            .addCase(skillArchive.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ////Interview Schedule Status store////
            //Get Interview Schedule Status List//
            .addCase(getInterviewScheduleStatusList.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(getInterviewScheduleStatusList.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.interview_schedule_status_List = action.payload;
                state.interview_schedule_option_List = action.payload.data.rows.map((e) => ({ ...e, label: e.status_name, value: e.id }));
            })
            .addCase(getInterviewScheduleStatusList.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            //Interview Schedule Status Create//
            .addCase(interviewScheduleStatusCreate.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(interviewScheduleStatusCreate.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_interview_schedule_status_Create`;
                state.interview_schedule_status_Create = action.payload;
            })
            .addCase(interviewScheduleStatusCreate.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ///Interview Schedule Status edit
            .addCase(interviewScheduleStatusEdit.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(interviewScheduleStatusEdit.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.interview_schedule_status_Edit = action.payload;
            })
            .addCase(interviewScheduleStatusEdit.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ///Interview Schedule Status Update
            .addCase(interviewScheduleStatusUpdate.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(interviewScheduleStatusUpdate.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_interview_schedule_status_Update`;
                state.interview_schedule_status_update = action.payload;
            })
            .addCase(interviewScheduleStatusUpdate.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            //////////Industry store section/////////
            ///all industry  list
            .addCase(getAllIndustryList.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(getAllIndustryList.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.industry_AllList = action.payload;
                state.industryOptions = !action.payload.data.rows ? [] : action.payload.data.rows.map((e) => ({ ...e, label: e.industry_name, value: e.id }));

            })
            .addCase(getAllIndustryList.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })
            /// get filterd industry list
            .addCase(getIndustryList.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(getIndustryList.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.industry_List = action.payload;
            })
            .addCase(getIndustryList.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })
            ///industry create
            .addCase(industryCreate.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(industryCreate.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_industry_Create`;
                state.industry_Create = action.payload;
            })
            .addCase(industryCreate.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ///industry edit
            .addCase(industryEdit.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(industryEdit.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.industry_Edit = action.payload;
            })
            .addCase(industryEdit.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ///industry Update
            .addCase(industryUpdate.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(industryUpdate.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_industry_Update`;
                state.industry_Update = action.payload;
            })
            .addCase(industryUpdate.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ///industry archive
            .addCase(industryArchive.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(industryArchive.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_industry_Archive`;
                state.industry_Archive = action.payload;
            })
            .addCase(industryArchive.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })


            //////////Qualification store section/////////
            ///all qualification  list
            .addCase(getAllQualificationList.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(getAllQualificationList.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.qualification_AllList = action.payload;
            })
            .addCase(getAllQualificationList.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })
            /// get filterd qualification list
            .addCase(getQualificationList.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(getQualificationList.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.qualification_List = action.payload;
            })
            .addCase(getQualificationList.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })
            ///qualification create
            .addCase(qualificationCreate.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(qualificationCreate.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_qualification_Create`;
                state.qualification_Create = action.payload;
            })
            .addCase(qualificationCreate.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ///qualification edit
            .addCase(qualificationEdit.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(qualificationEdit.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.qualification_Edit = action.payload;
            })
            .addCase(qualificationEdit.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ///qualification Update
            .addCase(qualificationUpdate.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(qualificationUpdate.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_qualification_Update`;
                state.qualification_Update = action.payload;
            })
            .addCase(qualificationUpdate.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ///qualification archive
            .addCase(qualificationArchive.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(qualificationArchive.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_qualification_Archive`;
                state.qualification_Archive = action.payload;
            })
            .addCase(qualificationArchive.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            /// create attachment 
            .addCase(createAttachment.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(createAttachment.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_create_Attachment`;
                state.create_Attachment = action.payload;
            })
            .addCase(createAttachment.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ///report master store section///
            //all report master list
            .addCase(getReportMasterList.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(getReportMasterList.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.reportMaster_List = action.payload;
            })
            .addCase(getReportMasterList.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })
            //report master create
            .addCase(reportMasterCreate.pending, (state, action) => {
                state.status = STATUSES.LOADING;
                state.error = ""

            })
            .addCase(reportMasterCreate.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_reportMaster_Create`;
                state.reportMaster_Create = action.payload;
                state.error = ""
            })
            .addCase(reportMasterCreate.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            //report master edit
            .addCase(reportMasterEdit.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(reportMasterEdit.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.reportMaster_Edit = action.payload;
            })
            .addCase(reportMasterEdit.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            //report master Update
            .addCase(reportMasterUpdate.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(reportMasterUpdate.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_reportMaster_update`;
                state.reportMaster_update = action.payload;
            })
            .addCase(reportMasterUpdate.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })


            //get notification list
            .addCase(getNotificationList.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(getNotificationList.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_notificationList`;
                state.notificationList = action.payload;
            })
            .addCase(getNotificationList.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            // ces section api call 
            //all questionSet list
            .addCase(getQuestionSetList.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(getQuestionSetList.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                let newArray = [];
                let newArray2 = []
                let result = action.payload;
                let res = result && result.data && result.data.rows
                let count = result && result.data && result.data.count
                res && res.length > 0 && res.map(e => {
                    return newArray.push({
                        id: e.id,
                        set_name: e.set_name,
                        maximum_time: e.maximum_time,
                        no_of_question: e.no_of_question,
                        set_key: e.set_key,
                        technology_id: e.technology_id,
                        technology: e.technology && e.technology.name,
                        level_id: e.level_id,
                        level: e.level && e.level.level_name,
                        created_by: e.created_by,
                        created_on: e.created_on,
                        updated_by: e.updated_by,
                        updated_on: e.updated_on,
                        status: e.status
                    })
                })
                if (newArray.length > 0) {
                    newArray2 = { "data": { "rows": newArray, "count": count } }
                }
                state.questionset_List = newArray2 && newArray2.data ? newArray2 : action.payload;
            })
            .addCase(getQuestionSetList.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            /// get filterd questionlevel list
            .addCase(getQuestionLevelList.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(getQuestionLevelList.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.questionlevel_List = action.payload;
            })
            .addCase(getQuestionLevelList.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            /// get filterd technology list
            .addCase(getTechnologyList.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(getTechnologyList.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.technology_List = action.payload;
            })
            .addCase(getTechnologyList.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            /// get filterd department list by industry ID list
            .addCase(getDepartmentListByIndustryId.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(getDepartmentListByIndustryId.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.department_list_by_industryId = action.payload;
            })
            .addCase(getDepartmentListByIndustryId.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ///get Technology List By Industry Department Id

            .addCase(getTechnologyListByIndustryDepartmentId.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(getTechnologyListByIndustryDepartmentId.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.technology_list_by_department_industryId = action.payload;
            })
            .addCase(getTechnologyListByIndustryDepartmentId.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ///create candidate

            .addCase(candidateCreate.pending, (state, action) => {
                state.status = STATUSES.LOADING;
                state.candidateCreateError = ""
            })
            .addCase(candidateCreate.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_createCandidate`;
                state.createCandidate = action.payload;
                state.candidateCreateError = ""
            })
            .addCase(candidateCreate.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.candidateCreateError = action.error
                state.createCandidate = []
                state.error = action.error
            })

            //get candidateExamScheduleCreate
            .addCase(createCandidateExamSchedule.pending, (state, action) => {
                state.candidateStatus = STATUSES.LOADING;
            })
            .addCase(createCandidateExamSchedule.fulfilled, (state, action) => {
                state.candidateStatus = `${STATUSES.SUCCESS}_candidateExamScheduleCreate`;
                state.candidateExamScheduleCreate = action.payload;
                state.candidateError = ""

            })
            .addCase(createCandidateExamSchedule.rejected, (state, action) => {
                state.candidateStatus = STATUSES.FAILURE;
                state.candidateError = action.error
                state.candidateExamScheduleCreate = []

            })

            //get manage availability list
            .addCase(getMangageAvaList.pending, (state, action) => {
                state.mangageAvalStatus = STATUSES.LOADING;
            })
            .addCase(getMangageAvaList.fulfilled, (state, action) => {
                state.mangageAvalStatus = `${STATUSES.SUCCESS}_mangageAvalList`;
                state.mangageAvalList = action.payload;
                state.mangageAvalError = ""

            })
            .addCase(getMangageAvaList.rejected, (state, action) => {
                state.mangageAvalStatus = STATUSES.FAILURE;
                state.mangageAvalError = action.error
                state.mangageAvalList = []

            })

            /// get filterd batch list
            .addCase(getBatchList.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(getBatchList.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.batch_List = action.payload;
            })
            .addCase(getBatchList.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            /// get filterd batch  technolgy list
            .addCase(getBatchTechnologyList.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(getBatchTechnologyList.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.batch_technology_filter_List = action.payload;
            })
            .addCase(getBatchTechnologyList.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            //////////preferred Location store section/////////
            ///all location  list
            .addCase(getAllLocationList.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(getAllLocationList.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.location_AllList = action.payload;
                state.location_option_List = !action.payload.data ? [] : action.payload.data.rows.map((e) => ({ ...e, label: e.city_name, value: e.id }));
            })
            .addCase(getAllLocationList.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            /// get filterd location list
            .addCase(getLocationList.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(getLocationList.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.location_List = action.payload;

            })
            .addCase(getLocationList.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })


            ///location create
            .addCase(locationCreate.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(locationCreate.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_location_Create`;
                state.location_Create = action.payload;
            })
            .addCase(locationCreate.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ///location edit
            .addCase(locationEdit.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(locationEdit.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.location_Edit = action.payload;
            })
            .addCase(locationEdit.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })


            ///location Update
            .addCase(locationUpdate.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(locationUpdate.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_location_Update`;
                state.location_Update = action.payload;
            })
            .addCase(locationUpdate.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ///location archive
            .addCase(locationArchive.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(locationArchive.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_location_Archive`;
                state.location_Archive = action.payload;
            })
            .addCase(locationArchive.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })


            //---------------------///country store///--------------------//

            ///all country  list
            .addCase(getAllCountryList.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(getAllCountryList.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.country_AllList = action.payload;
            })
            .addCase(getAllCountryList.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            /// get filterd country list
            .addCase(getCountryList.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(getCountryList.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.country_List = action.payload;
            })
            .addCase(getCountryList.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ///country create
            .addCase(countryCreate.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(countryCreate.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_country_Create`;
                state.country_Create = action.payload;
            })
            .addCase(countryCreate.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ///country edit
            .addCase(countryEdit.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(countryEdit.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.country_Edit = action.payload;
            })
            .addCase(countryEdit.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ///country update
            .addCase(countryUpdate.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(countryUpdate.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_country_Update`;
                state.country_Update = action.payload;
            })
            .addCase(countryUpdate.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            //country archive
            .addCase(countryArchive.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(countryArchive.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_country_Archive`;
                state.country_Archive = action.payload;
            })
            .addCase(countryArchive.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })


            //get ces question type pe list
            .addCase(getQuestionTypePEList.pending, (state, action) => {
                state.questionTypePEStatus = STATUSES.LOADING;
            })
            .addCase(getQuestionTypePEList.fulfilled, (state, action) => {
                state.questionTypePEStatus = `${STATUSES.SUCCESS}_questionTypePEList`;
                state.questionTypePEList = action.payload;
                state.questionTypePEError = ""

            })
            .addCase(getQuestionTypePEList.rejected, (state, action) => {
                state.questionTypePEStatus = STATUSES.FAILURE;
                state.questionTypePEError = action.error
                state.questionTypePEList = []

            })


            //get ces question type list
            .addCase(getCESQuestionTypeList.pending, (state, action) => {
                state.cesQuestionTypeStatus = STATUSES.LOADING;
            })
            .addCase(getCESQuestionTypeList.fulfilled, (state, action) => {
                state.cesQuestionTypeStatus = `${STATUSES.SUCCESS}_cesQuestionTypeList`;
                state.cesQuestionTypeList = action.payload;
                state.cesQuestionTypeError = ""

            })
            .addCase(getCESQuestionTypeList.rejected, (state, action) => {
                state.cesQuestionTypeStatus = STATUSES.FAILURE;
                state.questionTypePEError = action.error
                state.cesQuestionTypeList = []

            })

            //////////state store section/////////


            ///all state  list
            .addCase(getAllStateList.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(getAllStateList.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.state_AllList = action.payload;
            })
            .addCase(getAllStateList.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })


            //---------------------///vacancy store///--------------------//

            ///all vacancy  list
            .addCase(getAllVacancyList.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(getAllVacancyList.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.vacancy_AllList = action.payload;
            })
            .addCase(getAllVacancyList.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            /// get filterd vacancy list
            .addCase(getVacancyList.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(getVacancyList.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.vacancy_List = action.payload;
            })
            .addCase(getVacancyList.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ///vacancy create
            .addCase(vacancyCreate.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(vacancyCreate.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_vacancy_Create`;
                state.vacancy_Create = action.payload;
            })
            .addCase(vacancyCreate.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ///vacancy edit
            .addCase(vacancyEdit.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(vacancyEdit.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.vacancy_Edit = action.payload;
            })
            .addCase(vacancyEdit.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ///vacancy Update
            .addCase(vacancyUpdate.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(vacancyUpdate.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_vacancy_Update`;
                state.vacancy_Update = action.payload;
            })
            .addCase(vacancyUpdate.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ///vacancy archive
            .addCase(vacancyArchive.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(vacancyArchive.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_vacancy_Archive`;
                state.vacancy_Archive = action.payload;
            })
            .addCase(vacancyArchive.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            /// get filterd state list
            .addCase(getStateList.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(getStateList.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.state_List = action.payload;
            })
            .addCase(getStateList.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ///state create
            .addCase(stateCreate.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(stateCreate.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_state_Create`;
                state.state_Create = action.payload;
            })
            .addCase(stateCreate.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ///state edit
            .addCase(stateEdit.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(stateEdit.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.state_Edit = action.payload;
            })
            .addCase(stateEdit.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ///state Update
            .addCase(stateUpdate.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(stateUpdate.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_state_Update`;
                state.state_Update = action.payload;
            })
            .addCase(stateUpdate.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ///state archive
            .addCase(stateArchive.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(stateArchive.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_state_Archive`;
                state.state_Archive = action.payload;
            })
            .addCase(stateArchive.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            //////////hiring request store section/////////
            ///all hiring request  list
            .addCase(getAllHiringRequestList.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(getAllHiringRequestList.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.hiring_request_AllList = action.payload;
            })
            .addCase(getAllHiringRequestList.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })
            /// get filterd hiring request list
            .addCase(getHiringRequestList.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(getHiringRequestList.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.hiring_request_List = action.payload;
            })
            .addCase(getHiringRequestList.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })
            ///hiring request create
            .addCase(hiringRequestCreate.pending, (state, action) => {
                state.status = STATUSES.LOADING;
                state.error = ""
            })
            .addCase(hiringRequestCreate.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_hiring_request_Create`;
                state.hiring_request_Create = action.payload;
                state.error = ""
            })
            .addCase(hiringRequestCreate.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ///hiring request edit
            .addCase(hiringRequestEdit.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(hiringRequestEdit.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.hiring_request_Edit = action.payload;
                state.view_data = action.payload;

            })
            .addCase(hiringRequestEdit.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ///hiring request Update
            .addCase(hiringRequestUpdate.pending, (state, action) => {
                state.status = STATUSES.LOADING;
                state.error = ""
            })
            .addCase(hiringRequestUpdate.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_hiring_request_Update`;
                state.hiring_request_Update = action.payload;
                state.error = ""
            })
            .addCase(hiringRequestUpdate.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ///hiring request archive
            .addCase(hiringRequestArchive.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(hiringRequestArchive.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_hiring_request_Archive`;
                state.hiring_request_Archive = action.payload;
            })
            .addCase(hiringRequestArchive.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })



            //---------------------///working mode store///--------------------//

            ///all working mode  list
            .addCase(getAllWorkingModeList.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(getAllWorkingModeList.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.workingmode_AllList = action.payload;
            })
            .addCase(getAllWorkingModeList.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            /// get filterd working mode list
            .addCase(getWorkingModeList.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(getWorkingModeList.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.workingmode_List = action.payload;
            })
            .addCase(getWorkingModeList.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ///working mode create
            .addCase(WorkingModeCreate.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(WorkingModeCreate.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_workingmode_Create`;
                state.workingmode_Create = action.payload;
            })
            .addCase(WorkingModeCreate.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ///working mode edit
            .addCase(workingModeEdit.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(workingModeEdit.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.workingmode_Edit = action.payload;
            })
            .addCase(workingModeEdit.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ///working mode Update
            .addCase(WorkingModeUpdate.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(WorkingModeUpdate.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_workingmode_Update`;
                state.workingmode_Update = action.payload;
            })
            .addCase(WorkingModeUpdate.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ///working mode archive
            .addCase(WorkingModeArchive.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(WorkingModeArchive.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_workingmode_Archive`;
                state.workingmode_Archive = action.payload;
            })
            .addCase(WorkingModeArchive.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })
            //---------------------///More Specification mode store///--------------------//

            ///all More Specification  mode  list
            .addCase(getAllMoreSpecificationList.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(getAllMoreSpecificationList.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.moreSpecification_AllList = action.payload;
                state.specificationOptionList = !action.payload.data ? [] : action.payload.data.rows.map((e) => ({ ...e, label: e.specification_name, value: e.id }));
            })
            .addCase(getAllMoreSpecificationList.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            /// more specification mode list
            .addCase(getMoreSpecificationList.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(getMoreSpecificationList.fulfilled, (state, action) => {
                state.status = !action.payload.data ? STATUSES.SUCCESS : STATUSES.SUCCESS;
                state.error = !action.payload.data ? false : true;
                state.more_specification_List = action.payload;
                state.specification_count = action.payload.data.count;


            })
            .addCase(getMoreSpecificationList.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })



            ///more specification create
            .addCase(MoreSpecificationCreate.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(MoreSpecificationCreate.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_more_specification_Create`;
                state.more_specification_Create = action.payload;
            })
            .addCase(MoreSpecificationCreate.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })



            ///more specification edit
            .addCase(MoreSpecificationEdit.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(MoreSpecificationEdit.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.more_specification_Edit = action.payload;
            })
            .addCase(MoreSpecificationEdit.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })


            ///more specification Update
            .addCase(MoreSpecificationUpdate.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(MoreSpecificationUpdate.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_more_specification_Update`;
                state.more_specification_Update = action.payload;
            })
            .addCase(MoreSpecificationUpdate.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ///more specification archive
            .addCase(MoreSpecificationArchive.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(MoreSpecificationArchive.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_more_specification_Archive`;
                state.more_specification_Archive = action.payload;
            })
            .addCase(MoreSpecificationArchive.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            //---------------------///Urgency Type store///--------------------//

            ///all Urgency type list
            .addCase(getAllUrgencyList.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(getAllUrgencyList.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                let newArray = []
                let array = action.payload

                state.urgencytype_AllList = action.payload;
                if (array && array.data && array.data.rows) {
                    array.data.rows.map(e => {
                        newArray.push(
                            e
                        )
                        return newArray
                    })
                }

                // state.urgencyType_AllLists = newArray.concat([{ value: "other", label: "Other", id: "-1", status: true, urgency_type: "Other" }])
                state.urgencyType_AllLists = newArray

                // state.createNewProjectList = createProjectArray.concat([{ value: "other", label: "Other", id: "-1" }])
            })
            .addCase(getAllUrgencyList.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            /// get filterd urgency type list
            .addCase(getUrgencyList.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(getUrgencyList.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.urgencytype_List = action.payload;
            })
            .addCase(getUrgencyList.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ///Urgency type create
            .addCase(UrgencyCreate.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(UrgencyCreate.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_urgencytype_Create`;
                state.urgencytype_Create = action.payload;
            })
            .addCase(UrgencyCreate.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ///Urgency type edit
            .addCase(UrgencyEdit.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(UrgencyEdit.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.urgencytype_Edit = action.payload;
            })
            .addCase(UrgencyEdit.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ///Urgency type Update
            .addCase(UrgencyUpdate.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(UrgencyUpdate.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_urgencytype_Update`;
                state.urgencytype_Update = action.payload;
            })
            .addCase(UrgencyUpdate.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ///Urgency type archive
            .addCase(UrgencyArchive.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(UrgencyArchive.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_urgencytype_Archive`;
                state.urgencytype_Archive = action.payload;
            })
            .addCase(UrgencyArchive.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            //---------------------///core setting store///--------------------//

            ///all core settings list
            .addCase(getAllCoreSettingsList.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(getAllCoreSettingsList.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.coresettings_AllList = action.payload;
            })
            .addCase(getAllCoreSettingsList.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            /// get filterd core settings list
            .addCase(getCoreSettingsList.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(getCoreSettingsList.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.coresettings_List = action.payload;
                state.error = ""
            })
            .addCase(getCoreSettingsList.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            /// Core Settings create
            .addCase(CoreSettingsCreate.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(CoreSettingsCreate.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_coresettings_Create`;
                state.coresettings_Create = action.payload;
                state.error = ""
            })
            .addCase(CoreSettingsCreate.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ///Core Settings edit
            .addCase(CoreSettingsEdit.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(CoreSettingsEdit.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.coresettings_Edit = action.payload;
                state.error = ""
            })
            .addCase(CoreSettingsEdit.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ///Core Settings Update
            .addCase(CoreSettingsUpdate.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(CoreSettingsUpdate.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_coresettings_Update`;
                state.coresettings_Update = action.payload;
                state.error = ""
            })
            .addCase(CoreSettingsUpdate.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ///Core Settings archive
            .addCase(CoreSettingArchive.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(CoreSettingArchive.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_coresettings_Archive`;
                state.coresettings_Archive = action.payload;
                state.error = ""
            })
            .addCase(CoreSettingArchive.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })


            ///Get exam schedule ces set batch list
            .addCase(getScheduleExamBatchSetList.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(getScheduleExamBatchSetList.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_scheduleExamBatchSetList`;
                state.scheduleExamBatchSetList = action.payload;
            })
            .addCase(getScheduleExamBatchSetList.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            //hiring attach file create
            .addCase(hiringAttachFile.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(hiringAttachFile.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.attach_File = action.payload;
                state.error = "";
            })
            .addCase(hiringAttachFile.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            //hiring attach file remove
            .addCase(removeHiringAttachFile.pending, (state, action) => {
                state.status_Remove = STATUSES.LOADING;
            })
            .addCase(removeHiringAttachFile.fulfilled, (state, action) => {
                state.status_Remove = `${STATUSES.SUCCESS}_hiring_attach_remove`;
                state.hiring_attach_file_Remove = action.payload;
                state.error = "";
            })
            .addCase(removeHiringAttachFile.rejected, (state, action) => {
                state.status_Remove = STATUSES.FAILURE
                state.error = action.error
            })



            //---------------------///job location store///--------------------//

            ///all job location list
            .addCase(getAllJobLocationList.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(getAllJobLocationList.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.job_location_AllList = action.payload;
            })
            .addCase(getAllJobLocationList.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            /// get filterd job location list
            .addCase(getJobLocationList.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(getJobLocationList.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.job_location_List = action.payload;
            })
            .addCase(getJobLocationList.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ///job location create
            .addCase(JobLocationCreate.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(JobLocationCreate.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_job_location_Create`;
                state.job_location_Create = action.payload;
            })
            .addCase(JobLocationCreate.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ///job location edit
            .addCase(JobLocationEdit.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(JobLocationEdit.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.job_location_Edit = action.payload;
            })
            .addCase(JobLocationEdit.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ///job location Update
            .addCase(JobLocationUpdate.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(JobLocationUpdate.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_job_location_Update`;
                state.job_location_Update = action.payload;
            })
            .addCase(JobLocationUpdate.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ///job location archive
            .addCase(JobLocationArchive.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(JobLocationArchive.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_job_location_Archive`;
                state.job_location_Archive = action.payload;
            })
            .addCase(JobLocationArchive.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })


            //---------------------///hiring mode store///--------------------//

            ///all hiring mode list
            .addCase(getAllHiringModeList.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(getAllHiringModeList.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.hiring_mode_AllList = action.payload;
            })
            .addCase(getAllHiringModeList.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            /// get filterd hiring mode list
            .addCase(getHiringModeList.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(getHiringModeList.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.hiring_mode_List = action.payload;
            })
            .addCase(getHiringModeList.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ///hiring mode create
            .addCase(HiringModeCreate.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(HiringModeCreate.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_hiring_mode_Create`;
                state.hiring_mode_Create = action.payload;
            })
            .addCase(HiringModeCreate.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ///hiring mode edit
            .addCase(HiringModeEdit.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(HiringModeEdit.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.hiring_mode_Edit = action.payload;
            })
            .addCase(HiringModeEdit.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ///hiring mode Update
            .addCase(HiringModeUpdate.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(HiringModeUpdate.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_hiring_mode_Update`;
                state.hiring_mode_Update = action.payload;
            })
            .addCase(HiringModeUpdate.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ///hiring mode archive
            .addCase(HiringModeArchive.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(HiringModeArchive.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_hiring_mode_Archive`;
                state.hiring_mode_Archive = action.payload;
            })
            .addCase(HiringModeArchive.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            //---------------------///payment type store///--------------------//

            ///payment  list
            // .addCase(getpymenttype.pending, (state, action) => {
            //     state.status = STATUSES.LOADING;
            // })
            // .addCase(getpymenttype.fulfilled, (state, action) => {
            //     state.status = STATUSES.SUCCESS;
            //     state.country_AllList = action.payload;
            // })
            // .addCase(getAllCountryList.rejected, (state, action) => {
            //     state.status = STATUSES.FAILURE
            //     state.error = action.error
            // })

            /// get filterd country list
            .addCase(getpymenttype.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(getpymenttype.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.paymenttype_List = action.payload;
            })
            .addCase(getpymenttype.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ///country create
            .addCase(paymentypeCreat.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(paymentypeCreat.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_paymenttype_Create`;
                state.paymenttype_Create = action.payload;
            })
            .addCase(paymentypeCreat.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ///country edit
            .addCase(paymenttypeEdit.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(paymenttypeEdit.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_paymenttype_Edit`;
                state.paymenttype_Edit = action.payload;
            })
            .addCase(paymenttypeEdit.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ///country update
            .addCase(paymenttypeUpdate.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(paymenttypeUpdate.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_paymenttype_Update`;
                state.paymenttype_Update = action.payload;
            })
            .addCase(paymenttypeUpdate.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })
            //---------------------///Type of Service///--------------------//

            ///all Type of Service List
            .addCase(getAllServiceTypeList.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(getAllServiceTypeList.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.servicetype_AllList = action.payload;
            })
            .addCase(getAllServiceTypeList.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            /// get filterd Type of Service list
            .addCase(getServiceTypeList.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(getServiceTypeList.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.servicetype_List = action.payload;
                state.error = ""
            })
            .addCase(getServiceTypeList.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })
            /// Type of Service archive
            .addCase(ServiceTypeArchive.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(ServiceTypeArchive.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_servicetype_Archive`;
                state.servicetype_Archive = action.payload;
                state.error = ""
            })
            .addCase(ServiceTypeArchive.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ///Type of Service Update
            .addCase(ServiceTypeUpdate.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(ServiceTypeUpdate.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_servicetype_Update`;
                state.servicetype_Update = action.payload;
                state.error = ""
            })
            .addCase(ServiceTypeUpdate.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })
            ///Type of Service edit
            .addCase(ServiceTypeEdit.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(ServiceTypeEdit.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.servicetype_Edit = action.payload;
                state.error = ""
            })
            .addCase(ServiceTypeEdit.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })
            /// Type of Service create
            .addCase(ServiceTypeCreate.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(ServiceTypeCreate.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_servicetype_Create`;
                state.servicetype_Create = action.payload;
                state.error = ""
            })
            .addCase(ServiceTypeCreate.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            //---------------------///purchased packages store///--------------------//

            /// purchased packages list
            .addCase(getPurchesdpackage.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(getPurchesdpackage.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.purchasedPackeges_List = action.payload;
            })
            .addCase(getPurchesdpackage.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ///purchased packages create
            .addCase(purchesdpackageCreat.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(purchesdpackageCreat.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_purchasedPackeges_Create`;
                state.purchasedPackeges_Create = action.payload;
            })
            .addCase(purchesdpackageCreat.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ///purchased packages edit
            .addCase(purchesdpackageEdit.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(purchesdpackageEdit.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_purchasedPackeges_Edit`;
                state.purchasedPackeges_Edit = action.payload;
            })
            .addCase(purchesdpackageEdit.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ///purchased packages update
            .addCase(purchesdpackageUpdate.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(purchesdpackageUpdate.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_purchasedPackeges_Update`;
                state.purchasedPackeges_Update = action.payload;
            })
            .addCase(purchesdpackageUpdate.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            //---------------------///Specialisations ///--------------------//

            ///all Specialisations List
            .addCase(getAllSpecialisationsList.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(getAllSpecialisationsList.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.specialisations_AllList = action.payload;
                state.specialisations_All_List_option = !action.payload.data ? [] : action.payload.data.rows.map((e) => ({ ...e, label: e.name, value: e.id }));
            })
            .addCase(getAllSpecialisationsList.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            /// get Specialisations List list
            .addCase(getSpecialisationsList.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(getSpecialisationsList.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.specialisations_List = action.payload;
                state.specialisations_option_List = !action.payload.data ? [] : action.payload.data.rows.map((e) => ({ ...e, label: e.name, value: e.id }));
                state.error = ""
            })
            .addCase(getSpecialisationsList.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })


            ///specialisations create
            .addCase(SpecialisationsCreate.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(SpecialisationsCreate.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_specialisations_Create`;
                state.specialisations_Create = action.payload;
            })
            .addCase(SpecialisationsCreate.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ///specialisations edit
            .addCase(SpecialisationsEdit.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(SpecialisationsEdit.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.specialisations_Edit = action.payload;
            })
            .addCase(SpecialisationsEdit.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ///specialisations Update
            .addCase(SpecialisationsUpdate.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(SpecialisationsUpdate.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_specialisations_Update`;
                state.specialisations_Update = action.payload;
            })
            .addCase(SpecialisationsUpdate.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ///specialisations archive
            .addCase(SpecialisationsArchive.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(SpecialisationsArchive.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_specialisations_Archive`;
                state.specialisations_Archive = action.payload;
            })
            .addCase(SpecialisationsArchive.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            //---------------------///Currency///--------------------//

            ///all Currency List
            .addCase(getAllCurrencyList.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(getAllCurrencyList.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.currency_AllList = action.payload;
            })
            .addCase(getAllCurrencyList.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            /// get filterd Currency list
            .addCase(getCurrencyList.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(getCurrencyList.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.currency_List = action.payload;
                state.error = ""
            })
            .addCase(getCurrencyList.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })
            /// Currency archive
            .addCase(CurrencyArchive.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(CurrencyArchive.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_currency_Archive`;
                state.currency_Archive = action.payload;
                state.error = ""
            })
            .addCase(CurrencyArchive.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })
            /// Currency Update
            .addCase(CurrencyUpdate.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(CurrencyUpdate.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_currency_Update`;
                state.currency_Update = action.payload;
                state.error = ""
            })
            .addCase(CurrencyUpdate.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })
            /// Currency edit
            .addCase(CurrencyEdit.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(CurrencyEdit.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.currency_Edit = action.payload;
                state.error = ""
            })
            .addCase(CurrencyEdit.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })
            /// Type of Currency create
            .addCase(CurrencyCreate.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(CurrencyCreate.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_currency_Create`;
                state.currency_Create = action.payload;
                state.error = ""
            })
            .addCase(CurrencyCreate.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            //---------------------///Industry - Department store///--------------------//

            ///all Industry - Department
            .addCase(getAllIndustryDepartmentList.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(getAllIndustryDepartmentList.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.industry_department_AllList = action.payload;
            })
            .addCase(getAllIndustryDepartmentList.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            /// get filterd Industry - Department
            .addCase(getIndustryDepartmentList.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(getIndustryDepartmentList.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.industry_department_List = action.payload;
                state.industryDeprt_List = action.payload
                let newArray = [];
                let result = action.payload;
                let res = result && result.data && result.data.rows
                // state.department_List = !action.payload.data.rows ? [] : action.payload.data.rows.map((e) => {
                // eslint-disable-next-line
                res.map((e) => {
                    e.department.map((items) => {
                        return newArray.push({

                            id: items.id,
                            label: items.name,
                            value: items.id,
                            name: "department",


                        })
                    })
                });
                state.department_List = newArray
            })

            .addCase(getIndustryDepartmentList.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ///Industry - Department create
            .addCase(IndustryDepartmentCreate.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(IndustryDepartmentCreate.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_industry_department_Create`;
                state.industry_department_Create = action.payload;
            })
            .addCase(IndustryDepartmentCreate.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ///Industry - Department edit
            .addCase(IndustryDepartmentEdit.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(IndustryDepartmentEdit.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.industry_department_Edit = action.payload;
            })
            .addCase(IndustryDepartmentEdit.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ///Industry - Department Update
            .addCase(IndustryDepartmentUpdate.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(IndustryDepartmentUpdate.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_industry_department_Update`;
                state.industry_department_Update = action.payload;
            })
            .addCase(IndustryDepartmentUpdate.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ///Industry - Department archive
            .addCase(IndustryDepartmentArchive.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(IndustryDepartmentArchive.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_industry_department_Archive`;
                state.industry_department_Archive = action.payload;
            })
            .addCase(IndustryDepartmentArchive.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            //---------------------///Screening Master///--------------------//

            ///all Screening Master List
            .addCase(getAllScreeningMasterList.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(getAllScreeningMasterList.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.screeningmaster_AllList = action.payload;
            })
            .addCase(getAllScreeningMasterList.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })
            /// get filterd Screening Master list
            .addCase(getScreeningMasterList.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(getScreeningMasterList.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.screeningmaster_List = action.payload;
                state.error = ""
            })
            .addCase(getScreeningMasterList.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })
            /// Screening Master archive
            .addCase(ScreeningMasterArchive.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(ScreeningMasterArchive.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_screeningmaster_Archive`;
                state.screeningmaster_Archive = action.payload;
                state.error = ""
            })
            .addCase(ScreeningMasterArchive.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })
            ///Screening Master Update
            .addCase(ScreeningMasterUpdate.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(ScreeningMasterUpdate.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_screeningmaster_Update`;
                state.screeningmaster_Update = action.payload;
                state.error = ""
            })
            .addCase(ScreeningMasterUpdate.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })
            ///Screening Master edit
            .addCase(ScreeningMasterEdit.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(ScreeningMasterEdit.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.screeningmaster_Edit = action.payload;
                state.error = ""
            })
            .addCase(ScreeningMasterEdit.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })
            /// Screening Master create
            .addCase(ScreeningMasterCreate.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(ScreeningMasterCreate.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_screeningmaster_Create`;
                state.screeningmaster_Create = action.payload;
                state.error = ""
            })
            .addCase(ScreeningMasterCreate.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            //---------------------///Client crud store///--------------------//

            /// Client crud list
            .addCase(getClientcrud.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(getClientcrud.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.clientCrud_List = action.payload;
            })
            .addCase(getClientcrud.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })
            //---------------------/// mapping IndusDepSpecializationSkill store///--------------------//

            /// IndusDepSpecializationSkill list
            .addCase(getIndsDepSpecSkill.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(getIndsDepSpecSkill.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.indsDepSpecSkill_List = action.payload;
            })
            .addCase(getIndsDepSpecSkill.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ///Client crud create
            .addCase(ClientcrudCreat.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(ClientcrudCreat.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_clientCrud_creat`;
                state.clientCrud_creat = action.payload;
            })
            .addCase(ClientcrudCreat.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })
            ///IndusDepSpecializationSkill create
            .addCase(indsDepSpecSkillCreat.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(indsDepSpecSkillCreat.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_indsDepSpecSkill_Create`;
                state.indsDepSpecSkill_create = action.payload;
            })
            .addCase(indsDepSpecSkillCreat.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ///Client crud edit
            .addCase(ClientcrudEdit.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(ClientcrudEdit.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_clientCrud_Edit`;
                state.clientCrud_Edit = action.payload;
            })
            .addCase(ClientcrudEdit.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })
            ///IndusDepSpecializationSkill edit
            .addCase(indsDepSpecSkillEdit.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(indsDepSpecSkillEdit.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_indsDepSpecSkill_Edit`;
                state.indsDepSpecSkill_Edit = action.payload;
            })
            .addCase(indsDepSpecSkillEdit.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ///Client crud update
            .addCase(ClientcrudUpdate.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(ClientcrudUpdate.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_clientCrud_Update`;
                state.clientCrud_Update = action.payload;
            })
            .addCase(ClientcrudUpdate.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ///IndusDepSpecializationSkill update
            .addCase(indsDepSpecSkillUpdate.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(indsDepSpecSkillUpdate.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_indsDepSpecSkill_Update`;
                state.indsDepSpecSkill_Update = action.payload;
            })
            .addCase(indsDepSpecSkillUpdate.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            // // industrydeplist

            // .addCase(getIndustryDepartmentListData.pending, (state, action) => {
            //     state.status = STATUSES.LOADING;
            // })
            // .addCase(getIndustryDepartmentListData.fulfilled, (state, action) => {
            //     state.status = STATUSES.SUCCESS;
            //     state.industryDeprt_List = action.payload;
            // })
            // .addCase(getIndustryDepartmentListData.rejected, (state, action) => {
            //     state.status = STATUSES.FAILURE
            //     state.error = action.error
            // })


            .addCase(getIndusDepSpecListData.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(getIndusDepSpecListData.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.industryDepSpec_List_data = action.payload;
                state.industryDepSpeci_List = action.payload;
            })
            .addCase(getIndusDepSpecListData.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            /// get filterd Industry - Department
            // .addCase(getIndustryDepartmentList.pending, (state, action) => {
            //     state.status = STATUSES.LOADING;
            // })
            // .addCase(getIndustryDepartmentList.fulfilled, (state, action) => {
            //     state.status = STATUSES.SUCCESS;
            //     state.industry_department_List = action.payload;
            // })
            // .addCase(getIndustryDepartmentList.rejected, (state, action) => {

            // indus dep spec

            // .addCase(getIndusDepSpecListData.pending, (state, action) => {
            //     state.status = STATUSES.LOADING;
            // })
            // .addCase(getIndusDepSpecListData.fulfilled, (state, action) => {
            //     state.status = STATUSES.SUCCESS;
            //     state.industryDepSpeci_List = action.payload;
            // })
            // .addCase(getIndusDepSpecListData.rejected, (state, action) => {
            //     state.status = STATUSES.FAILURE
            //     state.error = action.error
            // })
            //------------Industry-Department-Specialisation---------//

            /// IndustryDepartmentSpecialisation all list
            .addCase(getAllIndustryDepSpecList.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(getAllIndustryDepSpecList.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.industryDepartmentSpecialisation_AllList = action.payload;
            })
            .addCase(getAllIndustryDepSpecList.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            /// Industry-Department-Specialisation list
            .addCase(getIndustryDepSpecList.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(getIndustryDepSpecList.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.industryDepartmentSpecialisation_List = action.payload;
            })
            .addCase(getIndustryDepSpecList.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })



            ///Industry-Department-Specialisation create
            .addCase(IndustryDepSpecCreate.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(IndustryDepSpecCreate.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_industryDepartmentSpecialisation_Create`;
                state.industryDepartmentSpecialisation_Create = action.payload;
            })
            .addCase(IndustryDepSpecCreate.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })



            ///Industry-Department-Specialisation edit
            .addCase(IndustryDepSpecEdit.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(IndustryDepSpecEdit.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_industryDepartmentSpecialisation_Edit`;
                state.industryDepartmentSpecialisation_Edit = action.payload;
            })
            .addCase(IndustryDepSpecEdit.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })


            ///Industry-Department-Specialisation Update
            .addCase(IndustryDepSpecUpdate.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(IndustryDepSpecUpdate.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_industryDepartmentSpecialisation_Update`;
                state.industryDepartmentSpecialisation_Update = action.payload;
            })
            .addCase(IndustryDepSpecUpdate.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ///Industry-Department-Specialisation archive
            .addCase(IndustryDepSpecArchive.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(IndustryDepSpecArchive.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_industryDepartmentSpecialisation_Archive`;
                state.industryDepartmentSpecialisation_Archive = action.payload;
            })
            .addCase(IndustryDepSpecArchive.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            // interviwerfeecback list
            /// IndusDepSpecializationSkill list
            .addCase(getInterviewerFeedbackList.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(getInterviewerFeedbackList.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.interviewerFeedbackList = action.payload;
            })
            .addCase(getInterviewerFeedbackList.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })
            ///all Type of contract list
            .addCase(getAllContractTypeList.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(getAllContractTypeList.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.contracttype_AllList = action.payload;
            })
            .addCase(getAllContractTypeList.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })
            /// get filterd contract type list
            .addCase(getContractTypeList.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(getContractTypeList.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.contracttype_List = action.payload;
            })
            .addCase(getContractTypeList.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })
            ///contract typecreate
            .addCase(ContractTypeCreate.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(ContractTypeCreate.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_contracttype_Create`;
                state.contracttype_Create = action.payload;
            })
            .addCase(ContractTypeCreate.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ///contract type edit
            .addCase(ContractTypeEdit.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(ContractTypeEdit.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.contracttype_Edit = action.payload;
            })
            .addCase(ContractTypeEdit.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ///contract type Update
            .addCase(ContractTypeUpdate.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(ContractTypeUpdate.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_contracttype_Update`;
                state.contracttype_Update = action.payload;
            })
            .addCase(ContractTypeUpdate.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ///contract type archive
            .addCase(ContractTypeArchive.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(ContractTypeArchive.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_contracttype_Archive`;
                state.contracttype_Archive = action.payload;
            })
            .addCase(ContractTypeArchive.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            /// get Resume Source list
            .addCase(getresumeSource.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(getresumeSource.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.resume_source_List = action.payload;
            })
            .addCase(getresumeSource.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ///Resume Source create
            .addCase(resumeSourceCreat.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(resumeSourceCreat.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_resume_source_create`;
                state.resume_source_create = action.payload;
            })
            .addCase(resumeSourceCreat.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ///Resume Source edit
            .addCase(resumeSourceEdit.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(resumeSourceEdit.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_resume_source_Edit`;
                state.resume_source_Edit = action.payload;
            })
            .addCase(resumeSourceEdit.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ///Resume Source update
            .addCase(resumeSourceUpdate.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(resumeSourceUpdate.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_resume_source_Update`;
                state.resume_source_Update = action.payload;
            })
            .addCase(resumeSourceUpdate.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            /// get filterd Resume Format list
            .addCase(getresumeformat.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(getresumeformat.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.resumeformat_type_List = action.payload;
            })
            .addCase(getresumeformat.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ///Resume Format create
            .addCase(resumeformatCreat.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(resumeformatCreat.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_resumeformat_type_creat`;
                state.resumeformat_type_creat = action.payload;
            })
            .addCase(resumeformatCreat.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ///Resume Format edit
            .addCase(resumeformatEdit.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(resumeformatEdit.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_resumeformat_type_Edit`;
                state.resumeformat_type_Edit = action.payload;
            })
            .addCase(resumeformatEdit.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ///Resume Format update
            .addCase(resumeformatUpdate.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(resumeformatUpdate.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_resumeformat_type_Update`;
                state.resumeformat_type_Update = action.payload;
            })
            .addCase(resumeformatUpdate.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            /// get filterd industry list
            .addCase(getIndustryListCES.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(getIndustryListCES.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.industry_List_Ces = action.payload;
            })
            .addCase(getIndustryListCES.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })


            /// get filterd industry list
            .addCase(getDepartmentListCES.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(getDepartmentListCES.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.department_List_Ces = action.payload;
            })
            .addCase(getDepartmentListCES.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })


    },
});

export default masterSlice.reducer;