import React, { useEffect } from "react";
import "./UcActionComponent.scss";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { Link } from "react-router-dom";
import ArchiveIcon from "@mui/icons-material/Archive";
import { Sync, InsertLink } from "@mui/icons-material";
import TaskIcon from '@mui/icons-material/Task';
import AddBoxRoundedIcon from '@mui/icons-material/AddBoxRounded';

function UcActionComponent(props) {

  const { REACT_APP_ENV } = process.env;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open1 = Boolean(anchorEl);
  const handleClickdrop = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClosedrop = () => {
    setAnchorEl(null);
  };

  const nothing = () => {
    //no action will perform
  }


  useEffect(() => {
    const handleScroll = () => {
      setAnchorEl(null);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
      if(props.resfilterQuery){
        console.log(props.resfilterQuery);
        localStorage.setItem(`${REACT_APP_ENV}_resourcefilter`,JSON.stringify(props.resfilterQuery))
      }
    };
  }, []);




  return (
    <div className="action-all-buttons">
      <IconButton

        onClick={handleClickdrop}
        size="small"
        aria-controls={open1 ? 'action-dropdown' : undefined}
        aria-haspopup="true"
        aria-expanded={open1 ? 'true' : undefined}
      >
        <MoreVertIcon fontSize="small" />

      </IconButton>
      <Menu
        anchorEl={anchorEl}
        className="MuiMenu-list-dropdown2"
        id="action-dropdown"
        open={open1}
        onClose={handleClosedrop}
        onClick={handleClosedrop}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,

            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {props.viewLinkUrl &&
          <MenuItem>
            <Link
              to={props.viewLinkUrl}
              state={props.moduleName === "resource" ? { resourceid: props.rowid }
                : props.moduleName === "task" ? { taskId: props.rowid }
                  : props.moduleName === "resource-credential" ? { id: props.rowid }
                    : props.moduleName === "attendance-report" ? { resourceid: props.rowid }
                      : ''}
              style={{ textDecoration: "none" }}>
              <div className="viewButton"><RemoveRedEyeIcon /> View </div>
            </Link>
          </MenuItem>
        }
        {props.viewButton && props.addMoreData &&
          <MenuItem onClick={() => { props.viewButton(props.addMoreData) }}>
            <div className="viewButton"><RemoveRedEyeIcon /> View </div>
          </MenuItem>
        }
        {/* {props.editLinkUrl &&
          <MenuItem>
            {props.addMoreData && props.addMoreData.jira_issue_id && props.addMoreData.jira_issue_id > 0 ?
              <div className="linkButton">
                <a
                  target="_blank"
                  rel="noreferrer" href={`https://unicodesystems.atlassian.net/browse/${props.addMoreData.ticket_jira_key}`}  ><InsertLink />Link</a>
              </div>
              :
              <Link
                to={props.editLinkUrl}
                state={props.moduleName === "resource" ? { resourceid: props.rowid } : props.moduleName === "task" ? { taskId: props.rowid } : ''}
                style={{ textDecoration: "none" }}>
                <div className="editButton"><EditIcon /> Edit </div>
              </Link>
            }

          </MenuItem>
        } */}
        {props.editLinkUrl && props.addMoreData && props.addMoreData.jira_issue_id &&
          props.addMoreData.jira_issue_id > 0 &&
          <MenuItem>
            <div className="linkButton">
              <a
                target="_blank"
                rel="noreferrer" href={`https://unicodesystems.atlassian.net/browse/${props.addMoreData.ticket_jira_key}`}  ><InsertLink />Link</a>

            </div>
          </MenuItem>
        }

        {props.editLinkUrl &&
          <Link
            to={props.editLinkUrl}
            state={props.moduleName === "resource" ? { resourceid: props.rowid } : props.moduleName === "task" ? { taskId: props.rowid } : ''}
            style={{ textDecoration: "none" }}>
            <MenuItem>
              <div className="editButton"><EditIcon /> Edit </div>
            </MenuItem>
          </Link>
        }

         {props.cloneButton &&
          <Link
            to={props.cloneButton}
            state={props.moduleName === "resource" ? { resourceid: props.rowid } : props.moduleName === "task" ? { taskId: props.rowid } : ''}
            style={{ textDecoration: "none" }}>
            <MenuItem>
              <div className="cloneButton">
                <EditIcon /> Clone
              </div>
            </MenuItem>
          </Link>
        }


        {props.editButton !== false &&
          <MenuItem className="editButton" style={props.addMoreData !== false ? { color: '#000' } : {}} onClick={() => props.moduleName === "resource-credential" || props.moduleName === "report-master" ||
            props.moduleName === "default-setting" || props.moduleName === "third-party" ||
            props.moduleName === "qualification" || props.moduleName === "job-post" ||
            props.moduleName === "skill" || props.moduleName === "jira-status" ||
            props.moduleName === "dynamic_table" || props.moduleName === "team-regular" ||
            props.moduleName === "source-type" || props.moduleName === "resume" ||
            props.moduleName === "task" || props.moduleName === "my-task-story" ||
            props.moduleName === "interview-schedule-status" ||
            props.moduleName === "state" || props.moduleName === "location" || props.moduleName === "country" ||
            props.moduleName === "vacancy" || props.moduleName === "job-location" ||
            props.moduleName === "specialization" || props.moduleName === "hiring-mode" ||
            props.moduleName === "state" || props.moduleName === "working-mode" ||
            props.moduleName === "working-mode" || props.moduleName === "urgency-type" ||
            props.moduleName === "more-specification" ||
            props.moduleName === "service-type" || props.moduleName === "test-type" ||
            props.moduleName === "core-settings" || props.moduleName === "pyment_type" ||
            props.moduleName === "service-type" || props.moduleName === "client-resource" ||
            props.moduleName === "client" ||
            props.moduleName === "core-settings" || props.moduleName === "pyment_type" || props.moduleName === "purches_package" ||
            props.moduleName === "service-type" || props.moduleName === "currency" ||
            props.moduleName === "core-settings" || props.moduleName === "pyment_type" || props.moduleName === "contract-type" ||
            props.moduleName === "more-specification" || props.moduleName === "industry_department" || props.moduleName === "currency" ||
            props.moduleName === "test-type" ||  props.moduleName === "source_type" ||
            props.moduleName === "core-settings" || props.moduleName === "pyment_type" || props.moduleName === "pyment_type" || props.moduleName === "purches_package" ||
            props.moduleName === "service-type" || props.moduleName === "currency" ||
            props.moduleName === "core-settings" || props.moduleName === "pyment_type" || props.moduleName === "contract-type" || props.moduleName === "format_type" ||
            props.moduleName === "menubar" ? props.addMoreData && props.addMoreData.jira_id > 0 ? nothing : props.moduleName === "dynamic_table" ||
              props.moduleName === "team-regular" || props.moduleName === "menubar" || props.moduleName === "resume" || props.moduleName === "my-task-story" ? props.editButton(props.addMoreData) : props.editButton(props.rowid) : nothing}>
            {props.moduleName === "team-regular" ? <div className="editButton">Regularize</div> : <div className="editButton"><EditIcon /> Edit</div>}
          </MenuItem>
        }

        {props.isSync === true && props.addMoreData.ticket_jira_key &&
          <MenuItem onClick={() => props.moduleName === "task" ? props.syncingButton(props.addMoreData.ticket_jira_key) : nothing} className="sycnButton">
            <div className="sycnButton"><Sync />Syncing</div>
          </MenuItem>
        }

        {
          props.additionalAction && props.additionalAction.length &&

          props.additionalAction.map((action, index) =>
            <MenuItem
              key={index}
              style={{ color: props.moduleName === 'menubar' && props.extraButton === false ? "#757575" : "#0052cc" }}
              onClick={() => props.moduleName === 'menubar' ? props.extraButton === false ? nothing() : props.extraButton() : props.extraButton(props.addMoreData, action.key)}
            >
              {/* //task === task ? task : add === add ?add :task */}
              {props.additionalActionIcon === 'TaskIcon' ? <TaskIcon /> : props.additionalActionIcon === 'AddBoxRoundedIcon' ? <TaskIcon /> : <AddBoxRoundedIcon />}{action.title}
              {/* {props.additionalActionIcon === 'AddBoxRoundedIcon' && <AddBoxRoundedIcon />}{action.title} */}
            </MenuItem>
          )
        }

        {props.isDelete === true &&

          <MenuItem onClick={() => props.moduleName === "users" ||
            props.moduleName === "department" ||
            props.moduleName === "credential" ||
            props.moduleName === "roles" ? props.deleteButton(false, props.rowid) :
            props.moduleName === "resource-credential" ? props.deleteButton(false, props.rowid) :
              props.moduleName === "task" && props.addMoreData.jira_issue_id > 0 ? nothing :
                props.moduleName === "jira-status" && props.addMoreData && props.addMoreData.jira_id > 0 ? nothing :
                  props.moduleName === "resource" && props.addMoreData ? props.deleteButton(props.addMoreData) : props.deleteButton(props.rowid)}
            className="deleteButton">
            {props.deleteIconName === "ArchiveIcon" ?
              <ArchiveIcon />
              :
              <DeleteIcon />
            }
            {
              props.activeInactive == "resourceList" ? props.status === true ? "In-Active" : "Active" : props.deleteIconName === "ArchiveIcon" ? props.statusUpdate === true ? "Active/Inactive" : "Active/Inactive" : "Delete"
            }
            {/* {props.deleteIconName === "ArchiveIcon" ? props.statusUpdate === true ? "Active/Inactive" : "Active/Inactive" : "Delete"} */}
          </MenuItem>
        }

      </Menu>
    </div>

  )
}
export default UcActionComponent