import Navbar from "../../components/navbar/Navbar"
import Sidebar from "../../components/sidebar/Sidebar"
import "./task.scss"
import { ToastContainer } from "react-toastify";
import { TablePagination, Tooltip } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getMyJobList, allIssuetype, allResource, getSprintList, jireStatus, defaultTaskColumn } from "../../redux/features/taskSlice";
import { errorHandling } from "../../redux/features/authLogoutSlice";
import { STATUSES } from "../../redux/common-status/constant";
import LoaderForm from "../../components/common-function/LoaderForm";
import UcActionComponent from "../../components/common-function/UcActionComponent";
import TablePaginationActions from "../../components/TablePaginationActions";
import LocalStorageDataService from "../../components/common-function/GetLocalstorage";
import { DataGrid, getGridSingleSelectOperators } from "@mui/x-data-grid";
import moment from "moment";
import Filter from "../../components/Filter";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CustomNoRowsOverlay from "../../components/CustomEmptyOverlay";
import { RefreshButton } from "../../components/common-function/RefreshButton";
import { DefaultFilterColumns } from "../../components/common-function/DefaultFilterColumns";

const default_task_columns = LocalStorageDataService.get_default_column()
const user = LocalStorageDataService.userDetail();
const domain_data = LocalStorageDataService.get_domain_data()
const tmsRole = user && user?.tms_role && user?.tms_role?.role_key;


const MyJob = () => {
    let my_job_list_column = DefaultFilterColumns.MY_JOB_LIST_COLUMNS
    const roleWiseFilter = `{"filter":{"resource_id":{"value":${user.id},"operation": "eq"}}, "order_by":{"column":"-id"}, "skip":0, "limit":25} `
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { defaultLoaderForMyjoList, error, status, myJobList, allIssueList, resource_list, jireStatusList, sprintList, defaultTaskColumns } = useSelector(state => state.task)
    const [currentPage, setCurrentPage] = useState(0);
    const [filterQuery, setFilterQuery] = useState(roleWiseFilter)
    const [pageSize, setPageSize] = useState(25);
    const [limit, setLimit] = useState(25);

    const [filterIssueTypeList, setFilterIssueTypeList] = useState([]);
    const [filterResourceList, setFilterResourceList] = useState([]);
    const [filterJiraStatusList, setFilterJiraStatusList] = useState([]);
    const [filterSprintList, setFilterSprintList] = useState([]);
    const [options, setOptions] = useState({})
    const [defaultValue, setDefaultValue] = useState({})
    const [fieldName, setFieldName] = useState("")
    const [columnVisibilityModel, setColumnVisibilityModel] = useState(default_task_columns && default_task_columns.my_job_column ? default_task_columns.my_job_column : {
        is_jira_issue: false,
        status: false,
        priority: false,
        story_point: false,
        sprint: false,
        ticket_jira_key: false,
        created_on: false,
        updated_on: false
    });



    if (filterIssueTypeList && filterIssueTypeList.length === 0 && status === `${STATUSES.SUCCESS}_allIssueList` && allIssueList && allIssueList.data) {
        let array = []
        allIssueList.data && allIssueList.data.rows.map(e => { return array.push({ value: e.id, label: e.issue_name }) })
        array.length > 0 && setFilterIssueTypeList(array)
        array.length > 0 && setOptions({ ...options, issue_type: array })
    }

    if (filterResourceList && filterResourceList.length === 0 && status === `${STATUSES.SUCCESS}_resource_list` && resource_list && resource_list.data) {
        let array = []
        resource_list.data && resource_list.data.rows.map(e => { return array.push({ value: e.id, label: e.name + "(" + e.email + ")" }) })
        array.length > 0 && setFilterResourceList(array)
        array.length > 0 && setOptions({ ...options, reported_by: array, assignee: array })
    }

    if (filterJiraStatusList && status === `${STATUSES.SUCCESS}_jireStatusList` && filterJiraStatusList.length === 0 && jireStatusList && jireStatusList.length > 0) {
        let array = []
        jireStatusList && jireStatusList.map(e => { return array.push({ value: e.id, label: e.name }) })
        array.length > 0 && setFilterJiraStatusList(array)
        array.length > 0 && setOptions({ ...options, status: array })
    }

    if (filterSprintList && filterSprintList.length === 0 && sprintList && sprintList.data) {
        let array = []
        sprintList.data && sprintList.data.rows.map(e => { return array.push({ value: e.id, label: e.sprint_name }) })
        array.length > 0 && setFilterSprintList(array)
        array.length > 0 && setOptions({ ...options, sprint: array })
    }

    useEffect(() => {
        //dispatch(getTaskList(roleWiseFilter))
        dispatch(allIssuetype())
        dispatch(allResource())
        dispatch(jireStatus())
        dispatch(getSprintList())
        dispatch(getMyJobList(roleWiseFilter))

        if (status === STATUSES.FAILURE && error) {
            errorHandling(error, navigate)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const setKeyForList = (obj) => {
        let data;
        if (default_task_columns) {
            data = { 'my_job_column': obj, 'reported_task_column': default_task_columns.reported_task_column, 'my_task_column': default_task_columns.my_task_column, 'task_column': default_task_columns.task_column }
        } else {
            data = { 'my_job_column': obj }
        }
        if (defaultTaskColumns.reported_task_column) {
            data['reported_task_column'] = defaultTaskColumns.reported_task_column
        }
        if (defaultTaskColumns.my_task_column) {
            data['my_task_column'] = defaultTaskColumns.my_task_column
        }
        if (defaultTaskColumns.task_column) {
            data['task_column'] = defaultTaskColumns.task_column
        }
        dispatch(defaultTaskColumn(data))
    }


    //custom pagination
    const handlePagination = (data) => {
        setCurrentPage(data)
        let newFilterData = JSON.parse(filterQuery);
        newFilterData['skip'] = data * pageSize
        newFilterData['limit'] = limit
        setLimit(limit)
        setFilterQuery(JSON.stringify(newFilterData))
        dispatch(getMyJobList(JSON.stringify(newFilterData)))
    }

    const handlePageSizeChange = (newPageSize) => {
        setPageSize(newPageSize.target.value)
        setCurrentPage(0)
        setLimit(newPageSize.target.value)
        let newFilterData = JSON.parse(filterQuery);
        newFilterData['skip'] = 0
        newFilterData['limit'] = newPageSize.target.value
        setFilterQuery(JSON.stringify(newFilterData))
        dispatch(getMyJobList(JSON.stringify(newFilterData)))
    }





    function CustomPagination() {
        return (
            <TablePagination
                component="div"
                count={(myJobList && myJobList.data && myJobList.data.count && myJobList.data.count) || 0}
                page={currentPage}
                rowsPerPage={pageSize}
                onPageChange={() => setCurrentPage(pageSize)}
                onRowsPerPageChange={handlePageSizeChange}
                ActionsComponent={(subProps) =>
                    <TablePaginationActions {...subProps}
                        handlePagination={handlePagination}
                    />}
            />
        );
    }
    const actionColumn = [
        {
            field: "issue_type", headerName: "", width: 50, type: 'singleSelect', filterable: false, valueOptions: filterIssueTypeList,
            renderCell: (params) => {
                let title = ""
                if (domain_data === true || tmsRole?.match('agent')) {
                    if (params.row.issue_type_name === 'Epic') {
                        title = "Jobs"
                    } else if (params.row.issue_type_name === 'Story') {
                        title = "Resumes"
                    } else {
                        title = params.row.issue_type_name
                    }
                } else {
                    title = params.row.issue_type_name
                }
                return (
                    <div style={{ cursor: 'pointer' }} onClick={() => params.row.issue_type_name === 'Epic' ? navigate(`/epic-task/${params.row.id}`) : params.row.issue_type_name === 'Story' ? "" : navigate(`/task/edit/${params.row.id}`)} className="cellWithStatus">
                        {params.row.issue_type && params.row.issue_type.icon_url ?
                            <Tooltip title={params.row.issue_type.issue_name ? params.row.issue_type.issue_name : params.row.issue_type_name}>
                                <img src={params.row.issue_type.icon_url} alt="" />
                            </Tooltip>
                            :
                            params.row.issue_type_icon ?
                                <Tooltip title={title}>
                                    <img src={params.row.issue_type_icon} alt="" />
                                </Tooltip>
                                :
                                <div></div>
                        }
                        {/* {"  "}{params.row.issue_type && params.row.issue_type.issue_name ? params.row.issue_type.issue_name : params.row.issue_type_name} */}
                    </div>
                );
            }
        },
        {
            field: "ticket_key", headerName: "RTMS Key", width: 200,
            renderCell: (params) => {
                return (
                    <div style={{ cursor: 'pointer' }} onClick={() => params.row.issue_type_name === 'Epic' ? navigate(`/epic-task/${params.row.id}`) : params.row.issue_type_name === 'Story' ? "" : navigate(`/task/edit/${params.row.id}`)} className="cellWithStatus">
                        {params.row.ticket_key}
                    </div>
                )
            }
        },
        {
            field: "summary", headerName: "summary", width: 300,
            renderCell: (params) => {
                return (
                    <div style={{ cursor: 'pointer' }} onClick={() => params.row.issue_type_name === 'Epic' ? navigate(`/epic-task/${params.row.id}`) : params.row.issue_type_name === 'Story' ? "" : navigate(`/task/edit/${params.row.id}`)} className="cellWithStatus">
                        {params.row.summary}
                    </div>
                )
            }
        },
        {
            field: "assignee", headerName: "Assignee", type: 'singleSelect', width: 200, valueOptions: filterResourceList,
            renderCell: (params) => {
                return (
                    <div style={{ cursor: 'pointer' }} className="cellWithStatus" onClick={() => navigate(`/profile/${params.row.assignee}`)}>
                        <Tooltip title="View Profile">
                            <p>
                                {params.row.assignee && params.row.assignee.name ? params.row.assignee.name : params.row.assignee_name}
                            </p>
                        </Tooltip>
                    </div>
                );
            }
        },
        {
            field: "status", headerName: "Status", type: 'singleSelect', width: 200, valueOptions: filterJiraStatusList,
            renderCell: (params) => {
                return (
                    <div className="cellWithStatus">
                        {params.row.status_name}
                    </div>
                );
            }
        },
        { field: "priority", headerName: "Priority", width: 150 },
        { field: "story_point", headerName: "Story Point", width: 300 },
        {
            field: "sprint", headerName: "Sprint", width: 300, type: 'singleSelect', valueOptions: filterSprintList,
            renderCell: (params) => {
                return (
                    <div className="cellWithStatus">
                        {params.row.sprint_name}
                    </div>
                );
            }
        },
        {
            field: "ticket_jira_key", headerName: "JIRA Key", width: 200,
            renderCell: (params) => {
                return (
                    <div style={{ color: 'blue', textUnderlinePosition: 'auto' }} className="cellWithStatus">
                        {params.row.jira_issue_id > 0 ?
                            <a
                                target="_blank"
                                rel="noreferrer" href={`https://unicodesystems.atlassian.net/browse/${params.row.ticket_jira_key}`}  >{params.row.ticket_jira_key}</a>
                            :
                            params.row.ticket_jira_key
                        }
                    </div>
                );
            }
        },
        {
            field: "created_on", headerName: "Created Date", width: 200, type: "date", filterable: false,
            renderCell: (params) => {
                return (
                    <div className="cellWithStatus">
                        {params.row.created_on ? moment(params.row.created_on).format('YYYY-MM-DD') : ""}
                    </div>
                );
            }
        },
        {
            field: "updated_on", headerName: "Updated Date", width: 200, type: "date", filterable: false,
            renderCell: (params) => {
                return (
                    <div className="cellWithStatus">
                        {params.row.updated_on ? moment(params.row.updated_on).format('YYYY-MM-DD') : ""}
                    </div>
                );
            }
        },
        {
            field: "action",
            headerName: "Action",
            width: "120",
            hideable: false,
            filterable: false,
            sortable: false,
            renderCell: (params) => {
                return (
                    <div className="cellAction">
                        <UcActionComponent
                            moduleName="task"
                            rowid={params.row.id}
                            addMoreData={{ jira_issue_id: params.row.jira_issue_id, ticket_jira_key: params.row.ticket_jira_key }}
                            editLinkUrl={params.row.issue_type_name === 'Epic' ? `/epic-task/${params.row.id}` : params.row.issue_type_name === 'Story' ? "" : `/task/edit/${params.row.id}`}
                            viewLinkUrl={params.row.issue_type_name === 'Epic' ? `/epic-task/${params.row.id}` : params.row.issue_type_name === 'Story' ? "" : `/task/edit/${params.row.id}`}
                            isDelete={false}
                            editButton={false}
                            viewButton={false}
                            //deleteButton={handleClickArchive}
                            //syncingButton={handleTaskByIdSync}
                            //isSync={isCredential === false ? false : true}
                            deleteIconName="ArchiveIcon"
                        />
                    </div>
                );
            },
        },
    ];


    const onFilterChange = (filterModel) => {
        if (filterModel.items && filterModel.items.length > 0 && filterModel.items[0].columnField) {
            setFieldName(filterModel.items[0].columnField)
        }
        if (filterModel.items && filterModel.items.length > 0 && filterModel.items[0].columnField && filterModel.items[0].value) {
            let operatorValue = filterModel.items[0].operatorValue === "contains" ? "icontains" :
                filterModel.items[0].operatorValue === "startsWith" ? 'istartswith' :
                    filterModel.items[0].operatorValue === "is" ? 'eq' :
                        filterModel.items[0].operatorValue === "onOrAfter" ? 'date_gte' :
                            filterModel.items[0].operatorValue === "after" ? 'date_gt' :
                                filterModel.items[0].operatorValue === "before" ? 'date_lt' :
                                    filterModel.items[0].operatorValue === "onOrBefore" ? 'date_lte' :
                                        filterModel.items[0].operatorValue === "endsWith" ? 'iendswith' : filterModel.items[0].operatorValue;
            const filterData = `{"filter":{"jira_rtms_issue__${[filterModel.items[0].columnField]}":{"value":"${filterModel.items[0].value}","operation": "${operatorValue}"},"resource_id":{"value":${user.id},"operation": "eq"}},"order_by":{"column":"-id"},"skip":0, "limit":${limit}}&columns=${my_job_list_column}`
            setFilterQuery(filterData)
            setCurrentPage(0)
            dispatch(getMyJobList(filterData))
        }
        if (filterModel.items && filterModel.items.length === 0) {
            setCurrentPage(0)
            setDefaultValue({})
            // setPageSize(25)
            setFilterQuery(roleWiseFilter)
            dispatch(getMyJobList(roleWiseFilter))
        }
    }

    // Custom filter
    function CustomFilterInputValue(props) {
        const { item, applyValue } = props;
        const handleFilterChange = (evt) => {
            if (evt) {
                setDefaultValue(evt)
                const { value } = evt;
                applyValue({ ...item, value: value });
            }
        };
        return (
            <Filter options={options[fieldName]} onChange={handleFilterChange} defaultValue={defaultValue} />
        );
    }
    const columns = useMemo(() =>
        actionColumn.map((col) => {
            if (col.field === "ticket_key" || col.field === "ticket_jira_key" || col.field === "summary" || col.field === "story_point" || col.field === "created_on" || col.field === "updated_on" || col.field === "is_jira_issue" || col.field === 'priority') {
                return col;
            }
            return {
                ...col,
                filterOperators: getGridSingleSelectOperators()
                    .filter((operator) => operator.value !== "isAnyOf" && operator.value !== "not")
                    .map((operator) => ({
                        ...operator,
                        InputComponent: operator.InputComponent
                            ? CustomFilterInputValue
                            : undefined
                    }))
            };
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }), [actionColumn]
    );

    return (
        <div className="task">
            <Sidebar />
            <div className="taskContainer">
                <Navbar />

                <ToastContainer />
                <div className="datatable">
                    <div className="top-headings">
                        <h3>My Job List</h3>
                        <div>
                            <RefreshButton api={getMyJobList} filter={filterQuery} />

                            <Tooltip title="Back">
                                <button onClick={() => navigate(-1)} className="btn btn-add-new"><ArrowBackIcon /></button>
                            </Tooltip>

                        </div>
                    </div>
                    {(defaultLoaderForMyjoList === true) && <LoaderForm />}
                    <DataGrid
                        columnVisibilityModel={defaultTaskColumns && defaultTaskColumns.my_job_column ? defaultTaskColumns.my_job_column : columnVisibilityModel}
                        onColumnVisibilityModelChange={(newModel) => { setColumnVisibilityModel(newModel); setKeyForList(newModel) }
                        }
                        className="datagrid"
                        rows={myJobList && myJobList.data && myJobList.data.rows ? myJobList.data.rows : []}
                        columns={columns}
                        pageSize={pageSize}
                        style={{ fontSize: 16 }}
                        onFilterModelChange={onFilterChange}
                        components={{
                            Pagination: CustomPagination,
                            NoRowsOverlay: CustomNoRowsOverlay
                        }}
                        disableSelectionOnClick
                    />
                </div>
            </div>
        </div >
    )
}

export default MyJob